import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import { useEffect } from 'react';
import Layout from './Layout';

const LoanPayoutView = () => {
  const [loading, setLoading] = useState(false);
  const [accordion, setActiveAccordion] = useState(-1);
  const [loanTypes, setLoanTypes] = useState([]);
  const [BankList, setBankList] = useState([]);
  const [searchV, setSearchV] = useState({
    customerMobile: '',
    startDate: '',
    endDate: '',
    loanType: '',
    bankId: '',
    searchLoanLead: [],
    searchError: false,
  });
  const [loan, setLoan] = useState([]);

  const {
    customerMobile,
    loanType,
    startDate,
    endDate,
    searchLoanLead,
    searchError,
    bankId,
  } = searchV;

  var today = new Date();
  var priorDate = new Date(new Date().setDate(today.getDate() - 30));

  useEffect(() => {
    const fetchdata = async () => {
      setLoading(true);
      try {
        await fetch(
          `${process.env.REACT_APP_API_URL}/getAllLoanPayoutDetails`,
          {
            method: 'GET',
            headers: {
              Accept: 'application/json',
            },
          }
        )
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            setLoan(data);
            setLoading(false);
          });
      } catch (error) {
        setLoading(false);
      }
    };
    fetchdata();

    const fetchDataLoanType = async () => {
      try {
        await fetch(`${process.env.REACT_APP_API_URL}/getLoanTypes`, {
          method: 'GET',
          headers: {
            Accept: 'application/json',
          },
        })
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            setLoanTypes(data);
          });
      } catch (error) {
        console.log(error);
      }
    };
    fetchDataLoanType();

    const fetchBankdata = async () => {
      await fetch(`${process.env.REACT_APP_API_URL}/getBankList`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
        },
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          setBankList(data);
        });
    };
    fetchBankdata();
  }, []);

  const toggleAccordion = (index) => {
    if (index === accordion) {
      setActiveAccordion(-1);
      return;
    }
    setActiveAccordion(index);
  };

  const showLoading = () =>
    loading && (
      <div className="alert ">
        <h2>Loading...</h2>
      </div>
    );

  const searchHandleChange = (name) => (e) => {
    setSearchV({
      ...searchV,
      [name]: e.target.value,
      searchLoanLead: '',
    });
  };

  const searchSubmit = (e) => {
    e.preventDefault();

    const fetchdata = async () => {
      setLoading(true);
      const startD = startDate ? moment(startDate).format('DD-MM-YYYY') : '';
      const endD = endDate ? moment(endDate).format('DD-MM-YYYY') : '';
      const customerMobileNumber = customerMobile
        ? `customerMobileNumber=${customerMobile}&`
        : '';
      const loanTypeValue = loanType ? `loanType=${loanType}&` : '';
      try {
        await fetch(
          `${process.env.REACT_APP_API_URL}/getAllLoanPayoutDetails?${customerMobileNumber}${loanTypeValue}processedBankId=${bankId}&endDate=${endD}&startDate=${startD}`,
          {
            method: 'GET',
            headers: {
              Accept: 'application/json',
            },
          }
        )
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            if (data.length === 0) {
              setSearchV({ ...searchV, searchError: true });
              setLoading(false);
            } else {
              setSearchV({
                ...searchV,
                searchLoanLead: data,
                searchError: false,
              });
              setLoading(false);
              console.log(data);
            }
          });
      } catch (error) {
        setSearchV({
          ...searchV,
          searchError: true,
        });
        setLoading(false);
      }
    };
    fetchdata();
  };

  const tableData = (getData) => {
    return (
      <table className="table table-borderless table-responsive-xl text-center mb-0">
        <thead>
          <tr>
            <th scope="col" className="current-assigned-th pr-2">
              Loan Application ID
            </th>
            <th scope="col" className="current-assigned-th pr-2">
              Loan Type
            </th>
            <th scope="col" className="current-assigned-th pr-2">
              Bank Name
            </th>
            <th scope="col" className="current-assigned-th pr-2">
              Actual Payout
            </th>
            <th scope="col" className="current-assigned-th pr-2">
              Agent Total Payout
            </th>
            <th scope="col" className="current-assigned-th pr-2">
              Net Margin
            </th>
            <th scope="col" className="current-assigned-th pr-2">
              View Details
            </th>
          </tr>
        </thead>
        <tbody>
          {showLoading()}
          {getData.length > 0 ? (
            getData.map((data, index) => {
              return (
                <React.Fragment key={index}>
                  <br />
                  <tr className="bg-f7f7f7">
                    <td>{data.loanApplicationId}</td>
                    <td>{data.loanType}</td>
                    <td>{data.loanProviderBankName}</td>
                    <td>{data.bankPayoutAmount}</td>
                    <td>{data.totalAgentPayoutAmount}</td>
                    <td>{data.netMargin}</td>
                    <td>
                      <button
                        className="btn-success btn btn-sm rounded-0 px-2"
                        onClick={() => toggleAccordion(index)}
                      >
                        {accordion === index ? (
                          <>
                            {' '}
                            <i className="fa-solid fa-minus"></i>
                          </>
                        ) : (
                          <>
                            {' '}
                            <i className="fa-solid fa-plus"></i>
                          </>
                        )}
                      </button>
                    </td>
                  </tr>
                  <tr
                    className={
                      accordion === index
                        ? 'active-accordion'
                        : 'inactive-accordion'
                    }
                  >
                    <td colspan="6">
                      <div>
                        <table className="table table-responsive-xl table-borderless">
                          <thead>
                            <tr>
                              <th scope="col">Broker Code</th>
                              <th scope="col">Broker Name</th>
                              <th scope="col">Broker Level </th>
                              <th scope="col">Pay Percentage</th>
                              <th scope="col">TDS</th>
                              <th scope="col">Net Pay</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data.loanPayoutDetailsViewList !== undefined &&
                              data.loanPayoutDetailsViewList.map(
                                (record, i) => {
                                  return (
                                    <React.Fragment key={i}>
                                      <tr>
                                        <td>{record.brokerCode}</td>
                                        <td>{record.brokerName}</td>
                                        <td>{record.brokerLevel}</td>
                                        <td>{record.brokerPayPercenatge}</td>

                                        <td>{record.brokerTds}</td>

                                        <td>{record.brokerNetPay}</td>
                                      </tr>
                                    </React.Fragment>
                                  );
                                }
                              )}
                          </tbody>
                        </table>
                      </div>
                    </td>
                  </tr>
                </React.Fragment>
              );
            })
          ) : (
            <>
              {searchError && (
                <>
                  <tr>
                    {' '}
                    <td colSpan="100">
                      <p className="text-center  m-0 text-danger">
                        No Result Found
                      </p>
                    </td>
                  </tr>
                </>
              )}
            </>
          )}
        </tbody>
      </table>
    );
  };
  return (
    <>
      <Layout>
        <div className="inner_banner text-center">
          <div className="container">Loan Payout View</div>
        </div>
        <div className="container-fluid">
          <ToastContainer />

          <form onSubmit={searchSubmit} className="mt-4">
            <div className="row justify-content-center">
              <div className="col-lg">
                <label for="inputEmail4">customer Mobile Number</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder=" Mobile Number"
                  onChange={searchHandleChange('customerMobile')}
                />
              </div>

              <div className="col-lg">
                <label for="inputEmail4">Loan Type</label>
                <select
                  id="inputState"
                  className="form-control text-capitalize"
                  onChange={searchHandleChange('loanType')}
                >
                  <option value="" selected>
                    Select Loan Type{' '}
                  </option>

                  {loanTypes.map((data, i) => (
                    <option key={i} value={data.description}>
                      {data.description}
                    </option>
                  ))}
                </select>
              </div>

              <div className="col-lg">
                <label for="inputEmail4">Bank Name</label>
                <select
                  className="form-control text-capitalize"
                  onChange={searchHandleChange('bankId')}
                >
                  <option value="" selected>
                    Select
                  </option>

                  {BankList.map((bank, i) => (
                    <option key={i} value={bank.bankId}>
                      {bank.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="col-lg">
                <label for="inputEmail4">Start Date</label>
                <input
                  type="date"
                  className="form-control"
                  onChange={searchHandleChange('startDate')}
                  value={startDate}
                />
              </div>
              <div className="col-lg">
                <label for="inputEmail4">End Date</label>
                <input
                  type="date"
                  className="form-control"
                  onChange={searchHandleChange('endDate')}
                  value={endDate}
                />
              </div>

              <div className="col-lg-1 pt-sm-2">
                <button type="submit" className="btn btn-primary mt-4">
                  Search
                </button>
              </div>
            </div>
          </form>
        </div>
        <hr />
        {searchLoanLead.length > 0 || searchError === true ? (
          <>
            <button
              className="btn btn-light mb-4"
              onClick={() =>
                setSearchV({
                  ...searchV,
                  searchLoanLead: [],
                  agentId: '',
                  searchError: false,
                  startDate: '',
                  endDate: '',
                })
              }
            >
              <i class="fa-solid fa-arrow-left"></i> Go Back
            </button>
          </>
        ) : (
          <></>
        )}
        {searchLoanLead.length > 0 || searchError === true
          ? tableData(searchLoanLead)
          : tableData(loan)}{' '}
        <br />
      </Layout>
    </>
  );
};

export default LoanPayoutView;

import React, { useEffect, useState } from 'react';
import Layout from '../components/Layout';
import ReactPaginate from 'react-paginate';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const ReviewLeadAndAssign = ({ history }) => {
  const [accordion, setActiveAccordion] = useState(-1);
  const [loanlead, setLoanlead] = useState([]);
  const [BankList, setBankList] = useState([]);
  const [EmployeeList, setEmployeeList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [showRowsData, setShowRowsData] = useState(15);
  const [Bank, setBank] = useState([]);
  const [Emp, setEmp] = useState([]);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');
  const [searchV, setSearchV] = useState({
    customerName: '',
    loanType: '',
    startDate: '',
    endDate: '',
    customerPan: '',
    searchLoanLead: [],
  });

  const {
    customerName,
    loanType,
    startDate,
    endDate,
    customerPan,
    searchLoanLead,
  } = searchV;

  const LoanDataPerPage = showRowsData;

  const pagesVisited = pageNumber * LoanDataPerPage;

  const showRows = (e) => {
    setShowRowsData(parseInt(e.target.value));
  };

  const MySwal = withReactContent(Swal);

  const toggleAccordion = (index) => {
    if (index === accordion) {
      setActiveAccordion(-1);
      return;
    }
    setActiveAccordion(index);
  };

  const assign = (data) => {
    console.log(data);
    if (data.Bank.length !== 0 || data.Emp.length !== 0) {
      var BankId;
      var Bankname;
      var assignedToEmpId;
      var assignedToEmployeeName;
      if (data.Bank.length > 0) {
        BankId = BankList.filter((item) => item.name === data.Bank.at(-1).bank);

        BankId = BankId[0].bankId;

        BankId =
          data.Bank.at(-1).leadId === data.lead.loanLead.leadId
            ? BankId
            : data.lead.loanLead.bankId;

        Bankname = BankList.filter(
          (item) => item.name === data.Bank.at(-1).bank
        );
        Bankname = Bankname[0].name;
        console.log(Bankname);
      } else {
        BankId = data.lead.loanLead.bankId;
        Bankname = data.lead.loanLead.bankName;
        console.log(BankId);
      }
      var EmployeeId;
      if (data.Emp.length > 0) {
        EmployeeId = EmployeeList.filter(
          (item) => item.employeeId == data.Emp.at(-1).Emp
        );

        EmployeeId = EmployeeId[0];

        EmployeeId =
          data.Emp.at(-1).leadId === data.lead.loanLead.leadId && EmployeeId;

        console.log(EmployeeId);
        assignedToEmployeeName = EmployeeId.name;
        assignedToEmpId = EmployeeId.employeeId;
      } else {
        EmployeeId = data.lead.loanLead;
        assignedToEmployeeName = EmployeeId.assignedToEmployeeName;
        assignedToEmpId = EmployeeId.assignedToEmpId;
        console.log(EmployeeId);
      }

      // Post Data

      const LoginEmp = JSON.parse(localStorage.getItem('loggedInEmployee'));

      fetch(`${process.env.REACT_APP_API_URL}/loans/updateLoanLead`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          assignByEmpId: LoginEmp.employeeId,
          assignByEmpName: LoginEmp.name,
          assignToEmpId: assignedToEmpId,
          assignToEmpName: assignedToEmployeeName,
          bankId: BankId,
          bankName: Bankname,
          loanLeadId: data.lead.loanLead.leadId,
          status: data.lead.loanLead.status,
        }),
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          MySwal.fire({
            text: 'Assigned',
          });
          setSuccess(true);
        });

      //
    } else {
      MySwal.fire({
        text: `Please select Employee`,
      });
    }
  };

  const changeEmpDetails = (data) => {
    var newData = [...Emp];
    newData.push(data);
    setEmp(newData);
  };

  const loanDataList = loanlead
    .slice(pagesVisited, pagesVisited + LoanDataPerPage)
    .map((lead, i) => {
      return (
        <tbody key={i}>
          <tr id={`review${lead.loanLead.leadId}`}>
            <td>
              {' '}
              <Link
                to={`/loan-under-proccess?customerMobileNumber=${lead.loanLead.customerMobileNumber}`}
              >
                {lead.loanLead.virtualLeadId}
              </Link>
            </td>

            <td name="customerName">{lead.loanLead.customerName}</td>
            <td>{lead.loanLead.customerMobileNumber}</td>
            <td>{lead.loanLead.loanType}</td>
            <td>{lead.loanLead.loanAmountAppliedFor}</td>
            <td>{lead.loanLead.loanTenure}</td>

            {/* <td>{lead.enableReassignButton}</td> */}

            <td>
              <select
                className="form-control rounded-0"
                onChange={(e) =>
                  changeEmpDetails({
                    Emp: e.target.value,
                    leadId: lead.loanLead.leadId,
                  })
                }
                disabled={lead.enableReassignButton === 'No' ? true : false}
              >
                {lead.loanLead.assignedToEmployeeName && (
                  <option
                    value={lead.loanLead.assignedToEmployeeName}
                    className="d-none"
                    selected
                  >
                    {lead.loanLead.assignedToEmployeeName}
                  </option>
                )}

                {lead.loanLead.assignedToEmployeeName === null && (
                  <option value="" selected>
                    Select
                  </option>
                )}

                {EmployeeList.map((emp, i) => (
                  <option key={i} value={emp.employeeId}>
                    {emp.name}
                  </option>
                ))}
              </select>
            </td>
            <td>
              {lead.loanLead.status === null ? (
                <>
                  <button
                    className="btn btn-success rounded-0"
                    onClick={() => assign({ lead, Bank, Emp })}
                  >
                    Assign
                  </button>
                </>
              ) : (
                <>
                  <div className="d-flex flex-row bd-highlight ">
                    <div className="p-2 bd-highlight">
                      {' '}
                      <button
                        className="btn btn-warning rounded-0"
                        onClick={() => assign({ lead, Bank, Emp })}
                        disabled={
                          lead.enableReassignButton === 'No' ? true : false
                        }
                      >
                        Reassign
                      </button>
                    </div>
                    {lead.enableResetStatusButton === 'No' ? (
                      ' '
                    ) : (
                      // <div className="p-2 bd-highlight">
                      //   {' '}
                      //   <button
                      //     className="btn btn-danger rounded-0"
                      //     onClick={() => reset({ lead, Bank, Emp })}
                      //   >
                      //     Reset
                      //   </button>
                      // </div>
                      <></>
                    )}
                  </div>
                </>
              )}
            </td>
            <td>
              <button
                className="btn-success btn btn-sm rounded-0 px-2"
                onClick={() => toggleAccordion(i)}
              >
                {accordion === i ? (
                  <>
                    {' '}
                    <i className="fa-solid fa-minus"></i>
                  </>
                ) : (
                  <>
                    {' '}
                    {/* <i className="fa-solid fa-plus"></i> */}
                    View Lead Status
                  </>
                )}
              </button>
            </td>
          </tr>
          <tr
            className={
              accordion === i ? 'active-accordion' : 'inactive-accordion'
            }
          >
            <td colspan="100%">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th className="p-1">Bank Name:</th>
                    <th className="p-1">Lead Status:</th>
                  </tr>
                </thead>
                <tbody>
                  {lead.loanLead.submittedLeadList &&
                    lead.loanLead.submittedLeadList.map((data, i) => (
                      <tr key={i}>
                        <td>{data.bankName}</td>
                        <td>{data.submittedLeadStatus}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      );
    });

  const pageCount = Math.ceil(loanlead.length / LoanDataPerPage);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  useEffect(() => {
    const jwt = JSON.parse(localStorage.getItem('jwt'));
    if (localStorage.getItem('userType') === null) {
      history.push('/');
    } else if (localStorage.getItem('userType') === 'Employee') {
      const fetchdata = async () => {
        setLoading(true);
        await fetch(`${process.env.REACT_APP_API_URL}/allLeads`, {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${jwt}`,
          },
        })
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            setLoanlead(data);
            setLoading(false);
          });
      };
      fetchdata();

      const fetchBankdata = async () => {
        await fetch(`${process.env.REACT_APP_API_URL}/getBankList`, {
          method: 'GET',
          headers: {
            Accept: 'application/json',
          },
        })
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            setBankList(data);
          });
      };
      fetchBankdata();

      const fetchEmpList = async () => {
        await fetch(
          `${process.env.REACT_APP_API_URL}/getEmployeeListByRole?role=FIELDEXECUTIVE`,
          {
            method: 'GET',
            headers: {
              Accept: 'application/json',
            },
          }
        )
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            setEmployeeList(data);
          });
      };
      fetchEmpList();
    } else {
      history.push('/');
    }
    setSuccess('');
    setError('');
  }, [success || error]);

  const searchHandleChange = (name) => (e) => {
    setSearchV({
      ...searchV,
      [name]: e.target.value,
      searchLoanLead: '',
    });
  };

  const searchSubmit = (e) => {
    e.preventDefault();
    const jwt = JSON.parse(localStorage.getItem('jwt'));
    const fetchdata = async () => {
      setLoading(true);
      const startD = moment(startDate).format('DD-MM-YYYY');
      const endD = moment(endDate).format('DD-MM-YYYY');

      await fetch(
        `${process.env.REACT_APP_API_URL}/leadsByFilter?customerName=${customerName}&endDate=${endD}&loanType=${loanType}&startDate=${startD}&customerPan=${customerPan}`,
        {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${jwt}`,
          },
        }
      )
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          setSearchV({
            ...searchV,
            searchLoanLead: [data],
          });
          setLoading(false);
        });
    };
    fetchdata();
  };

  const loanDataListBySearch = () => {
    return (
      searchLoanLead.length > 0 &&
      searchLoanLead[0].map((lead, i) => {
        return (
          <tbody key={i}>
            <tr>
              <td>
                <Link
                  to={`/loan-under-proccess?customerMobileNumber=${lead.loanLead.customerMobileNumber}`}
                >
                  {lead.loanLead.leadId}
                </Link>
              </td>

              <td name="customerName">{lead.loanLead.customerName}</td>
              <td>{lead.loanLead.customerMobileNumber}</td>
              <td>{lead.loanLead.loanType}</td>
              <td>{lead.loanLead.loanAmountAppliedFor}</td>
              <td>{lead.loanLead.loanTenure}</td>

              <td>
                <select
                  className="form-control rounded-0"
                  onChange={(e) => setEmp(e.target.value)}
                  disabled={lead.enableReassignButton === 'No' ? true : false}
                >
                  {lead.loanLead.assignedToEmployeeName && (
                    <option
                      value={lead.loanLead.assignedToEmployeeName}
                      className="d-none"
                      selected
                    >
                      {lead.loanLead.assignedToEmployeeName}
                    </option>
                  )}

                  {lead.loanLead.assignedToEmployeeName === null && (
                    <option value="" selected>
                      Select
                    </option>
                  )}

                  {EmployeeList.map((emp, i) => (
                    <option key={i} value={emp.employeeId}>
                      {emp.name}
                    </option>
                  ))}
                </select>
              </td>
              <td>
                {lead.loanLead.status === null ? (
                  <>
                    <button
                      className="btn btn-success rounded-0"
                      onClick={() => assign({ lead, Bank, Emp })}
                    >
                      Assign
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      className="btn btn-warning rounded-0"
                      onClick={() => assign({ lead, Bank, Emp })}
                      disabled={
                        lead.enableReassignButton === 'No' ? true : false
                      }
                    >
                      Reassign
                    </button>
                    {lead.enableResetStatusButton === 'No' ? (
                      ' '
                    ) : (
                      // <button
                      //   className="btn btn-danger mt-2 rounded-0"
                      //   onClick={() => assign({ lead, Bank, Emp })}
                      // >
                      //   Reset
                      // </button>
                      <></>
                    )}
                  </>
                )}
              </td>
              <td>
                <button
                  className="btn-success btn btn-sm rounded-0 px-2"
                  onClick={() => toggleAccordion(i)}
                >
                  {accordion === i ? (
                    <>
                      {' '}
                      <i className="fa-solid fa-minus"></i>
                    </>
                  ) : (
                    <>
                      {' '}
                      {/* <i className="fa-solid fa-plus"></i> */}
                      View Lead Status
                    </>
                  )}
                </button>
              </td>
            </tr>
            <tr
              className={
                accordion === i ? 'active-accordion' : 'inactive-accordion'
              }
            >
              <td colspan="100%">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th className="p-1">Bank Name:</th>
                      <th className="p-1">Lead Status:</th>
                    </tr>
                  </thead>
                  <tbody>
                    {lead.loanLead.submittedLeadList &&
                      lead.loanLead.submittedLeadList.map((data, i) => (
                        <tr key={i}>
                          <td>{data.bankName}</td>
                          <td>{data.submittedLeadStatus}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        );
      })
    );
  };

  const showLoading = () =>
    loading && (
      <div className="alert alert-info rounded-0">
        <h2 className="text-center">Loading...</h2>
      </div>
    );

  error &&
    MySwal.fire({
      text: `${error}`,
    });

  success &&
    MySwal.fire({
      text: `${success}`,
    });

  return (
    <>
      <Layout>
        <div className="inner_banner text-center">
          <div className="container">Active and In Process Leads</div>
        </div>

        <ToastContainer />

        <div className="container-fluid ">
          <div>
            <form onSubmit={searchSubmit}>
              <div className="row justify-content-center">
                <div className="col-lg">
                  <label for="inputEmail4">Customer Name</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Customer Name"
                    onChange={searchHandleChange('customerName')}
                    value={customerName}
                  />
                </div>
                <div className="col-lg">
                  <label for="inputEmail4">Customer PAN Number</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Customer PAN Number"
                    onChange={searchHandleChange('customerPan')}
                    value={customerPan}
                  />
                </div>
                <div className="col-lg">
                  <label for="inputEmail4">Loan Type</label>
                  <select
                    id="loantype"
                    className="form-control"
                    onChange={searchHandleChange('loanType')}
                    value={loanType}
                  >
                    <option value="">Select</option>
                    <option value="PERSONALLOAN">Personal loan</option>
                    <option value="HOMELOAN">Home loan</option>
                    <option value="BUSINESSLOAN">Business loan</option>
                    <option value="PERSONALLOANEXTENSION">
                      Personal loan extension
                    </option>
                  </select>
                </div>
                <div className="col-lg">
                  <label for="inputEmail4">Start Date</label>
                  <input
                    type="date"
                    className="form-control"
                    onChange={searchHandleChange('startDate')}
                    value={startDate}
                    required
                  />
                </div>
                <div className="col-lg">
                  <label for="inputEmail4">End Date</label>
                  <input
                    type="date"
                    className="form-control"
                    onChange={searchHandleChange('endDate')}
                    value={endDate}
                    required
                  />
                </div>

                <div className="col-lg pt-sm-2">
                  <button type="submit" className="btn btn-primary mt-4">
                    Search
                  </button>
                </div>
              </div>
            </form>
          </div>

          <br />
          {searchLoanLead.length > 0 ? (
            <>
              <button
                className="btn btn-light mb-4"
                onClick={() =>
                  setSearchV({
                    ...searchV,
                    searchLoanLead: [],
                    customerName: '',
                    loanType: '',
                    startDate: '',
                    endDate: '',
                    customerPan: '',
                  })
                }
              >
                <i className="fa-solid fa-arrow-left"></i> Go Back
              </button>
            </>
          ) : (
            <>
              <p>
                Show{'  '}
                <select className=" rounded-0 px-2 py-1" onChange={showRows}>
                  <option value="15" selected>
                    15
                  </option>
                  <option value="30">30</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
                {'  '}
                Rows
              </p>
            </>
          )}
          <table className="table table-striped table-responsive table-bordered ">
            <thead className="thead-blue sticky-top text-center">
              <tr>
                <th scope="col">Lead Id</th>

                <th scope="col">Customer Name</th>
                <th scope="col">Customer Mobile Number</th>
                <th scope="col">Loan Type</th>
                <th scope="col">Loan Amount</th>
                <th scope="col">Loan Tenure</th>
                <th scope="col" className="pr-5 pl-5">
                  Assign To
                </th>
                <th scope="col">Action</th>
                <th scope="col"></th>
              </tr>
            </thead>

            {searchLoanLead.length > 0 ? (
              <>{loanDataListBySearch()}</>
            ) : (
              <>{loanDataList}</>
            )}
          </table>
          {showLoading()}
        </div>

        <br />
        {searchLoanLead.length > 0 ? (
          ''
        ) : (
          <>
            <ReactPaginate
              previousLabel={<i className="fa-solid fa-angle-left"></i>}
              nextLabel={<i className="fa-solid fa-angle-right"></i>}
              pageCount={pageCount}
              onPageChange={changePage}
              containerClassName={'paginationBttns'}
              previousLinkClassName={'previousBttn'}
              nextLinkClassName={'nextBttn'}
              disabledClassName={'paginationDisabled'}
              activeClassName={'paginationActive'}
            />
          </>
        )}
      </Layout>
    </>
  );
};

export default ReviewLeadAndAssign;

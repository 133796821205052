import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Layout from '../components/Layout';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const EditProfile = ({ history }) => {
  const [userDetails, setUserDetails] = useState({});
  const [userData, setUserData] = useState({});
  const [photo, setPhoto] = useState(null);

  const [supportdoc, setSupportdoc] = useState({
    supportfile: null,
    supportdocumentCategory: null,
    supportselectedDocumentType: null,
    supportformData: new FormData(),
    supportdocumentsAvailable: null,
    supporteditdocument: false,
    supportfileSize: 0,
  });

  const {
    supportfile,
    supportdocumentCategory,
    supportselectedDocumentType,
    supportformData,
    supportdocumentsAvailable,
    supporteditdocument,
    supportfileSize,
  } = supportdoc;

  const MySwal = withReactContent(Swal);

  useEffect(() => {
    if (localStorage.getItem('userType') === null) {
      history.push('/');
    }

    if (localStorage.getItem('userType') === 'Agent') {
      setUserDetails(JSON.parse(localStorage.getItem('loggedInAgent')));

      fetch(
        `${process.env.REACT_APP_API_URL}/agents/name/${
          JSON.parse(localStorage.getItem('loggedInAgent')).name
        }`,
        {
          method: 'GET',
          headers: {
            Accept: 'application/json',
          },
        }
      )
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          setUserData(data[0]);
        });
    } else {
      setUserDetails(JSON.parse(localStorage.getItem('loggedInEmployee')));
      fetch(
        `${process.env.REACT_APP_API_URL}/employee/findEmployee?email=${
          JSON.parse(localStorage.getItem('loggedInEmployee')).email
        }`,
        {
          method: 'GET',
          headers: {
            Accept: 'application/json',
          },
        }
      )
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          setUserData(data);
        });
    }
  }, []);

  const onChangeSupportdoc = (e) => {
    const value = e.target.files[0];
    supportformData.set('file', value);
    setPhoto(e.target.files[0]);
    setSupportdoc({
      ...supportdoc,
      supportfile: supportformData,
      supportselectedDocumentType: 'Support Document',
    });
  };

  const {
    register,
    handleSubmit,
    watch,
    trigger,
    formState: { errors },
    reset,
  } = useForm();

  const [values, setValues] = useState({
    loading: false,
    form: '',
  });

  const { loading, form } = values;

  const clickRegister = (data) => {
    // data.preventDefault();

    setValues({
      ...values,
      loading: true,
    });

    fetch(`${process.env.REACT_APP_API_URL}/agents/updateAgent`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name: data.firstname + ' ' + data.lastname,
        dob: data.dob,
        gender: data.gender,
        email: userDetails.email,
        mobile: data.phone,
        address1: data.address1,
        address2: data.address2,
        city: data.city,
        state: data.state,
        pin: data.pin,
        firstName: data.firstname,
        lastName: data.lastname,
        // password: data.password,
        userId: userDetails.userId,
        agentId: userDetails.agentId,
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        MySwal.fire({
          text: `${data.message}`,
        });

        setValues({
          ...values,
          loading: false,
          form: data.message,
        });
      })
      .catch((err) => {
        setValues({
          ...values,
          loading: false,
        });

        MySwal.fire({
          text: 'Something went wrong',
        });
      });
  };

  const uploadProfileImg = async (e) => {
    e.preventDefault();
    console.log(localStorage.getItem('userType'));
    let user =
      localStorage.getItem('userType') === 'Agent'
        ? `${
            JSON.parse(localStorage.getItem('loggedInAgent')).userId
          }?userType=Agent `
        : `${
            JSON.parse(localStorage.getItem('loggedInEmployee')).userId
          }?userType=Employee`;

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/uploadImage/${user}`,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
          },
          body: supportfile,
        }
      );
      if (response.status === 200) {
        const data = await response.json();

        MySwal.fire({
          text: `${data.message}`,
        });
      } else if (response.status === 500) {
        MySwal.fire({
          text: 'Something went wrong',
        });
      }
    } catch (error) {
      MySwal.fire({
        text: 'Something went wrong',
      });
    }
  };

  const showLoading = () =>
    loading && (
      <div className="alert alert-info">
        <h2>Loading...</h2>
      </div>
    );

  const checkIfNumber = (event) => {
    const regex = new RegExp(
      /(^\d*$)|(Backspace|Tab|Delete|ArrowLeft|ArrowRight)/
    );

    return !event.key.match(regex) && event.preventDefault();
  };

  const checkIfTextOnly = (event) => {
    const regex = new RegExp(/[a-zåäö ]/i);

    return !event.key.match(regex) && event.preventDefault();
  };

  return (
    <>
      <Layout>
        <div className="inner_banner text-center">
          <div className="container">
            <i className="fa-solid fa-pen"></i> Edit Profile
          </div>
        </div>

        <ToastContainer />

        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-lg-3">
              <div className="card p-3 shadow-sm">
                {' '}
                <form onSubmit={uploadProfileImg}>
                  <div className="text-center mb-2">
                    {photo !== null ? (
                      <img
                        alt="profile"
                        width="50"
                        height="50"
                        className="rounded-circle shadow image-fit-profile "
                        src={URL.createObjectURL(photo)}
                      />
                    ) : (
                      <img
                        src="https://img.icons8.com/external-anggara-outline-color-anggara-putra/100/000000/external-avatar-interface-anggara-outline-color-anggara-putra.png"
                        alt="..."
                        className="img-thumbnail border-0"
                      />
                    )}

                    <div className="custom-file">
                      <input
                        type="file"
                        className="custom-file-input"
                        id="validatedCustomFile"
                        onChange={onChangeSupportdoc}
                        accept="image/png, image/png, image/jpeg, image/jpg"
                      />
                      <label
                        className="custom-file-label "
                        for="validatedCustomFile"
                      >
                        Choose file...
                      </label>
                    </div>
                  </div>
                  <div className="text-center mt-3">
                    <button type="submit" className="btn btn-sm btn-success">
                      Upload new photo
                    </button>
                  </div>{' '}
                </form>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="container">{showLoading()}</div>

              <div className="register-form shadow-sm">
                {/* <form onSubmit={handleSubmit(clickRegister)}> */}

                <>
                  <form>
                    <div className="row ">
                      <div className="col">
                        <div className="form-group">
                          <label htmlFor="fname">First Name</label>
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span
                                className="input-group-text rounded-0"
                                id="fname"
                              >
                                <i className="fa-solid fa-user"></i>
                              </span>
                            </div>
                            <input
                              type="text"
                              className="form-control rounded-0"
                              id="firstname"
                              placeholder="Enter your first name"
                              aria-describedby="fname"
                              defaultValue={userData.firstName}
                              {...register('firstname', {
                                maxLength: {
                                  value: 50,
                                  message: 'Maximum 50 characters',
                                },
                              })}
                              onKeyUp={() => {
                                trigger('firstname');
                              }}
                              onKeyDown={(e) => checkIfTextOnly(e)}
                              readOnly
                            />
                          </div>
                        </div>

                        {errors.firstname && (
                          <p className="text-danger">
                            {errors.firstname.message}
                          </p>
                        )}
                      </div>
                      <div className="col">
                        <div className="form-group">
                          <label htmlFor="lname">Last Name</label>
                          <div className="input-group">
                            <div className="input-group-prepend ">
                              <span
                                className="input-group-text rounded-0"
                                id="lname"
                              >
                                <i className="fa-solid fa-user"></i>
                              </span>
                            </div>
                            <input
                              type="text"
                              className="form-control rounded-0"
                              placeholder="Enter your last name"
                              id="lastname"
                              defaultValue={userData.lastName}
                              aria-describedby="lname"
                              {...register('lastname')}
                              onKeyDown={(e) => checkIfTextOnly(e)}
                              readOnly
                            />
                          </div>
                        </div>
                        {errors.lastname && (
                          <p className="text-danger">
                            {errors.lastname.message}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="form-group">
                      <label htmlFor="date">Date Of Birth</label>
                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <span
                            className="input-group-text rounded-0"
                            id="date"
                          >
                            <i
                              className="fa fa-calendar"
                              aria-hidden="true"
                            ></i>
                          </span>
                        </div>
                        <input
                          type="date"
                          className="form-control rounded-0"
                          id="date"
                          defaultValue={userData.dob}
                          aria-describedby="date"
                          {...register('dob')}
                        />
                      </div>
                    </div>
                    {errors.dob && (
                      <p className="text-danger">{errors.dob.message}</p>
                    )}

                    <div className="form-group">
                      <label htmlFor="gender">Gender</label>
                      <div className="input-group mb-3">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="gender"
                            id="gMale"
                            value="Male"
                            {...register('gender')}
                            checked={
                              userData.gender === 'male' ||
                              userData.gender === 'Male'
                                ? true
                                : null
                            }
                          />
                          <label className="form-check-label" htmlFor="gMale">
                            male
                          </label>
                        </div>
                        <div className="form-check form-check-inline ">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="gender"
                            id="gFemale"
                            value="female"
                            {...register('gender')}
                            checked={
                              userData.gender === 'female' ||
                              userData.gender === 'Female'
                                ? true
                                : null
                            }
                          />
                          <label className="form-check-label" htmlFor="gFemale">
                            female
                          </label>
                        </div>
                      </div>
                    </div>
                    {errors.gender && (
                      <p className="text-danger">{errors.gender.message}</p>
                    )}
                    <div className="form-group">
                      <label htmlFor="email">Email Address</label>
                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <span
                            className="input-group-text rounded-0"
                            id="email"
                          >
                            <i className="fa-solid fa-at"></i>
                          </span>
                        </div>
                        <input
                          type="email"
                          className="form-control rounded-0"
                          id="email"
                          placeholder="Enter your email address"
                          aria-describedby="email"
                          defaultValue={userData.email}
                          {...register('email', {
                            pattern: {
                              value:
                                /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                              message: 'Please enter a valid email',
                            },
                          })}
                          onKeyUp={() => {
                            trigger('email');
                          }}
                          readOnly={true}
                        />
                      </div>
                    </div>
                    {errors.email && (
                      <p className="text-danger">{errors.email.message}</p>
                    )}
                    {/* <div className="form-group">
                      <label htmlFor="password">Password</label>
                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <span
                            className="input-group-text rounded-0"
                            id="password"
                          >
                            <i className="fa-solid fa-lock"></i>
                          </span>
                        </div>
                        <input
                          type="password"
                          className="form-control rounded-0"
                          id="password"
                          placeholder="Enter your password"
                          aria-describedby="password"
                          {...register('password', {
                            minLength: {
                              value: 5,
                              message: 'Password must be at least 5 characters',
                            },
                          })}
                          onKeyUp={() => {
                            trigger('password');
                          }}
                        />
                      </div>
                    </div>
                    {errors.password && (
                      <p className="text-danger">{errors.password.message}</p>
                    )} */}

                    <div className="form-group">
                      <label htmlFor="phone">Mobile Number</label>
                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <span
                            className="input-group-text rounded-0"
                            id="phone"
                          >
                            <i className="fa-solid fa-mobile"></i>
                          </span>
                        </div>
                        <input
                          type="number"
                          className="form-control rounded-0"
                          id="phone"
                          min="0"
                          defaultValue={userData.mobile}
                          placeholder="Enter your mobile number"
                          aria-describedby="phone"
                          {...register('phone', {
                            pattern: {
                              value: /^[0-9]{10}$/,
                              message: 'Please enter 10 digit phone no',
                            },
                          })}
                          onKeyUp={() => {
                            trigger('phone');
                          }}
                          onKeyDown={(e) => checkIfNumber(e)}
                        />
                      </div>
                    </div>
                    {errors.phone && (
                      <p className="text-danger">{errors.phone.message}</p>
                    )}
                    <div className="row ">
                      <div className="col">
                        <div className="form-group">
                          <label htmlFor="add1">Address 1</label>
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span
                                className="input-group-text rounded-0"
                                id="add1"
                              >
                                <i className="fa-solid fa-location-dot"></i>
                              </span>
                            </div>
                            <input
                              type="text"
                              className="form-control rounded-0"
                              id="address1"
                              placeholder="Address 1"
                              defaultValue={userData.address1}
                              aria-describedby="add1"
                              {...register('address1')}
                              onKeyUp={() => {
                                trigger('address1');
                              }}
                            />
                          </div>
                        </div>
                        {errors.address1 && (
                          <p className="text-danger mt-1">
                            {errors.address1.message}
                          </p>
                        )}
                      </div>
                      <div className="col">
                        <div className="form-group">
                          <label htmlFor="add2">Address 2</label>
                          <div className="input-group">
                            <div className="input-group-prepend ">
                              <span
                                className="input-group-text rounded-0"
                                id="add2"
                              >
                                <i className="fa-solid fa-location-dot"></i>
                              </span>
                            </div>
                            <input
                              type="text"
                              className="form-control rounded-0"
                              placeholder="Address 2"
                              defaultValue={userData.address2}
                              id="address2"
                              aria-describedby="add2"
                              {...register('address2')}
                            />
                          </div>
                          {errors.address2 && (
                            <p className="text-danger">
                              {errors.address2.message}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="row ">
                      <div className="col">
                        <div className="form-group">
                          <label htmlFor="city">City</label>
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span
                                className="input-group-text rounded-0"
                                id="city"
                              >
                                <i className="fa-solid fa-earth-asia"></i>
                              </span>
                            </div>
                            <input
                              type="text"
                              className="form-control rounded-0"
                              id="city"
                              placeholder="Enter your city"
                              defaultValue={userData.city}
                              aria-describedby="city"
                              {...register('city')}
                              onKeyUp={() => {
                                trigger('city');
                              }}
                            />
                          </div>
                        </div>
                        {errors.city && (
                          <p className="text-danger">{errors.city.message}</p>
                        )}
                      </div>
                      <div className="col">
                        <div className="form-group">
                          <label htmlFor="state">State</label>
                          <div className="input-group">
                            <div className="input-group-prepend ">
                              <span
                                className="input-group-text rounded-0"
                                id="state"
                              >
                                <i className="fa-solid fa-city"></i>
                              </span>
                            </div>
                            <input
                              type="text"
                              className="form-control rounded-0"
                              placeholder="Enter your state"
                              id="state"
                              aria-describedby="state"
                              defaultValue={userData.state}
                              {...register('state')}
                              onKeyUp={() => {
                                trigger('state');
                              }}
                            />
                          </div>
                        </div>
                        {errors.state && (
                          <p className="text-danger">{errors.state.message}</p>
                        )}
                      </div>
                    </div>

                    <div className="form-group">
                      <label htmlFor="pin">Pin Code</label>
                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <span className="input-group-text rounded-0" id="pin">
                            <i className="fa-solid fa-map-pin"></i>
                          </span>
                        </div>
                        <input
                          type="number"
                          className="form-control rounded-0"
                          id="pin"
                          placeholder="Enter your Pin Code"
                          aria-describedby="pin"
                          defaultValue={userData.pin}
                          {...register('pin', {
                            pattern: {
                              value: /^[1-9][0-9]{5}$/,
                              message: 'Pin code sould be 6 digit',
                            },
                          })}
                          onKeyUp={() => {
                            trigger('pin');
                          }}
                          onKeyDown={(e) => checkIfNumber(e)}
                        />
                      </div>
                    </div>
                    {errors.pin && (
                      <p className="text-danger">{errors.pin.message}</p>
                    )}

                    <button
                      type="submit"
                      onClick={handleSubmit(clickRegister)}
                      className="btn btn-primary rounded-0"
                    >
                      Update
                    </button>
                  </form>
                </>
              </div>
            </div>
          </div>
        </div>

        <br />
        <br />
      </Layout>
    </>
  );
};

export default EditProfile;

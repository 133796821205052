import React, { useEffect, useState } from 'react';
import CustomerDocumentsDetails from './CustomerDocumentsDetails';
import CustomerPersonalDetails from './CustomerPersonalDetails';
import moment from 'moment';
import { useHistory, useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Link } from 'react-router-dom';

const CurrentAssignedLoan = () => {
  const [allAssignedLoans, setAllAssignedLoans] = useState([]);
  const [accordion, setActiveAccordion] = useState(-1);
  const [changeStatus, setChangeStatus] = useState([]);
  const [loading, setLoading] = useState(false);
  const [submitcurrentstatus, setSubmitcurrentstatus] = useState();
  const [oldstatus, setOldstatus] = useState();
  const [validateData, setValidateData] = useState([]);
  const [Bank, setBank] = useState([]);
  const [BankList, setBankList] = useState([]);
  const [submitBank, setSubmitBank] = useState(false);

  const location = useLocation();
  const queryParameters = new URLSearchParams(location.search);

  const changeBankDetails = (data) => {
    console.log(data);
    var newData = [...Bank];
    newData.push(data);
    setBank(newData);
    setSubmitBank(false);
  };

  const [searchV, setSearchV] = useState({
    agentId: '',
    loanType: '',
    assignedByEmployeeId: '',
    assignedToEmployeeId: '',
    cMobileNum:
      queryParameters !== null
        ? queryParameters.get('customerMobileNumber')
        : '',
    cPanNumber: '',
    sStatus: '',
    searchLoanLead: [],
    searchError: false,
  });

  const {
    agentId,
    loanType,
    cMobileNum,
    cPanNumber,
    sStatus,
    searchLoanLead,
    assignedToEmployeeId,
    assignedByEmployeeId,
    searchError,
  } = searchV;

  const history = useHistory();

  const toggleAccordion = (index) => {
    if (index === accordion) {
      setActiveAccordion(-1);
      return;
    }
    setActiveAccordion(index);
  };
  const jwt = JSON.parse(localStorage.getItem('jwt'));
  useEffect(() => {
    const jwt = JSON.parse(localStorage.getItem('jwt'));

    if (localStorage.getItem('userType') === null) {
      history.push('/');
    } else if (localStorage.getItem('userType') === 'Employee') {
      const fetchdata = async () => {
        setLoading(true);
        try {
          await fetch(
            `${
              process.env.REACT_APP_API_URL
            }/findAllInProcessOrPendingLoansForOneCustomer?customerMobileNumber=${queryParameters.get(
              'customerMobileNumber'
            )}`,
            {
              method: 'GET',
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwt}`,
              },
            }
          )
            .then((res) => {
              return res.json();
            })
            .then((data) => {
              if (data.length === 0) {
                setSearchV({ ...searchV, searchError: true });
                setLoading(false);
              } else {
                setSearchV({
                  ...searchV,
                  searchLoanLead: data,
                  searchError: false,
                });
                setLoading(false);
              }
            });
        } catch (error) {
          setSearchV({
            ...searchV,
            searchError: true,
          });
          setLoading(false);
        }
      };
      queryParameters.get('customerMobileNumber') !== null && fetchdata();
      fetchBankdata();
    } else {
      history.push('/');
    }
  }, [submitcurrentstatus, validateData, submitBank]);

  const fetchBankdata = async () => {
    await fetch(`${process.env.REACT_APP_API_URL}/getBankList`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setBankList(data);
      });
  };

  const showLoading = () =>
    loading && (
      <div className="alert ">
        <h2>Loading...</h2>
      </div>
    );

  const searchHandleChange = (name) => (e) => {
    setSearchV({
      ...searchV,
      [name]: e.target.value,
      searchLoanLead: '',
    });
  };

  const searchSubmit = (e) => {
    e.preventDefault();

    const fetchdata = async () => {
      setLoading(true);
      // const startD = moment(startDate).format('DD-MM-YYYY');
      // const endD = moment(endDate).format('DD-MM-YYYY');

      try {
        await fetch(
          `${process.env.REACT_APP_API_URL}/findAllInProcessOrPendingLoansForOneCustomer?agentId=${agentId}&assignedByEmployeeId=${assignedByEmployeeId}&assignedToEmployeeId=${assignedToEmployeeId}&customerMobileNumber=${cMobileNum}&loanStatus=${sStatus}&loanType=${loanType}&customerPanNumber=${cPanNumber}`,
          {
            method: 'GET',
            headers: {
              Accept: 'application/json',
            },
          }
        )
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            if (data.length === 0) {
              setSearchV({ ...searchV, searchError: true });
              setLoading(false);
            } else {
              setSearchV({
                ...searchV,
                searchLoanLead: data,
                searchError: false,
              });
              setLoading(false);
            }
          });
      } catch (error) {
        setSearchV({
          ...searchV,
          searchError: true,
        });
        setLoading(false);
      }
    };
    fetchdata();
  };

  const onChangeStatus = (data) => {
    var selectstatus =
      changeStatus
        .filter((filterdata) => filterdata.id === data.loanInformation.id)
        .map((result) => result.value)
        .at(-1) === 'Select/Update Loan Status';

    var statestatusid = changeStatus.at(-1);

    if (selectstatus) {
      MySwal.fire({
        text: `please choose current status `,
      });

      // } else if (data.loanInformation.applicationId === null) {
      //   alert('Please complete all mandatory fields before changing the status');
      //   setOldstatus('Assigned');
      //   var newData = [...changeStatus];
      //   newData.push({ id: data.loanInformation.loanLeadId, value: 'Assigned' });
      //   setChangeStatus(newData);
    } else if (
      statestatusid == undefined ||
      data.loanInformation.id !== statestatusid.id
    ) {
      MySwal.fire({
        text: `please choose current status `,
      });
    } else {
      Swal.fire({
        text: 'Are you want to change the status?',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
      }).then((result) => {
        if (result.isConfirmed) {
          let docTypeResidence = data.loanDocumentList.filter((item) => {
            return item.documentCategory === 'Residence Proof';
          });

          let docTypeIdentityProof = data.loanDocumentList.filter((item) => {
            return item.documentCategory === 'Identity Proof';
          });

          let docTypeAdditionalIdentityProof = data.loanDocumentList.filter(
            (item) => {
              return item.documentCategory === 'Additional Identity Proof';
            }
          );

          let docTypeIncomeProof = data.loanDocumentList.filter((item) => {
            return item.documentCategory === 'Income Proof';
          });

          let docTypeBankStatmentProof = data.loanDocumentList.filter(
            (item) => {
              return item.documentCategory === 'Bank Statement';
            }
          );

          let docTypeSoaProof = data.loanDocumentList.filter((item) => {
            return item.documentCategory === 'SOA Of Past Loans';
          });

          let docTypeMobileBillProof = data.loanDocumentList.filter((item) => {
            return item.documentCategory === '3months Mobile Bill';
          });

          let documentsTypesDocumentsCompleteApp = data.loanDocumentList.filter(
            (item) => {
              return item.documentCategory === 'Completed Application Form';
            }
          );

          const submitStatus = async () => {
            try {
              const response = await fetch(
                `${process.env.REACT_APP_API_URL}/loans/verifyAndChangeLoanStatus`,
                {
                  method: 'POST',
                  headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${jwt}`,
                  },
                  body: JSON.stringify({
                    previousLoanDocumentList: [
                      {
                        applicationId: '',
                        bankStatement: '',
                        date: '',
                        documentCategory: '',
                        fileName: '',
                        idFile: '',
                        idFileType: '',
                        incomeFile: '',
                        incomeFileType: '',
                        loanDocumentId: '',
                        loanLeadId: data.loanInformation.loanLeadId,
                        loanNo: '',
                        mobileBill: '',
                        residenceFile: '',
                        residenceFileType: '',
                        selectedDocumentType: '',
                        soaFile: '',
                      },
                    ],
                    previousLoanInformation: {
                      actualRateOfInterest:
                        data.loanInformation.actualRateOfInterest,
                      agentEmailId: data.loanInformation.agentEmailId,
                      agentFirstName: data.loanInformation.agentFirstName,
                      agentId: data.loanInformation.agentId,
                      agentLastName: data.loanInformation.agentLastName,
                      agentMiddleName: data.loanInformation.agentMiddleName,
                      agentMobileNumber: data.loanInformation.agentMobileNumber,
                      agentName: data.loanInformation.agentName,
                      alternativeMobileNumber:
                        data.loanInformation.alternativeMobileNumber,
                      applicationId: null,
                      approxRateOfInterest: null,
                      assignedByEmployeeId:
                        data.loanInformation.assignedByEmployeeId,
                      assignedByEmployeeName:
                        data.loanInformation.assignedByEmployeeName,
                      assignedDate: data.loanInformation.assignedDate,
                      assignedToEmployeeId:
                        data.loanInformation.assignedToEmployeeId,
                      assignedToEmployeeName:
                        data.loanInformation.assignedToEmployeeName,
                      bankMICRCode: null,
                      countryOfBirth: null,
                      countryOfResidence: '',
                      currentResidenceType: '',
                      customerDOB: '',
                      customerFirstName: data.loanInformation.customerFirstName,
                      customerLastName: data.loanInformation.customerLastName,
                      customerMiddleName:
                        data.loanInformation.customerMiddleName,
                      customerMobileNumber:
                        data.loanInformation.customerMobileNumber,
                      customerName: data.loanInformation.customerName,
                      customerOfficialEMailId:
                        data.loanInformation.customerOfficialEMailId,
                      customerPersonalEmailId:
                        data.loanInformation.customerPersonalEmailId,
                      designation: '',
                      drivingLicense: '',
                      drivingLicenseValidityDate: '',
                      education: '',
                      existingCustomerId: '',
                      fatherName: '',
                      gstRegistrationNumber:
                        data.loanInformation.gstRegistrationNumber,
                      isGSTRegistered: data.loanInformation.isGSTRegistered,
                      kycNumber: '',
                      loanAmountAppliedFor:
                        data.loanInformation.loanAmountAppliedFor,
                      loanCategory: '',
                      loanId: null,
                      loanInterestRateType: '',
                      loanLeadFulfillmentId: null,
                      loanLeadId: data.loanInformation.loanLeadId,
                      loanPurpose: '',
                      loanTenure: data.loanInformation.loanTenure,
                      loanType: data.loanInformation.loanType,
                      maritalStatus: '',
                      mobileNumber: '',
                      motherMaidenName: '',
                      name: '',
                      nergaJobCardNumber: '',
                      noOfYearsInPresentResidenceAddress: null,
                      no_of_dependents: '',
                      noofYearsatCurrentCity: '',
                      noofYearsatPermanentResidenceAddress: '',
                      officeAddress: '',
                      officeCityOrVillage: '',
                      officeEmail: '',
                      officeLandLineNumber: '',
                      officeLandMark: '',
                      officePincode: '',
                      officeState: '',
                      officeType: '',
                      panCard: '',
                      passportNumber: '',
                      passportValidityDate: '',
                      permanentResidenceLandMark: '',
                      permanentResidencePhoneNumber: '',
                      permanentResidentialAddress: '',
                      permanentResidentialCityOrVillage: '',
                      permanentResidentialPhoneNumber: '',
                      permanentResidentialPincode: '',
                      permanentResidentialState: '',
                      placeOrCityOfBirth: '',
                      preferredCommunicationAddress: '',
                      preferredCommunicationLanguage: '',
                      presentCompanyWorkExperience: '',
                      presentResidenceLandMark: '',
                      presentResidentialAddress: '',
                      presentResidentialCityOrVillage: '',
                      presentResidentialPhoneNumber: '',
                      presentResidentialPincode: '',
                      presentResidentialState: '',
                      previousCompanyName: '',
                      processingBankId: data.loanInformation.processingBankId,
                      processingBankName:
                        data.loanInformation.processingBankName,
                      profile: data.loanInformation.profile,
                      residentialStatus: '',
                      spouseDOB: '',
                      spouseMobileNumber: '',
                      spouseName: '',
                      spouseOccupation: '',
                      status: data.loanInformation.status,
                      taxIdentificationNumber: '',
                      totalWorkExperience: '',
                      typeOfOrganization: '',
                      votedIdCard: '',
                      yearinwhichBankAccountOpenedwithBranch: '',
                    },
                    updatedByEmployeeId: JSON.parse(
                      localStorage.getItem('loggedInEmployee')
                    ).employeeId,
                    updatedByEmployeeName: JSON.parse(
                      localStorage.getItem('loggedInEmployee')
                    ).name,
                    updatedLoanDocumentList: [
                      {
                        applicationId: data.loanInformation.applicationId,
                        bankStatement:
                          docTypeBankStatmentProof[0] === undefined
                            ? ''
                            : docTypeBankStatmentProof[0].fileName,
                        date: new Date(),
                        documentCategory:
                          documentsTypesDocumentsCompleteApp[0] === undefined
                            ? ''
                            : documentsTypesDocumentsCompleteApp[0]
                                .documentCategory,
                        fileName:
                          documentsTypesDocumentsCompleteApp[0] === undefined
                            ? ''
                            : documentsTypesDocumentsCompleteApp[0].fileName,
                        idFile:
                          docTypeIdentityProof[0] === undefined
                            ? ''
                            : docTypeIdentityProof[0].fileName,
                        idFileType:
                          docTypeIdentityProof[0] === undefined
                            ? ''
                            : docTypeIdentityProof[0].selectedDocumentType,
                        incomeFile:
                          docTypeIncomeProof[0] === undefined
                            ? ''
                            : docTypeIncomeProof[0].fileName,
                        incomeFileType:
                          docTypeIncomeProof[0] === undefined
                            ? ''
                            : docTypeIncomeProof[0].selectedDocumentType,
                        loanDocumentId: '',
                        loanLeadId: data.loanInformation.loanLeadId,
                        loanNo: '',
                        mobileBill:
                          docTypeMobileBillProof[0] === undefined
                            ? ''
                            : docTypeMobileBillProof[0].fileName,
                        residenceFile:
                          docTypeResidence[0] === undefined
                            ? ''
                            : docTypeResidence[0].fileName,
                        residenceFileType:
                          docTypeResidence[0] === undefined
                            ? ''
                            : docTypeResidence[0].selectedDocumentType,
                        selectedDocumentType:
                          docTypeSoaProof[0] === undefined
                            ? ''
                            : docTypeSoaProof[0].selectedDocumentType,
                        soaFile:
                          docTypeSoaProof[0] === undefined
                            ? ''
                            : docTypeSoaProof[0].fileName,
                      },
                    ],
                    updatedLoanInformation: {
                      actualRateOfInterest:
                        data.loanInformation.actualRateOfInterest,
                      agentEmailId: data.loanInformation.agentEmailId,
                      agentFirstName: data.loanInformation.agentFirstName,
                      agentId: data.loanInformation.agentId,
                      agentLastName: data.loanInformation.agentLastName,
                      agentMiddleName: data.loanInformation.agentMiddleName,
                      agentMobileNumber: data.loanInformation.agentMobileNumber,
                      agentName: data.loanInformation.agentName,
                      alternativeMobileNumber:
                        data.loanInformation.alternativeMobileNumber,
                      applicationId: data.loanInformation.applicationId,
                      approxRateOfInterest:
                        data.loanInformation.approxRateOfInterest,
                      assignedByEmployeeId:
                        data.loanInformation.assignedByEmployeeId,
                      assignedByEmployeeName:
                        data.loanInformation.assignedByEmployeeName,
                      assignedDate: data.loanInformation.assignedDate,
                      assignedToEmployeeId:
                        data.loanInformation.assignedToEmployeeId,
                      assignedToEmployeeName:
                        data.loanInformation.assignedToEmployeeName,
                      bankMICRCode: data.loanInformation.bankMICRCode,
                      countryOfBirth: data.loanInformation.countryOfBirth,
                      countryOfResidence:
                        data.loanInformation.countryOfResidence,
                      currentResidenceType:
                        data.loanInformation.currentResidenceType,
                      customerDOB: data.loanInformation.customerDOB,
                      customerFirstName: data.loanInformation.customerFirstName,
                      customerLastName: data.loanInformation.customerLastName,
                      customerMiddleName:
                        data.loanInformation.customerMiddleName,
                      customerMobileNumber:
                        data.loanInformation.customerMobileNumber,
                      customerName: data.loanInformation.customerName,
                      customerOfficialEMailId:
                        data.loanInformation.customerOfficialEMailId,
                      customerPersonalEmailId:
                        data.loanInformation.customerPersonalEmailId,
                      designation: data.loanInformation.designation,
                      drivingLicense: data.loanInformation.drivingLicense,
                      drivingLicenseValidityDate:
                        data.loanInformation.drivingLicenseValidityDate,
                      education: data.loanInformation.education,
                      existingCustomerId:
                        data.loanInformation.existingCustomerId,
                      fatherName: data.loanInformation.fatherName,
                      gstRegistrationNumber:
                        data.loanInformation.gstRegistrationNumber,
                      isGSTRegistered: data.loanInformation.isGSTRegistered,
                      kycNumber: data.loanInformation.kycNumber,
                      loanAmountAppliedFor:
                        data.loanInformation.loanAmountAppliedFor,
                      loanCategory: data.loanInformation.loanCategory,
                      loanId: data.loanInformation.loanId,
                      loanInterestRateType:
                        data.loanInformation.loanInterestRateType,
                      loanLeadFulfillmentId:
                        data.loanInformation.loanLeadFulfillmentId,
                      loanLeadId: data.loanInformation.loanLeadId,
                      loanPurpose: data.loanInformation.loanPurpose,
                      loanTenure: data.loanInformation.loanTenure,
                      loanType: data.loanInformation.loanType,
                      maritalStatus: data.loanInformation.maritalStatus,
                      mobileNumber: data.loanInformation.mobileNumber,
                      motherMaidenName: data.loanInformation.motherMaidenName,
                      name: data.loanInformation.name,
                      nergaJobCardNumber:
                        data.loanInformation.nergaJobCardNumber,
                      noOfYearsInPresentResidenceAddress:
                        data.loanInformation.noOfYearsInPresentResidenceAddress,
                      no_of_dependents: data.loanInformation.no_of_dependents,
                      noofYearsatCurrentCity:
                        data.loanInformation.noofYearsatCurrentCity,
                      noofYearsatPermanentResidenceAddress:
                        data.loanInformation
                          .noofYearsatPermanentResidenceAddress,
                      officeAddress: data.loanInformation.officeAddress,
                      officeCityOrVillage:
                        data.loanInformation.officeCityOrVillage,
                      officeEmail: data.loanInformation.officeEmail,
                      officeLandLineNumber:
                        data.loanInformation.officeLandLineNumber,
                      officeLandMark: data.loanInformation.officeLandMark,
                      officePincode: data.loanInformation.officePincode,
                      officeState: data.loanInformation.officeState,
                      officeType: data.loanInformation.officeType,
                      panCard: data.loanInformation.panCard,
                      passportNumber: data.loanInformation.passportNumber,
                      passportValidityDate:
                        data.loanInformation.passportValidityDate,
                      permanentResidenceLandMark:
                        data.loanInformation.permanentResidenceLandMark,
                      permanentResidencePhoneNumber:
                        data.loanInformation.permanentResidencePhoneNumber,
                      permanentResidentialAddress:
                        data.loanInformation.permanentResidentialAddress,
                      permanentResidentialCityOrVillage:
                        data.loanInformation.permanentResidentialCityOrVillage,
                      permanentResidentialPhoneNumber:
                        data.loanInformation.permanentResidentialPhoneNumber,
                      permanentResidentialPincode:
                        data.loanInformation.permanentResidentialPincode,
                      permanentResidentialState:
                        data.loanInformation.permanentResidentialState,
                      placeOrCityOfBirth:
                        data.loanInformation.placeOrCityOfBirth,
                      preferredCommunicationAddress:
                        data.loanInformation.preferredCommunicationAddress,
                      preferredCommunicationLanguage:
                        data.loanInformation.preferredCommunicationLanguage,
                      presentCompanyWorkExperience:
                        data.loanInformation.presentCompanyWorkExperience,
                      presentResidenceLandMark:
                        data.loanInformation.presentResidenceLandMark,
                      presentResidentialAddress:
                        data.loanInformation.presentResidentialAddress,
                      presentResidentialCityOrVillage:
                        data.loanInformation.presentResidentialCityOrVillage,
                      presentResidentialPhoneNumber:
                        data.loanInformation.presentResidentialPhoneNumber,
                      presentResidentialPincode:
                        data.loanInformation.presentResidentialPincode,
                      presentResidentialState:
                        data.loanInformation.presentResidentialState,
                      previousCompanyName:
                        data.loanInformation.previousCompanyName,
                      processingBankId: data.loanInformation.processingBankId,
                      processingBankName:
                        data.loanInformation.processingBankName,
                      profile: data.loanInformation.profile,
                      residentialStatus: data.loanInformation.residentialStatus,
                      spouseDOB: data.loanInformation.spouseDOB,
                      spouseMobileNumber:
                        data.loanInformation.spouseMobileNumber,
                      spouseName: data.loanInformation.spouseName,
                      spouseOccupation: data.loanInformation.spouseOccupation,
                      status: statestatusid.value,
                      taxIdentificationNumber:
                        data.loanInformation.taxIdentificationNumber,
                      totalWorkExperience:
                        data.loanInformation.totalWorkExperience,
                      typeOfOrganization:
                        data.loanInformation.typeOfOrganization,
                      votedIdCard: data.loanInformation.votedIdCard,
                      yearinwhichBankAccountOpenedwithBranch:
                        data.loanInformation
                          .yearinwhichBankAccountOpenedwithBranch,
                    },
                  }),
                }
              );

              if (response.status === 200) {
                const resdata = await response.json();
                MySwal.fire({
                  text: `status successfully changed`,
                });
                setSubmitcurrentstatus('submit');
                setValidateData([]);
              } else if (response.status === 500) {
                MySwal.fire({
                  text: 'Something went wrong',
                });
              }
            } catch (error) {
              MySwal.fire({
                text: 'Something went wrong',
              });
            }
          };
          submitStatus();
        }
      });
    }
  };

  const changeStatusFun = (value) => {
    var newData = [...changeStatus];
    newData.push(value);
    setChangeStatus(newData);
    setSubmitcurrentstatus();
    setOldstatus();
  };
  const MySwal = withReactContent(Swal);

  const onValidateStatus = (loandata) => {
    const jwt = JSON.parse(localStorage.getItem('jwt'));
    const fetchdata = async () => {
      try {
        await fetch(
          `${process.env.REACT_APP_API_URL}/loans/validateEligibilityForBankSubmission/${loandata.loanInformation.loanLeadId}`,
          {
            method: 'GET',
            headers: {
              Accept: 'application/json',
              Authorization: `Bearer ${jwt}`,
            },
          }
        )
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            setValidateData([
              {
                loanlead: loandata.loanInformation.loanLeadId,
                BankvalidateStatus: data.validationStatus,
                id: loandata.loanInformation.id,
              },
            ]);
            if (data.validationStatus === 'Failed') {
              MySwal.fire({
                html: `
                

              ${
                data.missingMandatoryLoanDocument === null ||
                data.missingMandatoryLoanDocument == ''
                  ? ''
                  : `<ol style="text-align: left; margin-bottom: 0px;">
                        ${data.missingMandatoryLoanDocument
                          .map((data, i) => {
                            return `<li>${data}</li>`;
                          })
                          .join('')}
                      </ol>
                    ` +
                    `<p style="text-align: left; margin: 0px; padding: 0px;"> Loan Documents is Required </p>`
              }
             
              ${
                data.missingMandatoryLoanInformation === null ||
                data.missingMandatoryLoanInformation == ''
                  ? ''
                  : `<ol style="text-align: left; margin-bottom: 0px;">
                        ${data.missingMandatoryLoanInformation
                          .map((data, i) => {
                            return `<li>${data}</li>`;
                          })
                          .join('')}
                      </ol>
                    ` +
                    `<p style="text-align: left; margin: 0px; padding: 0px;"> Loan Information is Required </p>`
              } 
                `,
              });
            }
          });
      } catch (error) {}
    };

    const validateEligibilityForBankApproval = async () => {
      try {
        await fetch(
          `${process.env.REACT_APP_API_URL}/loans/validateEligibilityForBankApproval/${loandata.loanInformation.loanLeadId}/${loandata.loanInformation.customerMobileNumber}/${loandata.loanInformation.processingBankId}`,
          {
            method: 'GET',
            headers: {
              Accept: 'application/json',
              Authorization: `Bearer ${jwt}`,
            },
          }
        )
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            console.log(data);

            setValidateData([
              {
                loanlead: loandata.loanInformation.loanLeadId,
                validateStatus: data.validationStatus,
                id: loandata.loanInformation.id,
              },
            ]);
            if (data.validationStatus === 'Failed') {
              MySwal.fire({
                html: `
                

              ${
                data.missingMandatoryLoanDocument === null ||
                data.missingMandatoryLoanDocument == ''
                  ? ''
                  : `<ol style="text-align: left; margin-bottom: 0px;">
                        ${data.missingMandatoryLoanDocument
                          .map((data, i) => {
                            return `<li>${data}</li>`;
                          })
                          .join('')}
                      </ol>
                    ` +
                    `<p style="text-align: left; margin: 0px; padding: 0px;"> Loan Documents is Required </p>`
              }
             
              ${
                data.missingMandatoryLoanInformation === null ||
                data.missingMandatoryLoanInformation == ''
                  ? ''
                  : `<ol style="text-align: left; margin-bottom: 0px;">
                        ${data.missingMandatoryLoanInformation
                          .map((data, i) => {
                            return `<li>${data}</li>`;
                          })
                          .join('')}
                      </ol>
                    ` +
                    `<p style="text-align: left; margin: 0px; padding: 0px;"> Loan Information is Required </p>`
              } 
                `,
              });
            }
          });
      } catch (error) {}
    };
    if (loandata.loanInformation.processingBankName !== undefined) {
      validateEligibilityForBankApproval();
    } else {
      fetchdata();
    }
  };

  const assign = async (data) => {
    if (data.Bank.length !== 0) {
      var BankId;
      var Bankname;

      if (data.Bank.length > 0) {
        BankId = BankList.filter((item) => item.name === data.Bank.at(-1).bank);
        console.log(BankId);
        BankId = BankId[0].bankId;

        BankId =
          data.Bank.at(-1).leadId === data.loan.loanInformation.loanLeadId
            ? BankId
            : data.loan.loanInformation.processingBankId;

        Bankname = BankList.filter(
          (item) => item.name === data.Bank.at(-1).bank
        );
        Bankname = Bankname[0].name;
        console.log(Bankname);
        // console.log(BankId);
      } else {
        BankId = data.loan.loanInformation.processingBankId;
        Bankname = data.loan.loanInformation.processingBankName;
        console.log(BankId);
      }

      // Post Data

      const LoginEmp = JSON.parse(localStorage.getItem('loggedInEmployee'));

      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/validateAndSubmitToBankForApproval`,
          {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              Authorization: `Bearer ${jwt}`,
            },
            body: JSON.stringify({
              loanInformation: {
                assignedByEmployeeId: LoginEmp.employeeId,
                assignedByEmployeeName: LoginEmp.name,
                processingBankId: BankId,
                processingBankName: Bankname,
                loanLeadId: data.loan.loanInformation.loanLeadId,
              },
            }),
          }
        );

        if (response.status === 200) {
          const data = await response.json();
          MySwal.fire({
            text: `${data.validationdMessage}`,
          });
          setSubmitBank(true);
        } else if (response.status === 500) {
          MySwal.fire({
            text: 'Something went wrong',
          });
        }
      } catch (error) {
        MySwal.fire({
          text: 'Something went wrong',
        });
      }

      //
    } else {
      MySwal.fire({
        text: `Please select Bank`,
      });
    }
  };

  return (
    <>
      <div className="div">
        <ToastContainer />
        <form onSubmit={searchSubmit} className="mt-4">
          <div className="row justify-content-center">
            <div className="col-lg">
              <label for="inputEmail4">Agent Id</label>
              <input
                type="text"
                className="form-control"
                placeholder="Id"
                onChange={searchHandleChange('agentId')}
                value={agentId}
              />
            </div>

            <div className="col-lg">
              <label for="inputEmail4">Loan Type</label>
              <select
                id="loantype"
                className="form-control"
                onChange={searchHandleChange('loanType')}
                value={loanType}
              >
                <option value="">Select</option>
                <option value="PERSONAL LOAN">Personal loan</option>
                <option value="HOME LOAN">Home loan</option>
                <option value="BUSINESS LOAN">Business loan</option>
                <option value="PERSONAL LOAN EXTENSION">
                  Personal loan extension
                </option>
              </select>
            </div>

            <div className="col-lg">
              <label for="inputEmail4">Status</label>
              <input
                type="text"
                className="form-control"
                placeholder="Status"
                onChange={searchHandleChange('sStatus')}
                value={sStatus}
              />
            </div>

            <div className="col-lg">
              <label for="inputEmail4">Customer Mobile Number</label>
              <input
                type="number"
                className="form-control"
                placeholder="Customer Mobile Number"
                onChange={searchHandleChange('cMobileNum')}
                value={cMobileNum}
              />
            </div>

            <div className="col-lg">
              <label for="inputEmail4">Customer Pan Number</label>
              <input
                type="number"
                className="form-control"
                placeholder="Customer Pan Number"
                onChange={searchHandleChange('cPanNumber')}
                value={cPanNumber}
              />
            </div>
            <div className="col-lg-1 pt-sm-2">
              <button type="submit" className="btn btn-primary mt-4">
                Search
              </button>
            </div>
          </div>
        </form>
      </div>
      <br />
      {searchLoanLead.length > 0 || searchError ? (
        <>
          <Link to="/loan-under-proccess">
            <button
              className="btn btn-light text-danger mb-4"
              onClick={() =>
                setSearchV({
                  ...searchV,
                  searchLoanLead: [],
                  agentId: '',
                  loanType: '',
                  cMobileNum: '',
                  cPanNumber: '',
                  assignedByEmployeeId: '',
                  assignedToEmployeeId: '',
                  sStatus: '',
                  searchError: false,
                })
              }
            >
              <i class="fa-solid fa-xmark"></i> Cancel
            </button>
          </Link>
        </>
      ) : (
        <></>
      )}

      <table class="table table-striped table-borderless table-responsive mt-4 mb-0 ">
        <br />
        {showLoading()}

        {searchError && (
          <>
            <tr>
              {' '}
              <td colSpan="100">
                <p className="text-center  m-0 text-danger">No Result Found</p>
              </td>
            </tr>
          </>
        )}
        {searchLoanLead.length > 0 ? (
          <>
            <thead className=" text-center">
              <tr>
                <th scope="col" className="current-assigned-th pr-2">
                  Loan Application ID
                </th>
                <th scope="col" className="current-assigned-th pr-2">
                  Customer Name
                </th>
                <th scope="col" className="current-assigned-th pr-2">
                  Customer Mobile No
                </th>
                <th scope="col" className="current-assigned-th pr-2">
                  Agent Name
                </th>
                {/* <th scope="col" className="current-assigned-th pr-2">
                  Agent Mobile No
                </th> */}
                <th scope="col" className="current-assigned-th pr-2">
                  Loan Type
                </th>
                <th scope="col" className="current-assigned-th pr-2">
                  Loan Amount
                </th>
                <th scope="col" className="current-assigned-th pr-2">
                  Bank
                </th>
                <th scope="col" className="current-assigned-th pr-2">
                  Current Status
                </th>
                <th scope="col" className="current-assigned-th pr-2">
                  Submit
                </th>
                <th scope="col" className="current-assigned-th pr-2">
                  View Details
                </th>
              </tr>
            </thead>
            <tbody>
              {searchLoanLead.map((loan, index) => (
                <React.Fragment key={index}>
                  <tr>
                    <td> {loan.loanInformation.applicationId}</td>
                    <td> {loan.loanInformation.customerName}</td>
                    <td> {loan.loanInformation.customerMobileNumber}</td>
                    <td>{loan.loanInformation.agentName}</td>
                    {/* <td>{loan.loanInformation.agentMobileNumber}</td> */}
                    <td>{loan.loanInformation.loanType}</td>
                    <td>{loan.loanInformation.loanAmountAppliedFor}</td>

                    <td>
                      {loan.loanInformation.processingBankName !== undefined ? (
                        loan.loanInformation.processingBankName
                      ) : (
                        <>
                          <button
                            className="btn btn-warning rounded-0 btn-sm"
                            data-toggle="modal"
                            data-target={`#bankmodal${index}`}
                            disabled={
                              validateData
                                .filter(
                                  (a) =>
                                    a.loanlead ===
                                    loan.loanInformation.loanLeadId
                                )
                                .pop() &&
                              validateData
                                .filter(
                                  (a) =>
                                    a.loanlead ===
                                    loan.loanInformation.loanLeadId
                                )
                                .pop().BankvalidateStatus === 'Success'
                                ? false
                                : true
                            }
                          >
                            Submit To Bank
                          </button>
                        </>
                      )}
                      <div
                        class="modal fade"
                        id={`bankmodal${index}`}
                        tabindex="-1"
                        role="dialog"
                        aria-labelledby="bankmodal"
                        aria-hidden="true"
                      >
                        <div class="modal-dialog" role="document">
                          <div class="modal-content">
                            <div class="modal-header">
                              <h5 class="modal-title" id="bankmodal">
                                Submit To Bank
                              </h5>
                              <button
                                type="button"
                                class="close"
                                data-dismiss="modal"
                                aria-label="Close"
                              >
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                            <div class="modal-body">
                              <select
                                className="form-control rounded-0"
                                onChange={(e) =>
                                  changeBankDetails({
                                    bank: e.target.value,
                                    leadId: loan.loanInformation.loanLeadId,
                                  })
                                }
                                // disabled={lead.enableReassignButton === 'No' ? true : false}
                              >
                                <option value="" selected>
                                  Select
                                </option>

                                {BankList.map((bank, i) => (
                                  <option key={i} value={bank.name}>
                                    {bank.name}
                                  </option>
                                ))}
                              </select>
                              <button
                                type="submit"
                                class="btn btn-success mt-2 mb-2"
                                onClick={() => assign({ loan, Bank })}
                              >
                                Send
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      {loan.loanInformation.processingBankName == undefined ? (
                        loan.loanInformation.status
                      ) : (
                        <>
                          <select
                            id="inputState"
                            className="form-control text-capitalize"
                            onChange={(e) =>
                              changeStatusFun({
                                value: e.target.value,
                                id: loan.loanInformation.id,
                              })
                            }
                            disabled={
                              validateData
                                .filter((a) => a.id === loan.loanInformation.id)
                                .pop() &&
                              validateData
                                .filter((a) => a.id === loan.loanInformation.id)
                                .pop().validateStatus === 'Success'
                                ? false
                                : true
                            }
                          >
                            {/* <option value="Select/Update Loan Status">
                            Select/Update Loan Status{' '}
                          </option> */}
                            {oldstatus == 'Assigned' && (
                              <option
                                value={loan.loanInformation.status}
                                selected
                              >
                                {loan.loanInformation.status}
                              </option>
                            )}
                            {loan.loanInformation.status && (
                              <option
                                value={loan.loanInformation.status}
                                className="d-none"
                                selected
                              >
                                {loan.loanInformation.status}
                              </option>
                            )}
                            {loan.loanInformation.status === null && (
                              <option value="" selected>
                                Select/Update Loan Status
                              </option>
                            )}
                            {loan.loanInformation.status === 'Assigned' ||
                            loan.loanInformation.status ===
                              'Under Fulfillment' ? (
                              <>
                                <option value="Pending Bank Approval">
                                  Pending Bank Approval
                                </option>
                                <option value="Bank Approved">
                                  Bank Approved
                                </option>
                                <option value="Bank Rejected">
                                  Bank Rejected
                                </option>
                              </>
                            ) : (
                              <></>
                            )}

                            {loan.loanInformation.status ===
                              'Pending Bank Approval' && (
                              <>
                                <option value="Bank Approved">
                                  Bank Approved
                                </option>
                                <option value="Bank Rejected">
                                  Bank Rejected
                                </option>
                              </>
                            )}

                            {loan.loanInformation.status ===
                              'Bank Approved' && (
                              <>
                                <option value="Under Bank Negitiation">
                                  Under Bank Negitiation
                                </option>
                                <option value="Under Customer Negotiation">
                                  Under Customer Negotiation
                                </option>
                                <option value="Under Agreement Review">
                                  Under Agreement Review
                                </option>
                              </>
                            )}

                            {loan.loanInformation.status ===
                              'Under Bank Negitiation' ||
                            loan.loanInformation.status ===
                              'Under Customer Negotiation' ||
                            loan.loanInformation.status ===
                              'Under Agreement Review' ? (
                              <>
                                <option value="Agreement Signed">
                                  Agreement Signed
                                </option>
                                <option value="Bank Rejected">
                                  Bank Rejected
                                </option>
                              </>
                            ) : (
                              <></>
                            )}

                            {loan.loanInformation.status ===
                              'Agreement Signed' && (
                              <>
                                <option value="Disbursed">Disbursed</option>
                              </>
                            )}

                            {loan.loanInformation.status === 'Disbursed' && (
                              <>
                                <option value="Agent Payout">
                                  Agent Payout
                                </option>
                              </>
                            )}

                            {loan.loanInformation.status === 'Agent Payout' && (
                              <>
                                <option value="Processed and Payout complete">
                                  Processed and Payout complete
                                </option>
                              </>
                            )}

                            {loan.loanInformation.status ===
                              'Bank Rejected' && (
                              <>
                                <option value="Resubmit For Approval">
                                  Resubmit For Approval
                                </option>
                                <option value="Close">Close</option>
                              </>
                            )}
                          </select>
                        </>
                      )}
                    </td>
                    <td>
                      {validateData
                        .filter((a) => a.id === loan.loanInformation.id)
                        .pop() &&
                      validateData
                        .filter((a) => a.id === loan.loanInformation.id)
                        .pop().validateStatus === 'Success' ? (
                        loan.loanInformation.processingBankName !==
                          undefined && (
                          <button
                            className="btn btn-primary rounded-0 btn-sm"
                            onClick={() => onChangeStatus(loan)}
                          >
                            change status
                          </button>
                        )
                      ) : (
                        <>
                          <button
                            className="btn btn-primary rounded-0 btn-sm"
                            onClick={() => onValidateStatus(loan)}
                            disabled={
                              JSON.parse(
                                localStorage.getItem('loggedInEmployee')
                              ).role !== 'Admin' &&
                              loan.loanInformation.status === 'Agent Payout'
                            }
                          >
                            validate
                          </button>
                        </>
                      )}
                    </td>
                    <td>
                      <button
                        className="btn-success btn btn-sm rounded-0 px-2"
                        onClick={() => toggleAccordion(index)}
                        disabled={
                          JSON.parse(localStorage.getItem('loggedInEmployee'))
                            .role !== 'Admin' &&
                          loan.loanInformation.status === 'Agent Payout'
                        }
                        hidden={
                          changeStatus
                            .filter(
                              (data) => data.id === loan.loanInformation.id
                            )
                            .map((data) => data.value)
                            .pop() === 'Close' ||
                          changeStatus
                            .filter(
                              (data) => data.id === loan.loanInformation.id
                            )
                            .map((data) => data.value)
                            .pop() === 'Resubmit For Approval'
                            ? true
                            : false
                        }
                      >
                        {accordion === index ? (
                          <>
                            {' '}
                            <i className="fa-solid fa-minus"></i>
                          </>
                        ) : (
                          <> View/Update</>
                        )}
                      </button>
                    </td>
                  </tr>

                  <tr
                    className={
                      accordion === index
                        ? 'active-accordion'
                        : 'inactive-accordion'
                    }
                  >
                    <td colspan="4">
                      <div>
                        <div className="row">
                          <div className="col-lg-12">
                            <button
                              data-toggle="modal"
                              data-target={`#customerPersonalDetail${loan.loanInformation.id}`}
                              className="btn btn-primary mr-1 px-4"
                              hidden={
                                changeStatus
                                  .filter(
                                    (data) =>
                                      data.id === loan.loanInformation.id
                                  )
                                  .map((data) => data.value)
                                  .pop() === 'Select/Update Loan Status'
                                  ? true
                                  : false
                              }
                            >
                              {loan.loanInformation.status &&
                              changeStatus.filter(
                                (data) => data.id === loan.loanInformation.id
                              ).length === 0 ? (
                                <>
                                  {loan.loanInformation.status ===
                                  'Assigned' ? (
                                    <>Customer Personal Details</>
                                  ) : (
                                    loan.loanInformation.status
                                  )}
                                </>
                              ) : changeStatus
                                  .filter(
                                    (data) =>
                                      data.id === loan.loanInformation.id
                                  )
                                  .map((data) => data.value)
                                  .pop() === 'Assigned' ? (
                                <>
                                  <>Customer Personal Details</>
                                </>
                              ) : (
                                <>
                                  {changeStatus
                                    .filter(
                                      (data) =>
                                        data.id === loan.loanInformation.id
                                    )
                                    .map((data) => <>{data.value}</>)
                                    .pop()}
                                </>
                              )}
                            </button>
                          </div>
                          <div className="col-lg-6"></div>
                        </div>

                        {/* <!-- Large modal --> */}

                        <div
                          className="modal fade cus-perosonal-details"
                          id={`customerPersonalDetail${loan.loanInformation.id}`}
                          data-backdrop="static"
                          tabindex="-1"
                          role="dialog"
                          aria-labelledby="staticBackdropLabel"
                          aria-hidden="true"
                        >
                          <CustomerPersonalDetails
                            CPersonalDetails={loan}
                            ChangeStatusValue={
                              changeStatus.filter(
                                (data) => data.id === loan.loanInformation.id
                              ) &&
                              changeStatus
                                .filter(
                                  (data) => data.id === loan.loanInformation.id
                                )
                                .map((data) => data.value)
                                .pop()
                                ? changeStatus
                                    .filter(
                                      (data) =>
                                        data.id === loan.loanInformation.id
                                    )
                                    .map((data) => data.value)
                                    .pop()
                                : loan.loanInformation.status
                            }
                          />
                        </div>
                      </div>
                    </td>

                    <td colspan="4">
                      <div>
                        <div className="row">
                          <div className="col-lg-12">
                            {changeStatus
                              .filter(
                                (data) => data.id === loan.loanInformation.id
                              )
                              .map((data) => data.value)
                              .pop() === 'Bank Approved' ||
                            changeStatus
                              .filter(
                                (data) => data.id === loan.loanInformation.id
                              )
                              .map((data) => data.value)
                              .pop() === 'Bank Rejected' ||
                            changeStatus
                              .filter(
                                (data) => data.id === loan.loanInformation.id
                              )
                              .map((data) => data.value)
                              .pop() === 'Disbursed' ||
                            changeStatus
                              .filter(
                                (data) => data.id === loan.loanInformation.id
                              )
                              .map((data) => data.value)
                              .pop() === 'Agreement Signed' ? (
                              <>
                                <button
                                  className="btn btn-primary mr-1 px-4"
                                  data-toggle="modal"
                                  data-target={`#DocStaticId${loan.loanInformation.id}`}
                                >
                                  Support Document Details
                                </button>
                              </>
                            ) : changeStatus
                                .filter(
                                  (data) => data.id === loan.loanInformation.id
                                )
                                .map((data) => data.value)
                                .pop() === 'Assigned' ? (
                              <>
                                {' '}
                                <button
                                  className="btn btn-primary mr-1 px-4"
                                  data-toggle="modal"
                                  data-target={`#DocStaticId${loan.loanInformation.id}`}
                                >
                                  Customer Document Details
                                </button>
                              </>
                            ) : loan.loanInformation.status === 'Assigned' &&
                              changeStatus.filter(
                                (data) => data.id === loan.loanInformation.id
                              ).length === 0 ? (
                              <>
                                {' '}
                                <button
                                  className="btn btn-primary mr-1 px-4"
                                  data-toggle="modal"
                                  data-target={`#DocStaticId${loan.loanInformation.id}`}
                                >
                                  Customer Document Details
                                </button>
                              </>
                            ) : (loan.loanInformation.status ===
                                'Bank Approved' ||
                                loan.loanInformation.status ===
                                  'Bank Rejected' ||
                                loan.loanInformation.status === 'Disbursed' ||
                                loan.loanInformation.status ===
                                  'Agreement Signed') &&
                              changeStatus.filter(
                                (data) => data.id === loan.loanInformation.id
                              ).length === 0 ? (
                              <>
                                {' '}
                                <button
                                  className="btn btn-primary mr-1 px-4"
                                  data-toggle="modal"
                                  data-target={`#DocStaticId${loan.loanInformation.id}`}
                                >
                                  Support Document Details
                                </button>
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                          <div className="col-lg-6"></div>
                        </div>

                        {/* <!-- Modal --> */}
                        <div
                          class="modal fade"
                          id={`DocStaticId${loan.loanInformation.id}`}
                          data-backdrop="static"
                          tabindex="-1"
                          role="dialog"
                          aria-labelledby="staticBackdropLabel"
                          aria-hidden="true"
                        >
                          <CustomerDocumentsDetails
                            loanDetailsData={loan}
                            loanDetails={loan.loanInformation}
                            loanDocumentList={loan.loanDocumentList}
                            ChangeStatusValue={
                              changeStatus.filter(
                                (data) => data.id === loan.loanInformation.id
                              ) &&
                              changeStatus
                                .filter(
                                  (data) => data.id === loan.loanInformation.id
                                )
                                .map((data) => data.value)
                                .pop()
                                ? changeStatus
                                    .filter(
                                      (data) =>
                                        data.id === loan.loanInformation.id
                                    )
                                    .map((data) => data.value)
                                    .pop()
                                : loan.loanInformation.status
                            }
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                  <br />
                </React.Fragment>
              ))}
            </tbody>
          </>
        ) : (
          <></>
        )}
      </table>
      <div className="pt-5 pb-5"> </div>
    </>
  );
};

export default CurrentAssignedLoan;

import React, { useEffect, useState } from 'react';
import Layout from '../components/Layout';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const InitiateLoanLead = ({ history }) => {
  const [values, setValues] = useState({
    loading: false,
  });
  const [agentloginDetails, setAgentLoginDetails] = useState();
  const [agentMobilevalidation, setAgentMobileValidation] = useState('');
  const [postalData, setPostData] = useState(null);

  const [loanTypes, setLoanTypes] = useState([]);

  const { loading } = values;

  const MySwal = withReactContent(Swal);

  const {
    register,
    handleSubmit,
    watch,
    trigger,
    formState: { errors },
    reset,
  } = useForm();

  useEffect(() => {
    if (localStorage.getItem('userType') === null) {
      alert('Please login');
      history.push('/login');
    } else {
      const fetchdata = async () => {
        try {
          await fetch(`${process.env.REACT_APP_API_URL}/getLoanTypes`, {
            method: 'GET',
            headers: {
              Accept: 'application/json',
            },
          })
            .then((res) => {
              return res.json();
            })
            .then((data) => {
              setLoanTypes(data);
            });
        } catch (error) {
          console.log(error);
        }
      };
      fetchdata();

      setAgentLoginDetails(JSON.parse(localStorage.getItem('loggedInAgent')));
    }
  }, []);

  // Submit form
  const clickRegister = async (data) => {
    // e.preventDefault();
    setValues({
      loading: true,
    });
    const employeeName =
      JSON.parse(localStorage.getItem('loggedInEmployee')) &&
      JSON.parse(localStorage.getItem('loggedInEmployee')).name;
    const employeeId =
      JSON.parse(localStorage.getItem('loggedInEmployee')) &&
      JSON.parse(localStorage.getItem('loggedInEmployee')).userId;
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/initiateLead`,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            // isGuestAgent: data.registeredagent === 'no' ? 'Yes' : 'No',
            //  registeredAgentMobileNumber: data.registeredAgentMobileNumber,
            agentFirstName: agentloginDetails && agentloginDetails.firstName,
            agentLastName: agentloginDetails && agentloginDetails.lastName,
            agentEmailId: agentloginDetails && agentloginDetails.email,
            agentMobileNumber: agentloginDetails && agentloginDetails.mobile,
            initiatedByAgentId: agentloginDetails && agentloginDetails.agentId,
            employeeName: employeeName,
            employeeId: employeeId,
            customerFirstName: data.customerFirstName,
            customerLastName: data.customerLastName,
            customerEmailId: data.customerEmailId,
            customerMobileNumber: data.customerMobileNumber,
            loanAmountAppliedFor: data.loanAmountAppliedFor,
            loanType: data.loanType,
            loanTenure: data.loanTenure,
            brokerCode: agentloginDetails
              ? agentloginDetails.brokerCode
              : data.brokerCode,
            pinCode: data.pinCode,
            location: data.location,
            state: postalData !== null ? postalData[0].State : null,
            agentPanNumber: agentloginDetails && agentloginDetails.panNumber,
          }),
        }
      );
      if (response.status === 200) {
        const resdata = await response.json();
        setValues({
          loading: false,
        });

        MySwal.fire({
          text: `${resdata.message}`,
        });
        reset();
        setAgentMobileValidation('');
        setPostData(null);
      } else if (response.status === 500) {
        MySwal.fire({
          text: 'Something went wrong',
        });
      }
    } catch (error) {
      MySwal.fire({
        text: 'Something went wrong',
      });
    }
  };

  // Loading
  const showLoading = () =>
    loading && (
      <div className="alert alert-info">
        <h2>Loading...</h2>
      </div>
    );

  // check number
  const checkIfNumber = (event) => {
    const regex = new RegExp(
      /(^\d*$)|(Backspace|Tab|Delete|ArrowLeft|ArrowRight)/
    );

    return !event.key.match(regex) && event.preventDefault();
  };

  // check text
  const checkIfTextOnly = (event) => {
    const regex = new RegExp(/[a-zåäö ]/i);

    return !event.key.match(regex) && event.preventDefault();
  };

  // Agent mobile number validation
  const validateAgentNum = (data) => {
    const fetchdata = async () => {
      try {
        await fetch(
          `${process.env.REACT_APP_API_URL}/agents/validateMobile?mobile=${data}`,
          {
            method: 'GET',
            headers: {
              Accept: 'application/json',
            },
          }
        )
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            setAgentMobileValidation(data.status);
            if (data.status === 'validationPassed') {
              MySwal.fire({
                text: `Validation successful, agent name is ${data.userName}`,
              });
            } else {
              setValues({
                loading: false,
              });
              MySwal.fire({
                text: `Agent not found`,
              });
            }
          });
      } catch (error) {
        console.log(error);
      }
    };

    if (data.length < 10) {
      MySwal.fire({
        text: ` Please enter 10 digit phone no`,
      });
    } else {
      fetchdata();
    }
  };

  const fetchPostalData = async () => {
    if (watch().pinCode.length === 6) {
      try {
        await fetch(`https://api.postalpincode.in/pincode/${watch().pinCode}`, {
          method: 'GET',
          headers: {
            Accept: 'application/json',
          },
        })
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            setPostData(data[0].PostOffice);
          });
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <>
      <Layout>
        <div className="inner_banner text-center">
          {localStorage.getItem('userType') === null ? (
            <div className="container">Apply for Loan</div>
          ) : (
            <div className="container">Provide Loan Lead</div>
          )}
        </div>

        {/* Form */}

        <ToastContainer />

        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-lg-4">
              <div className="container">{showLoading()}</div>

              <div className="register-form shadow-sm">
                <form onSubmit={handleSubmit(clickRegister)}>
                  {localStorage.getItem('userType') === 'Agent' && (
                    <>
                      <label htmlFor="brokername">Broker Name</label>
                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <span
                            className="input-group-text rounded-0"
                            id="brokername"
                          >
                            <i className="fa-solid fa-user"></i>
                          </span>
                        </div>

                        <input
                          type="text"
                          className="form-control rounded-0"
                          id="brokerNamefield"
                          placeholder="Broker Name"
                          aria-describedby="brokerNamefield"
                          defaultValue={
                            JSON.parse(localStorage.getItem('loggedInAgent'))
                              .name
                          }
                          readOnly
                        />
                      </div>

                      <label htmlFor="brokercode">Broker Code</label>
                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <span
                            className="input-group-text rounded-0"
                            id="brokercode"
                          >
                            <i className="fa-solid fa-user-plus"></i>
                          </span>
                        </div>

                        <input
                          type="text"
                          className="form-control rounded-0"
                          id="brokerCodefield"
                          placeholder="Broker Code"
                          aria-describedby="brokerCodefield"
                          defaultValue={
                            JSON.parse(localStorage.getItem('loggedInAgent'))
                              .brokerCode
                          }
                          readOnly
                        />
                      </div>
                      <label htmlFor="brokerPan">Broker Pan number</label>
                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <span
                            className="input-group-text rounded-0"
                            id="brokerPan"
                          >
                            <i className="fa-regular fa-id-card"></i>
                          </span>
                        </div>

                        <input
                          type="text"
                          className="form-control rounded-0"
                          id="brokerPannumber"
                          placeholder="Broker Pan Number"
                          aria-describedby="brokerPannumber"
                          defaultValue={
                            JSON.parse(localStorage.getItem('loggedInAgent'))
                              .panNumber
                          }
                          readOnly
                        />
                      </div>
                    </>
                  )}

                  {localStorage.getItem('userType') === 'Employee' && (
                    <>
                      <label htmlFor="employeeName">Employee Name</label>
                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <span
                            className="input-group-text rounded-0"
                            id="employeeName"
                          >
                            <i className="fa-solid fa-user"></i>
                          </span>
                        </div>

                        <input
                          type="text"
                          className="form-control rounded-0"
                          id="employeeNameField"
                          placeholder="Broker Name"
                          aria-describedby="employeeNameField"
                          defaultValue={
                            JSON.parse(localStorage.getItem('loggedInEmployee'))
                              .name
                          }
                          readOnly
                        />
                      </div>
                      <label htmlFor="brokerCode">
                        Broker Code If Lead Provided behalf of broker
                      </label>
                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <span
                            className="input-group-text rounded-0"
                            id="brokerCode"
                          >
                            <i className="fa-solid fa-user"></i>
                          </span>
                        </div>

                        <input
                          type="text"
                          className="form-control rounded-0"
                          id="brokerCodeField"
                          placeholder="Broker Code"
                          aria-describedby="brokerCodeField"
                          {...register('brokerCode')}
                        />
                      </div>
                    </>
                  )}
                  <label htmlFor="customerFirstName">Customer First Name</label>
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span
                        className="input-group-text rounded-0"
                        id="customerFirstName"
                      >
                        <i className="fa-solid fa-user"></i>
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control rounded-0"
                      id="customerFirstName"
                      placeholder="Customer First Name"
                      aria-describedby="customerFirstName"
                      {...register('customerFirstName', {
                        required: 'Customer first name is required',
                        maxLength: {
                          value: 50,
                          message: 'Maximum 50 characters',
                        },
                      })}
                      onKeyUp={() => {
                        trigger('customerFirstName');
                      }}
                      onKeyDown={(e) => checkIfTextOnly(e)}
                    />
                  </div>
                  {errors.customerFirstName && (
                    <p className="text-danger">
                      {errors.customerFirstName.message}
                    </p>
                  )}
                  <label htmlFor="customerLastName">Customer Last Name</label>
                  <div className="input-group mb-3">
                    <div className="input-group-prepend ">
                      <span
                        className="input-group-text rounded-0"
                        id="customerLastName"
                      >
                        <i className="fa-solid fa-user"></i>
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control rounded-0"
                      placeholder="Customer Last Name"
                      id="customerLastName"
                      aria-describedby="customerLastName"
                      {...register('customerLastName')}
                      onKeyDown={(e) => checkIfTextOnly(e)}
                    />
                  </div>
                  {errors.customerLastName && (
                    <p className="text-danger">
                      {errors.customerLastName.message}
                    </p>
                  )}

                  <label htmlFor="customerEmailId">Customer Email Id</label>
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span
                        className="input-group-text rounded-0"
                        id="customerEmailId"
                      >
                        <i className="fa-solid fa-envelope"></i>
                      </span>
                    </div>
                    <input
                      type="email"
                      className="form-control rounded-0"
                      id="customerEmailId"
                      placeholder="Customer Email"
                      aria-describedby="customerEmailId"
                      {...register('customerEmailId', {
                        required: 'Customer email is required',
                        pattern: {
                          value:
                            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                          message: 'Please enter a valid email',
                        },
                      })}
                      onKeyUp={() => {
                        trigger('customerEmailId');
                      }}
                    />
                  </div>
                  {errors.customerEmailId && (
                    <p className="text-danger">
                      {errors.customerEmailId.message}
                    </p>
                  )}
                  <label htmlFor="customerMobileNumber">
                    Customer Mobile Number
                  </label>
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span
                        className="input-group-text rounded-0"
                        id="customerMobileNumber"
                      >
                        <i className="fa-solid fa-phone"></i>
                      </span>
                    </div>
                    <input
                      type="number"
                      className="form-control rounded-0"
                      id="customerMobileNumber"
                      placeholder="Customer Mobile Number"
                      aria-describedby="customerMobileNumber"
                      {...register('customerMobileNumber', {
                        required: 'Customer phone no is required',
                        pattern: {
                          value: /^[0-9]{10}$/,
                          message: 'Please enter 10 digit phone no',
                        },
                      })}
                      onKeyUp={() => {
                        trigger('customerMobileNumber');
                      }}
                      onKeyDown={(e) => checkIfNumber(e)}
                    />
                  </div>
                  {errors.customerMobileNumber && (
                    <p className="text-danger">
                      {errors.customerMobileNumber.message}
                    </p>
                  )}
                  <label htmlFor="loanAmountAppliedFor">
                    Loan Amount Applying For
                  </label>
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span
                        className="input-group-text rounded-0"
                        id="loanAmountAppliedFor"
                      >
                        <i className="fa-solid fa-money-bill-1"></i>
                      </span>
                    </div>
                    <input
                      type="number"
                      className="form-control rounded-0"
                      id="loanAmountAppliedFor"
                      placeholder="Loan Amount Applying For"
                      aria-describedby="loanAmountAppliedFor"
                      {...register('loanAmountAppliedFor', {
                        required: 'Loan Amount is required',
                      })}
                      onKeyUp={() => {
                        trigger('loanAmountAppliedFor');
                      }}
                      onKeyDown={(e) => checkIfNumber(e)}
                    />
                  </div>
                  {errors.loanAmountAppliedFor && (
                    <p className="text-danger">
                      {errors.loanAmountAppliedFor.message}
                    </p>
                  )}

                  <label htmlFor="loanType">Loan Type</label>
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span
                        className="input-group-text rounded-0"
                        id="loanType"
                      >
                        <i className="fa-solid fa-money-bill-1"></i>
                      </span>
                    </div>

                    <select
                      id="inputState"
                      className="form-control text-capitalize"
                      {...register('loanType', {
                        required: 'Loan type is required',
                      })}
                    >
                      <option value="" selected>
                        Select Loan Type{' '}
                      </option>

                      {loanTypes.map((data, i) => (
                        <option key={i} value={data.description}>
                          {data.description}
                        </option>
                      ))}
                    </select>
                  </div>
                  {errors.loanType && (
                    <p className="text-danger">{errors.loanType.message}</p>
                  )}

                  <label htmlFor="loanTenure">Loan Tenure</label>
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span
                        className="input-group-text rounded-0"
                        id="loanTenure"
                      >
                        <i className="fa-solid fa-money-bill-1"></i>
                      </span>
                    </div>
                    <input
                      type="number"
                      className="form-control rounded-0"
                      id="loanTenure"
                      placeholder="Loan Tenure"
                      aria-describedby="loanTenure"
                      {...register('loanTenure', {
                        required: 'Loan tenure is required',
                      })}
                      onKeyDown={(e) => checkIfNumber(e)}
                    />
                  </div>
                  {errors.loanTenure && (
                    <p className="text-danger">{errors.loanTenure.message}</p>
                  )}
                  <label htmlFor="pincode">Pin Code</label>
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text rounded-0" id="pincode">
                        <i className="fa-solid fa-map-pin"></i>
                      </span>
                    </div>
                    <input
                      type="number"
                      className="form-control rounded-0"
                      id="pincodefield"
                      placeholder="Pin Code"
                      aria-describedby="pincodefield"
                      {...register('pinCode', {
                        required: 'Pin code is required',
                        pattern: {
                          value: /^[1-9][0-9]{5}$/,
                          message: 'Pin code sould be 6 digit',
                        },
                      })}
                      onKeyUp={() => {
                        trigger('pinCode');
                        fetchPostalData();
                      }}
                      onKeyDown={(e) => checkIfNumber(e)}
                    />
                  </div>
                  {errors.pinCode && (
                    <p className="text-danger">{errors.pinCode.message}</p>
                  )}
                  <label htmlFor="location">Location</label>
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span
                        className="input-group-text rounded-0"
                        id="location"
                      >
                        <i class="fa-solid fa-map-location"></i>
                      </span>
                    </div>

                    <select
                      id="inputState"
                      className="form-control text-capitalize"
                      {...register('location', {
                        required: 'Location is required',
                      })}
                    >
                      <option value="" selected>
                        Select Location{' '}
                      </option>

                      {postalData !== null
                        ? postalData.map((data, i) => (
                            <option key={i} value={data.Name}>
                              {data.Name}
                            </option>
                          ))
                        : ''}
                    </select>
                  </div>
                  {errors.location && (
                    <p className="text-danger">{errors.location.message}</p>
                  )}

                  <label htmlFor="State">State</label>
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text rounded-0" id="state">
                        <i class="fa-solid fa-map"></i>
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control rounded-0"
                      id="state"
                      placeholder="State"
                      aria-describedby="statefield"
                      {...register('state')}
                      value={postalData !== null ? postalData[0].State : ''}
                      readOnly
                    />
                  </div>
                  {errors.state && (
                    <p className="text-danger">{errors.state.message}</p>
                  )}

                  <div className="form-group form-check ">
                    <label className="form-check-label" htmlFor="registercheck">
                      I agree with{' '}
                      <Link data-toggle="modal" data-target="#terms_condition">
                        terms and conditions
                      </Link>
                    </label>
                  </div>
                  {errors.registercheck && (
                    <p className="text-danger">
                      {errors.registercheck.message}
                    </p>
                  )}

                  <button type="submit" className="btn btn-primary rounded-0">
                    Initite Lead
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Modal --> */}
        <div
          className="modal fade"
          id="terms_condition"
          data-backdrop="static"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="staticBackdropLabel">
                  Terms And Conditions
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <p>
                  Please understand the following terms and conditions <br /> 1.
                  Commision payout amount will depend on various condition like
                  Loan Type,Processing Bank, approveed interest rate etc. <br />{' '}
                  2. Payout typically creited to bank account within 10th day of
                  each month but can be delayed occationaly. <br /> 3. Final
                  Payout amount will be notified only after Loan Amount
                  disbursed to Customer's Account. <br /> 4. In case of any
                  query/clarification you need to notify within 15 Calendar
                  Days.
                </p>
              </div>
              <div className="modal-footer">
                <input
                  type="checkbox"
                  className="checkbox-size text-success"
                  id="registercheck"
                  {...register('registercheck', {
                    required: 'Please accept the terms and conditions',
                  })}
                />
                <button
                  type="button"
                  data-dismiss="modal"
                  aria-label="Close"
                  className="btn btn-primary"
                  disabled={watch().registercheck ? false : true}
                >
                  Accept
                </button>
              </div>
            </div>
          </div>
        </div>
        <br />
      </Layout>
    </>
  );
};

export default InitiateLoanLead;

import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Helmet } from 'react-helmet';
import { Link, NavLink } from 'react-router-dom/cjs/react-router-dom.min';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import HomeHeader from '../components/HomeHeader';
import HomeFooter from '../components/HomeFooter';
import LoanRef from '../components/LoanRef';

const LoanReferralProgram = () => {
  return (
    <div>
      {' '}
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link href="css/custom.css" rel="stylesheet" type="text/css" />
        <title>Anex Finserv</title>
      </Helmet>
      <div>
        <HomeHeader />
        <div className="banner_inner">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-7 col-md-6 col-12">
                <div className="usp_titl">
                  <strong>Anex Finserv India's Leading Loan Distributor</strong>
                </div>
                <div className="man_img">
                  <img src="images/man_img.png" alt />
                </div>
                <div className="usp_sec">
                  <div className="usp_points">
                    <div className="usp_name"> Portfolio</div>
                    <div className="usp_no">200+</div>
                    <span>Distinguished Partner Banks and NBFCs</span>
                  </div>
                  <div className="usp_points blue_point">
                    <div className="usp_name"> Portfolio</div>
                    <div className="usp_no">200+</div>
                    <span>Distinguished Partner Banks and NBFCs</span>
                  </div>
                  <div className="usp_points blue_point">
                    <div className="usp_name"> Portfolio</div>
                    <div className="usp_no">200+</div>
                    <span>Distinguished Partner Banks and NBFCs</span>
                  </div>
                  <div className="usp_points">
                    <div className="usp_name"> Portfolio</div>
                    <div className="usp_no">200+</div>
                    <span>Distinguished Partner Banks and NBFCs</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 col-md-6 col-12">
                <LoanRef />
              </div>
            </div>
          </div>
        </div>
        <div className="elegb_outer">
          <div className="container">
            <div className="row">
              <div className="col-lg-7 col-md-6 col-12">
                <div className="elegb_sec">
                  <div className="elegb_sub">
                    Loan Partner Program Registration - Anex Finserv (CAN)
                  </div>
                  <div className="elegb_titl">
                    What Are The Eligibility Criteria For The CAN Registration
                    Process?
                  </div>
                  <div className="elegb_txt">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident
                    <ul>
                      <li>
                        <strong>Age</strong> You must be over 25 years of age.
                      </li>
                      <li>
                        <strong>Nationality</strong> You must be a Resident
                        Citizen of India
                      </li>
                      <li>
                        <strong>Education Qualifications</strong> There are no
                        educational requirements to qualify as a CAN Loan
                        Partner
                      </li>
                      <li>
                        <strong>Professional Qualifications</strong>
                        Whether you are a working professional, or a business
                        owner, you are welcome to register as a CAN Loan
                        Franchise
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 col-md-6 col-12 text-end">
                <img src="images/reg_img.jpg" />
              </div>
            </div>
          </div>
        </div>
        <div className="full_sec text-center">
          <div className="container">
            <div className="loan_titl">
              Who Can Become a Partner with Anex Finserv
            </div>
            <div className="prtnr_btn">
              <a href="#prtnrform">Become A Partner</a>
            </div>
          </div>
        </div>
        <div className="reg_process text-center">
          <div className="container">
            <div className="procs_sub">Our Process</div>
            <div className="procs_titl">
              Process Of Anex Finserv Registration
            </div>
            <div className="procs_up_txt">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.{' '}
            </div>
            <div className="row">
              <div className="col-lg-3 col-md-6 col-12 brdr_right">
                <div className="proc_conr">
                  <div className="proc_ico">
                    <img src="images/process_ico.png" />
                  </div>
                  <div className="proc_name">Apply</div>
                  <div className="proc_txt">
                    Click on 'Apply Now' and fill in the Loan Partner
                    registration details, and submit along with documents.
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-12 brdr_right">
                <div className="proc_conr">
                  <div className="proc_ico">
                    <img src="images/process_ico2.png" />
                  </div>
                  <div className="proc_name">Expect a Call</div>
                  <div className="proc_txt">
                    The CAN support team will call you to guide, and co-ordinate
                    a meeting with you.
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-12 brdr_right">
                <div className="proc_conr">
                  <div className="proc_ico">
                    <img src="images/process_ico3.png" />
                  </div>
                  <div className="proc_name">Meeting</div>
                  <div className="proc_txt">
                    At the meeting, a CAN Manager will explain to you all you
                    need to know including the process to follow while
                    generating leads.
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-12">
                <div className="proc_conr">
                  <div className="proc_ico">
                    <img src="images/process_ico4.png" />
                  </div>
                  <div className="proc_name">Sign Agreement</div>
                  <div className="proc_txt">
                    Click on 'Apply Now' and fill in the Loan Partner
                    registration details, and submit along with documents.
                  </div>
                </div>
              </div>
            </div>
            <div className="common_btn text-center mt-lg-5 mt-3">
              <a href="#">Apply Now</a>
            </div>
          </div>
        </div>
        <div className="benf_process text-center">
          <div className="container">
            <div className="procs_sub">Benefits and Perks</div>
            <div className="procs_titl">
              Why Partner With Anex Finserv - Benefits And Perks
            </div>
            <div className="procs_up_txt">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.{' '}
            </div>
            <hr />
            <div className="tabs_Sec pt-lg-4">
              <div className="d-flex align-items-start">
                <div
                  className="nav flex-column nav-pills"
                  id="v-pills-tab"
                  role="tablist"
                  aria-orientation="vertical"
                >
                  <a
                    className="nav-link active"
                    id="v-pills-if-tab"
                    data-toggle="pill"
                    href="#v-pills-if"
                    role="tab"
                    aria-controls="v-pills-if"
                    aria-selected="true"
                  >
                    Investment Free
                  </a>
                  <a
                    className="nav-link"
                    id="v-pills-hpi-tab"
                    data-toggle="pill"
                    href="#v-pills-hpi"
                    role="tab"
                    aria-controls="v-pills-hpi"
                    aria-selected="false"
                  >
                    High Potential Industry
                  </a>
                  <a
                    className="nav-link"
                    id="v-pills-cpr-tab"
                    data-toggle="pill"
                    href="#v-pills-cpr"
                    role="tab"
                    aria-controls="v-pills-cpr"
                    aria-selected="false"
                  >
                    Channel Partner Recognition
                  </a>
                  <a
                    className="nav-link"
                    id="v-pills-ipo-tab"
                    data-toggle="pill"
                    href="#v-pills-ipo"
                    role="tab"
                    aria-controls="v-pills-ipo"
                    aria-selected="false"
                  >
                    Instant Pay-outs
                  </a>
                  <a
                    className="nav-link"
                    id="v-pills-gs-tab"
                    data-toggle="pill"
                    href="#v-pills-gs"
                    role="tab"
                    aria-controls="v-pills-gs"
                    aria-selected="false"
                  >
                    Guaranteed Success
                  </a>
                  <a
                    className="nav-link"
                    id="v-pills-ep-tab"
                    data-toggle="pill"
                    href="#v-pills-ep"
                    role="tab"
                    aria-controls="v-pills-ep"
                    aria-selected="false"
                  >
                    Easy Process
                  </a>
                </div>
                <div className="tab-content" id="v-pills-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="v-pills-if"
                    role="tabpanel"
                    aria-labelledby="v-pills-if-tab"
                  >
                    Being a Anex Finserv Business Partner comes easy to you,
                    since you don't need to spend heavily and invest in setting
                    up shop. Start from wherever you are, and whatever you have,
                    you're assured smart returns without hard-earned deposit
                    money at risk.
                  </div>
                  <div
                    className="tab-pane fade"
                    id="v-pills-hpi"
                    role="tabpanel"
                    aria-labelledby="v-pills-hpi-tab"
                  >
                    According to estimates, the size of the total lending market
                    in India as of March 2021 was Rs. 156.9 Lakh crores and
                    still growing, with Anex Finserv doing a steady Rs 1200 plus
                    crores business per month. Jump aboard to be a part of a
                    booming finance industry.
                  </div>
                  <div
                    className="tab-pane fade"
                    id="v-pills-cpr"
                    role="tabpanel"
                    aria-labelledby="v-pills-cpr-tab"
                  >
                    By partnering with Anex Finserv Distribution Services
                    Private Limited, you're not only joining forces with the
                    best in the industry, but you also gain recognition as a
                    channel partner without restrictions on referrals, and
                    commissions!
                  </div>
                  <div
                    className="tab-pane fade"
                    id="v-pills-ipo"
                    role="tabpanel"
                    aria-labelledby="v-pills-ipo-tab"
                  >
                    Anex Finserv does not wait to transfer your earnings to your
                    earnings account until payday. As soon as your referral gets
                    approval for a loan, and disbursal of the loan takes place,
                    your money gets transferred to your Loan Franchise 'Earning
                    Account'. We call this the Credit Advisory Network Digital
                    Advantage.
                  </div>
                  <div
                    className="tab-pane fade"
                    id="v-pills-gs"
                    role="tabpanel"
                    aria-labelledby="v-pills-gs-tab"
                  >
                    We offer services and solutions that guarantee customers,
                    businesses, and partners succeed. Speed: On board Credit
                    Advisory Network Team, our speed-oriented approach brings in
                    results faster. Reliability: Our authentic reliable business
                    partner relationship with you leaves no scope for error.
                    Experience: Our 25 years in the finance industry strengthens
                    you in your quest for achievement.
                  </div>
                  <div
                    className="tab-pane fade"
                    id="v-pills-ep"
                    role="tabpanel"
                    aria-labelledby="v-pills-ep-tab"
                  >
                    Anex Finserv' online registration process enables you to be
                    become a partner in a few simple and easy steps. You just
                    need to fill out your basic information, and upload a few
                    documents. Our team gets in touch with you to get started on
                    your journey to be being a millionaire. Hop aboard,
                    together, we're going places.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="faq_outer text-center">
          <div className="container">
            <div className="procs_sub">FAQ</div>
            <div className="procs_titl">Frequently Asked Questions</div>
            <div className="accordion mt-lg-5" id="accordionExample">
              <div className="card">
                <div className="card-header" id="headingOne">
                  <h2 className="mb-0">
                    <button
                      className="btn btn-link btn-block text-left"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      Is there any Eligibility Criteria for becoming a Loan
                      Partner?
                    </button>
                  </h2>
                </div>
                <div
                  id="collapseOne"
                  className="collapse show"
                  aria-labelledby="headingOne"
                  data-parent="#accordionExample"
                >
                  <div className="card-body">
                    Anim pariatur cliche reprehenderit, enim eiusmod high life
                    accusamus terry richardson ad squid. 3 wolf moon officia
                    aute, non cupidatat skateboard dolor brunch. Food truck
                    quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor,
                    sunt aliqua put a bird on it squid single-origin coffee
                    nulla assumenda shoreditch et. Nihil anim keffiyeh
                    helvetica, craft beer labore wes anderson cred nesciunt
                    sapiente ea proident. Ad vegan excepteur butcher vice lomo.
                    Leggings occaecat craft beer farm-to-table, raw denim
                    aesthetic synth nesciunt you probably haven't heard of them
                    accusamus labore sustainable VHS.
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header" id="headingTwo">
                  <h2 className="mb-0">
                    <button
                      className="btn btn-link btn-block text-left collapsed"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      What details do we need to provide before Online Loan
                      Registration?
                    </button>
                  </h2>
                </div>
                <div
                  id="collapseTwo"
                  className="collapse"
                  aria-labelledby="headingTwo"
                  data-parent="#accordionExample"
                >
                  <div className="card-body">
                    Anim pariatur cliche reprehenderit, enim eiusmod high life
                    accusamus terry richardson ad squid. 3 wolf moon officia
                    aute, non cupidatat skateboard dolor brunch. Food truck
                    quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor,
                    sunt aliqua put a bird on it squid single-origin coffee
                    nulla assumenda shoreditch et. Nihil anim keffiyeh
                    helvetica, craft beer labore wes anderson cred nesciunt
                    sapiente ea proident. Ad vegan excepteur butcher vice lomo.
                    Leggings occaecat craft beer farm-to-table, raw denim
                    aesthetic synth nesciunt you probably haven't heard of them
                    accusamus labore sustainable VHS.
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header" id="headingThree">
                  <h2 className="mb-0">
                    <button
                      className="btn btn-link btn-block text-left collapsed"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      What are my responsibilities as a Loan Partner?
                    </button>
                  </h2>
                </div>
                <div
                  id="collapseThree"
                  className="collapse"
                  aria-labelledby="headingThree"
                  data-parent="#accordionExample"
                >
                  <div className="card-body">
                    Anim pariatur cliche reprehenderit, enim eiusmod high life
                    accusamus terry richardson ad squid. 3 wolf moon officia
                    aute, non cupidatat skateboard dolor brunch. Food truck
                    quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor,
                    sunt aliqua put a bird on it squid single-origin coffee
                    nulla assumenda shoreditch et. Nihil anim keffiyeh
                    helvetica, craft beer labore wes anderson cred nesciunt
                    sapiente ea proident. Ad vegan excepteur butcher vice lomo.
                    Leggings occaecat craft beer farm-to-table, raw denim
                    aesthetic synth nesciunt you probably haven't heard of them
                    accusamus labore sustainable VHS.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="choose_outer">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-12">
                <div className="trust_sec">
                  <div className="ch_sub">Transparent Process</div>
                  <div className="ch_titl">
                    Why Do People <strong>Trust Us</strong>
                  </div>
                  <div className="ph_img_fotter">
                    <img src="images/phone_img.png" alt />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-12">
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-12">
                    <div className="ch_sec">
                      <div className="ch_ico">
                        <img src="images/ch_ico.png" alt />
                      </div>
                      <div className="ch_name">Compare Lenders</div>
                      <div className="ch_txt">
                        You can compare and choose your lender from an
                        exhaustive list of 100+ partnered Banks &amp; NBFCs.
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-12">
                    <div className="ch_sec">
                      <div className="ch_ico">
                        <img src="images/ch_ico2.png" alt />
                      </div>
                      <div className="ch_name">Fastest Process</div>
                      <div className="ch_txt">
                        Our easy application process provides quick and seamless
                        disbursals to our clients.
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-12">
                    <div className="ch_sec">
                      <div className="ch_ico">
                        <img src="images/ch_ico3.png" alt />
                      </div>
                      <div className="ch_name">Expert Guidance</div>
                      <div className="ch_txt">
                        Our financial experts with astronomical experience
                        assist you in borrowing right.
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-12">
                    <div className="ch_sec">
                      <div className="ch_ico">
                        <img src="images/ch_ico4.png" alt />
                      </div>
                      <div className="ch_name">Nationwide Presence</div>
                      <div className="ch_txt">
                        With a PAN India presence, we are able to cater to the
                        financial needs of individuals in every corner.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Footer */}
        <footer className="footer_outer">
          <div className="container">
            <div className="row px-lg-5">
              <div className="col-lg-4 col-md-6 col-12">
                <div className="abt_foot">
                  <div className="foot_titl">About Us</div>
                  <div className="foot_txt pe-lg-5">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                  </div>
                  <div className="social_sec">
                    <a href="#">
                      <img src="images/fb.png" />
                    </a>
                    <a href="#">
                      <img src="images/linkd.png" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-12 ps-lg-5">
                <div className="abt_foot">
                  <div className="foot_titl">Business</div>
                  <div className="foot_txt">
                    <ul>
                      <li>
                        <a href="#">Home Loan</a>
                      </li>
                      <li>
                        <a href="#">Personal Loan</a>
                      </li>
                      <li>
                        <a href="#">Business Loan</a>
                      </li>
                      <li>
                        <a href="#">Car Loan</a>
                      </li>
                      <li>
                        <a href="#">Car Refinance</a>
                      </li>
                      <li>
                        <a href="#">Used Car Loan</a>
                      </li>
                      <li>
                        <a href="#">Gold Loan</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-12 ps-lg-4">
                <div className="abt_foot">
                  <div className="foot_titl">CALCULATORS</div>
                  <div className="foot_txt">
                    <ul>
                      <li>
                        <a href="#">Personal Loan</a>
                      </li>
                      <li>
                        <a href="#">Home Loan</a>
                      </li>
                      <li>
                        <a href="#">Business Loan</a>
                      </li>
                      <li>
                        <a href="#">Loan Against Property</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-6 col-12">
                <div className="abt_foot">
                  <div className="foot_titl">CONTACT DETAILS</div>
                  <div className="foot_txt">
                    <ul>
                      <li>
                        <a href="#">+91 0123 456 789</a>
                      </li>
                      <li>
                        <a href="#">+91 2134 568 789</a>
                      </li>
                      <li>
                        <a href="#">@Tech Query</a>
                      </li>
                      <li>
                        <a href="#">@Sales Query</a>
                      </li>
                      <li>
                        <a href="#">@Service Request</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="footer_btm">
              <div className="row">
                <div className="col-lg-6 ol-md-6 col-12">
                  <div className="copy_right">
                    Copyright @2023 All Rights Reserved
                  </div>
                </div>
                <div className="col-lg-6 ol-md-6 col-12">
                  <div className="copy_right text-end">
                    <a href="#"> Privacy & Policy</a> |{' '}
                    <Link data-toggle="modal" data-target="#terms_condition">
                      terms &amp; conditions
                    </Link>
                  </div>
                </div>
                {/* <!-- Modal --> */}
                <div
                  className="modal fade"
                  id="terms_condition"
                  data-backdrop="static"
                  tabIndex="-1"
                  role="dialog"
                  aria-labelledby="staticBackdropLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog" role="document">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title" id="staticBackdropLabel">
                          Terms And Conditions
                        </h5>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <p>
                          Please understand the following terms and conditions{' '}
                          <br /> 1. Commision payout amount will depend on
                          various condition like Loan Type,Processing Bank,
                          approveed interest rate etc. <br /> 2. Payout
                          typically creited to bank account within 10th day of
                          each month but can be delayed occationaly. <br /> 3.
                          Final Payout amount will be notified only after Loan
                          Amount disbursed to Customer's Account. <br /> 4. In
                          case of any query/clarification you need to notify
                          within 15 Calendar Days.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default LoanReferralProgram;

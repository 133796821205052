import React from 'react';
import Layout from '../components/Layout';
import '../agentLoginDashboard.css';
import { useEffect } from 'react';
import { useState } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const AgentDashboard = () => {
  const [LoggedInAgent, setLoggedInAgnet] = useState([]);
  const [searchAgentDashboard, setsearchAgentDashboard] = useState({
    startDate: '',
    endDate: '',
    searchData: null,
  });

  const [searchAgentPayout, setsearchAgentPayout] = useState({
    payoutstartD: '',
    payoutendD: '',
    payoutsearchData: null,
  });

  const { startDate, endDate, searchData } = searchAgentDashboard;
  const { payoutstartD, payoutendD, payoutsearchData } = searchAgentPayout;

  useEffect(() => {
    const jwt = JSON.parse(localStorage.getItem('jwt'));

    fetch(
      `${
        process.env.REACT_APP_API_URL
      }/agents/getLoggedInAgentDashboardView?loggedInAgentId=${
        JSON.parse(localStorage.getItem('loggedInAgent')).agentId
      }`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${jwt}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setLoggedInAgnet(data);
      });
  }, []);

  const searchSubmit = (e) => {
    e.preventDefault();
    const jwt = JSON.parse(localStorage.getItem('jwt'));
    const fetchdata = async () => {
      const sDate = moment(startDate).format('DD-MM-YYYY');
      const eDate = moment(endDate).format('DD-MM-YYYY');

      try {
        await fetch(
          `${
            process.env.REACT_APP_API_URL
          }/agents/agentDashboardViewLoanCountsOnly?endDate=${eDate}&loggedInAgentId=${
            JSON.parse(localStorage.getItem('loggedInAgent')).agentId
          }&startDate=${sDate}`,
          {
            method: 'GET',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              Authorization: `Bearer ${jwt}`,
            },
          }
        )
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            setsearchAgentDashboard({
              ...searchAgentDashboard,
              searchData: data,
            });
          });
      } catch (error) {
        setsearchAgentDashboard({
          ...searchAgentDashboard,
          searchData: null,
        });
      }
    };
    fetchdata();
  };

  const searchpayoutSubmit = (e) => {
    e.preventDefault();
    const jwt = JSON.parse(localStorage.getItem('jwt'));
    const fetchdata = async () => {
      const sDate = moment(payoutstartD).format('DD-MM-YYYY');
      const eDate = moment(payoutendD).format('DD-MM-YYYY');
      try {
        await fetch(
          `${
            process.env.REACT_APP_API_URL
          }/agents/agentDashboardViewPayoutDataOnly?endDate=${eDate}&loggedInAgentId=${
            JSON.parse(localStorage.getItem('loggedInAgent')).agentId
          }&startDate=${sDate}`,
          {
            method: 'GET',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              Authorization: `Bearer ${jwt}`,
            },
          }
        )
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            setsearchAgentPayout({
              ...searchAgentPayout,
              payoutsearchData: data,
            });
          });
      } catch (error) {
        setsearchAgentPayout({
          ...searchAgentPayout,
          payoutsearchData: null,
        });
      }
    };
    fetchdata();
  };

  const searchHandleChange = (name) => (e) => {
    setsearchAgentDashboard({
      ...searchAgentDashboard,
      [name]: e.target.value,
    });
  };

  const searchHandleChangepayout = (name) => (e) => {
    setsearchAgentPayout({
      ...searchAgentPayout,
      [name]: e.target.value,
    });
  };

  const leadlog = (data) => {
    return (
      <>
        <p className="mt-4 font-weight-bold text-center">
          Showing data for date range:{' '}
          <span className="text-primary">{data.loanDataDateRange}</span>
        </p>
        <div className="table_cont mt-4">
          <div className="leadlog">
            <strong>Lead Logged In :</strong>
            <span>{data.submittedLeadCount}</span>
          </div>
          <div className="leadsub">
            <strong>Lead Submitted to Bank :</strong>
            <span>{data.leadSubmittedToBankAfterVerificationCount}</span>
          </div>
          <div className="disb">
            <strong>Disbursed :</strong>
            <span>{data.disbursedLeadCount}</span>
          </div>
          <div className="reject">
            <strong>Rejected :</strong>
            <span>{data.rejectedLeadCount}</span>
          </div>
        </div>
      </>
    );
  };

  const payoutlead = (data) => {
    return (
      <>
        <p className="mt-4 font-weight-bold text-center">
          Showing data for date range:{' '}
          <span className="text-primary">{data.payoutDataDateRange}</span>
        </p>
        <div className="table_cont">
          <div className="leadlog">
            <strong>Pay Earned :</strong>
            <span>{data.earnedNetPayout}</span>
          </div>
          <div className="leadsub">
            <strong>Claimed Payout :</strong>
            <span>{data.claimedPayout}</span>
          </div>
          <div className="disb">
            <strong>Balance :</strong>
            <span>{data.walletBalance}</span>
          </div>
        </div>
      </>
    );
  };

  return (
    <Layout>
      <div className="agent_inner">
        <div className="container">
          <div className="agent_cont">
            <div className="row">
              <div className="col-lg-3 col-md-3 col-12 offset-lg-3">
                <div className="agent_img">
                  {LoggedInAgent.agentProfileImage == undefined ? (
                    <img
                      src="images/user_im2.png"
                      className="img-fluid"
                      alt="profile"
                    />
                  ) : (
                    <img
                      src={`data:image/png;base64, ${LoggedInAgent.agentProfileImage}`}
                      className="img-fluid"
                      alt="profile"
                    />
                  )}
                </div>
              </div>
              <div className="col-lg-5 col-md-9 col-12">
                <div className="agent_content">
                  <div className="name_sec">
                    <span>Name:</span>
                    <span>{LoggedInAgent.agentName}</span>
                  </div>
                  <div className="name_sec">
                    <span>Supervisor Name:</span>
                    <span>{LoggedInAgent.agentSupervisorName}</span>
                  </div>
                  <div className="name_sec">
                    <span>Sub Agent Code:</span>
                    <span>{LoggedInAgent.subAgentCode}</span>
                  </div>
                  <div className="name_sec">
                    <span>PAN Number:</span>
                    <span>{LoggedInAgent.agentPanNumber}</span>
                  </div>
                  <div className="name_sec">
                    <span>Agent Code:</span>
                    <span>{LoggedInAgent.agentCode}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="table_data">
          <div className="date_range">
            <form
              id="form"
              name="form"
              onSubmit={searchSubmit}
              className="form-inline"
            >
              <div className="form-group">
                <label for="startDate">Start Date</label>
                <input
                  id="startDate"
                  name="startDate"
                  type="date"
                  className="form-control"
                  onChange={searchHandleChange('startDate')}
                  required
                />
                &nbsp;
                <label for="endDate">End Date</label>
                <input
                  id="endDate"
                  name="endDate"
                  type="date"
                  className="form-control"
                  onChange={searchHandleChange('endDate')}
                  required
                />
                <input className="srch_btn" type="submit" value="Search" />
              </div>
            </form>
          </div>

          {searchData !== null ? leadlog(searchData) : leadlog(LoggedInAgent)}
        </div>

        <div className="table_data">
          <div className="ch_titl text-center">
            Payout <strong>Data</strong>
          </div>
          <div className="date_range">
            <form
              id="form2"
              name="form"
              className="form-inline"
              onSubmit={searchpayoutSubmit}
            >
              <div className="form-group">
                <label for="startDate">Start Date</label>
                <input
                  id="startDate"
                  name="payoutstartD"
                  type="date"
                  className="form-control"
                  onChange={searchHandleChangepayout('payoutstartD')}
                  required
                />
                &nbsp;
                <label for="endDate">End Date</label>
                <input
                  id="endDate"
                  name="payoutendD"
                  type="date"
                  className="form-control"
                  onChange={searchHandleChangepayout('payoutendD')}
                  required
                />
                <input className="srch_btn" type="submit" value="Search" />
              </div>
            </form>
          </div>
          {payoutsearchData !== null
            ? payoutlead(payoutsearchData)
            : payoutlead(LoggedInAgent)}
        </div>
      </div>
      <div className="loan_procs">
        <div className="container">
          <ul>
            <li>
              <Link to="/viewAgentPayout?loan=Pending">
                <img
                  src="images/ss.jpg"
                  className="img-fluid"
                  alt="pending application"
                />
                <span>Pending Application</span>
              </Link>
            </li>
            <li>
              <Link to="/viewAgentPayout?loan=Payouts">
                <img
                  src="images/ss2.jpg"
                  className="img-fluid"
                  alt="processed loan"
                />
                <span>Processed Loan Details</span>
              </Link>
            </li>
            <li>
              <Link to="/payout-withdraw">
                <img
                  src="images/6617-min.jpg"
                  className="img-fluid"
                  alt="payout withdraw"
                />
                <span>Payout Withdraw</span>
              </Link>
            </li>
          </ul>
        </div>
      </div>{' '}
    </Layout>
  );
};

export default AgentDashboard;

import React from 'react';
import Layout from '../components/Layout';
import { ToastContainer, toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const ValidateOtp = ({ history }) => {
  const MySwal = withReactContent(Swal);

  const {
    register,
    handleSubmit,
    watch,
    trigger,
    formState: { errors },
    reset,
  } = useForm();

  const [values, setValues] = React.useState({
    authorization: false,
  });

  const { authorization } = values;

  // const activeuserhandleChange = (name) => (event) => {
  //   setActivateUser({
  //     ...activateUser,
  //     [name]: event.target.value,
  //   });
  // };

  const forgotSubmit = (data) => {
    // e.preventDefault();

    fetch(
      `${process.env.REACT_APP_API_URL}/agents/validateOTP/${data.otp}?email=${data.email}&mobile=${data.mobile}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        // history.push('/activateAgent');
        // alert(JSON.stringify(data));

        if (data.status === 'Success') {
          setValues({
            authorization: true,
          });
        } else {
          toast.error('Check your OTP ', {
            position: 'top-right',
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          });
        }
      })
      .catch((err) => {
        toast.error('Check your OTP', {
          position: 'top-right',
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      });
  };

  const resetPasswordSubmit = (data) => {
    // e.preventDefault();

    fetch(`${process.env.REACT_APP_API_URL}/user/activateUser`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        userName: data.userName,
        newPassword: data.newPassword,
        confirmPassword: data.confirmPassword,
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.value !== 'resetSuccessful') {
          MySwal.fire({
            text: `${data.value}`,
          });
        } else if (data.value === 'resetSuccessful') {
          MySwal.fire({
            text: `${data.value}`,
          });
          history.push('/login');
        }
      })
      .catch((err) => {
        toast.error('Something went wrong', {
          position: 'top-right',
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      });
  };

  const checkIfNumber = (event) => {
    const regex = new RegExp(
      /(^\d*$)|(Backspace|Tab|Delete|ArrowLeft|ArrowRight)/
    );

    return !event.key.match(regex) && event.preventDefault();
  };

  return (
    <>
      <Layout>
        <div className="inner_banner text-center">
          <div className="container">
            {' '}
            {authorization ? 'Activate User' : 'Validate OTP'}
          </div>
        </div>
        <ToastContainer />
        <div className="container mb-5">
          <div className="row justify-content-center">
            <div className="col-lg-5">
              <div className="reset-form shadow-sm">
                {authorization === false && (
                  <>
                    <form onSubmit={handleSubmit(forgotSubmit)}>
                      <label htmlFor="email">Email Address</label>
                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <span
                            className="input-group-text rounded-0"
                            id="email"
                          >
                            <i className="fa-solid fa-envelope"></i>
                          </span>
                        </div>
                        <input
                          type="email"
                          className="form-control rounded-0"
                          id="email"
                          placeholder="Email"
                          aria-describedby="email"
                          {...register('email', {
                            required: 'Email is required',
                            pattern: {
                              value:
                                /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                              message: 'Please enter a valid email',
                            },
                          })}
                          onKeyUp={() => {
                            trigger('email');
                          }}
                        />
                      </div>
                      {errors.email && (
                        <p className="text-danger">{errors.email.message}</p>
                      )}
                      <label htmlFor="phone">Mobile Number</label>
                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <span
                            className="input-group-text rounded-0"
                            id="mobile"
                          >
                            <i className="fa-solid fa-mobile"></i>
                          </span>
                        </div>
                        <input
                          type="number"
                          className="form-control rounded-0"
                          id="mobile"
                          placeholder="Mobile Number"
                          aria-describedby="mobile"
                          {...register('mobile', {
                            pattern: {
                              value: /^[0-9]{10}$/,
                              message: 'Please enter 10 digit phone no',
                            },
                          })}
                          onKeyUp={() => {
                            trigger('mobile');
                          }}
                          onKeyDown={(e) => checkIfNumber(e)}
                        />
                      </div>
                      {errors.mobile && (
                        <p className="text-danger">{errors.mobile.message}</p>
                      )}

                      <label htmlFor="otp">OTP</label>
                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <span className="input-group-text rounded-0" id="otp">
                            <i className="fa-solid fa-lock"></i>
                          </span>
                        </div>
                        <input
                          type="text"
                          className="form-control rounded-0"
                          id="otp"
                          placeholder="OTP"
                          aria-describedby="otp"
                          {...register('otp', {
                            required: 'otp is required',
                          })}
                          onKeyUp={() => {
                            trigger('otp');
                          }}
                        />
                      </div>
                      {errors.otp && (
                        <p className="text-danger mt-1">{errors.otp.message}</p>
                      )}
                      <button
                        type="submit"
                        className="btn btn-primary rounded-0"
                      >
                        Validate OTP
                      </button>
                    </form>
                  </>
                )}

                <br />
                {authorization === true && (
                  <>
                    <form onSubmit={handleSubmit(resetPasswordSubmit)}>
                      <label htmlFor="email">Email Address</label>
                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <span
                            className="input-group-text rounded-0"
                            id="email"
                          >
                            <i className="fa-solid fa-envelope"></i>
                          </span>
                        </div>
                        <input
                          type="email"
                          className="form-control rounded-0"
                          id="userName"
                          placeholder="Username"
                          aria-describedby="userName"
                          defaultValue={watch().email}
                          {...register('userName', {
                            required: 'User name is required',
                            pattern: {
                              value:
                                /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                              message: 'Please enter a valid username',
                            },
                          })}
                          onKeyUp={() => {
                            trigger('userName');
                          }}
                          readOnly
                        />
                      </div>
                      {errors.userName && (
                        <p className="text-danger mt-1">
                          {errors.userName.message}
                        </p>
                      )}

                      <label htmlFor="password">Password</label>
                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <span
                            className="input-group-text rounded-0"
                            id="password"
                          >
                            <i className="fa-solid fa-lock"></i>
                          </span>
                        </div>
                        <input
                          type="password"
                          className="form-control rounded-0"
                          id="password"
                          placeholder="Password"
                          aria-describedby="password"
                          {...register('newPassword', {
                            required: 'Password is required',
                            minLength: {
                              value: 5,
                              message: 'Password must be at least 5 characters',
                            },
                          })}
                          onKeyUp={() => {
                            trigger('newPassword');
                          }}
                        />
                      </div>
                      {errors.newPassword && (
                        <p className="text-danger">
                          {errors.newPassword.message}
                        </p>
                      )}
                      <label htmlFor="cpassword">Confirm Password</label>
                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <span
                            className="input-group-text rounded-0"
                            id="cpassword"
                          >
                            <i className="fa-solid fa-lock"></i>
                          </span>
                        </div>
                        <input
                          type="password"
                          className="form-control rounded-0"
                          id="confirmPassword"
                          placeholder="Confirm password"
                          aria-describedby="confirmPassword"
                          {...register('confirmPassword', {
                            required: 'Password is required',
                            minLength: {
                              value: 5,
                              message: 'Password must be at least 5 characters',
                            },
                            validate: (val) => {
                              if (watch('newPassword') != val) {
                                return 'Your passwords do no match';
                              }
                            },
                          })}
                          onKeyUp={() => {
                            trigger('confirmPassword');
                          }}
                        />
                      </div>
                      {errors.confirmPassword && (
                        <p className="text-danger">
                          {errors.confirmPassword.message}
                        </p>
                      )}

                      <button
                        type="submit"
                        className="btn btn-success rounded-0"
                      >
                        Reset
                      </button>
                    </form>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default ValidateOtp;

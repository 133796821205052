import React, { useState, useEffect } from 'react';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useForm, useWatch } from 'react-hook-form';
import moment from 'moment';
import axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const CustomerPersonalDetails = ({ CPersonalDetails, ChangeStatusValue }) => {
  const MySwal = withReactContent(Swal);

  const {
    register,
    handleSubmit,
    watch,
    trigger,
    formState: { errors },
    reset,
  } = useForm();

  const [agentpa, setAgentpa] = useState(
    CPersonalDetails.loanInformation.agentWisePayout
  );

  const handleagent = (e, i) => {
    const { name, value } = e.target;
    const onChangeVal = [...agentpa];
    onChangeVal[i][name] = value;
    setAgentpa(onChangeVal);
  };

  const [checkdata, setCheckdata] = useState(false);

  const [daterange, setDateRange] = useState(null);
  const [step, setStep] = useState(0);

  const [descriptionDetails, setDescriptionDetails] = useState({
    applicationIdn: CPersonalDetails.loanInformation.applicationId,
    applicationsubdate: CPersonalDetails.loanInformation
      .applicationSubmitToBankDate
      ? moment(
          CPersonalDetails.loanInformation.applicationSubmitToBankDate
        ).format('YYYY-MM-DD')
      : moment(new Date()).format('YYYY-MM-DD'),
    additionalcomment:
      CPersonalDetails.loanInformation.pendingApprovalAdditionalComments,
    approvaldate: CPersonalDetails.loanInformation.loanApprovalDate
      ? moment(CPersonalDetails.loanInformation.loanApprovalDate).format(
          'YYYY-MM-DD'
        )
      : moment(new Date()).format('YYYY-MM-DD'),
    loanAccountNumber: CPersonalDetails.loanInformation.loanAccountNumber,
    approvedinterest: CPersonalDetails.loanInformation.loanApprovalInterestRate,
    approvedinterest: CPersonalDetails.loanInformation.loanApprovalInterestRate,
    approvedamount: CPersonalDetails.loanInformation.loanApprovalAmount,
    approvedtenture: CPersonalDetails.loanInformation.loanApprovalTenure,
    approveddocument: [],
    formData: '',
    additionalcommentbankapp:
      CPersonalDetails.loanInformation.bankApprovalAdditionalComments,
    rejectionreason: CPersonalDetails.loanInformation.bankRejectionDetails,
    banknegotiate: CPersonalDetails.loanInformation.bankNegotiationDetails,
    negotiationcusdesc:
      CPersonalDetails.loanInformation.customerNegotiationDetails,
    negotiationbankdesc:
      CPersonalDetails.loanInformation.bankNegotiationDetails,
    additionalcommentunderagre:
      CPersonalDetails.loanInformation.underAgreementDetails,
    agreesigndate: CPersonalDetails.loanInformation.agreementSignedDate
      ? moment(CPersonalDetails.loanInformation.agreementSignedDate).format(
          'YYYY-MM-DD'
        )
      : moment(new Date()).format('YYYY-MM-DD'),
    approveddocagresign: '',
    agreesignaddicom:
      CPersonalDetails.loanInformation.agreementSignedAdditionalComment,
    leadorgdate: moment(
      CPersonalDetails.loanInformation.leadInitiateDate
    ).format('YYYY-MM-DD'),
    disburseddate: CPersonalDetails.loanInformation.disbursedDate
      ? moment(CPersonalDetails.loanInformation.disbursedDate).format(
          'YYYY-MM-DD'
        )
      : moment(new Date()).format('YYYY-MM-DD'),

    disbursedamount: CPersonalDetails.loanInformation.disbursedAmount,
    disbursedbank: CPersonalDetails.loanInformation.disbursedBankName,
    agentpanno: CPersonalDetails.loanInformation.agentPanNumber,
    paypoutpercen: CPersonalDetails.loanInformation.agentPayoutPercentage,
    payoutamou: CPersonalDetails.loanInformation.agentPayoutAmount,
    payoutagentName: CPersonalDetails.loanInformation.agentName,
    payoutbankn: CPersonalDetails.loanInformation.agentPayoutBankName,
    payoutdate: moment(
      CPersonalDetails.loanInformation.agentPaymentDate
    ).format('YYYY-MM-DD'),
    payoutmode: CPersonalDetails.loanInformation.paymentMode,
    payoutaddcomm:
      CPersonalDetails.loanInformation.agentPayoutAdditionalComments,
    proccesssumm: CPersonalDetails.loanInformation.loanClosingComment,
    description: '',
  });

  const {
    applicationIdn,
    description,
    applicationsubdate,
    additionalcomment,
    approvaldate,
    loanAccountNumber,
    approvedinterest,
    approvedamount,
    approvedtenture,
    approveddocument,
    additionalcommentbankapp,
    rejectionreason,
    postData,
    banknegotiate,
    negotiationcusdesc,
    negotiationbankdesc,
    additionalcommentunderagre,
    agreesigndate,
    approveddocagresign,
    agreesignaddicom,
    leadorgdate,
    disburseddate,
    disbursedamount,
    disbursedbank,
    agentpanno,
    paypoutpercen,
    payoutamou,
    payoutbankn,
    payoutagentName,
    payoutdate,
    payoutmode,
    payoutaddcomm,
    proccesssumm,
    formData,
  } = descriptionDetails;

  const [loanInformations, setLoanInformations] = useState({
    actualRateOfInterest: null,
    agentEmailId: null,
    agentFirstName: null,
    agentId: CPersonalDetails.loanInformation.agentId,
    agentLastName: null,
    agentMiddleName: null,
    agentMobileNumber: null,
    agentName: null,
    alternativeMobileNumber: null,
    applicationId: CPersonalDetails.loanInformation.applicationId,
    approxRateOfInterest: null,
    assignedByEmployeeId: null,
    assignedByEmployeeName: null,
    assignedDate: null,
    assignedToEmployeeId: null,
    assignedToEmployeeName: null,
    bankMICRCode: null,
    countryOfBirth: null,
    countryOfResidence: null,
    customerDOB: CPersonalDetails.loanInformation.customerDOB,
    customerFirstName: CPersonalDetails.loanInformation.customerFirstName,
    customerLastName: CPersonalDetails.loanInformation.customerLastName,
    customerMiddleName: CPersonalDetails.loanInformation.customerMiddleName,
    customerMobileNumber: CPersonalDetails.loanInformation.customerMobileNumber,
    customerName: null,
    customerOfficialEMailId: null,
    customerPersonalEmailId:
      CPersonalDetails.loanInformation.customerPersonalEmailId,
    designation: null,
    education: CPersonalDetails.loanInformation.education,
    religion: CPersonalDetails.loanInformation.religion,
    preferredCommunicationAddress:
      CPersonalDetails.loanInformation.preferredCommunicationAddress,
    preferredCommunicationLanguage:
      CPersonalDetails.loanInformation.preferredCommunicationLanguage,
    officeOrBusinessAddress:
      CPersonalDetails.loanInformation.officeOrBusinessAddress,
    officeOrBusinessLandMark:
      CPersonalDetails.loanInformation.officeOrBusinessLandMark,
    officeOrBusinessCityOrVillage:
      CPersonalDetails.loanInformation.officeOrBusinessCityOrVillage,
    officeOrBusinessState:
      CPersonalDetails.loanInformation.officeOrBusinessState,
    officeOrBusinessPincode:
      CPersonalDetails.loanInformation.officeOrBusinessPincode,
    officeOrBusinessPhone:
      CPersonalDetails.loanInformation.officeOrBusinessPhone,
    currentCompanyName: CPersonalDetails.loanInformation.currentCompanyName,
    existingCustomerId: null,
    fatherName: null,
    kycNumber: null,
    loanAmountAppliedFor: CPersonalDetails.loanInformation.loanAmountAppliedFor,
    loanId: CPersonalDetails.loanInformation.loanId,
    loanLeadFulfillmentId: null,
    loanLeadId: CPersonalDetails.loanInformation.loanLeadId,
    loanTenure: CPersonalDetails.loanInformation.loanTenure,
    loanType: CPersonalDetails.loanInformation.loanType,
    maritalStatus: null,
    mobileNumber: null,
    motherMaidenName: null,
    name: null,
    noOfYearsInPresentResidenceAddress:
      CPersonalDetails.loanInformation.noOfYearsInPresentResidenceAddress,
    no_of_dependents: CPersonalDetails.loanInformation.no_of_dependents,
    noofYearsatCurrentCity: null,
    noofYearsatPermanentResidenceAddress: null,
    officeAddress: null,
    officeLandLineNumber: null,
    officeLandMark: null,
    permanentResidenceLandMark: null,
    permanentResidencePhoneNumber: null,
    placeOrCityOfBirth: null,
    presentCompanyWorkExperience: null,
    presentResidenceLandMark:
      CPersonalDetails.loanInformation.presentResidenceLandMark,
    presentResidentialAddress:
      CPersonalDetails.loanInformation.presentResidentialAddress,
    presentResidentialCityOrVillage:
      CPersonalDetails.loanInformation.presentResidentialCityOrVillage,
    presentResidentialPhoneNumber:
      CPersonalDetails.loanInformation.presentResidentialPhoneNumber,
    presentResidentialPincode:
      CPersonalDetails.loanInformation.presentResidentialPincode,
    presentResidentialState:
      CPersonalDetails.loanInformation.presentResidentialState,
    previousCompanyName: null,
    processingBankId: CPersonalDetails.loanInformation.processingBankId,
    processingBankName: CPersonalDetails.loanInformation.processingBankName,
    residentialStatus: null,
    spouseDOB: null,
    spouseMobileNumber: null,
    spouseName: null,
    spouseOccupation: null,
    status: null,
    taxIdentificationNumber: null,
    totalWorkExperience: null,
    yearinwhichBankAccountOpenedwithBranch: null,
    successmessage: '',
    errormessage: '',
  });

  const {
    actualRateOfInterest,
    agentEmailId,
    agentFirstName,
    agentId,
    agentLastName,
    agentMiddleName,
    agentMobileNumber,
    agentName,
    officeOrBusinessPhone,
    preferredCommunicationAddress,
    preferredCommunicationLanguage,
    officeOrBusinessAddress,
    officeOrBusinessLandMark,
    officeOrBusinessCityOrVillage,
    officeOrBusinessState,
    officeOrBusinessPincode,
    currentCompanyName,
    alternativeMobileNumber,
    applicationId,
    approxRateOfInterest,
    assignedByEmployeeId,
    assignedByEmployeeName,
    assignedDate,
    assignedToEmployeeId,
    assignedToEmployeeName,
    bankMICRCode,
    countryOfBirth,
    countryOfResidence,
    customerDOB,
    customerFirstName,
    customerLastName,
    customerMiddleName,
    customerMobileNumber,
    customerName,
    customerOfficialEMailId,
    customerPersonalEmailId,
    designation,
    education,
    religion,
    existingCustomerId,
    fatherName,
    kycNumber,
    loanAmountAppliedFor,
    loanId,
    loanLeadFulfillmentId,
    loanLeadId,
    loanTenure,
    loanType,
    maritalStatus,
    mobileNumber,
    motherMaidenName,
    name,
    noOfYearsInPresentResidenceAddress,
    no_of_dependents,
    noofYearsatCurrentCity,
    noofYearsatPermanentResidenceAddress,
    officeAddress,
    officeLandLineNumber,
    officeLandMark,
    permanentResidenceLandMark,
    permanentResidencePhoneNumber,
    placeOrCityOfBirth,
    presentCompanyWorkExperience,
    presentResidenceLandMark,
    presentResidentialAddress,
    presentResidentialCityOrVillage,
    presentResidentialPhoneNumber,
    presentResidentialPincode,
    presentResidentialState,
    previousCompanyName,
    processingBankId,
    processingBankName,
    residentialStatus,
    spouseDOB,
    spouseMobileNumber,
    spouseName,
    spouseOccupation,
    status,
    taxIdentificationNumber,
    totalWorkExperience,
    yearinwhichBankAccountOpenedwithBranch,
    successmessage,
    errormessage,
  } = loanInformations;

  useEffect(() => {
    var pendingBankApprovalDetails =
      ChangeStatusValue === 'Pending Bank Approval'
        ? CPersonalDetails.loanInformation.pendingBankApprovalDetails
        : '';
    var bankApprovalDetails =
      ChangeStatusValue === 'Bank Approved'
        ? CPersonalDetails.loanInformation.bankApprovalDetails
        : '';
    var bankRejectionDetails =
      ChangeStatusValue === 'Bank Rejected'
        ? CPersonalDetails.loanInformation.bankRejectionDetails
        : '';
    var bankNegotiationDetails =
      ChangeStatusValue === 'Under Bank Negitiation'
        ? CPersonalDetails.loanInformation.bankNegotiationDetails
        : '';
    var customerNegotiationDetails =
      ChangeStatusValue === 'Under Customer Negotiation'
        ? CPersonalDetails.loanInformation.customerNegotiationDetails
        : '';
    var agreementDetails =
      ChangeStatusValue === 'Under Agreement Review'
        ? CPersonalDetails.loanInformation.agreementDetails
        : '';
    var disbursedDetails =
      ChangeStatusValue === 'Disbursed'
        ? CPersonalDetails.loanInformation.disbursedDetails
        : '';
    var agentPayoutDetails =
      ChangeStatusValue === 'Agent Payout'
        ? CPersonalDetails.loanInformation.agentPayoutDetails
        : '';

    var agreementSigned = ChangeStatusValue === 'Agreement Signed' ? '' : '';

    var changePropsValue =
      pendingBankApprovalDetails ||
      bankApprovalDetails ||
      bankRejectionDetails ||
      bankNegotiationDetails ||
      customerNegotiationDetails ||
      agreementDetails ||
      disbursedDetails ||
      agentPayoutDetails ||
      agreementSigned;

    setDescriptionDetails({
      ...descriptionDetails,
      description: changePropsValue,
    });
  }, [ChangeStatusValue]);

  const nextStep = (e) => {
    e.preventDefault();
    setStep(step + 1);
  };

  const previousStep = (e) => {
    e.preventDefault();
    setStep(step - 1);
  };

  const renderButton = () => {
    if (step > 1) {
      return undefined;
    } else if (step === 1) {
      return;
      <button
        type="submit"
        onClick={handleSubmit(saveButtonSubmit)}
        className="btn btn-primary rounded-0"
      >
        Submit
      </button>;
    } else {
      return (
        <button
          type="button"
          className="btn btn-primary rounded-0"
          onClick={nextStep}
        >
          Next
        </button>
      );
    }
  };

  const previousrenderButton = () => {
    if (step > 0 && step < 2) {
      return (
        <button
          type="button"
          onClick={previousStep}
          className="btn btn-secondary rounded-0"
        >
          Previous Step
        </button>
      );
    } else {
      return undefined;
    }
  };

  const handleChange = (name) => (event) => {
    setLoanInformations({
      ...loanInformations,
      [name]: event.target.value,
      successmessage: '',
      errormessage: '',
    });
  };

  const saveButtonSubmit = async (data) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/loans/updateLoanInformation `,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            loanInformation: {
              customerName:
                data.customerFirstName + ' ' + data.customerLastName,
              customerDOB: data.customerDOB,
              customerFirstName: data.customerFirstName,
              customerLastName: data.customerLastName,
              customerMiddleName: data.customerMiddleName,
              customerMobileNumber: data.customerMobileNumber,
              customerPersonalEmailId: data.customerPersonalEmailId,
              education: data.education,
              religion: data.religion,
              loanProductType: data.loanProductType,
              preferredCommunicationAddress: data.preferredCommunicationAddress,
              preferredCommunicationLanguage:
                data.preferredCommunicationLanguage,
              officeOrBusinessAddress: data.officeOrBusinessAddress,
              officeOrBusinessLandMark: data.officeOrBusinessLandMark,
              officeOrBusinessCityOrVillage: data.officeOrBusinessCityOrVillage,
              officeOrBusinessState: data.officeOrBusinessState,
              officeOrBusinessPincode: data.officeOrBusinessPincode,
              officeOrBusinessPhone: data.officeOrBusinessPhone,
              currentCompanyName: data.currentCompanyName,
              loanAmountAppliedFor: data.loanAmountAppliedFor,
              applicationId: data.applicationId,
              loanTenure: data.loanTenure,
              loanLeadId: CPersonalDetails.loanInformation.loanLeadId,
              loanType: data.loanType,
              no_of_dependents: data.no_of_dependents,
              noOfYearsInPresentResidenceAddress:
                data.noOfYearsInPresentResidenceAddress,
              presentResidenceLandMark: data.presentResidenceLandMark,
              presentResidentialAddress: data.presentResidentialAddress,
              presentResidentialCityOrVillage:
                data.presentResidentialCityOrVillage,
              presentResidentialPhoneNumber: data.presentResidentialPhoneNumber,
              presentResidentialPincode: data.presentResidentialPincode,
              presentResidentialState: data.presentResidentialState,
              countryOfResidence: data.countryOfResidence,
              taxIdentificationNumber: data.taxIdentificationNumber,
              placeOrCityOfBirth: data.placeOrCityOfBirth,
              countryOfBirth: data.countryOfBirth,

              permanentResidentialAddress:
                checkdata === false
                  ? data.permanentResidentialAddress
                  : data.presentResidentialAddress,
              permanentResidenceLandMark:
                checkdata === false
                  ? data.permanentResidenceLandMark
                  : data.presentResidenceLandMark,
              permanentResidentialCityOrVillage:
                checkdata === false
                  ? data.permanentResidentialCityOrVillage
                  : data.presentResidentialCityOrVillage,
              permanentResidentialState:
                checkdata === false
                  ? data.permanentResidentialState
                  : data.presentResidentialState,
              permanentResidentialPincode:
                checkdata === false
                  ? data.permanentResidentialPincode
                  : data.presentResidentialPincode,
              permanentResidencePhoneNumber:
                checkdata === false
                  ? data.permanentResidencePhoneNumber
                  : data.presentResidentialPhoneNumber,
              currentResidenceType: data.currentResidenceType,
              isGSTRegistered: data.isGSTRegistered,
              gstRegistrationNumber: data.gstRegistrationNumber,
              designation: data.designation,
              paymentMode: data.paymentMode,
              previousCompanyName: data.previousCompanyName,
              totalWorkExperience: data.totalWorkExperience,
              fatherOrHusbandFirstName: data.fatherOrHusbandFirstName,
              fatherOrHusbandMiddleName: data.fatherOrHusbandMiddleName,
              fatherOrHusbandLastName: data.fatherOrHusbandLastName,
              maritalStatus: data.maritalStatus,
              motherMaidenFirstName: data.motherMaidenFirstName,
              motherMaidenMiddleName: data.motherMaidenMiddleName,
              motherMaidenLastName: data.motherMaidenLastName,
              gender: data.gender,
              residentialStatus: data.residentialStatus,
              officeOrBusinessLandLineNumber:
                data.officeOrBusinessLandLineNumber,
              officeOrBusinessEmail: data.officeOrBusinessEmail,
              nationality: data.nationality,
              employmentType: data.employmentType,
              typeOfOrganization: data.typeOfOrganization,
              noOfYearsInCurrentJob: data.noOfYearsInCurrentJob,
              officeType: data.officeType,
              grossMonthlyIncome: data.grossMonthlyIncome,
              netMonthlyIncome: data.netMonthlyIncome,
              modeOfPayment: data.modeOfPayment,
              typeOfIndustry: data.typeOfIndustry,
              citizenshipcountry: data.citizenshipcountry,
              votedIdCard: data.votedIdCard,
              customerPanNumber: data.customerPanNumber,
              drivingLicense: data.drivingLicense,
              passportNumber: data.passportNumber,
              passportValidityDate: data.passportValidityDate,
              nergaJobCardNumber: data.nergaJobCardNumber,
              reference1Name: data.reference1Name,
              reference1Relation: data.reference1Relation,
              reference1Address: data.reference1Address,
              reference1Mobile: data.reference1Mobile,
              reference1Email: data.reference1Email,
              reference2Name: data.reference2Name,
              reference2Relation: data.reference2Relation,
              reference2Address: data.reference2Address,
              reference2Mobile: data.reference2Mobile,
              reference2Email: data.reference2Email,
              processingBankId:
                CPersonalDetails.loanInformation.processingBankId,
              processingBankName:
                CPersonalDetails.loanInformation.processingBankName,
            },
            updatedByEmployeeId: JSON.parse(
              localStorage.getItem('loggedInEmployee')
            ).employeeId,
            updatedByEmployeeName: JSON.parse(
              localStorage.getItem('loggedInEmployee')
            ).name,
          }),
        }
      );

      if (response.status === 200) {
        const resdata = await response.json();
        setDescriptionDetails({
          ...descriptionDetails,
        });
        const filteredData = resdata
          .filter((res) => res.validationdMessage !== null)
          .map((res, i) => {
            return res.validationdMessage;
          });

        MySwal.fire({
          text: `${filteredData}`,
        });
      } else if (response.status === 500) {
        MySwal.fire({
          text: 'Something went wrong',
        });
      }
    } catch (error) {
      setLoanInformations({
        ...loanInformations,
      });
      MySwal.fire({
        text: 'Something went wrong',
      });
    }
  };

  const closeModal = () => {
    // const mandatoryFields = CPersonalDetails.madatoryFields[
    //   'Loan Information'
    // ].map((field) => {
    //   return field;
    // });
    // if (mandatoryFields.length > 0) {
    //   return alert(`${mandatoryFields} is mandatory field`);
    // }
  };

  const savependingbankappr = async (data) => {
    //  data.preventDefault();
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/loans/updateLoanInformation`,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            loanInformation: {
              loanLeadId,
              applicationSubmitToBankDate: data.applicationsubdate,
              pendingApprovalAdditionalComments: data.additionalcomment,
              customerMobileNumber:
                CPersonalDetails.loanInformation.customerMobileNumber,
              processingBankId:
                CPersonalDetails.loanInformation.processingBankId,
            },
            updatedByEmployeeId: JSON.parse(
              localStorage.getItem('loggedInEmployee')
            ).employeeId,
            updatedByEmployeeName: JSON.parse(
              localStorage.getItem('loggedInEmployee')
            ).name,
          }),
        }
      );

      if (response.status === 200) {
        const resdata = await response.json();
        setDescriptionDetails({
          ...descriptionDetails,
        });
        const filteredData = resdata
          .filter((res) => res.validationdMessage !== null)
          .map((res, i) => {
            return res.validationdMessage;
          });

        MySwal.fire({
          text: `${filteredData}`,
        });
      } else if (response.status === 500) {
        MySwal.fire({
          text: 'Something went wrong',
        });
      }
    } catch (error) {
      setDescriptionDetails({
        ...descriptionDetails,
      });
      MySwal.fire({
        text: 'Something went wrong',
      });
    }
  };

  const savebankreject = async (data) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/loans/updateLoanInformation`,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            loanInformation: {
              loanLeadId,
              bankRejectionDetails: data.rejectionreason,
              bankNegotiationDetails: data.banknegotiate,
              customerMobileNumber:
                CPersonalDetails.loanInformation.customerMobileNumber,
              processingBankId:
                CPersonalDetails.loanInformation.processingBankId,
            },
            updatedByEmployeeId: JSON.parse(
              localStorage.getItem('loggedInEmployee')
            ).employeeId,
            updatedByEmployeeName: JSON.parse(
              localStorage.getItem('loggedInEmployee')
            ).name,
          }),
        }
      );
      if (response.status === 200) {
        const resdata = await response.json();
        setDescriptionDetails({
          ...descriptionDetails,
        });
        const filteredData = resdata
          .filter((res) => res.validationdMessage !== null)
          .map((res, i) => {
            return res.validationdMessage;
          });

        MySwal.fire({
          text: `${filteredData}`,
        });
      } else if (response.status === 500) {
        MySwal.fire({
          text: 'Something went wrong',
        });
      }
    } catch (error) {
      setDescriptionDetails({
        ...descriptionDetails,
      });
      MySwal.fire({
        text: 'Something went wrong',
      });
    }
  };

  const saveunderbanknego = async (data) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/loans/updateLoanInformation`,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            loanInformation: {
              loanLeadId,
              bankNegotiationDetails: data.negotiationbankdesc,
              customerMobileNumber:
                CPersonalDetails.loanInformation.customerMobileNumber,
              processingBankId:
                CPersonalDetails.loanInformation.processingBankId,
            },
            updatedByEmployeeId: JSON.parse(
              localStorage.getItem('loggedInEmployee')
            ).employeeId,
            updatedByEmployeeName: JSON.parse(
              localStorage.getItem('loggedInEmployee')
            ).name,
          }),
        }
      );
      if (response.status === 200) {
        const resdata = await response.json();
        setDescriptionDetails({
          ...descriptionDetails,
        });
        const filteredData = resdata
          .filter((res) => res.validationdMessage !== null)
          .map((res, i) => {
            return res.validationdMessage;
          });

        MySwal.fire({
          text: `${filteredData}`,
        });
      } else if (response.status === 500) {
        MySwal.fire({
          text: 'Something went wrong',
        });
      }
    } catch (error) {
      setDescriptionDetails({
        ...descriptionDetails,
      });
      MySwal.fire({
        text: 'Something went wrong',
      });
    }
  };

  const saveundercustomernego = async (data) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/loans/updateLoanInformation`,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            loanInformation: {
              loanLeadId,
              customerNegotiationDetails: data.negotiationcusdesc,
              customerMobileNumber:
                CPersonalDetails.loanInformation.customerMobileNumber,
              processingBankId:
                CPersonalDetails.loanInformation.processingBankId,
            },
            updatedByEmployeeId: JSON.parse(
              localStorage.getItem('loggedInEmployee')
            ).employeeId,
            updatedByEmployeeName: JSON.parse(
              localStorage.getItem('loggedInEmployee')
            ).name,
          }),
        }
      );
      if (response.status === 200) {
        const resdata = await response.json();
        setDescriptionDetails({
          ...descriptionDetails,
        });
        const filteredData = resdata
          .filter((res) => res.validationdMessage !== null)
          .map((res, i) => {
            return res.validationdMessage;
          });

        MySwal.fire({
          text: `${filteredData}`,
        });
      } else if (response.status === 500) {
        MySwal.fire({
          text: 'Something went wrong',
        });
      }
    } catch (error) {
      setDescriptionDetails({
        ...descriptionDetails,
      });
      MySwal.fire({
        text: 'Something went wrong',
      });
    }
  };

  const saveagrereview = async (data) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/loans/updateLoanInformation`,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            loanInformation: {
              loanLeadId,
              underAgreementDetails: data.additionalcommentunderagre,
              customerMobileNumber:
                CPersonalDetails.loanInformation.customerMobileNumber,
              processingBankId:
                CPersonalDetails.loanInformation.processingBankId,
            },
            updatedByEmployeeId: JSON.parse(
              localStorage.getItem('loggedInEmployee')
            ).employeeId,
            updatedByEmployeeName: JSON.parse(
              localStorage.getItem('loggedInEmployee')
            ).name,
          }),
        }
      );

      if (response.status === 200) {
        const resdata = await response.json();
        setDescriptionDetails({
          ...descriptionDetails,
        });
        const filteredData = resdata
          .filter((res) => res.validationdMessage !== null)
          .map((res, i) => {
            return res.validationdMessage;
          });

        MySwal.fire({
          text: `${filteredData}`,
        });
      } else if (response.status === 500) {
        MySwal.fire({
          text: 'Something went wrong',
        });
      }
    } catch (error) {
      setDescriptionDetails({
        ...descriptionDetails,
      });
      MySwal.fire({
        text: 'Something went wrong',
      });
    }
  };

  const saveagresigned = async (data) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/loans/updateLoanInformation`,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            loanInformation: {
              loanLeadId,
              agreementSignedDate: data.agreesigndate,
              agreementSignedAdditionalComment: data.agreesignaddicom,
              customerMobileNumber:
                CPersonalDetails.loanInformation.customerMobileNumber,
              processingBankId:
                CPersonalDetails.loanInformation.processingBankId,
            },
            updatedByEmployeeId: JSON.parse(
              localStorage.getItem('loggedInEmployee')
            ).employeeId,
            updatedByEmployeeName: JSON.parse(
              localStorage.getItem('loggedInEmployee')
            ).name,
          }),
        }
      );
      if (response.status === 200) {
        const resdata = await response.json();
        setDescriptionDetails({
          ...descriptionDetails,
        });
        const filteredData = resdata
          .filter((res) => res.validationdMessage !== null)
          .map((res, i) => {
            return res.validationdMessage;
          });

        MySwal.fire({
          text: `${filteredData}`,
        });
      } else if (response.status === 500) {
        MySwal.fire({
          text: 'Something went wrong',
        });
      }
    } catch (error) {
      setDescriptionDetails({
        ...descriptionDetails,
      });
      MySwal.fire({
        text: 'Something went wrong',
      });
    }
  };

  const savedisbursed = async (data) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/loans/updateLoanInformation`,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            loanInformation: {
              loanLeadId,
              loanApprovalInterestRate: data.approvedinterest,
              loanAccountNumber: data.loanAccountNumber,
              leadInitiateDate: data.leadorgdate,
              disbursedDate: data.disburseddate,
              loanCategory: data.loanCategory,
              netDisbursedAmount: data.netDisbursedAmount,
              disbursedAmount: data.disbursedamount,
              disbursedBankName: data.disbursedbank,
              customerMobileNumber:
                CPersonalDetails.loanInformation.customerMobileNumber,
              processingBankId:
                CPersonalDetails.loanInformation.processingBankId,
            },
            updatedByEmployeeId: JSON.parse(
              localStorage.getItem('loggedInEmployee')
            ).employeeId,
            updatedByEmployeeName: JSON.parse(
              localStorage.getItem('loggedInEmployee')
            ).name,
          }),
        }
      );
      if (response.status === 200) {
        const resdata = await response.json();
        setDescriptionDetails({
          ...descriptionDetails,
        });
        const filteredData = resdata
          .filter((res) => res.validationdMessage !== null)
          .map((res, i) => {
            return res.validationdMessage;
          });

        MySwal.fire({
          text: `${filteredData}`,
        });
      } else if (response.status === 500) {
        MySwal.fire({
          text: 'Something went wrong',
        });
      }
    } catch (error) {
      setDescriptionDetails({
        ...descriptionDetails,
      });
      MySwal.fire({
        text: 'Something went wrong',
      });
    }
  };

  const saveagentpayout = async (data) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/loans/updateLoanInformation`,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            loanInformation: {
              agentWisePayout: agentpa,
              loanLeadId,
              agentPaymentDate: data.payoutdate,
              paymentMode: data.payoutmode,
              agentPayoutAdditionalComments: data.payoutaddcomm,
              customerMobileNumber:
                CPersonalDetails.loanInformation.customerMobileNumber,
              processingBankId:
                CPersonalDetails.loanInformation.processingBankId,
            },
            updatedByEmployeeId: JSON.parse(
              localStorage.getItem('loggedInEmployee')
            ).employeeId,
            updatedByEmployeeName: JSON.parse(
              localStorage.getItem('loggedInEmployee')
            ).name,
          }),
        }
      );

      if (response.status === 200) {
        const resdata = await response.json();
        setDescriptionDetails({
          ...descriptionDetails,
        });
        const filteredData = resdata
          .filter((res) => res.validationdMessage !== null)
          .map((res, i) => {
            return res.validationdMessage;
          });

        MySwal.fire({
          text: `${filteredData}`,
        });
      } else if (response.status === 500) {
        MySwal.fire({
          text: 'Something went wrong',
        });
      }
    } catch (error) {
      setDescriptionDetails({
        ...descriptionDetails,
      });
      MySwal.fire({
        text: 'Something went wrong',
      });
    }
  };

  const saveprocessedandpay = async (data) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/loans/updateLoanInformation`,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            loanInformation: {
              loanLeadId,
              loanClosingComment: data.proccesssumm,
            },
            updatedByEmployeeId: JSON.parse(
              localStorage.getItem('loggedInEmployee')
            ).employeeId,
            updatedByEmployeeName: JSON.parse(
              localStorage.getItem('loggedInEmployee')
            ).name,
          }),
        }
      );
      if (response.status === 200) {
        const resdata = await response.json();
        setDescriptionDetails({
          ...descriptionDetails,
        });
        const filteredData = resdata
          .filter((res) => res.validationdMessage !== null)
          .map((res, i) => {
            return res.validationdMessage;
          });

        MySwal.fire({
          text: `${filteredData}`,
        });
      } else if (response.status === 500) {
        MySwal.fire({
          text: 'Something went wrong',
        });
      }
    } catch (error) {
      setDescriptionDetails({
        ...descriptionDetails,
      });
      MySwal.fire({
        text: 'Something went wrong',
      });
    }
  };
  const checkIfNumber = (event) => {
    const regex = new RegExp(
      /(^\d*$)|(Backspace|Tab|Delete|ArrowLeft|ArrowRight)/
    );

    return !event.key.match(regex) && event.preventDefault();
  };

  const checkIfTextOnly = (event) => {
    const regex = new RegExp(/[a-zåäö ]/i);

    return !event.key.match(regex) && event.preventDefault();
  };

  const savebankapprov = async (data) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/loans/updateLoanInformation`,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            loanInformation: {
              loanLeadId,
              applicationId: data.applicationId,
              loanApprovalAmount: data.approvedamount,
              loanApprovalTenure: data.approvedtenture,
              loanApprovalDate: data.approvaldate,
              bankApprovalAdditionalComments: data.additionalcommentbankapp,
              customerMobileNumber:
                CPersonalDetails.loanInformation.customerMobileNumber,
              processingBankId:
                CPersonalDetails.loanInformation.processingBankId,
            },
            updatedByEmployeeId: JSON.parse(
              localStorage.getItem('loggedInEmployee')
            ).employeeId,
            updatedByEmployeeName: JSON.parse(
              localStorage.getItem('loggedInEmployee')
            ).name,
          }),
        }
      );
      if (response.status === 200) {
        const resdata = await response.json();
        setDescriptionDetails({
          ...descriptionDetails,
        });
        const filteredData = resdata
          .filter((res) => res.validationdMessage !== null)
          .map((res, i) => {
            return res.validationdMessage;
          });

        MySwal.fire({
          text: `${filteredData}`,
        });
      } else if (response.status === 500) {
        MySwal.fire({
          text: 'Something went wrong',
        });
      }
    } catch (error) {
      setDescriptionDetails({
        ...descriptionDetails,
      });
      MySwal.fire({
        text: 'Something went wrong',
      });
    }
  };

  return (
    <React.Fragment>
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">
              {' '}
              {ChangeStatusValue === 'Assigned'
                ? 'Customer Personal Details'
                : ChangeStatusValue}
            </h5>

            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={ChangeStatusValue === 'Assigned' ? closeModal : ''}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div className="container text-left">
            <ToastContainer />

            {ChangeStatusValue === 'Pending Bank Approval' && (
              <>
                <form
                  className="mt-2 mb-5"
                  onSubmit={handleSubmit(savependingbankappr)}
                >
                  <div class="form-group mb-2">
                    <label for="applsub"> Application Submit Date</label>
                    <input
                      className="form-control"
                      type="date"
                      defaultValue={applicationsubdate}
                      {...register('applicationsubdate', {
                        required: 'Application submit date is required',
                      })}
                    />
                    {errors.applicationsubdate && (
                      <p className="text-danger">
                        {errors.applicationsubdate.message}
                      </p>
                    )}
                  </div>

                  <div class="form-group mb-2">
                    <label for="bankStatement"> Additional Comments </label>
                    <textarea
                      type="text"
                      className="form-control"
                      defaultValue={additionalcomment}
                      {...register('additionalcomment', {
                        required: 'Additional Comment is required',
                      })}
                    ></textarea>
                    {errors.additionalcomment && (
                      <p className="text-danger">
                        {errors.additionalcomment.message}
                      </p>
                    )}
                  </div>
                  <button type="submit" class="btn btn-success mb-2">
                    Save
                  </button>
                </form>
              </>
            )}
            {/* Bank Approved */}
            {ChangeStatusValue === 'Bank Approved' && (
              <>
                <form
                  className="mt-2 mb-5"
                  onSubmit={handleSubmit(savebankapprov)}
                >
                  <div class="form-group mb-2">
                    <label for="bankStatement"> Loan Application ID </label>
                    <input
                      className="form-control"
                      type="text"
                      {...register(
                        'applicationId'
                        // , {
                        //   required: 'Application Id Type is required',
                        // }
                      )}
                      defaultValue={applicationIdn}
                    />
                    {errors.applicationId && (
                      <p className="text-danger">
                        {errors.applicationId.message}
                      </p>
                    )}
                  </div>
                  <div class="form-group mb-2">
                    <label for="bankStatement">Approval Date</label>
                    <input
                      className="form-control"
                      type="date"
                      defaultValue={approvaldate}
                      {...register('approvaldate', {
                        required: 'Approval Date is required',
                      })}
                    />
                    {errors.approvaldate && (
                      <p className="text-danger">
                        {errors.approvaldate.message}
                      </p>
                    )}
                  </div>

                  <div class="form-group mb-2">
                    <label for="bankStatement">Approved Amount</label>
                    <input
                      className="form-control"
                      type="number"
                      defaultValue={approvedamount}
                      {...register('approvedamount', {
                        required: 'Approved Amount is required',
                      })}
                    />
                    {errors.approvedamount && (
                      <p className="text-danger">
                        {errors.approvedamount.message}
                      </p>
                    )}
                  </div>

                  <div class="form-group mb-2">
                    <label for="bankStatement">Approved Tenure</label>
                    <input
                      className="form-control"
                      type="number"
                      defaultValue={approvedtenture}
                      {...register('approvedtenture', {
                        required: 'Approved Tenure is required',
                      })}
                    />
                    {errors.approvedtenture && (
                      <p className="text-danger">
                        {errors.approvedtenture.message}
                      </p>
                    )}
                  </div>

                  <div class="form-group mb-2">
                    <label for="bankStatement"> Additional Comments </label>
                    <textarea
                      type="text"
                      className="form-control"
                      defaultValue={additionalcommentbankapp}
                      {...register('additionalcommentbankapp')}
                    ></textarea>

                    {errors.additionalcommentbankapp && (
                      <p className="text-danger">
                        {errors.additionalcommentbankapp.message}
                      </p>
                    )}
                  </div>
                  <button type="submit" class="btn btn-success mb-2">
                    Save
                  </button>
                </form>
              </>
            )}

            {ChangeStatusValue === 'Bank Rejected' && (
              <>
                <form
                  className="mt-2 mb-5"
                  onSubmit={handleSubmit(savebankreject)}
                >
                  <div class="form-group mb-2">
                    <label for="bankStatement">Rejection Reason</label>
                    <input
                      className="form-control"
                      type="text"
                      defaultValue={rejectionreason}
                      {...register('rejectionreason', {
                        required: 'Rejection Reason is required',
                      })}
                    />
                    {errors.rejectionreason && (
                      <p className="text-danger">
                        {errors.rejectionreason.message}
                      </p>
                    )}
                  </div>

                  <div class="form-group mb-2">
                    <label for="bankStatement"> Bank Ready to Negotiate</label>
                    <textarea
                      type="text"
                      className="form-control"
                      defaultValue={banknegotiate}
                      {...register('banknegotiate', {
                        required: 'Bank Ready to Negotiate is required',
                      })}
                    ></textarea>
                    {errors.banknegotiate && (
                      <p className="text-danger">
                        {errors.banknegotiate.message}
                      </p>
                    )}
                  </div>
                  <button type="submit" class="btn btn-success mb-2">
                    Save
                  </button>
                </form>
              </>
            )}

            {ChangeStatusValue === 'Under Customer Negotiation' ? (
              <>
                <form
                  className="mt-2 mb-5"
                  onSubmit={handleSubmit(saveundercustomernego)}
                >
                  <div class="form-group mb-2">
                    <label for="bankStatement"> Negotiation Description </label>
                    <textarea
                      type="text"
                      className="form-control"
                      defaultValue={negotiationcusdesc}
                      {...register('negotiationcusdesc', {
                        required: 'Negotiation Description is required',
                      })}
                    ></textarea>
                    {errors.negotiationcusdesc && (
                      <p className="text-danger">
                        {errors.negotiationcusdesc.message}
                      </p>
                    )}
                  </div>
                  <button type="submit" class="btn btn-success mb-2">
                    Save
                  </button>
                </form>
              </>
            ) : (
              <></>
            )}

            {ChangeStatusValue === 'Under Bank Negitiation' ? (
              <>
                <form
                  className="mt-2 mb-5"
                  onSubmit={handleSubmit(saveunderbanknego)}
                >
                  <div class="form-group mb-2">
                    <label for="bankStatement"> Negotiation Description </label>
                    <textarea
                      type="text"
                      className="form-control"
                      defaultValue={negotiationbankdesc}
                      {...register('negotiationbankdesc', {
                        required: 'Negotiation Description is required',
                      })}
                    ></textarea>
                    {errors.negotiationbankdesc && (
                      <p className="text-danger">
                        {errors.negotiationbankdesc.message}
                      </p>
                    )}
                  </div>
                  <button type="submit" class="btn btn-success mb-2">
                    Save
                  </button>
                </form>
              </>
            ) : (
              <></>
            )}

            {ChangeStatusValue === 'Under Agreement Review' && (
              <>
                <form
                  className="mt-2 mb-5"
                  onSubmit={handleSubmit(saveagrereview)}
                >
                  <div class="form-group mb-2">
                    <label for="bankStatement"> Additional Comments </label>
                    <textarea
                      type="text"
                      className="form-control"
                      defaultValue={additionalcommentunderagre}
                      {...register('additionalcommentunderagre', {
                        required: 'Additional Comments is required',
                      })}
                    ></textarea>
                    {errors.additionalcommentunderagre && (
                      <p className="text-danger">
                        {errors.additionalcommentunderagre.message}
                      </p>
                    )}
                  </div>
                  <button type="submit" class="btn btn-success mb-2">
                    Save
                  </button>
                </form>
              </>
            )}

            {ChangeStatusValue === 'Agreement Signed' && (
              <>
                <form
                  className="mt-2 mb-5"
                  onSubmit={handleSubmit(saveagresigned)}
                >
                  <div class="form-group mb-2">
                    <label for="bankStatement">Agreement Signed Date</label>
                    <input
                      className="form-control"
                      type="date"
                      defaultValue={agreesigndate}
                      {...register('agreesigndate', {
                        required: 'Agreement Signed Date is required',
                      })}
                    />
                    {errors.agreesigndate && (
                      <p className="text-danger">
                        {errors.agreesigndate.message}
                      </p>
                    )}
                  </div>

                  <div class="form-group mb-2">
                    <label for="bankStatement"> Additional Comments </label>
                    <textarea
                      type="text"
                      className="form-control"
                      defaultValue={agreesignaddicom}
                      {...register('agreesignaddicom')}
                    ></textarea>

                    {errors.agreesignaddicom && (
                      <p className="text-danger">
                        {errors.agreesignaddicom.message}
                      </p>
                    )}
                  </div>
                  <button type="submit" class="btn btn-success mb-2">
                    Save
                  </button>
                </form>
              </>
            )}

            {ChangeStatusValue === 'Disbursed' && (
              <>
                <form
                  className="mt-2 mb-5"
                  onSubmit={handleSubmit(savedisbursed)}
                >
                  <div class="form-group mb-2">
                    <label for="bankStatement">Loan Account Number</label>
                    <input
                      className="form-control"
                      type="text"
                      step="any"
                      defaultValue={loanAccountNumber}
                      {...register('loanAccountNumber', {
                        required: 'Loan Account Number is required',
                      })}
                    />
                    {errors.loanAccountNumber && (
                      <p className="text-danger">
                        {errors.loanAccountNumber.message}
                      </p>
                    )}
                  </div>

                  <div class="form-group mb-2">
                    <label for="bankStatement">Approved Interest Rate</label>
                    <input
                      className="form-control"
                      type="number"
                      step="any"
                      defaultValue={approvedinterest}
                      {...register('approvedinterest', {
                        required: 'Approved Interest Rate is required',
                      })}
                    />
                    {errors.approvedinterest && (
                      <p className="text-danger">
                        {errors.approvedinterest.message}
                      </p>
                    )}
                  </div>
                  <div class="form-group mb-2">
                    <label for="bankStatement">Lead Origination Date</label>
                    <input
                      className="form-control"
                      type="date"
                      defaultValue={leadorgdate}
                      {...register('leadorgdate')}
                      readOnly
                    />
                    {errors.leadorgdate && (
                      <p className="text-danger">
                        {errors.leadorgdate.message}
                      </p>
                    )}
                  </div>
                  <div class="form-group mb-2">
                    <label for="bankStatement">Disbursed Date</label>
                    <input
                      className="form-control"
                      type="date"
                      defaultValue={disburseddate}
                      {...register('disburseddate', {
                        required: 'Disbursed Date is required',
                      })}
                    />
                    {errors.disburseddate && (
                      <p className="text-danger">
                        {errors.disburseddate.message}
                      </p>
                    )}
                  </div>

                  <div class="form-group mb-2">
                    <label htmlFor="title">Loan Category</label>
                    <select
                      id="title"
                      className="form-control"
                      {...register('loanCategory', {
                        required: 'Disbursed Loan Category is required',
                      })}
                    >
                      {CPersonalDetails.loanInformation.loanCategory && (
                        <option
                          value={CPersonalDetails.loanInformation.loanCategory}
                          className="d-none"
                          selected
                        >
                          {CPersonalDetails.loanInformation.loanCategory}
                        </option>
                      )}
                      {CPersonalDetails.loanInformation.loanCategory ===
                        undefined && (
                        <option value="" selected>
                          Choose...
                        </option>
                      )}

                      <option value="Top Up">Top Up</option>
                      <option value="Balance Transfer">Balance Transfer</option>
                      <option value="New">New</option>
                    </select>
                    {errors.loanCategory && (
                      <p className="text-danger">
                        {errors.loanCategory.message}
                      </p>
                    )}
                  </div>
                  {watch().loanCategory === 'Top Up' ? (
                    <>
                      <div class="form-group mb-2">
                        <label for="bankStatement">Net Amount</label>
                        <input
                          className="form-control"
                          type="number"
                          {...register('netDisbursedAmount', {
                            required: 'Net Amount is required',
                          })}
                          defaultValue={
                            CPersonalDetails.loanInformation.netDisbursedAmount
                          }
                        />
                      </div>{' '}
                      {errors.netDisbursedAmount && (
                        <p className="text-danger">
                          {errors.netDisbursedAmount.message}
                        </p>
                      )}
                    </>
                  ) : (
                    <></>
                  )}

                  <div class="form-group mb-2">
                    <label for="bankStatement">
                      {watch().loanCategory === 'Top Up' ? (
                        <span>Gross Amount </span>
                      ) : (
                        <span>Disbursed Amount</span>
                      )}
                    </label>
                    <input
                      className="form-control"
                      type="number"
                      defaultValue={disbursedamount}
                      {...register('disbursedamount', {
                        required: 'Amount is required',
                      })}
                      onKeyDown={(e) => checkIfNumber(e)}
                    />
                    {errors.disbursedamount && (
                      <p className="text-danger">
                        {errors.disbursedamount.message}
                      </p>
                    )}
                  </div>

                  <div class="form-group mb-2">
                    <label for="bankStatement">Disbursed Bank Name </label>
                    <input
                      className="form-control"
                      type="text"
                      defaultValue={disbursedbank}
                      {...register('disbursedbank', {
                        required: 'Disbursed Bank Name is required',
                      })}
                    />
                    {errors.disbursedbank && (
                      <p className="text-danger">
                        {errors.disbursedbank.message}
                      </p>
                    )}
                  </div>

                  <button type="submit" class="btn btn-success mb-2">
                    Save
                  </button>
                </form>
              </>
            )}

            {ChangeStatusValue === 'Agent Payout' && (
              <>
                <p className="text-primary mt-3">
                  <i class="fa-solid fa-circle-info"></i> Please update the
                  payout amount if required, percentage we will calculate{' '}
                </p>
                <form className="mt-1" onSubmit={handleSubmit(saveagentpayout)}>
                  <fieldset
                    disabled={
                      JSON.parse(localStorage.getItem('loggedInEmployee'))
                        .role !== 'Admin'
                    }
                  >
                    {CPersonalDetails.loanInformation.agentWisePayout.map(
                      (pay, i) => {
                        return (
                          <div className="card m-2 p-2" key={i}>
                            <div class="form-group mb-2">
                              <label for="bankStatement">Agent Id</label>
                              <input
                                className="form-control"
                                type="text"
                                value={pay.agentId}
                                readOnly
                              />
                            </div>
                            <div class="form-group mb-2">
                              <label for="bankStatement">Agent Name</label>
                              <input
                                className="form-control"
                                type="text"
                                defaultValue={pay.name}
                                {...register('payoutagentName', {
                                  required: 'Agent Name is required',
                                })}
                                readOnly
                              />
                              {errors.payoutagentName && (
                                <p className="text-danger">
                                  {errors.payoutagentName.message}
                                </p>
                              )}
                            </div>
                            <div class="form-group mb-2">
                              <label for="bankStatement">
                                Agent PAN Number
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                defaultValue={pay.agentPanNumber}
                                {...register('agentpanno', {
                                  required: 'Agent PAN Number is required',
                                })}
                                readOnly
                              />
                              {errors.agentpanno && (
                                <p className="text-danger">
                                  {errors.agentpanno.message}
                                </p>
                              )}
                            </div>

                            <div class="form-group mb-2">
                              <label for="bankStatement">
                                Payout Percentage
                              </label>
                              <input
                                className="form-control"
                                type="number"
                                step="any"
                                defaultValue={pay.agentPayoutPercentage}
                                // {...register('paypoutpercen', {
                                //   required: 'Payout Percentage is required',
                                // })}
                                name="agentPayoutPercentage"
                                onChange={(e) => handleagent(e, i)}
                                readOnly
                              />
                              {errors.paypoutpercen && (
                                <p className="text-danger">
                                  {errors.paypoutpercen.message}
                                </p>
                              )}
                            </div>

                            <div class="form-group mb-2">
                              <label for="bankStatement">Payout Amount</label>
                              <input
                                className="form-control"
                                type="number"
                                name="agentPayoutAmount"
                                defaultValue={pay.agentPayoutAmount}
                                // defaultValue={`${
                                //   pay.agentPayoutAmount *
                                //   (pay.agentPayoutPercentage * 10)
                                // }`}
                                // {...register('payoutamou', {
                                //   required: 'Payout Amount is required',
                                // })}
                                // onKeyDown={(e) => checkIfNumber(e)}
                                //     value="30"
                                onChange={(e) => handleagent(e, i)}
                              />
                              {errors.payoutamou && (
                                <p className="text-danger">
                                  {errors.payoutamou.message}
                                </p>
                              )}
                            </div>

                            <div class="form-group mb-2">
                              <label for="bankStatement">
                                Payout Bank Name
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                name="agentPayoutBankName"
                                defaultValue={pay.agentPayoutBankName}
                                // {...register('payoutbankn', {
                                //   required: 'Payout Bank Name is required',
                                // })}
                                onChange={(e) => handleagent(e, i)}
                              />
                              {errors.payoutbankn && (
                                <p className="text-danger">
                                  {errors.payoutbankn.message}
                                </p>
                              )}
                            </div>
                          </div>
                        );
                      }
                    )}
                    <div class="form-group mb-2">
                      <label for="bankStatement">Payout Date</label>
                      <input
                        className="form-control"
                        type="date"
                        defaultValue={payoutdate}
                        {...register('payoutdate', {
                          required: 'Payout Date is required',
                        })}
                      />
                      {errors.payoutdate && (
                        <p className="text-danger">
                          {errors.payoutdate.message}
                        </p>
                      )}
                    </div>
                    <div class="form-group mb-2">
                      <label for="bankStatement">Payout Mode</label>
                      <input
                        className="form-control"
                        type="text"
                        defaultValue={payoutmode}
                        {...register('payoutmode', {
                          required: 'Payout Mode is required',
                        })}
                      />
                      {errors.payoutmode && (
                        <p className="text-danger">
                          {errors.payoutmode.message}
                        </p>
                      )}
                    </div>
                    <div class="form-group mb-2">
                      <label for="bankStatement"> Additional Comments </label>
                      <textarea
                        type="text"
                        className="form-control"
                        defaultValue={payoutaddcomm}
                        {...register('payoutaddcomm')}
                      ></textarea>
                      {errors.payoutaddcomm && (
                        <p className="text-danger">
                          {errors.payoutaddcomm.message}
                        </p>
                      )}
                    </div>
                    <button type="submit" class="btn btn-success mb-2">
                      Save
                    </button>{' '}
                  </fieldset>
                </form>
              </>
            )}

            {ChangeStatusValue === 'Processed and Payout complete' && (
              <>
                <form
                  className="mt-2 mb-5"
                  onSubmit={handleSubmit(saveprocessedandpay)}
                >
                  <div class="form-group mb-2">
                    <label for="bankStatement">Process Summary </label>
                    <textarea
                      type="text"
                      className="form-control"
                      defaultValue={proccesssumm}
                      {...register('proccesssumm', {
                        required: 'Process Summary is required',
                      })}
                    ></textarea>
                    {errors.proccesssumm && (
                      <p className="text-danger">
                        {errors.proccesssumm.message}
                      </p>
                    )}
                  </div>

                  <button type="submit" class="btn btn-success mb-2">
                    Save
                  </button>
                </form>
              </>
            )}

            {ChangeStatusValue === 'Assigned' ||
            ChangeStatusValue === 'Under Fulfillment' ? (
              <>
                <form>
                  {step === 0 && (
                    <>
                      {/* Loan Details: */}
                      <p className="text-primary mt-3"> Loan Details:</p>
                      <div className="form-row">
                        {CPersonalDetails.loanInformation.processingBankName ===
                        undefined ? (
                          <></>
                        ) : (
                          <>
                            <div className="form-group col-md-4">
                              <label htmlFor="loanAppId">
                                Loan Application ID{' '}
                                {/* <span className="text-danger font-weight-bold">
                              *
                            </span> */}
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Loan Application#"
                                {...register(
                                  'applicationId'
                                  // , {
                                  //   required: 'Application Id Type is required',
                                  // }
                                )}
                                defaultValue={
                                  CPersonalDetails.loanInformation.applicationId
                                }
                              />
                              {errors.applicationId && (
                                <p className="text-danger">
                                  {errors.applicationId.message}
                                </p>
                              )}
                            </div>
                          </>
                        )}
                        <div className="form-group col-md-4">
                          <label htmlFor="loanamount">
                            Loan Amount{' '}
                            <span className="text-danger font-weight-bold">
                              *
                            </span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Loan Amount#"
                            {...register('loanAmountAppliedFor', {
                              required: 'Loan Amount is required',
                            })}
                            defaultValue={
                              CPersonalDetails.loanInformation
                                .loanAmountAppliedFor
                            }
                            onKeyDown={(e) => checkIfNumber(e)}
                          />
                          {errors.loanAmountAppliedFor && (
                            <p className="text-danger">
                              {errors.loanAmountAppliedFor.message}
                            </p>
                          )}
                        </div>

                        <div className="form-group col-md-4">
                          <label htmlFor="loantype">
                            Loan Type{' '}
                            <span className="text-danger font-weight-bold">
                              *
                            </span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Loan Type#"
                            {...register('loanType', {
                              required: 'Loan Type is required',
                            })}
                            defaultValue={
                              CPersonalDetails.loanInformation.loanType
                            }
                            readOnly={
                              CPersonalDetails.loanInformation
                                .processingBankName === undefined
                                ? false
                                : true
                            }
                          />
                          {errors.loanType && (
                            <p className="text-danger">
                              {errors.loanType.message}
                            </p>
                          )}
                        </div>
                        <div className="form-group col-md-4">
                          <label htmlFor="loantenture">
                            Loan Tenture{' '}
                            <span className="text-danger font-weight-bold">
                              *
                            </span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Loan Tenure#"
                            {...register('loanTenure', {
                              required: 'Loan Tenure is required',
                            })}
                            defaultValue={
                              CPersonalDetails.loanInformation.loanTenure
                            }
                            onKeyDown={(e) => checkIfNumber(e)}
                          />
                          {errors.loanTenure && (
                            <p className="text-danger">
                              {errors.loanTenure.message}
                            </p>
                          )}
                        </div>
                        <div className="form-group col-md-5">
                          <label htmlFor="loanamount">
                            loan product type (secured/unsecured){' '}
                            <span className="text-danger font-weight-bold">
                              *
                            </span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Loan Product Type"
                            {...register('loanProductType', {
                              required: 'Loan Product Type is required',
                            })}
                            defaultValue={
                              CPersonalDetails.loanInformation.loanProductType
                            }
                            readOnly={
                              CPersonalDetails.loanInformation
                                .processingBankName === undefined
                                ? false
                                : true
                            }
                          />
                          {errors.loanProductType && (
                            <p className="text-danger">
                              {errors.loanProductType.message}
                            </p>
                          )}
                        </div>
                        {CPersonalDetails.loanInformation.processingBankName ===
                        undefined ? (
                          <></>
                        ) : (
                          <>
                            <div className="form-group col-md-4">
                              <label htmlFor="processingBankName">
                                Processing Bank Name
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Processing Bank Name#"
                                readOnly
                                {...register('processingBankName')}
                                defaultValue={
                                  CPersonalDetails.loanInformation
                                    .processingBankName
                                }
                              />
                              {errors.processingBankName && (
                                <p className="text-danger">
                                  {errors.processingBankName.message}
                                </p>
                              )}
                            </div>
                          </>
                        )}
                      </div>
                      {/* Personal Details */}
                      <p className="text-primary mt-3 "> Personal Details:</p>
                      <div className="form-row">
                        <div className="form-group col-md-2">
                          <label htmlFor="title">Title</label>
                          <select
                            id="title"
                            className="form-control"
                            readOnly={
                              CPersonalDetails.loanInformation
                                .processingBankName === undefined
                                ? false
                                : true
                            }
                          >
                            <option selected>Mr</option>
                            <option>Mrs</option>
                            <option>Ms</option>
                            <option>Dr</option>
                          </select>
                        </div>
                        <div className="form-group col-md-4">
                          <label htmlFor="fname">
                            First Name{' '}
                            <span className="text-danger font-weight-bold">
                              *
                            </span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="First Name"
                            {...register('customerFirstName', {
                              required: 'First Name is required',
                            })}
                            defaultValue={
                              CPersonalDetails.loanInformation.customerFirstName
                            }
                            readOnly={
                              CPersonalDetails.loanInformation
                                .processingBankName === undefined
                                ? false
                                : true
                            }
                          />
                          {errors.customerFirstName && (
                            <p className="text-danger">
                              {errors.customerFirstName.message}
                            </p>
                          )}
                        </div>
                        <div className="form-group col-md-3">
                          <label htmlFor="middlename">Middle Name</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Middle Name"
                            {...register('customerMiddleName')}
                            defaultValue={
                              CPersonalDetails.loanInformation
                                .customerMiddleName
                            }
                            readOnly={
                              CPersonalDetails.loanInformation
                                .processingBankName === undefined
                                ? false
                                : true
                            }
                          />
                        </div>
                        <div className="form-group col-md-3">
                          <label htmlFor="lname">Last Name</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Last Name"
                            {...register('customerLastName')}
                            defaultValue={
                              CPersonalDetails.loanInformation.customerLastName
                            }
                            readOnly={
                              CPersonalDetails.loanInformation
                                .processingBankName === undefined
                                ? false
                                : true
                            }
                          />
                          {errors.customerLastName && (
                            <p className="text-danger">
                              {errors.customerLastName.message}
                            </p>
                          )}
                        </div>
                      </div>
                      {/* Maiden Name */}
                      <p className="text-primary mb-2 ">
                        {' '}
                        Maiden Name (If Applicable):
                      </p>
                      <div className="form-row">
                        <div className="form-group col-md-2">
                          <label htmlFor="title">Title</label>
                          <select
                            id="title"
                            className="form-control"
                            readOnly={
                              CPersonalDetails.loanInformation
                                .processingBankName === undefined
                                ? false
                                : true
                            }
                          >
                            <option selected>Mr</option>
                            <option>Mrs</option>
                            <option>Ms</option>
                            <option>Dr</option>
                          </select>
                        </div>
                        <div className="form-group col-md-4">
                          <label htmlFor="fname">First Name</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="First Name"
                            readOnly={
                              CPersonalDetails.loanInformation
                                .processingBankName === undefined
                                ? false
                                : true
                            }
                          />
                        </div>
                        <div className="form-group col-md-3">
                          <label htmlFor="middlename">Middle Name</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Middle Name"
                            readOnly={
                              CPersonalDetails.loanInformation
                                .processingBankName === undefined
                                ? false
                                : true
                            }
                          />
                        </div>
                        <div className="form-group col-md-3">
                          <label htmlFor="lname">Last Name</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Last Name"
                            readOnly={
                              CPersonalDetails.loanInformation
                                .processingBankName === undefined
                                ? false
                                : true
                            }
                          />
                        </div>
                      </div>
                      {/*Father's/Spouse Name: */}
                      <p className="text-primary mb-2 ">
                        {' '}
                        Father's/Spouse Name:
                      </p>
                      <div className="form-row">
                        <div className="form-group col-md-2">
                          <label htmlFor="title">Title</label>
                          <select
                            id="inputState"
                            className="form-control"
                            readOnly={
                              CPersonalDetails.loanInformation
                                .processingBankName === undefined
                                ? false
                                : true
                            }
                          >
                            <option selected>Mr</option>
                            <option>Mrs</option>
                            <option>Ms</option>
                            <option>Dr</option>
                          </select>
                        </div>
                        <div className="form-group col-md-4">
                          <label htmlFor="fname">
                            First Name{' '}
                            <span className="text-danger font-weight-bold">
                              *
                            </span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="First Name"
                            {...register('fatherOrHusbandFirstName', {
                              required: 'First Name is required',
                            })}
                            defaultValue={
                              CPersonalDetails.loanInformation
                                .fatherOrHusbandFirstName
                            }
                            readOnly={
                              CPersonalDetails.loanInformation
                                .processingBankName === undefined
                                ? false
                                : true
                            }
                          />
                          {errors.fatherOrHusbandFirstName && (
                            <p className="text-danger">
                              {errors.fatherOrHusbandFirstName.message}
                            </p>
                          )}
                        </div>
                        <div className="form-group col-md-3">
                          <label htmlFor="middlename">Middle Name</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Middle Name"
                            {...register('fatherOrHusbandMiddleName')}
                            defaultValue={
                              CPersonalDetails.loanInformation
                                .fatherOrHusbandLastName
                            }
                            readOnly={
                              CPersonalDetails.loanInformation
                                .processingBankName === undefined
                                ? false
                                : true
                            }
                          />
                        </div>
                        <div className="form-group col-md-3">
                          <label htmlFor="lname">Last Name</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Last Name"
                            {...register('fatherOrHusbandLastName')}
                            defaultValue={
                              CPersonalDetails.loanInformation
                                .fatherOrHusbandLastName
                            }
                            readOnly={
                              CPersonalDetails.loanInformation
                                .processingBankName === undefined
                                ? false
                                : true
                            }
                          />
                        </div>
                      </div>
                      {/* Mother's Name: */}
                      <p className="text-primary mb-2 "> Mother's Name:</p>
                      <div className="form-row">
                        <div className="form-group col-md-2">
                          <label htmlFor="title">Title</label>
                          <select
                            id="inputState"
                            className="form-control"
                            readOnly={
                              CPersonalDetails.loanInformation
                                .processingBankName === undefined
                                ? false
                                : true
                            }
                          >
                            <option selected>Mr</option>
                            <option>Mrs</option>
                            <option>Ms</option>
                            <option>Dr</option>
                          </select>
                        </div>
                        <div className="form-group col-md-4">
                          <label htmlFor="fname">
                            First Name{' '}
                            <span className="text-danger font-weight-bold">
                              *
                            </span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="First Name"
                            {...register('motherMaidenFirstName', {
                              required: 'First Name is required',
                            })}
                            defaultValue={
                              CPersonalDetails.loanInformation
                                .motherMaidenFirstName
                            }
                            readOnly={
                              CPersonalDetails.loanInformation
                                .processingBankName === undefined
                                ? false
                                : true
                            }
                          />
                          {errors.motherMaidenFirstName && (
                            <p className="text-danger">
                              {errors.motherMaidenFirstName.message}
                            </p>
                          )}
                        </div>
                        <div className="form-group col-md-3">
                          <label htmlFor="middlename">Middle Name</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Middle Name"
                            {...register('motherMaidenMiddleName')}
                            defaultValue={
                              CPersonalDetails.loanInformation
                                .motherMaidenMiddleName
                            }
                            readOnly={
                              CPersonalDetails.loanInformation
                                .processingBankName === undefined
                                ? false
                                : true
                            }
                          />
                        </div>
                        <div className="form-group col-md-3">
                          <label htmlFor="lname">Last Name</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Last Name"
                            {...register('motherMaidenLastName')}
                            defaultValue={
                              CPersonalDetails.loanInformation
                                .motherMaidenLastName
                            }
                            readOnly={
                              CPersonalDetails.loanInformation
                                .processingBankName === undefined
                                ? false
                                : true
                            }
                          />
                        </div>
                      </div>
                      {/* Mobile and Email: */}
                      <div className="form-row">
                        <div className="form-group col-md-4">
                          <label for="mobile" className="text-primary">
                            Mobile :
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            id="mobile"
                            placeholder="Mobile"
                            {...register('customerMobileNumber', {
                              required: 'Mobile is required',
                              pattern: {
                                value: /^[0-9]{10}$/,
                                message: 'Please enter 10 digit phone no',
                              },
                            })}
                            onKeyUp={() => {
                              trigger('customerMobileNumber');
                            }}
                            defaultValue={
                              CPersonalDetails.loanInformation
                                .customerMobileNumber
                            }
                            readOnly={
                              CPersonalDetails.loanInformation
                                .processingBankName === undefined
                                ? false
                                : true
                            }
                          />
                          {errors.customerMobileNumber && (
                            <p className="text-danger">
                              {errors.customerMobileNumber.message}
                            </p>
                          )}
                        </div>
                        <div className="form-group col-md-4">
                          <label for="emailid" className="text-primary">
                            Email :
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            id="emailid"
                            placeholder="Email"
                            {...register('customerPersonalEmailId', {
                              required: 'Email is required',
                              pattern: {
                                value:
                                  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                message: 'Please enter a valid email',
                              },
                            })}
                            onKeyUp={() => {
                              trigger('customerPersonalEmailId');
                            }}
                            defaultValue={
                              CPersonalDetails.loanInformation
                                .customerPersonalEmailId
                            }
                            readOnly={
                              CPersonalDetails.loanInformation
                                .processingBankName === undefined
                                ? false
                                : true
                            }
                          />
                          {errors.customerPersonalEmailId && (
                            <p className="text-danger">
                              {errors.customerPersonalEmailId.message}
                            </p>
                          )}
                        </div>
                        <div className="form-group col-md-4">
                          <label for="Pan" className="text-primary">
                            Pan Card Number{' '}
                            <span className="text-danger font-weight-bold">
                              *
                            </span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="Pan"
                            placeholder="Pan Card Number"
                            {...register('customerPanNumber', {
                              required: 'Pan Card Number is required',
                            })}
                            defaultValue={
                              CPersonalDetails.loanInformation.customerPanNumber
                            }
                            readOnly={
                              CPersonalDetails.loanInformation
                                .processingBankName === undefined
                                ? false
                                : true
                            }
                          />
                          {errors.customerPanNumber && (
                            <p className="text-danger">
                              {errors.customerPanNumber.message}
                            </p>
                          )}
                        </div>
                      </div>
                      {/* Gender DOB Dependents */}
                      <div className="form-row">
                        <div className="form-group col-md-4">
                          <label for="gender" className="text-primary">
                            Gender{' '}
                            <span className="text-danger font-weight-bold">
                              *
                            </span>
                          </label>
                          <select
                            id="gender"
                            className="form-control"
                            {...register('gender', {
                              required: 'Gender is required',
                            })}
                            readOnly={
                              CPersonalDetails.loanInformation
                                .processingBankName === undefined
                                ? false
                                : true
                            }
                          >
                            {CPersonalDetails.loanInformation.gender && (
                              <option
                                value={CPersonalDetails.loanInformation.gender}
                                className="d-none"
                                selected
                              >
                                {CPersonalDetails.loanInformation.gender}
                              </option>
                            )}

                            {CPersonalDetails.loanInformation.gender ===
                              null && (
                              <option value="" selected>
                                Choose...
                              </option>
                            )}
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                            <option value="other">Other</option>
                          </select>
                          {errors.gender && (
                            <p className="text-danger">
                              {errors.gender.message}
                            </p>
                          )}
                        </div>
                        <div className="form-group col-md-4">
                          <label for="dob" className="text-primary">
                            Date Of Birth/ Inception
                            <span className="text-danger font-weight-bold">
                              *
                            </span>
                          </label>
                          <input
                            type="date"
                            className="form-control"
                            id="dob"
                            {...register('customerDOB', {
                              required: 'Date Of Birth/ Inception is required',
                            })}
                            defaultValue={
                              CPersonalDetails.loanInformation.customerDOB
                            }
                            readOnly={
                              CPersonalDetails.loanInformation
                                .processingBankName === undefined
                                ? false
                                : true
                            }
                          />
                          {errors.customerDOB && (
                            <p className="text-danger">
                              {errors.customerDOB.message}
                            </p>
                          )}
                        </div>
                        <div className="form-group col-md-4">
                          <label for="dependents" className="text-primary">
                            No.Of Dependents
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            id="dependents"
                            placeholder="No.Of Dependents"
                            {...register('no_of_dependents')}
                            defaultValue={
                              CPersonalDetails.loanInformation.no_of_dependents
                            }
                            readOnly={
                              CPersonalDetails.loanInformation
                                .processingBankName === undefined
                                ? false
                                : true
                            }
                          />
                        </div>
                      </div>
                      {/* Marital Status: */}
                      <p className="text-primary mb-2 ">
                        {' '}
                        Marital Status{' '}
                        <span className="text-danger font-weight-bold">*</span>
                      </p>
                      <div className="form-row">
                        <div className="form-group col-md-5">
                          <select
                            id="inputState"
                            className="form-control"
                            {...register('maritalStatus', {
                              required: 'Marital status is required',
                            })}
                            readOnly={
                              CPersonalDetails.loanInformation
                                .processingBankName === undefined
                                ? false
                                : true
                            }
                          >
                            {CPersonalDetails.loanInformation.maritalStatus && (
                              <option
                                value={
                                  CPersonalDetails.loanInformation.maritalStatus
                                }
                                className="d-none"
                                selected
                              >
                                {CPersonalDetails.loanInformation.maritalStatus}
                              </option>
                            )}

                            {CPersonalDetails.loanInformation.maritalStatus ===
                              null && (
                              <option value="" selected>
                                Choose...
                              </option>
                            )}
                            <option value="Married">Married</option>
                            <option value="Single">Single</option>
                            <option value="Other">Other</option>
                          </select>
                          {errors.maritalStatus && (
                            <p className="text-danger">
                              {errors.maritalStatus.message}
                            </p>
                          )}
                        </div>
                        <div className="form-group col-md">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Other Status"
                            readOnly={
                              watch().maritalStatus == 'Other' ? false : true
                            }
                          />
                        </div>
                      </div>

                      {/*Citizenship: */}
                      <p className="text-primary mb-2 ">
                        Citizenship{' '}
                        <span className="text-danger font-weight-bold">*</span>
                      </p>
                      <div className="form-row">
                        <div className="form-group col-md-5">
                          <select
                            id="inputState"
                            className="form-control"
                            {...register('nationality', {
                              required: 'Citizenship field is required',
                            })}
                            readOnly={
                              CPersonalDetails.loanInformation
                                .processingBankName === undefined
                                ? false
                                : true
                            }
                          >
                            {CPersonalDetails.loanInformation.nationality && (
                              <option
                                value={
                                  CPersonalDetails.loanInformation.nationality
                                }
                                className="d-none"
                                selected
                              >
                                {CPersonalDetails.loanInformation.nationality}
                              </option>
                            )}

                            {CPersonalDetails.loanInformation.nationality ===
                              null && (
                              <option value="" selected>
                                Choose...
                              </option>
                            )}
                            <option value="India">India</option>
                            <option value="Other">Other</option>
                          </select>
                          {errors.nationality && (
                            <p className="text-danger">
                              {errors.nationality.message}
                            </p>
                          )}
                        </div>
                        <div className="form-group col-md">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Citizenship Country"
                            {...register('citizenshipcountry')}
                            readOnly={
                              watch().nationality == 'Other' ? false : true
                            }
                          />
                        </div>
                      </div>
                      {/* Educational Qualification: */}

                      <div className="form-row">
                        <div className="form-group col-md-4">
                          <label htmlFor="religion">
                            {' '}
                            <span className="text-primary mb-2 ">
                              {' '}
                              Religion{' '}
                            </span>
                            <span className="text-danger font-weight-bold">
                              *
                            </span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Religion"
                            {...register('religion', {
                              required: 'Religion is required',
                            })}
                            defaultValue={
                              CPersonalDetails.loanInformation.religion
                            }
                            readOnly={
                              CPersonalDetails.loanInformation
                                .processingBankName === undefined
                                ? false
                                : true
                            }
                          />
                          {errors.religion && (
                            <p className="text-danger">
                              {errors.religion.message}
                            </p>
                          )}
                        </div>
                        <div className="form-group col-md">
                          <label htmlFor="lname">
                            {' '}
                            <span className="text-primary mb-2 ">
                              {' '}
                              Educational Qualification{' '}
                            </span>{' '}
                            <span className="text-danger font-weight-bold">
                              *
                            </span>
                          </label>
                          <select
                            id="inputState"
                            className="form-control"
                            {...register('education', {
                              required: 'Educational Qualification is required',
                            })}
                            readOnly={
                              CPersonalDetails.loanInformation
                                .processingBankName === undefined
                                ? false
                                : true
                            }
                          >
                            {CPersonalDetails.loanInformation.education && (
                              <option
                                value={
                                  CPersonalDetails.loanInformation.education
                                }
                                className="d-none"
                                selected
                              >
                                {CPersonalDetails.loanInformation.education}
                              </option>
                            )}

                            {CPersonalDetails.loanInformation.education ===
                              null && (
                              <option value="" selected>
                                Choose...
                              </option>
                            )}

                            <option value="Under Graduate">
                              Under Graduate
                            </option>
                            <option value="Graduate">Graduate</option>
                            <option value="Post Graduate">Post Graduate</option>
                            <option value="CA">CA</option>
                            <option value="MBA">MBA</option>
                            <option value="Doctor">Doctor</option>
                          </select>
                          {errors.education && (
                            <p className="text-danger">
                              {errors.education.message}
                            </p>
                          )}
                        </div>
                      </div>
                      {/* Residential Status: */}
                      <p className="text-primary mb-2 ">
                        Residential Status{' '}
                        <span className="text-danger font-weight-bold">*</span>
                      </p>
                      <div className="form-row">
                        <div className="form-group col-md-12">
                          <select
                            id="inputState"
                            className="form-control"
                            {...register('residentialStatus', {
                              required: 'Residential Status is required',
                            })}
                            readOnly={
                              CPersonalDetails.loanInformation
                                .processingBankName === undefined
                                ? false
                                : true
                            }
                          >
                            {CPersonalDetails.loanInformation
                              .residentialStatus && (
                              <option
                                value={
                                  CPersonalDetails.loanInformation
                                    .residentialStatus
                                }
                                className="d-none"
                                selected
                              >
                                {
                                  CPersonalDetails.loanInformation
                                    .residentialStatus
                                }
                              </option>
                            )}

                            {CPersonalDetails.loanInformation
                              .residentialStatus === null && (
                              <option value="" selected>
                                Choose...
                              </option>
                            )}
                            <option value="Resident Individual">
                              Resident Individual
                            </option>
                            <option value="Person Of Indian Origin">
                              Person Of Indian Origin
                            </option>
                            <option value="Foreign National">
                              Foreign National
                            </option>
                          </select>
                          {errors.residentialStatus && (
                            <p className="text-danger">
                              {errors.residentialStatus.message}
                            </p>
                          )}
                        </div>
                        <div className="form-group col-md-6">
                          <label htmlFor="countryOfResidencee">
                            Country Of Residence{' '}
                            <span className="text-danger font-weight-bold">
                              *
                            </span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Country Of Residence"
                            {...register('countryOfResidence', {
                              required: 'Country Of Residence is required',
                            })}
                            defaultValue={
                              CPersonalDetails.loanInformation
                                .countryOfResidence
                            }
                            readOnly={
                              CPersonalDetails.loanInformation
                                .processingBankName === undefined
                                ? false
                                : true
                            }
                          />
                          {errors.countryOfResidence && (
                            <p className="text-danger">
                              {errors.countryOfResidence.message}
                            </p>
                          )}
                        </div>
                        <div className="form-group col-md-6">
                          <label htmlFor="taxIdentificationNumber">
                            Tax Identification Number or Equivalent
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Tax Identification Number or Equivalent"
                            {...register('taxIdentificationNumber')}
                            defaultValue={
                              CPersonalDetails.loanInformation
                                .taxIdentificationNumber
                            }
                            readOnly={
                              CPersonalDetails.loanInformation
                                .processingBankName === undefined
                                ? false
                                : true
                            }
                          />
                        </div>
                        <div className="form-group col-md-4">
                          <label htmlFor="placeOrCityOfBirth">
                            Place/City Of Birth{' '}
                            <span className="text-danger font-weight-bold">
                              *
                            </span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Place/City Of Birth"
                            {...register('placeOrCityOfBirth', {
                              required: 'Place/City Of Birth is required',
                            })}
                            defaultValue={
                              CPersonalDetails.loanInformation
                                .placeOrCityOfBirth
                            }
                            readOnly={
                              CPersonalDetails.loanInformation
                                .processingBankName === undefined
                                ? false
                                : true
                            }
                          />
                          {errors.placeOrCityOfBirth && (
                            <p className="text-danger">
                              {errors.placeOrCityOfBirth.message}
                            </p>
                          )}
                        </div>
                        <div className="form-group col-md-4">
                          <label htmlFor="countryOfBirth">
                            Country Of Birth
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Country Of Birth"
                            {...register('countryOfBirth', {
                              required: 'Country Of Birth is required',
                            })}
                            defaultValue={
                              CPersonalDetails.loanInformation.countryOfBirth
                            }
                            readOnly={
                              CPersonalDetails.loanInformation
                                .processingBankName === undefined
                                ? false
                                : true
                            }
                          />
                          {errors.countryOfBirth && (
                            <p className="text-danger">
                              {errors.countryOfBirth.message}
                            </p>
                          )}
                        </div>
                      </div>
                      {/* Residential Address : */}
                      <p className="text-primary mb-2 ">Residential Address</p>
                      <div className="form-row">
                        <div className="form-group col-md-4">
                          <label htmlFor="address">
                            Address{' '}
                            <span className="text-danger font-weight-bold">
                              *
                            </span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Address"
                            {...register('presentResidentialAddress', {
                              required: 'Address is required',
                            })}
                            defaultValue={
                              CPersonalDetails.loanInformation
                                .presentResidentialAddress
                            }
                            readOnly={
                              CPersonalDetails.loanInformation
                                .processingBankName === undefined
                                ? false
                                : true
                            }
                          />
                          {errors.presentResidentialAddress && (
                            <p className="text-danger">
                              {errors.presentResidentialAddress.message}
                            </p>
                          )}
                        </div>
                        <div className="form-group col-md-4">
                          <label htmlFor="landmark">
                            Landmark{' '}
                            <span className="text-danger font-weight-bold">
                              *
                            </span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Landmark"
                            {...register('presentResidenceLandMark', {
                              required: 'Landmark is required',
                            })}
                            defaultValue={
                              CPersonalDetails.loanInformation
                                .presentResidenceLandMark
                            }
                            readOnly={
                              CPersonalDetails.loanInformation
                                .processingBankName === undefined
                                ? false
                                : true
                            }
                          />
                          {errors.presentResidenceLandMark && (
                            <p className="text-danger">
                              {errors.presentResidenceLandMark.message}
                            </p>
                          )}
                        </div>
                        <div className="form-group col-md-4">
                          <label htmlFor="cityvillage">
                            City/Village{' '}
                            <span className="text-danger font-weight-bold">
                              *
                            </span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="City/Village"
                            {...register('presentResidentialCityOrVillage', {
                              required: 'City/Village is required',
                            })}
                            defaultValue={
                              CPersonalDetails.loanInformation
                                .presentResidentialCityOrVillage
                            }
                            readOnly={
                              CPersonalDetails.loanInformation
                                .processingBankName === undefined
                                ? false
                                : true
                            }
                          />
                          {errors.presentResidentialCityOrVillage && (
                            <p className="text-danger">
                              {errors.presentResidentialCityOrVillage.message}
                            </p>
                          )}
                        </div>
                        <div className="form-group col-md-4">
                          <label htmlFor="state">
                            State{' '}
                            <span className="text-danger font-weight-bold">
                              *
                            </span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="State"
                            {...register('presentResidentialState', {
                              required: 'State is required',
                            })}
                            defaultValue={
                              CPersonalDetails.loanInformation
                                .presentResidentialState
                            }
                            readOnly={
                              CPersonalDetails.loanInformation
                                .processingBankName === undefined
                                ? false
                                : true
                            }
                          />
                          {errors.presentResidentialState && (
                            <p className="text-danger">
                              {errors.presentResidentialState.message}
                            </p>
                          )}
                        </div>
                        <div className="form-group col-md-4">
                          <label htmlFor="pincode">
                            Pincode{' '}
                            <span className="text-danger font-weight-bold">
                              *
                            </span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Pincode"
                            {...register('presentResidentialPincode', {
                              required: 'Pincode is required',
                            })}
                            defaultValue={
                              CPersonalDetails.loanInformation
                                .presentResidentialPincode
                            }
                            readOnly={
                              CPersonalDetails.loanInformation
                                .processingBankName === undefined
                                ? false
                                : true
                            }
                          />
                          {errors.presentResidentialPincode && (
                            <p className="text-danger">
                              {errors.presentResidentialPincode.message}
                            </p>
                          )}
                        </div>
                        <div className="form-group col-md-4">
                          <label htmlFor="std">STD</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="STD"
                            readOnly={
                              CPersonalDetails.loanInformation
                                .processingBankName === undefined
                                ? false
                                : true
                            }
                          />
                        </div>
                        <div className="form-group col-md-4">
                          <label htmlFor="phone">
                            Phone Number{' '}
                            <span className="text-danger font-weight-bold">
                              *
                            </span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Phone Number"
                            {...register('presentResidentialPhoneNumber', {
                              required: 'Phone Number is required',
                              pattern: {
                                value: /^[0-9]{10}$/,
                                message: 'Please enter 10 digit phone no',
                              },
                            })}
                            onKeyUp={() => {
                              trigger('presentResidentialPhoneNumber');
                            }}
                            onKeyDown={(e) => checkIfNumber(e)}
                            defaultValue={
                              CPersonalDetails.loanInformation
                                .presentResidentialPhoneNumber
                            }
                            readOnly={
                              CPersonalDetails.loanInformation
                                .processingBankName === undefined
                                ? false
                                : true
                            }
                          />
                          {errors.presentResidentialPhoneNumber && (
                            <p className="text-danger">
                              {errors.presentResidentialPhoneNumber.message}
                            </p>
                          )}
                        </div>

                        <div className="form-group col-md-4">
                          <label htmlFor="noofyears">
                            No of years in current address{' '}
                            <span className="text-danger font-weight-bold">
                              *
                            </span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Years in current address"
                            {...register('noOfYearsInPresentResidenceAddress', {
                              required:
                                'No of years in current address is required',
                            })}
                            onKeyDown={(e) => checkIfNumber(e)}
                            defaultValue={
                              CPersonalDetails.loanInformation
                                .noOfYearsInPresentResidenceAddress
                            }
                            readOnly={
                              CPersonalDetails.loanInformation
                                .processingBankName === undefined
                                ? false
                                : true
                            }
                          />
                          {errors.noOfYearsInPresentResidenceAddress && (
                            <p className="text-danger">
                              {
                                errors.noOfYearsInPresentResidenceAddress
                                  .message
                              }
                            </p>
                          )}
                        </div>

                        <div className="form-group col-md-4">
                          <label htmlFor="noofmonths">
                            No of Months in current address
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Months in current address"
                            readOnly={
                              CPersonalDetails.loanInformation
                                .processingBankName === undefined
                                ? false
                                : true
                            }
                          />
                        </div>
                      </div>
                      {/* Permanent Address : */}
                      <p className="text-primary mb-2 ">Permanent Address :</p>
                      <div className="form-group form-check ">
                        <input
                          type="checkbox"
                          className="form-check-input rounded-0"
                          id="registercheck"
                          // {...register('checkaddress')}
                          onChange={(e) => setCheckdata(e.target.checked)}
                          readOnly={
                            CPersonalDetails.loanInformation
                              .processingBankName === undefined
                              ? false
                              : true
                          }
                        />
                        <label
                          className="form-check-label"
                          htmlFor="registercheck"
                        >
                          Same as Residential Address
                        </label>
                      </div>
                      <div className="form-row">
                        {checkdata === true && (
                          <>
                            <div className="form-group col-md-4">
                              <label htmlFor="address">
                                Address{' '}
                                <span className="text-danger font-weight-bold">
                                  *
                                </span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Address"
                                {...register('presentResidentialAddress', {
                                  required: 'Address is required',
                                })}
                                defaultValue={
                                  CPersonalDetails.loanInformation
                                    .presentResidentialAddress
                                }
                                readOnly
                              />
                              {errors.presentResidentialAddress && (
                                <p className="text-danger">
                                  {errors.presentResidentialAddress.message}
                                </p>
                              )}
                            </div>
                            <div className="form-group col-md-4">
                              <label htmlFor="landmark">
                                Landmark{' '}
                                <span className="text-danger font-weight-bold">
                                  *
                                </span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Landmark"
                                {...register('presentResidenceLandMark', {
                                  required: 'Landmark is required',
                                })}
                                defaultValue={
                                  CPersonalDetails.loanInformation
                                    .presentResidenceLandMark
                                }
                                readOnly
                              />
                              {errors.presentResidenceLandMark && (
                                <p className="text-danger">
                                  {errors.presentResidenceLandMark.message}
                                </p>
                              )}
                            </div>
                            <div className="form-group col-md-4">
                              <label htmlFor="cityvillage">
                                City/Village{' '}
                                <span className="text-danger font-weight-bold">
                                  *
                                </span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="City/Village"
                                {...register(
                                  'presentResidentialCityOrVillage',
                                  {
                                    required: 'City/Village is required',
                                  }
                                )}
                                defaultValue={
                                  CPersonalDetails.loanInformation
                                    .presentResidentialCityOrVillage
                                }
                                readOnly
                              />
                              {errors.presentResidentialCityOrVillage && (
                                <p className="text-danger">
                                  {
                                    errors.presentResidentialCityOrVillage
                                      .message
                                  }
                                </p>
                              )}
                            </div>
                            <div className="form-group col-md-4">
                              <label htmlFor="state">
                                State{' '}
                                <span className="text-danger font-weight-bold">
                                  *
                                </span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="State"
                                {...register('presentResidentialState', {
                                  required: 'State is required',
                                })}
                                defaultValue={
                                  CPersonalDetails.loanInformation
                                    .presentResidentialState
                                }
                                readOnly
                              />
                              {errors.presentResidentialState && (
                                <p className="text-danger">
                                  {errors.presentResidentialState.message}
                                </p>
                              )}
                            </div>
                            <div className="form-group col-md-4">
                              <label htmlFor="pincode">
                                Pincode{' '}
                                <span className="text-danger font-weight-bold">
                                  *
                                </span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Pincode"
                                {...register('presentResidentialPincode', {
                                  required: 'Pincode is required',
                                })}
                                defaultValue={
                                  CPersonalDetails.loanInformation
                                    .presentResidentialPincode
                                }
                                readOnly
                              />
                              {errors.presentResidentialPincode && (
                                <p className="text-danger">
                                  {errors.presentResidentialPincode.message}
                                </p>
                              )}
                            </div>
                            <div className="form-group col-md-4">
                              <label htmlFor="std">STD</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="STD"
                                readOnly
                              />
                            </div>
                            <div className="form-group col-md-4">
                              <label htmlFor="phone">
                                Phone Number{' '}
                                <span className="text-danger font-weight-bold">
                                  *
                                </span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Phone Number"
                                {...register('presentResidentialPhoneNumber', {
                                  required: 'Phone Number is required',
                                  pattern: {
                                    value: /^[0-9]{10}$/,
                                    message: 'Please enter 10 digit phone no',
                                  },
                                })}
                                onKeyUp={() => {
                                  trigger('presentResidentialPhoneNumber');
                                }}
                                onKeyDown={(e) => checkIfNumber(e)}
                                defaultValue={
                                  CPersonalDetails.loanInformation
                                    .presentResidentialPhoneNumber
                                }
                                readOnly
                              />
                              {errors.presentResidentialPhoneNumber && (
                                <p className="text-danger">
                                  {errors.presentResidentialPhoneNumber.message}
                                </p>
                              )}
                            </div>
                          </>
                        )}
                      </div>
                      <div className="form-row">
                        {checkdata === false && (
                          <>
                            <div className="form-group col-md-4">
                              <label htmlFor="address">
                                Address{' '}
                                <span className="text-danger font-weight-bold">
                                  *
                                </span>
                              </label>

                              <input
                                type="text"
                                className="form-control"
                                placeholder="Address"
                                {...register('permanentResidentialAddress', {
                                  required: 'Address is required',
                                })}
                                defaultValue={
                                  CPersonalDetails.loanInformation
                                    .permanentResidentialAddress
                                }
                                readOnly={
                                  CPersonalDetails.loanInformation
                                    .processingBankName === undefined
                                    ? false
                                    : true
                                }
                              />
                              {errors.permanentResidentialAddress && (
                                <p className="text-danger">
                                  {errors.permanentResidentialAddress.message}
                                </p>
                              )}
                            </div>
                            <div className="form-group col-md-4">
                              <label htmlFor="landmark">
                                Landmark{' '}
                                <span className="text-danger font-weight-bold">
                                  *
                                </span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Landmark"
                                {...register('permanentResidenceLandMark', {
                                  required: 'Landmark is required',
                                })}
                                defaultValue={
                                  CPersonalDetails.loanInformation
                                    .permanentResidenceLandMark
                                }
                                readOnly={
                                  CPersonalDetails.loanInformation
                                    .processingBankName === undefined
                                    ? false
                                    : true
                                }
                              />
                              {errors.permanentResidenceLandMark && (
                                <p className="text-danger">
                                  {errors.permanentResidenceLandMark.message}
                                </p>
                              )}
                            </div>
                            <div className="form-group col-md-4">
                              <label htmlFor="cityvillage">
                                City/Village{' '}
                                <span className="text-danger font-weight-bold">
                                  *
                                </span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="City/Village"
                                {...register(
                                  'permanentResidentialCityOrVillage',
                                  {
                                    required: 'City/Village is required',
                                  }
                                )}
                                defaultValue={
                                  CPersonalDetails.loanInformation
                                    .permanentResidentialCityOrVillage
                                }
                                readOnly={
                                  CPersonalDetails.loanInformation
                                    .processingBankName === undefined
                                    ? false
                                    : true
                                }
                              />
                              {errors.permanentResidentialCityOrVillage && (
                                <p className="text-danger">
                                  {
                                    errors.permanentResidentialCityOrVillage
                                      .message
                                  }
                                </p>
                              )}
                            </div>
                            <div className="form-group col-md-4">
                              <label htmlFor="state">
                                State{' '}
                                <span className="text-danger font-weight-bold">
                                  *
                                </span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="State"
                                {...register('permanentResidentialState', {
                                  required: 'State is required',
                                })}
                                defaultValue={
                                  CPersonalDetails.loanInformation
                                    .permanentResidentialState
                                }
                                readOnly={
                                  CPersonalDetails.loanInformation
                                    .processingBankName === undefined
                                    ? false
                                    : true
                                }
                              />
                              {errors.permanentResidentialState && (
                                <p className="text-danger">
                                  {errors.permanentResidentialState.message}
                                </p>
                              )}
                            </div>
                            <div className="form-group col-md-4">
                              <label htmlFor="pincode">
                                Pincode{' '}
                                <span className="text-danger font-weight-bold">
                                  *
                                </span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Pincode"
                                {...register('permanentResidentialPincode', {
                                  required: 'Pincode is required',
                                })}
                                defaultValue={
                                  CPersonalDetails.loanInformation
                                    .permanentResidentialPincode
                                }
                                readOnly={
                                  CPersonalDetails.loanInformation
                                    .processingBankName === undefined
                                    ? false
                                    : true
                                }
                              />
                              {errors.permanentResidentialPincode && (
                                <p className="text-danger">
                                  {errors.permanentResidentialPincode.message}
                                </p>
                              )}
                            </div>
                            <div className="form-group col-md-4">
                              <label htmlFor="std">STD</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="STD"
                                readOnly={
                                  CPersonalDetails.loanInformation
                                    .processingBankName === undefined
                                    ? false
                                    : true
                                }
                              />
                            </div>
                            <div className="form-group col-md-4">
                              <label htmlFor="phone">
                                Phone Number{' '}
                                <span className="text-danger font-weight-bold">
                                  *
                                </span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Phone Number"
                                {...register('permanentResidencePhoneNumber', {
                                  required: 'Phone number is required',
                                  pattern: {
                                    value: /^[0-9]{10}$/,
                                    message: 'Please enter 10 digit phone no',
                                  },
                                })}
                                onKeyUp={() => {
                                  trigger('permanentResidencePhoneNumber');
                                }}
                                onKeyDown={(e) => checkIfNumber(e)}
                                defaultValue={
                                  CPersonalDetails.loanInformation
                                    .permanentResidencePhoneNumber
                                }
                                readOnly={
                                  CPersonalDetails.loanInformation
                                    .processingBankName === undefined
                                    ? false
                                    : true
                                }
                              />
                              {errors.permanentResidencePhoneNumber && (
                                <p className="text-danger">
                                  {errors.permanentResidencePhoneNumber.message}
                                </p>
                              )}
                            </div>
                          </>
                        )}
                      </div>
                      {/* Current Residence Type: */}
                      <p className="text-primary mb-2 ">
                        {' '}
                        Current Residence Type{' '}
                        <span className="text-danger font-weight-bold">*</span>
                      </p>
                      <div className="form-row">
                        <div className="form-group col-md-6">
                          <select
                            id="inputState"
                            className="form-control"
                            {...register('currentResidenceType', {
                              required: 'Current Residence Type is required',
                            })}
                            readOnly={
                              CPersonalDetails.loanInformation
                                .processingBankName === undefined
                                ? false
                                : true
                            }
                          >
                            {CPersonalDetails.loanInformation
                              .currentResidenceType && (
                              <option
                                value={
                                  CPersonalDetails.loanInformation
                                    .currentResidenceType
                                }
                                className="d-none"
                                selected
                              >
                                {
                                  CPersonalDetails.loanInformation
                                    .currentResidenceType
                                }
                              </option>
                            )}

                            {CPersonalDetails.loanInformation
                              .currentResidenceType === null && (
                              <option value="" selected>
                                Choose...
                              </option>
                            )}
                            <option value="Rented">Rented</option>
                            <option value="Owned">Owned</option>
                            <option value="Parental">Parental</option>
                            <option value="Company Provided">
                              Company Provided
                            </option>
                            <option value="Others">Others</option>
                          </select>
                          {errors.currentResidenceType && (
                            <p className="text-danger">
                              {errors.currentResidenceType.message}
                            </p>
                          )}
                        </div>
                        <div className="form-group col-md-6">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Other Residence Type"
                            readOnly={
                              watch().currentResidenceType == 'Others'
                                ? false
                                : true
                            }
                          />
                        </div>
                      </div>
                      {/* Preferred Communication Address: */}
                      <div className="form-row">
                        <div className="form-group col-md-6">
                          <label
                            for="precommunication"
                            className="text-primary"
                          >
                            Preferred Communication Address:
                          </label>
                          <select
                            id="inputState"
                            className="form-control"
                            {...register('preferredCommunicationAddress')}
                            readOnly={
                              CPersonalDetails.loanInformation
                                .processingBankName === undefined
                                ? false
                                : true
                            }
                          >
                            <option value="" selected>
                              Choose...
                            </option>
                            <option value="Residence Address">
                              Residence Address
                            </option>
                            <option value="Permanent Address">
                              Permanent Address
                            </option>
                            <option value="Office Address">
                              Office Address
                            </option>
                          </select>
                        </div>
                        <div className="form-group col-md-6">
                          <label for="commlanguage" className="text-primary">
                            Preferred Communication Language:
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="commlanguage"
                            placeholder="Preferred Communication Language:"
                            {...register('preferredCommunicationLanguage')}
                            readOnly={
                              CPersonalDetails.loanInformation
                                .processingBankName === undefined
                                ? false
                                : true
                            }
                          />
                        </div>
                      </div>
                      {/* Office Address : */}
                      <p className="text-primary mb-2 ">Employment Details :</p>
                      <div className="form-row">
                        <div className="form-group col-md-4">
                          <label htmlFor="address">
                            Address{' '}
                            <span className="text-danger font-weight-bold">
                              *
                            </span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Address"
                            {...register('officeOrBusinessAddress', {
                              required: 'Address is required',
                            })}
                            defaultValue={
                              CPersonalDetails.loanInformation
                                .officeOrBusinessAddress
                            }
                            readOnly={
                              CPersonalDetails.loanInformation
                                .processingBankName === undefined
                                ? false
                                : true
                            }
                          />
                          {errors.officeOrBusinessAddress && (
                            <p className="text-danger">
                              {errors.officeOrBusinessAddress.message}
                            </p>
                          )}
                        </div>
                        <div className="form-group col-md-4">
                          <label htmlFor="landmark">
                            Landmark{' '}
                            <span className="text-danger font-weight-bold">
                              *
                            </span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Landmark"
                            {...register('officeOrBusinessLandMark', {
                              required: 'Landmark is required',
                            })}
                            defaultValue={
                              CPersonalDetails.loanInformation
                                .officeOrBusinessLandMark
                            }
                            readOnly={
                              CPersonalDetails.loanInformation
                                .processingBankName === undefined
                                ? false
                                : true
                            }
                          />
                          {errors.officeOrBusinessLandMark && (
                            <p className="text-danger">
                              {errors.officeOrBusinessLandMark.message}
                            </p>
                          )}
                        </div>
                        <div className="form-group col-md-4">
                          <label htmlFor="cityvillage">
                            City/Village{' '}
                            <span className="text-danger font-weight-bold">
                              *
                            </span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="City/Village"
                            {...register('officeOrBusinessCityOrVillage', {
                              required: 'City/Village is required',
                            })}
                            defaultValue={
                              CPersonalDetails.loanInformation
                                .officeOrBusinessCityOrVillage
                            }
                            readOnly={
                              CPersonalDetails.loanInformation
                                .processingBankName === undefined
                                ? false
                                : true
                            }
                          />
                          {errors.officeOrBusinessCityOrVillage && (
                            <p className="text-danger">
                              {errors.officeOrBusinessCityOrVillage.message}
                            </p>
                          )}
                        </div>
                        <div className="form-group col-md-4">
                          <label htmlFor="state">
                            State{' '}
                            <span className="text-danger font-weight-bold">
                              *
                            </span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="State"
                            {...register('officeOrBusinessState', {
                              required: 'State is required',
                            })}
                            defaultValue={
                              CPersonalDetails.loanInformation
                                .officeOrBusinessState
                            }
                            readOnly={
                              CPersonalDetails.loanInformation
                                .processingBankName === undefined
                                ? false
                                : true
                            }
                          />
                          {errors.officeOrBusinessState && (
                            <p className="text-danger">
                              {errors.officeOrBusinessState.message}
                            </p>
                          )}
                        </div>
                        <div className="form-group col-md-4">
                          <label htmlFor="pincode">
                            Pincode{' '}
                            <span className="text-danger font-weight-bold">
                              *
                            </span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Pincode"
                            {...register('officeOrBusinessPincode', {
                              required: 'Pincode is required',
                            })}
                            onKeyDown={(e) => checkIfNumber(e)}
                            defaultValue={
                              CPersonalDetails.loanInformation
                                .officeOrBusinessPincode
                            }
                            readOnly={
                              CPersonalDetails.loanInformation
                                .processingBankName === undefined
                                ? false
                                : true
                            }
                          />
                          {errors.officeOrBusinessPincode && (
                            <p className="text-danger">
                              {errors.officeOrBusinessPincode.message}
                            </p>
                          )}
                        </div>

                        <div className="form-group col-md-4">
                          <label htmlFor="phone">
                            Phone Number{' '}
                            <span className="text-danger font-weight-bold">
                              *
                            </span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Phone Number"
                            {...register('officeOrBusinessPhone', {
                              required: 'Phone Number is required',
                              pattern: {
                                value: /^[0-9]{10}$/,
                                message: 'Please enter 10 digit phone no',
                              },
                            })}
                            onKeyUp={() => {
                              trigger('presentResidentialPhoneNumber');
                            }}
                            onKeyDown={(e) => checkIfNumber(e)}
                            defaultValue={
                              CPersonalDetails.loanInformation
                                .officeOrBusinessPhone
                            }
                            readOnly={
                              CPersonalDetails.loanInformation
                                .processingBankName === undefined
                                ? false
                                : true
                            }
                          />
                          {errors.officeOrBusinessPhone && (
                            <p className="text-danger">
                              {errors.officeOrBusinessPhone.message}
                            </p>
                          )}
                        </div>
                      </div>
                      {/* Profile -- Organization -- Office  */}
                      <div className="form-row">
                        <div className="form-group col-md-6">
                          <label for="commlanguage" className="text-primary">
                            Company Name{' '}
                            <span className="text-danger font-weight-bold">
                              *
                            </span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="commlanguage"
                            placeholder="Company Name:"
                            {...register('currentCompanyName', {
                              required: 'Company Name is required',
                            })}
                            defaultValue={
                              CPersonalDetails.loanInformation
                                .currentCompanyName
                            }
                            readOnly={
                              CPersonalDetails.loanInformation
                                .processingBankName === undefined
                                ? false
                                : true
                            }
                          />
                          {errors.currentCompanyName && (
                            <p className="text-danger">
                              {errors.currentCompanyName.message}
                            </p>
                          )}
                        </div>
                        <div className="form-group col-md-6">
                          <label for="profile" className="text-primary">
                            Employment Type:{' '}
                            <span className="text-danger font-weight-bold">
                              *
                            </span>
                          </label>
                          <select
                            id="profile"
                            className="form-control"
                            {...register('employmentType', {
                              required: 'Employment Type is required',
                            })}
                            readOnly={
                              CPersonalDetails.loanInformation
                                .processingBankName === undefined
                                ? false
                                : true
                            }
                          >
                            <option value="" selected>
                              Select Profile
                            </option>
                            {CPersonalDetails.loanInformation
                              .employmentType && (
                              <option
                                value={
                                  CPersonalDetails.loanInformation
                                    .employmentType
                                }
                                className="d-none"
                                selected
                              >
                                {
                                  CPersonalDetails.loanInformation
                                    .employmentType
                                }
                              </option>
                            )}

                            {CPersonalDetails.loanInformation.employmentType ===
                              null && (
                              <option value="" selected>
                                Select Profile
                              </option>
                            )}
                            <option value="salaried">Salaried</option>
                            <option value="publicSector">Public Sector</option>
                            <option value="privateSector">
                              Private Sector
                            </option>
                            <option value="govermentSector">
                              Goverment Sector
                            </option>
                            <option value="selfEmployed">Self Employed</option>
                            <option value="pensioner">Pensioner/Retired</option>
                          </select>
                          {errors.employmentType && (
                            <p className="text-danger">
                              {errors.employmentType.message}
                            </p>
                          )}
                        </div>
                        <div className="form-group col-md-6">
                          <label for="typeOrg" className="text-primary">
                            Type Of Organization:
                          </label>
                          <select
                            id="typeOrg"
                            className="form-control"
                            {...register('typeOfOrganization')}
                            readOnly={
                              CPersonalDetails.loanInformation
                                .processingBankName === undefined
                                ? false
                                : true
                            }
                          >
                            <option value="" selected>
                              Select Organization Type
                            </option>
                            {CPersonalDetails.loanInformation
                              .typeOfOrganization && (
                              <option
                                value={
                                  CPersonalDetails.loanInformation
                                    .typeOfOrganization
                                }
                                className="d-none"
                                selected
                              >
                                {
                                  CPersonalDetails.loanInformation
                                    .typeOfOrganization
                                }
                              </option>
                            )}

                            {CPersonalDetails.loanInformation
                              .typeOfOrganization === null && (
                              <option value="" selected>
                                Select Organization Type
                              </option>
                            )}

                            <option value="mnc">MNC</option>
                            <option value="propitorship">Propitorship</option>
                            <option value="partnership">Prartnership</option>
                            <option value="publicLimited">
                              Public Limited
                            </option>
                            <option value="privateLimited">
                              Private Limited
                            </option>
                            <option value="llp">LLP/Partnership Firm</option>
                            <option value="trust">Trust</option>
                            <option value="society">Society</option>
                            <option value="goverment">Goverment</option>
                          </select>
                        </div>
                        <div className="form-group col-md-6">
                          <label for="officeType" className="text-primary">
                            Office Type:
                          </label>

                          <select
                            id="officeType"
                            className="form-control"
                            {...register('officeType')}
                            readOnly={
                              CPersonalDetails.loanInformation
                                .processingBankName === undefined
                                ? false
                                : true
                            }
                          >
                            <option value="" selected>
                              Select Office Type
                            </option>
                            {CPersonalDetails.loanInformation.officeType && (
                              <option
                                value={
                                  CPersonalDetails.loanInformation.officeType
                                }
                                className="d-none"
                                selected
                              >
                                {CPersonalDetails.loanInformation.officeType}
                              </option>
                            )}

                            {CPersonalDetails.loanInformation.officeType ===
                              null && (
                              <option value="" selected>
                                Select Office Type
                              </option>
                            )}

                            <option value="rented">Rented</option>
                            <option value="owned">Owned</option>
                            <option value="leased">Leased</option>
                            <option value="companyProvided">
                              Company Provided
                            </option>
                          </select>
                        </div>
                      </div>
                      {/* Mobile -- Landline -- Email */}
                      <div className="form-row">
                        <div className="form-group col-md-4">
                          <label for="mobile" className="text-primary">
                            Work Mobile :
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            id="mobile"
                            placeholder="Mobile"
                            onKeyDown={(e) => checkIfNumber(e)}
                            readOnly={
                              CPersonalDetails.loanInformation
                                .processingBankName === undefined
                                ? false
                                : true
                            }
                          />
                        </div>
                        <div className="form-group col-md-4">
                          <label for="landline" className="text-primary">
                            Work Landline{' '}
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            id="landline"
                            placeholder="Landline"
                            {...register('officeOrBusinessLandLineNumber')}
                            onKeyDown={(e) => checkIfNumber(e)}
                            defaultValue={
                              CPersonalDetails.loanInformation
                                .officeOrBusinessLandLineNumber
                            }
                            readOnly={
                              CPersonalDetails.loanInformation
                                .processingBankName === undefined
                                ? false
                                : true
                            }
                          />
                          {errors.officeOrBusinessLandLineNumber && (
                            <p className="text-danger">
                              {errors.officeOrBusinessLandLineNumber.message}
                            </p>
                          )}
                        </div>
                        <div className="form-group col-md-4">
                          <label for="Email" className="text-primary">
                            Work Email{' '}
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            id="Email"
                            placeholder="Email"
                            {...register('officeOrBusinessEmail', {
                              pattern: {
                                value:
                                  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                message: 'Please enter a valid email',
                              },
                            })}
                            onKeyUp={() => {
                              trigger('officeOrBusinessEmail');
                            }}
                            defaultValue={
                              CPersonalDetails.loanInformation
                                .officeOrBusinessEmail
                            }
                            readOnly={
                              CPersonalDetails.loanInformation
                                .processingBankName === undefined
                                ? false
                                : true
                            }
                          />
                          {errors.officeOrBusinessEmail && (
                            <p className="text-danger">
                              {errors.officeOrBusinessEmail.message}
                            </p>
                          )}
                        </div>
                      </div>
                      {/* Total Work Experience : */}
                      <div className="form-row">
                        <div className="form-group col-md-4 mt-3">
                          <label for="totalExp" className="text-primary mt-1">
                            Total Work Experience :
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            id="totalExp"
                            placeholder="Total Work Experience :"
                            {...register('totalWorkExperience')}
                            defaultValue={
                              CPersonalDetails.loanInformation
                                .totalWorkExperience
                            }
                            onKeyDown={(e) => checkIfNumber(e)}
                            readOnly={
                              CPersonalDetails.loanInformation
                                .processingBankName === undefined
                                ? false
                                : true
                            }
                          />
                        </div>
                        <div className="form-group col-md-4 mt-3">
                          <label
                            for="yearcurrentjob"
                            className="text-primary mt-1"
                          >
                            No. Of Years In Current Job :
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            id="yearcurrentjob"
                            placeholder="No. Of Years In Current Job"
                            {...register('noOfYearsInCurrentJob')}
                            defaultValue={
                              CPersonalDetails.loanInformation
                                .noOfYearsInCurrentJob
                            }
                            onKeyDown={(e) => checkIfNumber(e)}
                            readOnly={
                              CPersonalDetails.loanInformation
                                .processingBankName === undefined
                                ? false
                                : true
                            }
                          />
                        </div>
                        <div className="form-group col-md-4">
                          <label for="jobexpmore" className="text-primary">
                            If Current Job Less Than 1 Year, Name Of Previous
                            Company:
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="jobexpmore"
                            placeholder="Previous Company"
                            {...register('previousCompanyName')}
                            defaultValue={
                              CPersonalDetails.loanInformation
                                .previousCompanyName
                            }
                            readOnly={
                              CPersonalDetails.loanInformation
                                .processingBankName === undefined
                                ? false
                                : true
                            }
                          />
                        </div>
                      </div>
                      {/* Monthly Income --- Net -- mode of payment */}
                      <div className="form-row">
                        <div className="form-group col-md-4">
                          <label for="monthlyInc" className="text-primary">
                            Gross Monthly Income:
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            id="monthlyInc"
                            placeholder="Gross Monthly Income"
                            {...register('grossMonthlyIncome')}
                            defaultValue={
                              CPersonalDetails.loanInformation
                                .grossMonthlyIncome
                            }
                            readOnly={
                              CPersonalDetails.loanInformation
                                .processingBankName === undefined
                                ? false
                                : true
                            }
                          />
                        </div>
                        <div className="form-group col-md-4">
                          <label for="monthlyInc" className="text-primary">
                            Net Monthly Income :{' '}
                            <span className="text-danger font-weight-bold">
                              *
                            </span>
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            id="monthlyInc"
                            placeholder="Net Monthly Income"
                            {...register('netMonthlyIncome', {
                              required: 'Net Monthly Income is required',
                            })}
                            defaultValue={
                              CPersonalDetails.loanInformation.netMonthlyIncome
                            }
                            readOnly={
                              CPersonalDetails.loanInformation
                                .processingBankName === undefined
                                ? false
                                : true
                            }
                          />
                          {errors.netMonthlyIncome && (
                            <p className="text-danger">
                              {errors.netMonthlyIncome.message}
                            </p>
                          )}
                        </div>
                        <div className="form-group col-md-4">
                          <label for="paymentMode" className="text-primary">
                            Mode Of Payment:
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="paymentMode"
                            placeholder="Cash/Cheque/Bank Credit"
                            {...register('modeOfPayment')}
                            defaultValue={
                              CPersonalDetails.loanInformation.modeOfPayment
                            }
                            readOnly={
                              CPersonalDetails.loanInformation
                                .processingBankName === undefined
                                ? false
                                : true
                            }
                          />
                        </div>
                      </div>
                      {/* Industry --- Designation  */}
                      <div className="form-row">
                        <div className="form-group col-md-6">
                          <label for="typeOfIndustry" className="text-primary">
                            Type Of Industry:
                          </label>
                          {/* <select
                            id="typeOfIndustry"
                            className="form-control"
                            {...register('typeOfIndustry')}
                            readOnly={
                              CPersonalDetails.loanInformation
                                .processingBankName === undefined
                                ? false
                                : true
                            }
                          >
                            <option value="" selected>
                              Select Type Of Industry
                            </option>
                            {CPersonalDetails.loanInformation
                              .typeOfIndustry && (
                              <option
                                value={
                                  CPersonalDetails.loanInformation
                                    .typeOfIndustry
                                }
                                className="d-none"
                                selected
                              >
                                {
                                  CPersonalDetails.loanInformation
                                    .typeOfIndustry
                                }
                              </option>
                            )}

                            {CPersonalDetails.loanInformation.typeOfIndustry ===
                              null && (
                              <option value="" selected>
                                Select Type Of Industry
                              </option>
                            )}

                            <option value="accountancy">
                              Accountancy /Audit
                            </option>
                            <option value="agriculture">Agriculture</option>
                            <option value="airlines">Airlines</option>
                            <option value="automobiles">Automobiles</option>
                            <option value="banking">
                              Banking / Financial Services
                            </option>
                            <option value="it">Call Center/BPO/IT</option>
                            <option value="education">Education</option>
                            <option value="electrical">
                              Electrical/Electronics
                            </option>
                            <option value="entertainment">
                              Entertainment/Media
                            </option>
                            <option value="exportimport">Export/Import</option>
                            <option value="hospital">
                              Hospital/Healthcare
                            </option>
                            <option value="hotel">Hotel/Resturant</option>
                            <option value="insurance">Insurance</option>
                            <option value="ironandstell">Iron & Steel</option>
                            <option value="oilandgas">Oil & Gas</option>
                            <option value="pharma">
                              Pharma & Biotechnology
                            </option>
                            <option value="postandtelegraph">
                              Post and Telegraph
                            </option>
                            <option value="powerandelecticity">
                              Power and Electricity
                            </option>
                            <option value="retail">Retail</option>
                            <option value="infrastructure">
                              Roadways/Infrastructure
                            </option>
                            <option value="railways">Railways</option>
                            <option value="trading">Trading</option>
                            <option value="texttile">Textile & Leather</option>
                            <option value="telecom">Telecom</option>
                            <option value="travelandtourism">
                              Travel & Tourism
                            </option>
                          </select> */}
                          <input
                            type="text"
                            id="typeOfIndustry"
                            placeholder="Type Of Industry"
                            className="form-control"
                            {...register('typeOfIndustry')}
                            defaultValue={
                              CPersonalDetails.loanInformation.typeOfIndustry
                            }
                            readOnly={
                              CPersonalDetails.loanInformation
                                .processingBankName === undefined
                                ? false
                                : true
                            }
                          />
                        </div>
                        <div className="form-group col-md-6">
                          <label for="designationWork" className="text-primary">
                            Designation At Work:{' '}
                            <span className="text-danger font-weight-bold">
                              *
                            </span>
                          </label>

                          <input
                            type="text"
                            id="designationWork"
                            className="form-control"
                            placeholder="Designation"
                            {...register('designation', {
                              required: 'Designation is required',
                            })}
                            defaultValue={
                              CPersonalDetails.loanInformation.designation
                            }
                            readOnly={
                              CPersonalDetails.loanInformation
                                .processingBankName === undefined
                                ? false
                                : true
                            }
                          />
                          {errors.designation && (
                            <p className="text-danger">
                              {errors.designation.message}
                            </p>
                          )}
                        </div>
                      </div>
                      {/* GST Registered:*/}
                      <p className="text-primary mb-2 "> GST Registered: </p>
                      <div className="form-row">
                        <div className="form-group col-md-6">
                          <select
                            id="inputState"
                            className="form-control"
                            {...register('isGSTRegistered')}
                            readOnly={
                              CPersonalDetails.loanInformation
                                .processingBankName === undefined
                                ? false
                                : true
                            }
                          >
                            {CPersonalDetails.loanInformation
                              .isGSTRegistered && (
                              <option
                                value={
                                  CPersonalDetails.loanInformation
                                    .isGSTRegistered
                                }
                                className="d-none"
                                selected
                              >
                                {
                                  CPersonalDetails.loanInformation
                                    .isGSTRegistered
                                }
                              </option>
                            )}

                            {CPersonalDetails.loanInformation
                              .isGSTRegistered === null && (
                              <option value="" selected>
                                Choose...
                              </option>
                            )}
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                          </select>
                        </div>
                        <div className="form-group col-md-6">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="GST Registration Number"
                            {...register('gstRegistrationNumber')}
                            defaultValue={
                              CPersonalDetails.loanInformation
                                .gstRegistrationNumber
                            }
                            readOnly={
                              CPersonalDetails.loanInformation
                                .processingBankName === undefined
                                ? false
                                : true
                            }
                          />
                        </div>
                      </div>
                      {/* Reference Details 1 : */}
                      <p className="text-primary mb-2 ">Reference Details 1</p>
                      <div className="form-row">
                        <div className="form-group col-md-4">
                          <label htmlFor="fullname">Full Name</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Full Name"
                            {...register('reference1Name')}
                            defaultValue={
                              CPersonalDetails.loanInformation.reference1Name
                            }
                            readOnly={
                              CPersonalDetails.loanInformation
                                .processingBankName === undefined
                                ? false
                                : true
                            }
                          />
                        </div>
                        <div className="form-group col-md-4">
                          <label htmlFor="Relationship">Relationship</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Relationship"
                            {...register('reference1Relation')}
                            defaultValue={
                              CPersonalDetails.loanInformation
                                .reference1Relation
                            }
                            readOnly={
                              CPersonalDetails.loanInformation
                                .processingBankName === undefined
                                ? false
                                : true
                            }
                          />
                        </div>
                        <div className="form-group col-md-4">
                          <label htmlFor="address">Address</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Address"
                            {...register('reference1Address')}
                            defaultValue={
                              CPersonalDetails.loanInformation.reference1Address
                            }
                            readOnly={
                              CPersonalDetails.loanInformation
                                .processingBankName === undefined
                                ? false
                                : true
                            }
                          />
                        </div>
                        <div className="form-group col-md-4">
                          <label htmlFor="state">Mobile Number</label>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Mobile Number"
                            {...register('reference1Mobile', {
                              pattern: {
                                value: /^[0-9]{10}$/,
                                message: 'Please enter 10 digit phone no',
                              },
                            })}
                            onKeyUp={() => {
                              trigger('reference1Mobile');
                            }}
                            defaultValue={
                              CPersonalDetails.loanInformation.reference1Mobile
                            }
                            readOnly={
                              CPersonalDetails.loanInformation
                                .processingBankName === undefined
                                ? false
                                : true
                            }
                          />

                          {errors.reference1Mobile && (
                            <p className="text-danger">
                              {errors.reference1Mobile.message}
                            </p>
                          )}
                        </div>
                        <div className="form-group col-md-4">
                          <label htmlFor="pincode">Email </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Email"
                            {...register('reference1Email', {
                              pattern: {
                                value:
                                  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                message: 'Please enter a valid email',
                              },
                            })}
                            onKeyUp={() => {
                              trigger('reference1Email');
                            }}
                            defaultValue={
                              CPersonalDetails.loanInformation.reference1Email
                            }
                            readOnly={
                              CPersonalDetails.loanInformation
                                .processingBankName === undefined
                                ? false
                                : true
                            }
                          />
                          {errors.reference1Email && (
                            <p className="text-danger">
                              {errors.reference1Email.message}
                            </p>
                          )}
                        </div>
                      </div>
                      {/* Reference Details 1 : */}
                      <p className="text-primary mb-2 ">Reference Details 2</p>
                      <div className="form-row">
                        <div className="form-group col-md-4">
                          <label htmlFor="fullname">Full Name</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Full Name"
                            {...register('reference2Name')}
                            defaultValue={
                              CPersonalDetails.loanInformation.reference2Name
                            }
                            readOnly={
                              CPersonalDetails.loanInformation
                                .processingBankName === undefined
                                ? false
                                : true
                            }
                          />
                        </div>
                        <div className="form-group col-md-4">
                          <label htmlFor="Relationship">Relationship</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Relationship"
                            {...register('reference2Relation')}
                            defaultValue={
                              CPersonalDetails.loanInformation
                                .reference2Relation
                            }
                            readOnly={
                              CPersonalDetails.loanInformation
                                .processingBankName === undefined
                                ? false
                                : true
                            }
                          />
                        </div>
                        <div className="form-group col-md-4">
                          <label htmlFor="address">Address</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Address"
                            {...register('reference2Address')}
                            defaultValue={
                              CPersonalDetails.loanInformation.reference2Address
                            }
                            readOnly={
                              CPersonalDetails.loanInformation
                                .processingBankName === undefined
                                ? false
                                : true
                            }
                          />
                        </div>
                        <div className="form-group col-md-4">
                          <label htmlFor="state">Mobile Number</label>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Mobile Number"
                            {...register('reference2Mobile', {
                              pattern: {
                                value: /^[0-9]{10}$/,
                                message: 'Please enter 10 digit phone no',
                              },
                            })}
                            onKeyUp={() => {
                              trigger('reference2Mobile');
                            }}
                            defaultValue={
                              CPersonalDetails.loanInformation.reference2Mobile
                            }
                            readOnly={
                              CPersonalDetails.loanInformation
                                .processingBankName === undefined
                                ? false
                                : true
                            }
                          />

                          {errors.reference2Mobile && (
                            <p className="text-danger">
                              {errors.reference2Mobile.message}
                            </p>
                          )}
                        </div>
                        <div className="form-group col-md-4">
                          <label htmlFor="pincode">Email </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Email"
                            {...register('reference2Email', {
                              pattern: {
                                value:
                                  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                message: 'Please enter a valid email',
                              },
                            })}
                            onKeyUp={() => {
                              trigger('reference2Email');
                            }}
                            defaultValue={
                              CPersonalDetails.loanInformation.reference2Email
                            }
                            readOnly={
                              CPersonalDetails.loanInformation
                                .processingBankName === undefined
                                ? false
                                : true
                            }
                          />
                          {errors.reference2Email && (
                            <p className="text-danger">
                              {errors.reference2Email.message}
                            </p>
                          )}
                        </div>
                      </div>
                    </>
                  )}

                  {step === 1 && (
                    <>
                      {/* Voter ID, PAN Card */}
                      <div className="form-row">
                        <div className="form-group col-md">
                          <label for="voter" className="text-primary">
                            Voter ID:
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="voter"
                            placeholder="Voter ID"
                            {...register('votedIdCard')}
                            defaultValue={
                              CPersonalDetails.loanInformation.votedIdCard
                            }
                            readOnly={
                              CPersonalDetails.loanInformation
                                .processingBankName === undefined
                                ? false
                                : true
                            }
                          />
                        </div>
                      </div>

                      {/* Driving License*/}
                      <p className="text-primary mb-2 "> Driving License: </p>
                      <div className="form-row">
                        <div className="form-group col-md-6">
                          <input
                            type="text"
                            className="form-control"
                            id="license"
                            placeholder="Driving License"
                            {...register('drivingLicense')}
                            defaultValue={
                              CPersonalDetails.loanInformation.drivingLicense
                            }
                            readOnly={
                              CPersonalDetails.loanInformation
                                .processingBankName === undefined
                                ? false
                                : true
                            }
                          />
                        </div>
                        <div className="form-group col-md-6">
                          <input
                            type="date"
                            className="form-control"
                            id="drivingDate"
                            {...register('drivingLicenseValidityDate')}
                            defaultValue={
                              CPersonalDetails.loanInformation
                                .drivingLicenseValidityDate
                            }
                            readOnly={
                              CPersonalDetails.loanInformation
                                .processingBankName === undefined
                                ? false
                                : true
                            }
                          />
                        </div>
                      </div>

                      {/* Passport Number*/}
                      <p className="text-primary mb-2 "> Passport Number: </p>
                      <div className="form-row">
                        <div className="form-group col-md-6">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Passport Number"
                            {...register('passportNumber')}
                            defaultValue={
                              CPersonalDetails.loanInformation.passportNumber
                            }
                            readOnly={
                              CPersonalDetails.loanInformation
                                .processingBankName === undefined
                                ? false
                                : true
                            }
                          />
                        </div>
                        <div className="form-group col-md-6">
                          <input
                            type="date"
                            className="form-control"
                            {...register('passportValidityDate')}
                            defaultValue={
                              CPersonalDetails.loanInformation
                                .passportValidityDate
                            }
                            readOnly={
                              CPersonalDetails.loanInformation
                                .processingBankName === undefined
                                ? false
                                : true
                            }
                          />
                        </div>
                      </div>

                      {/*Business loan and personal loan condition */}

                      {CPersonalDetails.loanInformation.loanType !==
                      'Personal Loan' ? (
                        <>
                          {/* NERGA Job Card Number: */}
                          <p className="text-primary mb-2 ">
                            NERGA Job Card Number:
                          </p>
                          <div className="form-group ">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="NERGA Job Card Number"
                              {...register('nergaJobCardNumber')}
                              defaultValue={
                                CPersonalDetails.loanInformation
                                  .nergaJobCardNumber
                              }
                              readOnly={
                                CPersonalDetails.loanInformation
                                  .processingBankName === undefined
                                  ? false
                                  : true
                              }
                            />
                          </div>

                          {/* No Of Years In Business --- TAN -- PAN */}
                          <div className="form-row">
                            <div className="form-group col-md-6">
                              <label
                                for="YearsInBusiness"
                                className="text-primary"
                              >
                                No Of Years In Business :
                              </label>
                              <input
                                type="number"
                                className="form-control"
                                id="YearsInBusiness"
                                placeholder="No Of Years In Business :"
                                readOnly={
                                  CPersonalDetails.loanInformation
                                    .processingBankName === undefined
                                    ? false
                                    : true
                                }
                              />
                            </div>
                            <div className="form-group col-md-6">
                              <label for="TAN" className="text-primary">
                                TAN:
                              </label>
                              <input
                                type="number"
                                className="form-control"
                                id="TAN"
                                placeholder="TAN"
                                readOnly={
                                  CPersonalDetails.loanInformation
                                    .processingBankName === undefined
                                    ? false
                                    : true
                                }
                              />
                            </div>
                          </div>

                          {/* Nature Of Business: */}
                          <div className="form-row">
                            <div className="form-group col-md-5">
                              <label
                                for="natureOfBusiness"
                                className="text-primary"
                              >
                                Nature Of Business:
                              </label>
                              <select
                                id="natureOfBusiness"
                                className="form-control"
                                readOnly={
                                  CPersonalDetails.loanInformation
                                    .processingBankName === undefined
                                    ? false
                                    : true
                                }
                              >
                                <option selected>Choose...</option>
                                <option value="retailer">Retailer</option>
                                <option value="trader">Trader</option>
                                <option value="wholesaler">Wholsaler</option>
                                <option value="companyProvided">
                                  Company Provided
                                </option>
                              </select>
                            </div>
                            <div className="form-group col-md">
                              <label
                                for="annualTurnover"
                                className="text-primary"
                              >
                                Latest Annual Turnover From Business
                              </label>
                              <input
                                type="text"
                                id="annualTurnover"
                                className="form-control"
                                placeholder="Latest annual turnover from Business"
                                readOnly={
                                  CPersonalDetails.loanInformation
                                    .processingBankName === undefined
                                    ? false
                                    : true
                                }
                              />
                            </div>
                          </div>

                          {/* Date Range --- Profit after tax */}
                          <div className="form-row">
                            <div className="form-group col-md-5">
                              <label className="text-primary">
                                Date Range:
                              </label>
                              <br />
                              <DateRangePicker
                                id="dateRange"
                                onChange={setDateRange}
                                value={daterange}
                                readOnly={
                                  CPersonalDetails.loanInformation
                                    .processingBankName === undefined
                                    ? false
                                    : true
                                }
                              />
                            </div>
                            <div className="form-group col-md-7">
                              <label for="profitTax" className="text-primary">
                                Profit After Tax:
                              </label>
                              <input
                                type="text"
                                id="profitTax"
                                className="form-control"
                                placeholder=" Profit After Tax"
                                readOnly={
                                  CPersonalDetails.loanInformation
                                    .processingBankName === undefined
                                    ? false
                                    : true
                                }
                              />
                            </div>
                          </div>

                          {/* Type Of Industry: */}
                          <div className="form-row">
                            <div className="form-group col-md-4">
                              <label for="tinGST" className="text-primary">
                                TIN / GST number
                              </label>
                              <input
                                type="text"
                                id="tinGST"
                                className="form-control"
                                placeholder="TIN / GST number"
                                readOnly={
                                  CPersonalDetails.loanInformation
                                    .processingBankName === undefined
                                    ? false
                                    : true
                                }
                              />
                            </div>

                            <div className="form-group col-md-4">
                              <label for="MCA" className="text-primary">
                                Registration Number /MCA CIN
                              </label>
                              <input
                                type="text"
                                id="MCA"
                                className="form-control"
                                placeholder="Registration Number /MCA CIN"
                                readOnly={
                                  CPersonalDetails.loanInformation
                                    .processingBankName === undefined
                                    ? false
                                    : true
                                }
                              />
                            </div>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  )}

                  <div className="modal-footer">
                    {previousrenderButton()}
                    {renderButton()}

                    <button
                      type="button"
                      onClick={handleSubmit(saveButtonSubmit)}
                      className="btn btn-success rounded-0"
                    >
                      Save
                    </button>
                  </div>
                </form>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CustomerPersonalDetails;

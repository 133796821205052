import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import { useEffect } from 'react';
import Layout from './Layout';

const ViewPayoutAgent = () => {
  const [loading, setLoading] = useState(false);
  const [accordion, setActiveAccordion] = useState(-1);
  const [BankList, setBankList] = useState([]);
  const [loanTypes, setLoanTypes] = useState([]);
  const [searchV, setSearchV] = useState({
    agentId: '',
    startDate: '',
    endDate: '',
    searchLoanLead: [],
    searchError: false,
    selectloanTypes: '',
    networkLevel: '',
    selectBank: '',
    brokerName: '',
    brokerCode: '',
  });
  const [loan, setLoan] = useState([]);

  const {
    agentId,
    startDate,
    endDate,
    searchLoanLead,
    searchError,
    selectloanTypes,
    networkLevel,
    selectBank,
    brokerName,
    brokerCode,
  } = searchV;

  useEffect(() => {
    const fetchdata = async () => {
      setLoading(true);
      try {
        await fetch(
          `${process.env.REACT_APP_API_URL}/getAllAgentsPayoutWithinDateRange`,
          {
            method: 'GET',
            headers: {
              Accept: 'application/json',
            },
          }
        )
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            setLoan(data);
            setLoading(false);
          });
      } catch (error) {
        setLoading(false);
      }
    };
    fetchdata();
    const fetchBankdata = async () => {
      await fetch(`${process.env.REACT_APP_API_URL}/getBankList`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
        },
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          setBankList(data);
        });
    };
    fetchBankdata();
    const fetchDataLoanType = async () => {
      try {
        await fetch(`${process.env.REACT_APP_API_URL}/getLoanTypes`, {
          method: 'GET',
          headers: {
            Accept: 'application/json',
          },
        })
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            setLoanTypes(data);
          });
      } catch (error) {
        console.log(error);
      }
    };
    fetchDataLoanType();
  }, []);

  const toggleAccordion = (index) => {
    if (index === accordion) {
      setActiveAccordion(-1);
      return;
    }
    setActiveAccordion(index);
  };

  const showLoading = () =>
    loading && (
      <div className="alert ">
        <h2>Loading...</h2>
      </div>
    );

  const searchHandleChange = (name) => (e) => {
    setSearchV({
      ...searchV,
      [name]: e.target.value,
      searchLoanLead: '',
    });
  };

  const searchSubmit = (e) => {
    e.preventDefault();

    const fetchdata = async () => {
      setLoading(true);
      const startD = startDate ? moment(startDate).format('DD-MM-YYYY') : '';
      const endD = endDate ? moment(endDate).format('DD-MM-YYYY') : '';

      try {
        await fetch(
          `${process.env.REACT_APP_API_URL}/getAllAgentsPayoutWithinDateRange?agentId=${agentId}&brokerCode=${brokerCode}&loanType=${selectloanTypes}&processingBankId=${selectBank}&networkLevel=${networkLevel}&endDate=${endD}&startDate=${startD}`,
          {
            method: 'GET',
            headers: {
              Accept: 'application/json',
            },
          }
        )
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            if (data.length === 0) {
              setSearchV({ ...searchV, searchError: true });
              setLoading(false);
            } else {
              setSearchV({
                ...searchV,
                searchLoanLead: data,
                searchError: false,
              });
              setLoading(false);
            }
          });
      } catch (error) {
        setSearchV({
          ...searchV,
          searchError: true,
        });
        setLoading(false);
      }
    };
    fetchdata();
  };

  const tableData = (getData) => {
    return (
      <table className="table table-borderless table-responsive-xl text-center mb-0">
        <thead>
          <tr>
            <th scope="col" className="current-assigned-th pr-2">
              Agent Name
            </th>
            <th scope="col" className="current-assigned-th pr-2">
              Date Range
            </th>
            <th scope="col" className="current-assigned-th pr-2">
              Number of Disbursed Loans
            </th>
            <th scope="col" className="current-assigned-th pr-2">
              Payout
            </th>
            <th scope="col" className="current-assigned-th pr-2">
              View Details
            </th>
          </tr>
        </thead>
        <tbody>
          {showLoading()}
          {getData.length > 0 ? (
            getData.map((data, index) => {
              return (
                <React.Fragment key={index}>
                  <br />
                  <tr className="bg-f7f7f7">
                    <td>{data.agentName}</td>
                    <td>{data.dateRange}</td>
                    <td>{data.numberOfDisbursedLoans}</td>
                    <td>{data.totalPayoutAmount}</td>
                    <td>
                      <button
                        className="btn-success btn btn-sm rounded-0 px-2"
                        onClick={() => toggleAccordion(index)}
                      >
                        {accordion === index ? (
                          <>
                            {' '}
                            <i className="fa-solid fa-minus"></i>
                          </>
                        ) : (
                          <>
                            {' '}
                            <i className="fa-solid fa-plus"></i>
                          </>
                        )}
                      </button>
                    </td>
                  </tr>
                  <tr
                    className={
                      accordion === index
                        ? 'active-accordion'
                        : 'inactive-accordion'
                    }
                  >
                    <td colspan="6">
                      <div>
                        <table className="table table-responsive-xl table-borderless">
                          <thead>
                            <tr>
                              <th scope="col">Customer Name</th>
                              <th scope="col">Date</th>
                              <th scope="col">Loan Amount</th>
                              <th scope="col">Bank Name</th>
                              <th scope="col">Loan Type</th>
                              <th scope="col">Payout Amount</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data.payoutCustomerList.map((record, i) => {
                              return (
                                <React.Fragment key={i}>
                                  <tr>
                                    <td>{record.customerName}</td>
                                    <td>
                                      {moment(record.payoutDate).format(
                                        'DD-MM-YYYY'
                                      )}
                                    </td>
                                    <td>{record.loanAmount}</td>
                                    <td>{record.loanProviderBankName}</td>
                                    <td>{record.loanType}</td>
                                    <td>{record.payoutAmount}</td>
                                  </tr>
                                </React.Fragment>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </td>
                  </tr>
                </React.Fragment>
              );
            })
          ) : (
            <>
              {searchError && (
                <>
                  <tr>
                    {' '}
                    <td colSpan="100">
                      <p className="text-center  m-0 text-danger">
                        No Result Found
                      </p>
                    </td>
                  </tr>
                </>
              )}
            </>
          )}
        </tbody>
      </table>
    );
  };
  return (
    <>
      <Layout>
        <div className="inner_banner text-center">
          <div className="container">Agent Payout View</div>
        </div>
        <div className="container-fluid">
          <ToastContainer />

          <form onSubmit={searchSubmit} className="mt-4">
            <div className="row justify-content-center">
              <div className="col-lg-1">
                <label for="inputEmail4">Agent Id</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Id"
                  onChange={searchHandleChange('agentId')}
                  value={agentId}
                />
              </div>
              <div className="col-lg">
                <label for="inputEmail4">Start Date</label>
                <input
                  type="date"
                  className="form-control"
                  onChange={searchHandleChange('startDate')}
                  value={startDate}
                />
              </div>
              <div className="col-lg">
                <label for="inputEmail4">End Date</label>
                <input
                  type="date"
                  className="form-control"
                  onChange={searchHandleChange('endDate')}
                  value={endDate}
                />
              </div>
              <div className="col-lg">
                <label for="inputEmail4">Broker Name</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Broker Name"
                  onChange={searchHandleChange('brokerName')}
                  value={brokerName}
                />
              </div>
              <div className="col-lg">
                <label for="inputEmail4">Broker Code </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Broker Code"
                  onChange={searchHandleChange('brokerCode')}
                  value={brokerCode}
                />
              </div>
              <div class="col-lg">
                <label for="inputEmail4"> Loan Type</label>
                <select
                  className="form-control text-capitalize status-dropd"
                  onChange={searchHandleChange('selectloanTypes')}
                >
                  <option value="" selected>
                    {' '}
                    Select Loan Type
                  </option>
                  {loanTypes.map((data, i) => (
                    <option key={i} value={data.description}>
                      {data.description}
                    </option>
                  ))}
                </select>
              </div>{' '}
              <div className="col-lg">
                {' '}
                <label for="inputEmail4"> Lead Network Level</label>
                <select
                  className="form-control text-capitalize status-dropd"
                  onChange={searchHandleChange('networkLevel')}
                >
                  <option value="" selected>
                    {' '}
                    Select Lead Network Level
                  </option>
                  <option value="Self">Self</option>
                  <option value="2">Level 2</option>
                  <option value="3">Level 3</option>
                </select>
              </div>
              <div className="col-lg">
                {' '}
                <label for="inputEmail4"> Bank Name</label>
                <select
                  className="form-control text-capitalize status-dropd"
                  onChange={searchHandleChange('selectBank')}
                >
                  <option value="" selected>
                    {' '}
                    Select Bank Name
                  </option>
                  {BankList.map((bank, i) => (
                    <option key={i} value={bank.bankId}>
                      {bank.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-lg pt-sm-2">
                <button type="submit" className="btn btn-primary mt-4">
                  Search
                </button>
              </div>
            </div>
          </form>
        </div>
        <br />
        <hr />
        {searchLoanLead.length > 0 || searchError === true ? (
          <>
            <button
              className="btn btn-light mb-4"
              onClick={() =>
                setSearchV({
                  ...searchV,
                  searchLoanLead: [],
                  agentId: '',
                  searchError: false,
                  startDate: '',
                  endDate: '',
                })
              }
            >
              <i class="fa-solid fa-arrow-left"></i> Go Back
            </button>
          </>
        ) : (
          <></>
        )}
        {searchLoanLead.length > 0 || searchError === true
          ? tableData(searchLoanLead)
          : tableData(loan)}{' '}
        <br />
      </Layout>
    </>
  );
};

export default ViewPayoutAgent;

import { React, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const CustomerDocumentsDetails = ({
  loanDetailsData,
  loanDetails,
  loanDocumentList,
  ChangeStatusValue,
}) => {
  const MySwal = withReactContent(Swal);

  const [supportdoc, setSupportdoc] = useState({
    supportfile: null,
    supportdocumentCategory: null,
    supportselectedDocumentType: null,
    supportformData: new FormData(),
    supportdocumentsAvailable: null,
    supporteditdocument: false,
    supportfileSize: 0,
  });

  const [supportfileviewbankapp, setSupportfileviewbankapp] = useState({
    bankapprovedsupportDoc: null,
    editbankapprovedsupportDoc: false,
  });

  const { bankapprovedsupportDoc, editbankapprovedsupportDoc } =
    supportfileviewbankapp;

  const [supportfileviewbankrej, setSupportfileviewbankrej] = useState({
    bankrejectedsupportDoc: null,
    editbankrejectedsupportDoc: false,
  });

  const { bankrejectedsupportDoc, editbankrejectedsupportDoc } =
    supportfileviewbankrej;

  const [supportfileviewagreesign, setSupportfileviewagreesign] = useState({
    agreementsignedsupportDoc: null,
    editagreementsignedsupportDoc: false,
  });

  const { agreementsignedsupportDoc, editagreementsignedsupportDoc } =
    supportfileviewagreesign;

  const [supportfileviewdisburs, setSupportfileviewdisburs] = useState({
    disbursedsupportDoc: null,
    editdisbursedsupportDoc: false,
  });

  const { disbursedsupportDoc, editdisbursedsupportDoc } =
    supportfileviewdisburs;

  const [documents, setDocuments] = useState({
    file: null,
    documentCategory: null,
    selectedDocumentType: null,
    formData: new FormData(),
    documentsAvailable: null,
    editdocument: false,
    documentsfileSize: 0,
  });

  const [additionalIdenitydoc, setAdditionalIdenitydoc] = useState({
    addIdenityfile: null,
    documentIdentityCategory: null,
    selectedAdditionalDocumentType: null,
    additionalIdenformData: new FormData(),
    additionalIdendocumentsAvailable: null,
    additionalIdeneditdocument: false,
    additionalIdendocumentsfileSize: 0,
  });

  const [documentsresidence, setDocumentsresidence] = useState({
    residenceformData: new FormData(),
    residenceProof: null,
    residenceProofType: null,
    residenceProofFile: null,
    documentsAvailableResidence: null,
    editResidencedocument: false,
    residentfileSize: 0,
  });

  const [additionaldocumentsresidence, setAdditionalDocumentsresidence] =
    useState({
      additionalresidenceformData: new FormData(),
      additionalresidenceProof: null,
      additionalresidenceProofType: null,
      additionalresidenceProofFile: null,
      additionaldocumentsAvailableResidence: null,
      additionaleditResidencedocument: false,
      additionalresidentfileSize: 0,
    });

  const [documentsincome, setDocumentsIncome] = useState({
    incomeProof: null,
    incomeProofType: null,
    incomeProofFile: null,
    incomeformData: new FormData(),
    documentsAvailableIncome: null,
    editIncomedocument: false,
    incomefileSize: 0,
  });

  const [documentsbankstatement, setDocumentsBankStatement] = useState({
    bankStatement: null,
    bankStatementType: null,
    bankStatementFile: null,
    bankstatementformData: new FormData(),
    documentsAvailableBankStatement: null,
    editBankStatementdocument: false,
    bankStatementfileSize: 0,
  });

  const [documentssoa, setDocumentsSoa] = useState({
    sOAOfPastLoans: null,
    sOAOfPastLoansType: null,
    sOAOfPastLoansFile: null,
    soaformData: new FormData(),
    documentsAvailableSOA: null,
    editSOAdocument: false,
    sOAOfPastfileSize: 0,
  });

  const [documentsmobilebill, setDocumentsMobileBill] = useState({
    monthsMobileBill: null,
    monthsMobileBillType: null,
    monthsMobileBillFile: null,
    mobilebillformData: new FormData(),
    documentsAvailableMobileBill: null,
    editMobileBilldocument: false,
    mobilebillfileSize: 0,
  });

  const [documentscompleteApp, setDocumentsCompleteApp] = useState({
    completedApplicationForm: null,
    completedApplicationFormType: null,
    completedApplicationFormFile: null,
    completeappformData: new FormData(),
    documentsAvailableCompleteApp: null,
    editCompleteAppdocument: false,
    completedApplicationfileSize: 0,
  });

  const {
    addIdenityfile,
    documentIdentityCategory,
    selectedAdditionalDocumentType,
    additionalIdenformData,
    additionalIdendocumentsAvailable,
    additionalIdeneditdocument,
    additionalIdendocumentsfileSize,
  } = additionalIdenitydoc;

  const {
    file,
    documentCategory,
    selectedDocumentType,
    formData,
    documentsAvailable,
    editdocument,
    documentsfileSize,
  } = documents;
  const {
    residenceProofFile,
    residenceProof,
    residenceProofType,
    residenceformData,
    documentsAvailableResidence,
    editResidencedocument,
    residentfileSize,
  } = documentsresidence;

  const {
    additionalresidenceformData,
    additionalresidenceProof,
    additionalresidenceProofType,
    additionalresidenceProofFile,
    additionaldocumentsAvailableResidence,
    additionaleditResidencedocument,
    additionalresidentfileSize,
  } = additionaldocumentsresidence;

  const {
    incomeProofFile,
    incomeProof,
    incomeProofType,
    incomeformData,
    documentsAvailableIncome,
    editIncomedocument,
    incomefileSize,
  } = documentsincome;

  const {
    bankStatement,
    bankStatementType,
    bankStatementFile,
    bankstatementformData,
    documentsAvailableBankStatement,
    editBankStatementdocument,
    bankStatementfileSize,
  } = documentsbankstatement;

  const {
    sOAOfPastLoans,
    sOAOfPastLoansType,
    sOAOfPastLoansFile,
    soaformData,
    documentsAvailableSOA,
    editSOAdocument,
    sOAOfPastfileSize,
  } = documentssoa;

  const {
    monthsMobileBill,
    monthsMobileBillType,
    monthsMobileBillFile,
    mobilebillformData,
    documentsAvailableMobileBill,
    editMobileBilldocument,
    mobilebillfileSize,
  } = documentsmobilebill;

  const {
    completedApplicationForm,
    completedApplicationFormType,
    completedApplicationFormFile,
    completeappformData,
    documentsAvailableCompleteApp,
    editCompleteAppdocument,
    completedApplicationfileSize,
  } = documentscompleteApp;

  const {
    supportfile,
    supportdocumentCategory,
    supportselectedDocumentType,
    supportformData,
    supportdocumentsAvailable,
    supporteditdocument,
    supportfileSize,
  } = supportdoc;

  const jwt = JSON.parse(localStorage.getItem('jwt'));
  const onChangedoc = (name) => (e) => {
    const value = name === 'file' ? e.target.files[0] : e.target.value;

    formData.set(name, value);
    const documentsfileSize = name === 'file' ? e.target.files[0].size : 0;
    setDocuments({
      ...documents,
      [name]: value,
      documentCategory: 'Identity Proof',
      documentsfileSize,
    });
  };

  const onChangeAdditionalIdentitydoc = (name) => (e) => {
    const value =
      name === 'addIdenityfile' ? e.target.files[0] : e.target.value;

    const additionalIdendocumentsfileSize =
      name === 'addIdenityfile' ? e.target.files[0].size : 0;

    if (name === 'addIdenityfile') {
      additionalIdenformData.set('file', value);
    }

    setAdditionalIdenitydoc({
      ...additionalIdenitydoc,
      [name]: value,
      documentIdentityCategory: 'Additional Identity Proof',
      additionalIdendocumentsfileSize,
    });
  };

  const onChangedocresidence = (name) => (e) => {
    const value =
      name === 'residenceProofFile' ? e.target.files[0] : e.target.value;

    const residentfileSize =
      name === 'residenceProofFile' ? e.target.files[0].size : 0;

    if (name === 'residenceProofFile') {
      residenceformData.set('file', value);
    }

    setDocumentsresidence({
      ...documentsresidence,
      [name]: value,
      residenceProof: 'Residence Proof',
      residentfileSize,
    });
  };

  const onChangeAdditionaldocresidence = (name) => (e) => {
    const value =
      name === 'additionalresidenceProofFile'
        ? e.target.files[0]
        : e.target.value;

    const additionalresidentfileSize =
      name === 'additionalresidenceProofFile' ? e.target.files[0].size : 0;

    if (name === 'additionalresidenceProofFile') {
      additionalresidenceformData.set('file', value);
    }

    setAdditionalDocumentsresidence({
      ...additionaldocumentsresidence,
      [name]: value,
      additionalresidenceProof: 'Additional Residential Proof',
      additionalresidentfileSize,
    });
  };

  const onChangeSupportdoc = (e) => {
    const value = e.target.files[0];
    supportformData.set('file', value);

    setSupportdoc({
      ...supportdoc,
      supportfile: supportformData,
      supportselectedDocumentType: 'Support Document',
      supportdocumentCategory: ChangeStatusValue,
    });
  };

  const onChangedocincome = (name) => (e) => {
    const value =
      name === 'incomeProofFile' ? e.target.files[0] : e.target.value;

    const incomefileSize =
      name === 'incomeProofFile' ? e.target.files[0].size : 0;

    if (name === 'incomeProofFile') {
      incomeformData.set('file', value);
    }

    setDocumentsIncome({
      ...documentsincome,
      [name]: value,
      incomeProof: 'Income Proof',
      incomefileSize,
    });
  };

  const onChangedocbankstatement = (name) => (e) => {
    const value =
      name === 'bankStatementFile' ? e.target.files[0] : e.target.value;

    const bankStatementfileSize =
      name === 'bankStatementFile' ? e.target.files[0].size : 0;

    if (name === 'bankStatementFile') {
      bankstatementformData.set('file', value);
    }

    setDocumentsBankStatement({
      ...documentsbankstatement,
      [name]: value,
      bankStatementType: 'Bank Statement',
      bankStatement: 'Bank Statement',
      bankStatementfileSize,
    });
  };

  const onChangedocsoa = (name) => (e) => {
    const value =
      name === 'sOAOfPastLoansFile' ? e.target.files[0] : e.target.value;

    const sOAOfPastfileSize =
      name === 'sOAOfPastLoansFile' ? e.target.files[0].size : 0;

    if (name === 'sOAOfPastLoansFile') {
      soaformData.set('file', value);
    }

    setDocumentsSoa({
      ...documentssoa,
      [name]: value,
      sOAOfPastLoans: 'SOA of past loans',
      sOAOfPastLoansType: 'SOA of past loans',
      sOAOfPastfileSize,
    });
  };

  const onChangedocmobilebill = (name) => (e) => {
    const value =
      name === 'monthsMobileBillFile' ? e.target.files[0] : e.target.value;

    const mobilebillfileSize =
      name === 'monthsMobileBillFile' ? e.target.files[0].size : 0;

    if (name === 'monthsMobileBillFile') {
      mobilebillformData.set('file', value);
    }

    setDocumentsMobileBill({
      ...documentsmobilebill,
      [name]: value,
      monthsMobileBill: '3months Mobile Bill',
      monthsMobileBillType: '3months Mobile Bill',
      mobilebillfileSize,
    });
  };

  const onChangedoccompleteApp = (name) => (e) => {
    const value =
      name === 'completedApplicationFormFile'
        ? e.target.files[0]
        : e.target.value;

    const completedApplicationfileSize =
      name === 'completedApplicationFormFile' ? e.target.files[0].size : 0;

    if (name === 'completedApplicationFormFile') {
      completeappformData.set('file', value);
    }

    setDocumentsCompleteApp({
      ...documentscompleteApp,
      [name]: value,
      completedApplicationForm: 'Completed Application Form',
      completedApplicationFormType: 'Completed Application Form',
      completedApplicationfileSize,
    });
  };

  const submitdoc = (e) => {
    e.preventDefault();

    if (documentsfileSize > 10000000) {
      MySwal.fire({
        text: 'Maximum upload file size is 10MB',
      });
      return false;
    } else {
      const fetchdata = async () => {
        try {
          var url =
            loanDetails.processingBankId === undefined
              ? `${process.env.REACT_APP_API_URL}/uploadCommonDocument/${loanDetails.loanLeadId}?documentCategory=${documentCategory}`
              : `${process.env.REACT_APP_API_URL}/uploadBankSpecificDocument/${loanDetails.loanLeadId}/${loanDetails.processingBankId}?documentCategory=${documentCategory}`;

          await fetch(url, {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              Authorization: `Bearer ${jwt}`,
            },
            body: formData,
          })
            .then((res) => {
              return res.json();
            })
            .then((data) => {
              MySwal.fire({
                text: 'Document Saved Successfully...',
              });
            });
        } catch (error) {
          MySwal.fire({
            text: 'Something went wrong',
          });
        }
      };
      if (
        documentCategory === null ||
        selectedDocumentType === null ||
        file === null
      ) {
        MySwal.fire({
          text: 'Something went wrong',
        });
      } else {
        fetchdata();
      }
    }
  };

  const submitAdditonalIdentitydoc = (e) => {
    e.preventDefault();

    if (additionalIdendocumentsfileSize > 10000000) {
      MySwal.fire({
        text: 'Maximum upload file size is 10MB',
      });
      return false;
    } else {
      const fetchdata = async () => {
        try {
          var url =
            loanDetails.processingBankId === undefined
              ? `${process.env.REACT_APP_API_URL}/uploadCommonDocument/${loanDetails.loanLeadId}?documentCategory=${documentIdentityCategory}&selectedDocumentType=${selectedAdditionalDocumentType}`
              : `${process.env.REACT_APP_API_URL}/uploadBankSpecificDocument/${loanDetails.loanLeadId}/${loanDetails.processingBankId}?documentCategory=${documentIdentityCategory}&selectedDocumentType=${selectedAdditionalDocumentType}`;

          await fetch(url, {
            method: 'POST',
            headers: {
              Accept: 'application/json',
            },
            body: additionalIdenformData,
            Authorization: `Bearer ${jwt}`,
          })
            .then((res) => {
              return res.json();
            })
            .then((data) => {
              MySwal.fire({
                text: 'Document Saved Successfully...',
              });
            });
        } catch (error) {
          MySwal.fire({
            text: 'Something went wrong',
          });
        }
      };
      if (
        documentIdentityCategory === null ||
        selectedAdditionalDocumentType === null ||
        addIdenityfile === null
      ) {
        MySwal.fire({
          text: 'Something went wrong',
        });
      } else {
        fetchdata();
      }
    }
  };

  const submitdocresidence = (e) => {
    e.preventDefault();

    if (residentfileSize > 10000000) {
      MySwal.fire({
        text: 'Maximum upload file size is 10MB',
      });
      return false;
    } else {
      const fetchdata = async () => {
        var url =
          loanDetails.processingBankId === undefined
            ? `${process.env.REACT_APP_API_URL}/uploadCommonDocument/${loanDetails.loanLeadId}?documentCategory=${residenceProof}&selectedDocumentType=${residenceProofType}`
            : `${process.env.REACT_APP_API_URL}/uploadBankSpecificDocument/${loanDetails.loanLeadId}/${loanDetails.processingBankId}?documentCategory=${residenceProof}&selectedDocumentType=${residenceProofType}`;

        await fetch(url, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${jwt}`,
          },
          body: residenceformData,
        })
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            MySwal.fire({
              text: 'Document Saved Successfully...',
            });
          });
      };
      if (
        residenceProof === null ||
        residenceProofType === null ||
        residenceProofFile === null
      ) {
        MySwal.fire({
          text: 'Something went wrong',
        });
      } else {
        fetchdata();
      }
    }
  };

  const submitadditionaldocresidence = (e) => {
    e.preventDefault();

    if (additionalresidentfileSize > 10000000) {
      MySwal.fire({
        text: 'Maximum upload file size is 10MB',
      });
      return false;
    } else {
      const fetchdata = async () => {
        var url =
          loanDetails.processingBankId === undefined
            ? `${process.env.REACT_APP_API_URL}/uploadCommonDocument/${loanDetails.loanLeadId}?documentCategory=${additionalresidenceProof}&selectedDocumentType=${additionalresidenceProofType}`
            : `${process.env.REACT_APP_API_URL}/uploadBankSpecificDocument/${loanDetails.loanLeadId}/${loanDetails.processingBankId}?documentCategory=${additionalresidenceProof}&selectedDocumentType=${additionalresidenceProofType}`;

        await fetch(url, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${jwt}`,
          },
          body: additionalresidenceformData,
        })
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            MySwal.fire({
              text: 'Document Saved Successfully...',
            });
          });
      };
      if (
        additionalresidenceProof === null ||
        additionalresidenceProofType === null ||
        additionalresidenceProofFile === null
      ) {
        MySwal.fire({
          text: 'Something went wrong',
        });
      } else {
        fetchdata();
      }
    }
  };

  const submitdocincome = (e) => {
    e.preventDefault();

    if (incomefileSize > 10000000) {
      MySwal.fire({
        text: 'Maximum upload file size is 10MB',
      });
      return false;
    } else {
      const fetchdata = async () => {
        var url =
          loanDetails.processingBankId === undefined
            ? `${process.env.REACT_APP_API_URL}/uploadCommonDocument/${loanDetails.loanLeadId}?documentCategory=${incomeProof}&selectedDocumentType=${incomeProofType}`
            : `${process.env.REACT_APP_API_URL}/uploadBankSpecificDocument/${loanDetails.loanLeadId}/${loanDetails.processingBankId}?documentCategory=${incomeProof}&selectedDocumentType=${incomeProofType}`;

        await fetch(url, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${jwt}`,
          },
          body: incomeformData,
        })
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            MySwal.fire({
              text: 'Document Saved Successfully...',
            });
          });
      };
      if (
        incomeProof === null ||
        incomeProofType === null ||
        incomeProofFile === null
      ) {
        MySwal.fire({
          text: 'Something went wrong',
        });
      } else {
        fetchdata();
      }
    }
  };

  const submitdocbankStatement = (e) => {
    e.preventDefault();

    if (bankStatementfileSize > 10000000) {
      MySwal.fire({
        text: 'Maximum upload file size is 10MB',
      });
      return false;
    } else {
      const fetchdata = async () => {
        var url =
          loanDetails.processingBankId === undefined
            ? `${process.env.REACT_APP_API_URL}/uploadCommonDocument/${loanDetails.loanLeadId}?documentCategory=${bankStatement}&selectedDocumentType=${bankStatementType}`
            : `${process.env.REACT_APP_API_URL}/uploadBankSpecificDocument/${loanDetails.loanLeadId}/${loanDetails.processingBankId}?documentCategory=${bankStatement}&selectedDocumentType=${bankStatementType}`;

        await fetch(url, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${jwt}`,
          },
          body: bankstatementformData,
        })
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            MySwal.fire({
              text: 'Document Saved Successfully...',
            });
          });
      };
      if (
        bankStatement === null ||
        bankStatementType === null ||
        bankStatementFile === null
      ) {
        MySwal.fire({
          text: 'Something went wrong',
        });
      } else {
        fetchdata();
      }
    }
  };

  const submitdocsoapastloan = (e) => {
    e.preventDefault();

    if (sOAOfPastfileSize > 10000000) {
      MySwal.fire({
        text: 'Maximum upload file size is 10MB',
      });
      return false;
    } else {
      const fetchdata = async () => {
        var url =
          loanDetails.processingBankId === undefined
            ? `${process.env.REACT_APP_API_URL}/uploadCommonDocument/${loanDetails.loanLeadId}?documentCategory=${sOAOfPastLoans}&selectedDocumentType=${sOAOfPastLoansType}`
            : `${process.env.REACT_APP_API_URL}/uploadBankSpecificDocument/${loanDetails.loanLeadId}/${loanDetails.processingBankId}?documentCategory=${sOAOfPastLoans}&selectedDocumentType=${sOAOfPastLoansType}`;

        await fetch(url, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${jwt}`,
          },
          body: soaformData,
        })
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            MySwal.fire({
              text: 'Document Saved Successfully...',
            });
          });
      };
      if (
        sOAOfPastLoans === null ||
        sOAOfPastLoansType === null ||
        sOAOfPastLoansFile === null
      ) {
        MySwal.fire({
          text: 'Something went wrong',
        });
      } else {
        fetchdata();
      }
    }
  };

  const submitdocmobilebill = (e) => {
    e.preventDefault();

    if (mobilebillfileSize > 10000000) {
      MySwal.fire({
        text: 'Maximum upload file size is 10MB',
      });
      return false;
    } else {
      const fetchdata = async () => {
        var url =
          loanDetails.processingBankId === undefined
            ? `${process.env.REACT_APP_API_URL}/uploadCommonDocument/${loanDetails.loanLeadId}?documentCategory=${monthsMobileBill}&selectedDocumentType=${monthsMobileBillType}`
            : `${process.env.REACT_APP_API_URL}/uploadBankSpecificDocument/${loanDetails.loanLeadId}/${loanDetails.processingBankId}?documentCategory=${monthsMobileBill}&selectedDocumentType=${monthsMobileBillType}`;

        await fetch(url, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${jwt}`,
          },
          body: mobilebillformData,
        })
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            MySwal.fire({
              text: 'Document Saved Successfully...',
            });
          });
      };
      if (
        monthsMobileBill === null ||
        monthsMobileBillType === null ||
        monthsMobileBillFile === null
      ) {
        MySwal.fire({
          text: 'Something went wrong',
        });
      } else {
        fetchdata();
      }
    }
  };

  const submitdocCompletedapp = (e) => {
    e.preventDefault();

    if (completedApplicationfileSize > 10000000) {
      MySwal.fire({
        text: 'Maximum upload file size is 10MB',
      });
      return false;
    } else {
      const fetchdata = async () => {
        var url =
          loanDetails.processingBankId === undefined
            ? `${process.env.REACT_APP_API_URL}/uploadCommonDocument/${loanDetails.loanLeadId}?documentCategory=${completedApplicationForm}&selectedDocumentType=${completedApplicationFormType}`
            : `${process.env.REACT_APP_API_URL}/uploadBankSpecificDocument/${loanDetails.loanLeadId}/${loanDetails.processingBankId}?documentCategory=${completedApplicationForm}&selectedDocumentType=${completedApplicationFormType}`;

        await fetch(url, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${jwt}`,
          },
          body: completeappformData,
        })
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            MySwal.fire({
              text: 'Document Saved Successfully...',
            });
          });
      };
      if (
        completedApplicationForm === null ||
        completedApplicationFormType === null ||
        completedApplicationFormFile === null
      ) {
        MySwal.fire({
          text: 'Something went wrong',
        });
      } else {
        fetchdata();
      }
    }
  };

  useEffect(() => {
    let bankbankapprovedsupportDoc = loanDocumentList.filter((item) => {
      return item.documentCategory === 'Bank Approved';
    });
    setSupportfileviewbankapp({
      ...supportfileviewbankapp,
      bankapprovedsupportDoc: bankbankapprovedsupportDoc[0],
    });

    let bankbankrejectedsupportDoc = loanDocumentList.filter((item) => {
      return item.documentCategory === 'Bank Rejected';
    });

    setSupportfileviewbankrej({
      ...supportfileviewbankrej,
      bankrejectedsupportDoc: bankbankrejectedsupportDoc[0],
    });

    let disdisbursedsupportDoc = loanDocumentList.filter((item) => {
      return item.documentCategory === 'Disbursed';
    });

    setSupportfileviewdisburs({
      ...supportfileviewdisburs,
      disbursedsupportDoc: disdisbursedsupportDoc[0],
    });

    let agreeagreementsignedsupportDoc = loanDocumentList.filter((item) => {
      return item.documentCategory === 'Agreement Signed';
    });

    setSupportfileviewagreesign({
      ...supportfileviewagreesign,
      agreementsignedsupportDoc: agreeagreementsignedsupportDoc[0],
    });

    let documentsData = loanDocumentList.filter((item) => {
      return item.documentCategory === 'Identity Proof';
    });
    setDocuments({
      ...documents,
      documentsAvailable: documentsData[0],
    });

    let documentsAdditionalIdentityData = loanDocumentList.filter((item) => {
      return item.documentCategory === 'Additional Identity Proof';
    });
    setAdditionalIdenitydoc({
      ...additionalIdenitydoc,
      additionalIdendocumentsAvailable: documentsAdditionalIdentityData[0],
    });

    let documentsResidenceData = loanDocumentList.filter((item) => {
      return item.documentCategory === 'Residence Proof';
    });
    setDocumentsresidence({
      ...documentsresidence,
      documentsAvailableResidence: documentsResidenceData[0],
    });

    let additionaldocumentsResidenceData = loanDocumentList.filter((item) => {
      return item.documentCategory === 'Additional Residential Proof';
    });
    setAdditionalDocumentsresidence({
      ...additionaldocumentsresidence,
      additionaldocumentsAvailableResidence:
        additionaldocumentsResidenceData[0],
    });

    let documentsDocumentsIncome = loanDocumentList.filter((item) => {
      return item.documentCategory === 'Income Proof';
    });
    setDocumentsIncome({
      ...documentsincome,
      documentsAvailableIncome: documentsDocumentsIncome[0],
    });

    let documentsDocumentsBankstatement = loanDocumentList.filter((item) => {
      return item.documentCategory === 'Bank Statement';
    });
    setDocumentsBankStatement({
      ...documentsbankstatement,
      documentsAvailableBankStatement: documentsDocumentsBankstatement[0],
    });

    let documentsDocumentsSoa = loanDocumentList.filter((item) => {
      return item.documentCategory === 'SOA of past loans';
    });
    setDocumentsSoa({
      ...documentssoa,
      documentsAvailableSOA: documentsDocumentsSoa[0],
    });

    let documentsDocumentsMobilebill = loanDocumentList.filter((item) => {
      return item.documentCategory === '3months Mobile Bill';
    });
    setDocumentsMobileBill({
      ...documentsmobilebill,
      documentsAvailableMobileBill: documentsDocumentsMobilebill[0],
    });

    let documentsDocumentsCompleteApp = loanDocumentList.filter((item) => {
      return item.documentCategory === 'Completed Application Form';
    });
    setDocumentsCompleteApp({
      ...documentscompleteApp,
      documentsAvailableCompleteApp: documentsDocumentsCompleteApp[0],
    });
  }, [loanDetails, loanDocumentList]);

  const closeModalDoc = () => {
    const mandatoryFields = loanDetailsData.madatoryFields['Loan Document'].map(
      (field) => {
        return field;
      }
    );

    if (mandatoryFields.length > 0) {
      return alert(`${mandatoryFields} is mandatory field`);
    }
  };

  const supportDoc = (e) => {
    e.preventDefault();

    const fetchdata = async () => {
      try {
        await fetch(
          `${process.env.REACT_APP_API_URL}/uploadBankSpecificDocument/${loanDetails.loanLeadId}/${loanDetails.processingBankId}?documentCategory=${supportdocumentCategory}&selectedDocumentType=${supportselectedDocumentType}`,
          {
            method: 'POST',
            headers: {
              Accept: 'application/json',
            },
            body: supportfile,
          }
        )
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            MySwal.fire({
              text: 'Document Saved Successfully...',
            });
          });
      } catch (error) {
        MySwal.fire({
          text: 'Something went wrong',
        });
      }
    };
    if (
      supportdocumentCategory === null ||
      supportselectedDocumentType === null ||
      supportfile === null
    ) {
      MySwal.fire({
        text: 'Something went wrong',
      });
    } else {
      fetchdata();
    }
  };

  const supportDocComp = () => {
    return (
      <form onSubmit={supportDoc}>
        <div class="form-group">
          <label for="supportDoc">Support Document Details</label>
          <input
            type="file"
            class="form-control-file"
            id="supportDoc"
            onChange={onChangeSupportdoc}
            accept="image/jpeg,image/png,application/pdf,image/x-eps"
          />
          <button className="btn btn-success mt-4">Save</button>
        </div>
      </form>
    );
  };

  return (
    <>
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content ">
          <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropLabel">
              Customer Documents Details
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              //    onClick={ChangeStatusValue === 'Assigned' ? closeModalDoc : ''}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div className="container-fluid text-left ">
              <ToastContainer />

              {ChangeStatusValue === 'Bank Approved' ||
              ChangeStatusValue === 'Bank Rejected' ||
              ChangeStatusValue === 'Agreement Signed' ||
              ChangeStatusValue === 'Disbursed' ? (
                <>
                  {/* Agreementsigned */}
                  {agreementsignedsupportDoc &&
                  editagreementsignedsupportDoc === false
                    ? ChangeStatusValue === 'Agreement Signed' && (
                        <>
                          {' '}
                          <a
                            href={`${process.env.REACT_APP_API_URL}/downloadDocumentByFileName/${agreementsignedsupportDoc.fileName}`}
                            className="btn btn-primary"
                            download
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            View
                          </a>
                          <button
                            type="button"
                            className="btn btn-success mr-1 ml-2"
                            onClick={() =>
                              setSupportfileviewagreesign({
                                ...supportfileviewagreesign,
                                editagreementsignedsupportDoc: true,
                              })
                            }
                          >
                            Edit{' '}
                          </button>
                        </>
                      )
                    : ChangeStatusValue === 'Agreement Signed' && (
                        <>{supportDocComp()}</>
                      )}

                  {/* bank approved */}
                  {bankapprovedsupportDoc &&
                  editbankapprovedsupportDoc === false
                    ? ChangeStatusValue === 'Bank Approved' && (
                        <>
                          {' '}
                          <a
                            href={`${process.env.REACT_APP_API_URL}/downloadDocumentByFileName/${bankapprovedsupportDoc.fileName}`}
                            className="btn btn-primary"
                            download
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            View
                          </a>
                          <button
                            type="button"
                            className="btn btn-success mr-1 ml-2"
                            onClick={() =>
                              setSupportfileviewbankapp({
                                ...supportfileviewbankapp,
                                editbankapprovedsupportDoc: true,
                              })
                            }
                          >
                            Edit{' '}
                          </button>
                        </>
                      )
                    : ChangeStatusValue === 'Bank Approved' && (
                        <> {supportDocComp()}</>
                      )}

                  {/* bank rejected */}
                  {bankrejectedsupportDoc &&
                  editbankrejectedsupportDoc === false
                    ? ChangeStatusValue === 'Bank Rejected' && (
                        <>
                          {' '}
                          <a
                            href={`${process.env.REACT_APP_API_URL}/downloadDocumentByFileName/${bankrejectedsupportDoc.fileName}`}
                            className="btn btn-primary"
                            download
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            View
                          </a>
                          <button
                            type="button"
                            className="btn btn-success mr-1 ml-2"
                            onClick={() =>
                              setSupportfileviewbankrej({
                                ...supportfileviewbankrej,
                                editbankrejectedsupportDoc: true,
                              })
                            }
                          >
                            Edit{' '}
                          </button>
                        </>
                      )
                    : ChangeStatusValue === 'Bank Rejected' && (
                        <>{supportDocComp()}</>
                      )}

                  {/* Disbursed */}

                  {disbursedsupportDoc && editdisbursedsupportDoc === false
                    ? ChangeStatusValue === 'Disbursed' && (
                        <>
                          <a
                            href={`${process.env.REACT_APP_API_URL}/downloadDocumentByFileName/${disbursedsupportDoc.fileName}`}
                            className="btn btn-primary"
                            download
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            View{' '}
                          </a>
                          <button
                            type="button"
                            className="btn btn-success mr-1 ml-2"
                            onClick={() =>
                              setSupportfileviewdisburs({
                                ...supportfileviewdisburs,
                                editdisbursedsupportDoc: true,
                              })
                            }
                          >
                            Edit{' '}
                          </button>
                        </>
                      )
                    : ChangeStatusValue === 'Disbursed' && (
                        <>{supportDocComp()}</>
                      )}
                </>
              ) : (
                <>
                  <form>
                    {/* Identity Proof: */}
                    <div>
                      <div className="form-row">
                        <div className="form-group col-md-6">
                          <p className="text-primary mb-2">Identity Proof:</p>
                          <select
                            id="identityProof"
                            className="form-control"
                            onChange={onChangedoc('selectedDocumentType')}
                          >
                            {documentsAvailable && editdocument === false ? (
                              <option
                                value={documentsAvailable.selectedDocumentType}
                                className="d-none"
                                selected
                              >
                                {documentsAvailable.selectedDocumentType}
                              </option>
                            ) : (
                              <option value="" selected>
                                Select Document for Identity Proof
                              </option>
                            )}

                            <option value="aadharCard">AdharCard</option>
                            <option value="passport">Passport</option>
                            <option value="pancard">PAN Card</option>
                            <option value="voterIdCard">Voters ID Card</option>
                            <option value="drivingLicense">
                              Driving License
                            </option>
                          </select>
                        </div>
                        <div className="col-md">
                          <p className="text-primary mb-2">Upload Document: </p>

                          <div className="form-inline">
                            {documentsAvailable && editdocument === false ? (
                              <>
                                <>
                                  <small>
                                    {' '}
                                    <i class="fa-solid fa-file-circle-check file-icon-size text-success"></i>{' '}
                                    {documentsAvailable.fileName.substring(
                                      0,
                                      13
                                    )}
                                    ...
                                  </small>

                                  <a
                                    href={`${process.env.REACT_APP_API_URL}/downloadDocumentByFileName/${documentsAvailable.fileName}`}
                                    className="btn btn-primary mr-1 ml-2"
                                    download
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    View{' '}
                                  </a>
                                  <button
                                    type="button"
                                    className="btn btn-success mr-1 ml-2"
                                    onClick={() =>
                                      setDocuments({
                                        ...documents,
                                        editdocument: true,
                                      })
                                    }
                                  >
                                    Edit{' '}
                                  </button>
                                </>
                              </>
                            ) : (
                              <>
                                <input
                                  type="file"
                                  className="form-control pt-1 w-60 w-sm-100 mb-sm-10 mr-1"
                                  id="identityDoc"
                                  name="file"
                                  onChange={onChangedoc('file')}
                                />

                                <button
                                  className="btn btn-primary "
                                  onClick={submitdoc}
                                >
                                  Save
                                </button>

                                {documentsAvailable && (
                                  <button
                                    className="btn btn-none  ml-2"
                                    type="button"
                                    onClick={() =>
                                      setDocuments({
                                        ...documents,
                                        editdocument: false,
                                      })
                                    }
                                  >
                                    <i class="fa-solid fa-circle-arrow-right"></i>
                                  </button>
                                )}
                              </>
                            )}

                            {file !== null && (
                              <>
                                <object
                                  data={URL.createObjectURL(file)}
                                  type="application/pdf"
                                  width="150"
                                  height="100"
                                  className="mt-4 mb-4 image-fit"
                                ></object>
                              </>
                            )}
                          </div>
                        </div>
                        {documentCategory === null &&
                        documentsAvailable === null ? (
                          <p className="text-danger ">
                            Identity Proof Document is mandatory to process your
                            request
                          </p>
                        ) : (
                          <span></span>
                        )}
                      </div>
                    </div>

                    {/* Additional Identity Proof */}

                    <div>
                      <div className="form-row">
                        <div className="form-group col-md-6">
                          <p className="text-primary mb-2">
                            Additional Identity Proof:
                          </p>
                          <select
                            id="identityProof"
                            className="form-control"
                            onChange={onChangeAdditionalIdentitydoc(
                              'selectedAdditionalDocumentType'
                            )}
                          >
                            {additionalIdendocumentsAvailable &&
                            additionalIdeneditdocument === false ? (
                              <option
                                value={
                                  additionalIdendocumentsAvailable.selectedDocumentType
                                }
                                className="d-none"
                                selected
                              >
                                {
                                  additionalIdendocumentsAvailable.selectedDocumentType
                                }
                              </option>
                            ) : (
                              <option value="" selected>
                                Select Document for Aditional Identity Proof
                              </option>
                            )}

                            <option value="aadharCard">AdharCard</option>
                            <option value="passport">Passport</option>
                            <option value="pancard">PAN Card</option>
                            <option value="voterIdCard">Voters ID Card</option>
                            <option value="drivingLicense">
                              Driving License
                            </option>
                          </select>
                        </div>
                        <div className="col-md">
                          <p className="text-primary mb-2">Upload Document: </p>

                          <div className="form-inline">
                            {additionalIdendocumentsAvailable &&
                            additionalIdeneditdocument === false ? (
                              <>
                                <>
                                  <small>
                                    {' '}
                                    <i class="fa-solid fa-file-circle-check file-icon-size text-success"></i>{' '}
                                    {additionalIdendocumentsAvailable.fileName.substring(
                                      0,
                                      13
                                    )}
                                    ...
                                  </small>

                                  <a
                                    href={`${process.env.REACT_APP_API_URL}/downloadDocumentByFileName/${additionalIdendocumentsAvailable.fileName}`}
                                    className="btn btn-primary mr-1 ml-2"
                                    download
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    View{' '}
                                  </a>
                                  <button
                                    type="button"
                                    className="btn btn-success mr-1 ml-2"
                                    onClick={() =>
                                      setAdditionalIdenitydoc({
                                        ...additionalIdenitydoc,
                                        additionalIdeneditdocument: true,
                                      })
                                    }
                                  >
                                    Edit{' '}
                                  </button>
                                </>
                              </>
                            ) : (
                              <>
                                <input
                                  type="file"
                                  className="form-control pt-1 w-60 w-sm-100 mb-sm-10 mr-1"
                                  id="identityDoc"
                                  onChange={onChangeAdditionalIdentitydoc(
                                    'addIdenityfile'
                                  )}
                                />

                                <button
                                  className="btn btn-primary "
                                  onClick={submitAdditonalIdentitydoc}
                                >
                                  Save
                                </button>

                                {additionalIdendocumentsAvailable && (
                                  <button
                                    className="btn btn-none  ml-2"
                                    type="button"
                                    onClick={() =>
                                      setAdditionalIdenitydoc({
                                        ...additionalIdenitydoc,
                                        additionalIdeneditdocument: false,
                                      })
                                    }
                                  >
                                    <i class="fa-solid fa-circle-arrow-right"></i>
                                  </button>
                                )}
                              </>
                            )}

                            {addIdenityfile !== null && (
                              <>
                                <object
                                  data={URL.createObjectURL(addIdenityfile)}
                                  type="application/pdf"
                                  width="150"
                                  height="100"
                                  className="mt-4 mb-4 image-fit"
                                ></object>
                              </>
                            )}
                          </div>
                        </div>
                        {/* {documentIdentityCategory === null &&
                        additionalIdendocumentsAvailable === null ? (
                          <p className="text-danger ">
                            Identity Proof Document is mandatory to process your
                            request
                          </p>
                        ) : (
                          <span></span>
                        )} */}
                      </div>
                    </div>

                    {/*Current Residence Proof: */}
                    <div>
                      <div className="form-row">
                        <div className="form-group col-md-6">
                          <p className="text-primary mb-2">
                            Current Residence Proof:
                          </p>
                          <select
                            id="identityProof"
                            onChange={onChangedocresidence(
                              'residenceProofType'
                            )}
                            className="form-control"
                          >
                            {documentsAvailableResidence &&
                            editResidencedocument === false ? (
                              <option
                                value={
                                  documentsAvailableResidence.selectedDocumentType
                                }
                                className="d-none"
                                selected
                              >
                                {
                                  documentsAvailableResidence.selectedDocumentType
                                }
                              </option>
                            ) : (
                              <option value="" selected>
                                Select Document for Residence Proof
                              </option>
                            )}

                            <option value="rationCard">Ration Card</option>
                            <option value="passport">
                              Passport-With Current Address
                            </option>
                            <option value="voterIdCard">Voters ID Card</option>
                            <option value="drivingLicense">
                              Driving License
                            </option>
                            <option value="utilityBill">
                              Latest Electricity/ Telephone Bill
                            </option>
                          </select>
                        </div>
                        <div className="col-md">
                          <p className="text-primary mb-2">Upload Document:</p>
                          <div className="form-inline">
                            {documentsAvailableResidence &&
                            editResidencedocument === false ? (
                              <>
                                <>
                                  <small>
                                    {' '}
                                    <i class="fa-solid fa-file-circle-check file-icon-size text-success"></i>{' '}
                                    {documentsAvailableResidence.fileName.substring(
                                      0,
                                      13
                                    )}
                                    ...
                                  </small>

                                  <a
                                    href={`${process.env.REACT_APP_API_URL}/downloadDocumentByFileName/${documentsAvailableResidence.fileName}`}
                                    className="btn btn-primary mr-1 ml-2"
                                    download
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    View{' '}
                                  </a>
                                  <button
                                    type="button"
                                    className="btn btn-success mr-1 ml-2"
                                    onClick={() =>
                                      setDocumentsresidence({
                                        ...documentsresidence,
                                        editResidencedocument: true,
                                      })
                                    }
                                  >
                                    Edit{' '}
                                  </button>
                                </>
                              </>
                            ) : (
                              <>
                                <input
                                  type="file"
                                  className="form-control pt-1  w-60 w-sm-100 mb-sm-10 mr-1"
                                  id="identityDoc"
                                  onChange={onChangedocresidence(
                                    'residenceProofFile'
                                  )}
                                />

                                <button
                                  className="btn btn-primary "
                                  onClick={submitdocresidence}
                                >
                                  Save
                                </button>
                                {documentsAvailableResidence && (
                                  <button
                                    className="btn btn-none  ml-2"
                                    type="button"
                                    onClick={() =>
                                      setDocumentsresidence({
                                        ...documentsresidence,
                                        editResidencedocument: false,
                                      })
                                    }
                                  >
                                    <i class="fa-solid fa-circle-arrow-right"></i>
                                  </button>
                                )}
                              </>
                            )}

                            {residenceProofFile !== null && (
                              <>
                                <object
                                  data={URL.createObjectURL(residenceProofFile)}
                                  type="application/pdf"
                                  width="150"
                                  height="100"
                                  className="mt-4 mb-4 image-fit"
                                ></object>
                              </>
                            )}
                          </div>
                        </div>
                        {residenceProof === null &&
                        documentsAvailableResidence === undefined ? (
                          <p className="text-danger">
                            Address Proof Document is mandatory to process your
                            request
                          </p>
                        ) : (
                          <span></span>
                        )}
                      </div>
                    </div>

                    {/*Additional Residential Proof: */}
                    <div>
                      <div className="form-row">
                        <div className="form-group col-md-6">
                          <p className="text-primary mb-2">
                            Additional Residential Proof:
                          </p>
                          <select
                            id="identityProof"
                            onChange={onChangeAdditionaldocresidence(
                              'additionalresidenceProofType'
                            )}
                            className="form-control"
                          >
                            {additionaldocumentsAvailableResidence &&
                            additionaleditResidencedocument === false ? (
                              <option
                                value={
                                  additionaldocumentsAvailableResidence.selectedDocumentType
                                }
                                className="d-none"
                                selected
                              >
                                {
                                  additionaldocumentsAvailableResidence.selectedDocumentType
                                }
                              </option>
                            ) : (
                              <option value="" selected>
                                Select Document for Residence Proof
                              </option>
                            )}

                            <option value="rationCard">Ration Card</option>
                            <option value="passport">
                              Passport-With Current Address
                            </option>
                            <option value="voterIdCard">Voters ID Card</option>
                            <option value="drivingLicense">
                              Driving License
                            </option>
                            <option value="utilityBill">
                              Latest Electricity/ Telephone Bill
                            </option>
                          </select>
                        </div>
                        <div className="col-md">
                          <p className="text-primary mb-2">Upload Document:</p>
                          <div className="form-inline">
                            {additionaldocumentsAvailableResidence &&
                            additionaleditResidencedocument === false ? (
                              <>
                                <>
                                  <small>
                                    {' '}
                                    <i class="fa-solid fa-file-circle-check file-icon-size text-success"></i>{' '}
                                    {additionaldocumentsAvailableResidence.fileName.substring(
                                      0,
                                      13
                                    )}
                                    ...
                                  </small>

                                  <a
                                    href={`${process.env.REACT_APP_API_URL}/downloadDocumentByFileName/${additionaldocumentsAvailableResidence.fileName}`}
                                    className="btn btn-primary mr-1 ml-2"
                                    download
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    View{' '}
                                  </a>
                                  <button
                                    type="button"
                                    className="btn btn-success mr-1 ml-2"
                                    onClick={() =>
                                      setAdditionalDocumentsresidence({
                                        ...additionaldocumentsresidence,
                                        additionaleditResidencedocument: true,
                                      })
                                    }
                                  >
                                    Edit{' '}
                                  </button>
                                </>
                              </>
                            ) : (
                              <>
                                <input
                                  type="file"
                                  className="form-control pt-1  w-60 w-sm-100 mb-sm-10 mr-1"
                                  id="identityDoc"
                                  onChange={onChangeAdditionaldocresidence(
                                    'additionalresidenceProofFile'
                                  )}
                                />

                                <button
                                  className="btn btn-primary "
                                  onClick={submitadditionaldocresidence}
                                >
                                  Save
                                </button>
                                {additionaldocumentsAvailableResidence && (
                                  <button
                                    className="btn btn-none  ml-2"
                                    type="button"
                                    onClick={() =>
                                      setAdditionalDocumentsresidence({
                                        ...additionaldocumentsresidence,
                                        additionaleditResidencedocument: false,
                                      })
                                    }
                                  >
                                    <i class="fa-solid fa-circle-arrow-right"></i>
                                  </button>
                                )}
                              </>
                            )}

                            {additionalresidenceProofFile !== null && (
                              <>
                                <object
                                  data={URL.createObjectURL(
                                    additionalresidenceProofFile
                                  )}
                                  type="application/pdf"
                                  width="150"
                                  height="100"
                                  className="mt-4 mb-4 image-fit"
                                ></object>
                              </>
                            )}
                          </div>
                        </div>
                        {/* {additionalresidenceProofFile === null &&
                        additionaldocumentsAvailableResidence === undefined ? (
                          <p className="text-danger">
                            Address Proof Document is mandatory to process your
                            request
                          </p>
                        ) : (
                          <span></span>
                        )} */}
                      </div>
                    </div>

                    {/* Income Proof: */}
                    <div>
                      <div className="form-row">
                        <div className="form-group col-md-6">
                          <p className="text-primary mb-2">Income Proof:</p>
                          <select
                            id="identityProof"
                            onChange={onChangedocincome('incomeProofType')}
                            className="form-control"
                          >
                            {documentsAvailableIncome &&
                            editIncomedocument === false ? (
                              <option
                                value={
                                  documentsAvailableIncome.selectedDocumentType
                                }
                                className="d-none"
                                selected
                              >
                                {documentsAvailableIncome.selectedDocumentType}
                              </option>
                            ) : (
                              <option value="" selected>
                                Select Document for Income Proof
                              </option>
                            )}
                            <option value="itr">ITR-Last Financial Year</option>
                            <option value="salarySlip">
                              Salary Slip / Certificate{' '}
                            </option>
                            <option value="bankStatements">
                              3 Months Bank Statement
                            </option>
                          </select>
                        </div>
                        <div className="col-md">
                          <p className="text-primary mb-2">Upload Document:</p>
                          <div className="form-inline">
                            {documentsAvailableIncome &&
                            editIncomedocument === false ? (
                              <>
                                <>
                                  <small>
                                    {' '}
                                    <i class="fa-solid fa-file-circle-check file-icon-size text-success"></i>{' '}
                                    {documentsAvailableIncome.fileName.substring(
                                      0,
                                      13
                                    )}
                                    ...
                                  </small>

                                  <a
                                    href={`${process.env.REACT_APP_API_URL}/downloadDocumentByFileName/${documentsAvailableIncome.fileName}`}
                                    className="btn btn-primary mr-1 ml-2"
                                    download
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    View{' '}
                                  </a>
                                  <button
                                    type="button"
                                    className="btn btn-success mr-1 ml-2"
                                    onClick={() =>
                                      setDocumentsIncome({
                                        ...documentsincome,
                                        editIncomedocument: true,
                                      })
                                    }
                                  >
                                    Edit{' '}
                                  </button>
                                </>
                              </>
                            ) : (
                              <>
                                <input
                                  type="file"
                                  className="form-control pt-1 w-60 w-sm-100 mb-sm-10 mr-1"
                                  id="identityDoc"
                                  onChange={onChangedocincome(
                                    'incomeProofFile'
                                  )}
                                />

                                <button
                                  className="btn btn-primary "
                                  onClick={submitdocincome}
                                >
                                  Save
                                </button>
                                {documentsAvailableIncome && (
                                  <button
                                    className="btn btn-none  ml-2"
                                    type="button"
                                    onClick={() =>
                                      setDocumentsIncome({
                                        ...documentsincome,
                                        editIncomedocument: false,
                                      })
                                    }
                                  >
                                    <i class="fa-solid fa-circle-arrow-right"></i>
                                  </button>
                                )}
                              </>
                            )}

                            {incomeProofFile !== null &&
                              documentsAvailableIncome === null && (
                                <>
                                  <object
                                    data={URL.createObjectURL(incomeProofFile)}
                                    type="application/pdf"
                                    width="150"
                                    height="100"
                                    className="mt-4 mb-4 image-fit"
                                  ></object>
                                </>
                              )}
                          </div>
                        </div>
                        {incomeProof === null &&
                        documentsAvailableIncome === undefined ? (
                          <p className="text-danger">
                            Income Proof Document is mandatory to process your
                            request
                          </p>
                        ) : (
                          <span></span>
                        )}
                      </div>
                    </div>

                    {/* Bank Statement: --- SOA Of Past Loans:*/}
                    <div>
                      <div className="form-row">
                        <div className="form-group col-md-6">
                          <p className="text-primary mb-2">
                            Bank Statement: <br /> Upload Document
                          </p>
                          <div className="form-inline">
                            {documentsAvailableBankStatement &&
                            editBankStatementdocument === false ? (
                              <>
                                <>
                                  <small>
                                    {documentsAvailableBankStatement.fileName}
                                  </small>
                                  <a
                                    href={`${process.env.REACT_APP_API_URL}/downloadDocumentByFileName/${documentsAvailableBankStatement.fileName}`}
                                    className="btn btn-primary mr-1 ml-2"
                                    download
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    View{' '}
                                  </a>
                                  <button
                                    type="button"
                                    className="btn btn-success mr-1 ml-2"
                                    onClick={() =>
                                      setDocumentsBankStatement({
                                        ...documentsbankstatement,
                                        editBankStatementdocument: true,
                                      })
                                    }
                                  >
                                    Edit{' '}
                                  </button>
                                </>
                              </>
                            ) : (
                              <>
                                <input
                                  type="file"
                                  className="form-control pt-1  w-60 w-sm-100 mb-sm-10 mr-1"
                                  id="identityDoc"
                                  onChange={onChangedocbankstatement(
                                    'bankStatementFile'
                                  )}
                                />

                                <button
                                  className="btn btn-primary "
                                  onClick={submitdocbankStatement}
                                >
                                  Save
                                </button>
                                {documentsAvailableBankStatement && (
                                  <button
                                    className="btn btn-none  ml-2"
                                    type="button"
                                    onClick={() =>
                                      setDocumentsBankStatement({
                                        ...documentsbankstatement,
                                        editBankStatementdocument: false,
                                      })
                                    }
                                  >
                                    <i class="fa-solid fa-circle-arrow-right"></i>
                                  </button>
                                )}
                              </>
                            )}

                            {bankStatementFile !== null && (
                              <>
                                <object
                                  data={URL.createObjectURL(bankStatementFile)}
                                  type="application/pdf"
                                  width="150"
                                  height="100"
                                  className="mt-4 mb-4 image-fit"
                                ></object>
                              </>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <p className="text-primary mb-2">
                            SOA Of Past Loans: <br /> Upload Document:
                          </p>
                          <div className="form-inline">
                            {documentsAvailableSOA &&
                            editSOAdocument === false ? (
                              <>
                                <>
                                  <small>
                                    {' '}
                                    <i class="fa-solid fa-file-circle-check file-icon-size text-success"></i>{' '}
                                    {documentsAvailableSOA.fileName.substring(
                                      0,
                                      13
                                    )}
                                    ...
                                  </small>

                                  <a
                                    href={`${process.env.REACT_APP_API_URL}/downloadDocumentByFileName/${documentsAvailableSOA.fileName}`}
                                    className="btn btn-primary mr-1 ml-2"
                                    download
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    View{' '}
                                  </a>
                                  <button
                                    type="button"
                                    className="btn btn-success mr-1 ml-2"
                                    onClick={() =>
                                      setDocumentsSoa({
                                        ...documentssoa,
                                        editSOAdocument: true,
                                      })
                                    }
                                  >
                                    Edit{' '}
                                  </button>
                                </>
                              </>
                            ) : (
                              <>
                                <input
                                  type="file"
                                  className="form-control pt-1  w-60 w-sm-100 mb-sm-10 mr-1"
                                  id="identityDoc"
                                  onChange={onChangedocsoa(
                                    'sOAOfPastLoansFile'
                                  )}
                                />

                                <button
                                  className="btn btn-primary "
                                  onClick={submitdocsoapastloan}
                                >
                                  Save
                                </button>
                                {documentsAvailableSOA && (
                                  <button
                                    className="btn btn-none  ml-2"
                                    type="button"
                                    onClick={() =>
                                      setDocumentsSoa({
                                        ...documentssoa,
                                        editSOAdocument: false,
                                      })
                                    }
                                  >
                                    <i class="fa-solid fa-circle-arrow-right"></i>
                                  </button>
                                )}
                              </>
                            )}

                            {sOAOfPastLoansFile !== null && (
                              <>
                                <object
                                  data={URL.createObjectURL(sOAOfPastLoansFile)}
                                  type="application/pdf"
                                  width="150"
                                  height="100"
                                  className="mt-4 mb-4 image-fit"
                                ></object>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* 3 Months Mobile Bill: --- Completed Application Form:*/}
                    <div>
                      <div className="form-row">
                        <div className="form-group col-md-6">
                          <p className="text-primary mb-2">
                            3 Months Mobile Bill: <br /> Upload Document
                          </p>
                          <div className="form-inline">
                            {documentsAvailableMobileBill &&
                            editMobileBilldocument === false ? (
                              <>
                                <>
                                  <small>
                                    {' '}
                                    <i class="fa-solid fa-file-circle-check file-icon-size text-success"></i>{' '}
                                    {documentsAvailableMobileBill.fileName.substring(
                                      0,
                                      13
                                    )}
                                    ...
                                  </small>

                                  <a
                                    href={`${process.env.REACT_APP_API_URL}/downloadDocumentByFileName/${documentsAvailableMobileBill.fileName}`}
                                    className="btn btn-primary mr-1 ml-2"
                                    download
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    View{' '}
                                  </a>
                                  <button
                                    type="button"
                                    className="btn btn-success mr-1 ml-2"
                                    onClick={() =>
                                      setDocumentsMobileBill({
                                        ...documentsmobilebill,
                                        editMobileBilldocument: true,
                                      })
                                    }
                                  >
                                    Edit{' '}
                                  </button>
                                </>
                              </>
                            ) : (
                              <>
                                <input
                                  type="file"
                                  className="form-control pt-1  w-60 w-sm-100 mb-sm-10 mr-1"
                                  onChange={onChangedocmobilebill(
                                    'monthsMobileBillFile'
                                  )}
                                />

                                <button
                                  className="btn btn-primary "
                                  onClick={submitdocmobilebill}
                                >
                                  Save
                                </button>

                                {documentsAvailableMobileBill && (
                                  <button
                                    className="btn btn-none  ml-2"
                                    type="button"
                                    onClick={() =>
                                      setDocumentsMobileBill({
                                        ...documentsmobilebill,
                                        editMobileBilldocument: false,
                                      })
                                    }
                                  >
                                    <i class="fa-solid fa-circle-arrow-right"></i>
                                  </button>
                                )}
                              </>
                            )}

                            {monthsMobileBillFile !== null && (
                              <>
                                <object
                                  data={URL.createObjectURL(
                                    monthsMobileBillFile
                                  )}
                                  type="application/pdf"
                                  width="150"
                                  height="100"
                                  className="mt-4 mb-4 image-fit"
                                ></object>
                              </>
                            )}
                          </div>
                        </div>
                        {loanDetails.processingBankName !== undefined && (
                          <>
                            <div className="col-md-6">
                              <p className="text-primary mb-2">
                                Completed Application Form: <br /> Upload
                                Document:
                              </p>
                              <div className="form-inline">
                                {documentsAvailableCompleteApp &&
                                editCompleteAppdocument === false ? (
                                  <>
                                    <>
                                      <small>
                                        {' '}
                                        <i class="fa-solid fa-file-circle-check file-icon-size text-success"></i>{' '}
                                        {documentsAvailableCompleteApp.fileName.substring(
                                          0,
                                          13
                                        )}
                                        ...
                                      </small>
                                      <a
                                        href={`${process.env.REACT_APP_API_URL}/downloadDocumentByFileName/${documentsAvailableCompleteApp.fileName}`}
                                        className="btn btn-primary mr-1 ml-2"
                                        download
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        View{' '}
                                      </a>
                                      <button
                                        type="button"
                                        className="btn btn-success mr-1 ml-2"
                                        onClick={() =>
                                          setDocumentsCompleteApp({
                                            ...documentscompleteApp,
                                            editCompleteAppdocument: true,
                                          })
                                        }
                                      >
                                        Edit{' '}
                                      </button>
                                    </>
                                  </>
                                ) : (
                                  <>
                                    <input
                                      type="file"
                                      className="form-control pt-1  w-60 w-sm-100 mb-sm-10 mr-1"
                                      onChange={onChangedoccompleteApp(
                                        'completedApplicationFormFile'
                                      )}
                                    />

                                    <button
                                      className="btn btn-primary "
                                      onClick={submitdocCompletedapp}
                                    >
                                      Save
                                    </button>
                                    {documentsAvailableCompleteApp && (
                                      <button
                                        className="btn btn-none  ml-2"
                                        type="button"
                                        onClick={() =>
                                          setDocumentsCompleteApp({
                                            ...documentscompleteApp,
                                            editCompleteAppdocument: false,
                                          })
                                        }
                                      >
                                        <i class="fa-solid fa-circle-arrow-right"></i>
                                      </button>
                                    )}
                                  </>
                                )}

                                {completedApplicationFormFile !== null && (
                                  <>
                                    <object
                                      data={URL.createObjectURL(
                                        completedApplicationFormFile
                                      )}
                                      type="application/pdf"
                                      width="150"
                                      height="100"
                                      className="mt-4 mb-4 image-fit"
                                    ></object>
                                  </>
                                )}
                              </div>
                              {completedApplicationForm === null &&
                              documentsAvailableCompleteApp === undefined ? (
                                <p className="text-danger">
                                  Loan Application Document is mandatory to
                                  process your request
                                </p>
                              ) : (
                                <span></span>
                              )}
                            </div>
                          </>
                        )}
                      </div>
                    </div>

                    {/* <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-success rounded-0"
                      >
                        Save
                      </button>
                    </div> */}
                  </form>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerDocumentsDetails;

import React from 'react';
import Layout from '../components/Layout';

const TermsConditions = () => {
  return (
    <>
      <Layout>
        <div className="inner_banner text-center">
          <div className="container">Terms And Conditions</div>
        </div>
        <div className="container">
          <p>
            Please understand the following terms and conditions <br /> 1.
            Commision payout amount will depend on various condition like Loan
            Type,Processing Bank, approveed interest rate etc. <br /> 2. Payout
            typically creited to bank account within 10th day of each month but
            can be delayed occationaly. <br /> 3. Final Payout amount will be
            notified only after Loan Amount disbursed to Customer's Account.{' '}
            <br /> 4. In case of any query/clarification you need to notify
            within 15 Calendar Days.
          </p>
        </div>
      </Layout>
    </>
  );
};

export default TermsConditions;

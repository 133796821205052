import React from 'react';
import { Link } from 'react-router-dom';
import Layout from '../components/Layout';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import SecureLS from 'secure-ls';

const Login = ({ history }) => {
  const {
    register,
    handleSubmit,
    watch,
    trigger,
    formState: { errors },
    reset,
  } = useForm();

  const [values, setValues] = React.useState({
    loading: false,
  });
  const { loading } = values;
  const MySwal = withReactContent(Swal);

  var ls = new SecureLS({ encodingType: 'aes', isCompression: false });

  const clickAgentLogin = (data) => {
    // e.preventDefault();

    setValues({
      ...values,
      loading: true,
    });
    fetch(`${process.env.REACT_APP_API_URL}/agents/login`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username: data.email,
        password: data.password,
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        if (res.userStatus === 'Failure') {
          MySwal.fire({
            text: `${res.messageToDisplay}`,
          });
          setValues({
            ...values,
            loading: false,
          });
        } else if (res.messageToDisplay === 'Please Activate UserId') {
          // alert(res.messageToDisplay);
          history.push('/user-profile-activation/username/' + data.email);
        } else if (res.loggedInAgent.status === 'GuestAgent') {
          // alert(res.messageToDisplay);
          history.push('/user-profile-activation/username/' + data.email);
        } else {
          setValues({
            ...values,
            loading: false,
            userName: '',
            password: '',
          });

          localStorage.setItem('username', res.loggedInAgent.firstName);
          localStorage.setItem('userType', watch().userType);
          localStorage.setItem(
            'loggedInAgent',
            JSON.stringify(res.loggedInAgent)
          );
          localStorage.setItem('jwt', JSON.stringify(res.authParameters.token));

          ls.set('LoginMsg', res.messageToDisplay);

          history.push(`/${res.defaultPageToRedirect}`);
        }
      })
      .catch((err) => {
        setValues({
          ...values,
          loading: false,
        });
        MySwal.fire({
          text: 'You have entered incorrect username or password',
        });
      });
  };

  const clickEmployeeLogin = (data) => {
    // e.preventDefault();

    setValues({
      ...values,
      loading: true,
    });

    fetch(`${process.env.REACT_APP_API_URL}/employee/login`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username: data.email,
        password: data.password,
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        if (res.userStatus === 'Failure') {
          MySwal.fire({
            text: `${res.messageToDisplay}`,
          });
          setValues({
            ...values,
            loading: false,
          });
        } else {
          setValues({
            ...values,
            loading: false,
          });

          localStorage.setItem('username', res.loggedInEmployee.firstName);
          localStorage.setItem('userType', watch().userType);
          localStorage.setItem(
            'loggedInEmployee',
            JSON.stringify(res.loggedInEmployee)
          );
          localStorage.setItem('jwt', JSON.stringify(res.authParameters.token));

          ls.set('LoginMsg', res.messageToDisplay);

          history.push(`/${res.defaultPageToRedirect}`);
        }
      })
      .catch((err) => {
        setValues({
          ...values,
          loading: false,
        });
        MySwal.fire({
          text: 'You have entered incorrect username or password',
        });
      });
  };

  // const clickEmployeeLogin = (data) => {
  //   console.log(data);
  // };

  const showLoading = () =>
    loading && (
      <div className="alert alert-info">
        <h2>Loading...</h2>
      </div>
    );

  return (
    <>
      <Layout>
        <div className="inner_banner text-center">
          <div className="container">Login</div>
        </div>

        {/* Form */}

        <ToastContainer />

        <div className="container-fluid">
          <div className="container-fluid">
            <div className="row justify-content-center">
              <div className="col-lg-4">
                <div className="container">{showLoading()}</div>
                <div className="register-form shadow-sm">
                  <form>
                    <div className="input-group mb-3">
                      <div className="input-group-prepend">
                        <span
                          className="input-group-text rounded-0"
                          id="basic-addon1"
                        >
                          <i className="fa-solid fa-user"></i>
                        </span>
                      </div>
                      <select
                        id="inputState"
                        className="form-control rounded-0"
                        aria-describedby="basic-addon1"
                        {...register('userType', {
                          required: 'User Type is required',
                        })}
                      >
                        <option value="" selected>
                          Select User Type
                        </option>
                        <option value="Agent">Agent</option>
                        <option value="Employee">Employee</option>
                        <option value="Customer">Customer</option>
                      </select>
                    </div>
                    {errors.userType && (
                      <p className="text-danger">{errors.userType.message}</p>
                    )}
                    <label htmlFor="email">Email Address</label>
                    <div className="input-group mb-3">
                      <div className="input-group-prepend">
                        <span className="input-group-text rounded-0" id="email">
                          <i className="fa-solid fa-envelope"></i>
                        </span>
                      </div>
                      <input
                        type="email"
                        className="form-control rounded-0"
                        id="email"
                        placeholder="Email"
                        aria-describedby="email"
                        {...register('email', {
                          required: 'email is required',
                          pattern: {
                            value:
                              /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            message: 'Please enter a valid email',
                          },
                        })}
                        onKeyUp={() => {
                          trigger('email');
                        }}
                      />
                    </div>
                    {errors.email && (
                      <p className="text-danger">{errors.email.message}</p>
                    )}
                    <label htmlFor="password">Password</label>
                    <div className="input-group mb-3">
                      <div className="input-group-prepend">
                        <span
                          className="input-group-text rounded-0"
                          id="password"
                        >
                          <i className="fa-solid fa-lock"></i>
                        </span>
                      </div>
                      <input
                        type="password"
                        className="form-control rounded-0"
                        id="password"
                        placeholder="password"
                        aria-describedby="password"
                        {...register('password', {
                          required: 'Password is required',
                        })}
                        onKeyUp={() => {
                          trigger('password');
                        }}
                      />
                    </div>
                    {errors.password && (
                      <p className="text-danger">{errors.password.message}</p>
                    )}
                    <div className="mb-3">
                      <Link to="/forgot-password"> Forgot Password?</Link>
                    </div>
                    {watch().userType === 'Employee' ? (
                      <button
                        type="submit"
                        onClick={handleSubmit(clickEmployeeLogin)}
                        className="btn btn-primary rounded-0"
                      >
                        Login
                      </button>
                    ) : (
                      <button
                        type="submit"
                        onClick={handleSubmit(clickAgentLogin)}
                        className="btn btn-primary rounded-0"
                      >
                        Login
                      </button>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
      </Layout>
    </>
  );
};

export default Login;

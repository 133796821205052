import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Helmet } from 'react-helmet';
import { Link, NavLink } from 'react-router-dom/cjs/react-router-dom.min';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import HomeHeader from '../components/HomeHeader';
import HomeFooter from '../components/HomeFooter';
import LoanRef from '../components/LoanRef';

const Home = ({ history }) => {
  const MySwal = withReactContent(Swal);

  const {
    register,
    handleSubmit,
    watch,
    trigger,
    formState: { errors },
    reset,
  } = useForm();

  const [values, setValues] = useState({
    loading: false,
  });

  const { loading } = values;

  function calculate() {
    // Look up the input and output elements in the document
    var amount = document.getElementById('amount');
    var apr = document.getElementById('apr');
    var years = document.getElementById('years');
    var zipcode = document.getElementById('zipcode');
    var payment = document.getElementById('payment');
    var total = document.getElementById('total');
    var totalinterest = document.getElementById('totalinterest');

    // Get the user's input from the input elements.
    // Assume all input is valid (for now).
    // Convert interest rate from percentage to decimal
    // Convert from annual rate to monthly rate
    // Convert payment period in years to number of monthly payments
    var principal = parseFloat(amount.value.replace(/,/g, ''));
    var rate = parseFloat(apr.value) / 100 / 12;
    var payments = parseFloat(years.value) * 12;

    // Compute the monthly payment
    var x = Math.pow(1 + rate, payments);
    var monthly = (principal * x * rate) / (x - 1);

    // If the result is a finite number, the user's input was good
    // and we have meaningful results to display
    if (isFinite(monthly)) {
      // Fill in the output fields, rounding to 2 decimal places
      payment.innerHTML = formatNumber(monthly, 2);
      total.innerHTML = formatNumber(monthly * payments, 2);
      totalinterest.innerHTML = formatNumber(monthly * payments - principal, 2);

      // Finally, chart loan balance, interest, and equity payments
      chart(principal, rate, monthly, payments);
    } else {
      // Result was NaN or Infinite,
      // which means the input was incomplete or invalid.
      // Clear any previously-displayed output.
      payment.innerHTML = '';
      total.innerHTML = '';
      totalinterest.innerHTML = '';
      chart(); // With no arguments, clears the chart
    }
  }

  // Chart the monthly loan balance, interest, and
  // equity in an HTML <canvas> element.
  // If called with no arguments, then just erase
  // any previously drawn chart.
  function chart(principal, rate, monthly, payments) {
    var graph = document.getElementById('graph');
    graph.width = graph.width; // Magic to clear and reset the canvas element

    // If we're called with no arguments,
    // or if the browser does not support graphics
    // in a <canvas> element, then just return now.
    if (arguments.length === 0 || !graph.getContext) return;

    // Get the "context" object for the <canvas>
    // that defines the API
    // All drawing is done with this object
    var g = graph.getContext('2d');

    var width = graph.width,
      height = graph.height; // Get canvas size

    // These functions convert payment numbers
    // and dollar amounts to pixels
    function paymentToX(n) {
      return (n * width) / payments;
    }
    function amountToY(a) {
      return height - (a * height) / (monthly * payments * 1.05);
    }

    // Payments are a straight line from (0,0)
    // to (payments, monthly * payments)
    g.moveTo(paymentToX(0), amountToY(0)); // Start at lower left
    g.lineTo(
      paymentToX(payments), // Draw to upper right
      amountToY(monthly * payments)
    );
    g.lineTo(paymentToX(payments), amountToY(0)); // Down to lower right
    g.closePath(); // And back to start
    g.fillStyle = '#72AAD0'; // Light blue
    g.fill(); // Fill the triangle

    // Cumulative equity is non-linear and trickier to chart
    var equity = 0;
    g.beginPath();
    g.moveTo(paymentToX(0), amountToY(0)); // Start at lower left
    for (var p = 1; p <= payments; p++) {
      // For each payment, figure out how much is interest
      var thisMonthsInterest = (principal - equity) * rate;
      equity += monthly - thisMonthsInterest; // The rest goes to equity
      g.lineTo(paymentToX(p), amountToY(equity)); // Line to this point
    }
    g.lineTo(paymentToX(payments), amountToY(0)); // Line back to X axis
    g.closePath(); // And back to starting point
    g.fillStyle = '#40FD65';
    g.fill(); // Fill area under curve

    // Loop again, as above, but chart loan balance as a black line
    var bal = principal;
    g.beginPath();
    g.moveTo(paymentToX(0), amountToY(bal));
    for (var p = 1; p <= payments; p++) {
      var thisMonthsInterest = bal * rate;
      bal -= monthly - thisMonthsInterest; // The rest goes to equity
      g.lineTo(paymentToX(p), amountToY(bal)); // Draw line to this point
    }

    g.lineWidth = 2;
    g.strokeStyle = '#444';
    g.stroke(); // Draw the balance curve

    // Now make yearly tick marks and year numbers on X axis
    g.textAlign = 'center'; // Center text over ticks
    g.fillStyle = '#444';
    g.font = "0.6em 'Open Sans'";
    var y = amountToY(0); // Y coordinate of X axis
    for (var year = 1; year * 12 <= payments; year++) {
      // For each year
      var x = paymentToX(year * 12); // Compute tick position
      g.fillRect(x - 0.5, y - 3, 1, 3); // Draw the tick
      if (year === 1) g.fillText('Year', x, y - 5); // Label the axis
      if (year % 5 === 0 && year * 12 !== payments)
        // Number every 5 years
        g.fillText(String(year), x, y - 5);
    }

    // Mark payment amounts along the right edge
    g.textAlign = 'right'; // Right-justify the text
    g.textBaseline = 'middle'; // Center it vertically
    var ticks = [monthly * payments, principal]; // The two points we'll mark
    var rightEdge = paymentToX(payments); // X coordinate of Y axis
    for (var i = 0; i < ticks.length; i++) {
      // For each of the 2 points
      var y = amountToY(ticks[i]); // Compute y position of tick
      g.fillRect(rightEdge - 3, y - 0.5, 3, 1); // Draw the tick mark
      g.fillText(String(formatNumber(ticks[i]), 2), rightEdge - 5, y); // And label it
    }
  }

  function formatNumber(num, decimals) {
    return num.toFixed(decimals).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    // http://blog.tompawlak.org/number-currency-formatting-javascript
  }

  // check number
  const checkIfNumber = (event) => {
    const regex = new RegExp(
      /(^\d*$)|(Backspace|Tab|Delete|ArrowLeft|ArrowRight)/
    );

    return !event.key.match(regex) && event.preventDefault();
  };

  // check text
  const checkIfTextOnly = (event) => {
    const regex = new RegExp(/[a-zåäö ]/i);

    return !event.key.match(regex) && event.preventDefault();
  };

  const clickRegister = async (data) => {
    // e.preventDefault();
    setValues({
      loading: true,
    });
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/initiateLead`,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            occupation: data.occupation,
            customerFirstName: data.customerFirstName,
            customerLastName: data.customerLastName,
            customerEmailId: data.customerEmailId,
            customerMobileNumber: data.customerMobileNumber,
            loanAmountAppliedFor: data.loanAmountAppliedFor,
            companyName: data.companyName,
            companyType: data.companyType,
            loanType: data.loanType,
            city: data.city,
            state: data.state,
          }),
        }
      );

      if (response.status === 200) {
        const resdata = await response.json();
        setValues({
          loading: false,
        });

        MySwal.fire({
          text: `${resdata.message}`,
        });
        reset();
      } else if (response.status === 500) {
        setValues({
          loading: false,
        });
        MySwal.fire({
          text: 'Something went wrong',
        });
      }
    } catch (error) {
      setValues({
        loading: false,
      });
      MySwal.fire({
        text: 'Something went wrong',
      });
    }
  };

  const showLoading = () =>
    loading && (
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    );

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link href="css/custom.css" rel="stylesheet" type="text/css" />
        <title>Anex Finserv</title>
      </Helmet>
      <HomeHeader />
      <div>
        <div className="banner_home">
          {/* <div id="owl-demo" className="owl-carousel owl-theme">
            <div className="item">
              <img src="images/slider1.jpg" alt />
            </div>
            <div className="item">
              <img src="images/slider1.jpg" alt />
            </div>
          </div> */}
          <div
            id="carouselExampleControls"
            class="carousel slide"
            data-ride="carousel"
          >
            <div class="carousel-inner">
              {/* <div class="carousel-item active">
                <img
                  src="images/slider1.jpg"
                  className="d-block w-100"
                  alt="..."
                />
                <div class="carousel-caption">
                  <Link data-toggle="modal" data-target="#learnMoreCarousel">
                    {' '}
                    <button className="btn btn-primary">Learn More</button>{' '}
                  </Link>
                </div>
              </div>*/}
              <div class="carousel-item active">
                <Link data-toggle="modal" data-target="#learnMoreCarousel">
                  {' '}
                  <img
                    src="images/carousel/banner-3.jpg"
                    className="d-block w-100"
                    alt="..."
                  />{' '}
                </Link>
              </div>
              <div class="carousel-item ">
                <Link data-toggle="modal" data-target="#staticBackdrop2">
                  {' '}
                  <img
                    src="images/carousel/banner-2-loan.jpg"
                    className="d-block w-100"
                    alt="..."
                  />
                </Link>
              </div>
              <div class="carousel-item ">
                <Link data-toggle="modal" data-target="#staticBackdrop2">
                  {' '}
                  <img
                    src="images/carousel/banner-bussiness-loan.jpg"
                    className="d-block w-100"
                    alt="..."
                  />{' '}
                </Link>
              </div>
            </div>
            <a
              class="carousel-control-prev"
              href="#carouselExampleControls"
              role="button"
              data-slide="prev"
            >
              <span
                class="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span class="sr-only">Previous</span>
            </a>
            <a
              class="carousel-control-next"
              href="#carouselExampleControls"
              role="button"
              data-slide="next"
            >
              <span
                class="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span class="sr-only">Next</span>
            </a>
          </div>
        </div>

        {/* <!-- Modal --> */}
        <div
          className="modal fade"
          id="learnMoreCarousel"
          data-backdrop="static"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body p-0 m-0">
                <LoanRef />
              </div>
            </div>
          </div>
        </div>

        <div className="bank_logos">
          <div className="container">
            <marquee>
              <img src="images/logos_sec.png" alt />{' '}
              <img src="images/logos_sec.png" alt />{' '}
              <img src="images/logos_sec.png" alt />
            </marquee>
          </div>
        </div>
        <div className="full_sec text-center">
          <div className="container">
            <div className="row g-0">
              <div className="col-lg-6 col-md-6 col-12 border-right">
                <div className="loan_titl">Calculate Your Loan EMI</div>
                <div className="prtnr_btn">
                  <a
                    data-bs-toggle="modal"
                    className="btn btn-primary"
                    data-toggle="modal"
                    data-target="#staticBackdrop"
                    href="#"
                  >
                    Calculate Now
                  </a>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-12">
                <div className="loan_titl">Need Loan? Apply Now</div>
                <div className="prtnr_btn">
                  <a
                    data-bs-toggle="modal"
                    className="btn btn-primary"
                    data-toggle="modal"
                    data-target="#staticBackdrop2"
                    href="#"
                  >
                    Apply Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="step_outer">
          <div className="container">
            <div className="step_titl">
              From application to disbursement in{' '}
              <strong>4 Simple Steps!</strong>
            </div>
            <div className="row">
              <div className="col-lg-3 col-md-6 col-12">
                <div className="step_cont">
                  <div className="step_ico">
                    <img src="images/step_icon_u.png" alt />
                  </div>
                  <div className="step_name">Call Us or Whatsapp</div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-12">
                <div className="step_cont">
                  <div className="step_ico pink_step">
                    <img src="images/step_icon_u2.png" alt />
                  </div>
                  <div className="step_name">Submit Your Documents</div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-12">
                <div className="step_cont">
                  <div className="step_ico ">
                    <img src="images/step_icon_u3.png" alt />
                  </div>
                  <div className="step_name">Loan Approved</div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-12">
                <div className="step_cont">
                  <div className="step_ico pink_step">
                    <img src="images/step_icon_u4.png" alt />
                  </div>
                  <div className="step_name">Amount Credited</div>
                </div>
              </div>
            </div>
            <div className="whats_btn text-center">
              <a href="#">Start Now! call to +917890007785</a>{' '}
              <a
                href="#"
                className="cal_btn"
                data-toggle="modal"
                data-target="#staticBackdrop"
              >
                Calculate Your Loan EMI
              </a>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="staticBackdrop"
          data-backdrop="static"
          data-keyboard="false"
          tabIndex={-1}
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header p-2">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="container">
                  <div className="row">
                    <div className="col col-main">
                      <div className="calc_titl">Enter Loan Data</div>
                      <label htmlFor="amount" className="label">
                        Amount of the loan
                      </label>
                      <div className="input-wrapper">
                        <span className="input-addon">₹</span>
                        <input
                          id="amount"
                          className="input-field"
                          type="text"
                          defaultValue="15,000,00"
                        />
                      </div>
                      <label htmlFor="apr" className="label">
                        Annual interest rate
                      </label>
                      <div className="input-wrapper">
                        <input
                          id="apr"
                          className="input-field"
                          type="text"
                          defaultValue="8.5"
                        />
                        <span className="input-addon">%</span>
                      </div>
                      <label htmlFor="years" className="label">
                        Repayment period
                      </label>
                      <div className="input-wrapper">
                        <input
                          id="years"
                          className="input-field"
                          type="text"
                          defaultValue={20}
                        />
                        <span className="input-addon">years</span>
                      </div>
                      {/*
<label for="zipcode" className="label">Zipcode (to find lenders)</label>
<div className="input-wrapper">
  <input id="zipcode" className="input-field" type="text">
</div>
*/}
                      <button className="btn_cal" onClick={calculate}>
                        Calculate
                      </button>
                    </div>
                    <div className="col">
                      <div className="calc_titl">Payments</div>
                      <div className="output-wrapper">
                        <p className="label">Monthly payment</p>
                        <p className="output-value">
                          ₹<span id="payment" />
                        </p>
                      </div>
                      <div className="output-wrapper">
                        <p className="label">Total payments</p>
                        <p className="output-value">
                          ₹<span id="total" />
                        </p>
                      </div>
                      <div className="output-wrapper">
                        <p className="label">Total interest payments</p>
                        <p className="output-value">
                          ₹<span id="totalinterest" />
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="calc_titl">Balance vs. Payments</div>
                  <canvas id="graph" />
                  <div className="legend">
                    <div className="legend-item">
                      <div
                        className="legend-swatch"
                        id="total-interest-payments-swatch"
                      />
                      <div className="legend-label">
                        Total Interest Payments
                      </div>
                    </div>
                    <div className="legend-item">
                      <div className="legend-swatch" id="total-equity-swatch" />
                      <div className="legend-label">Total Equity</div>
                    </div>
                    <div className="legend-item">
                      <div className="legend-swatch" id="loan-balance-swatch" />
                      <div className="legend-label">Loan Balance</div>
                    </div>
                  </div>
                  <footer />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="staticBackdrop2"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header p-2">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="form_sec_pop">
                  <form
                    action
                    id="prtnrform"
                    onSubmit={handleSubmit(clickRegister)}
                  >
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-12">
                        <div className="form_radio">
                          <label>Occupation*</label>
                          <input
                            type="radio"
                            id="ocp"
                            name="ocp"
                            value={'Salaried'}
                            {...register('occupation', {
                              required: 'Occupation is required',
                            })}
                          />{' '}
                          Salaried
                          <input
                            type="radio"
                            id="ocp"
                            name="ocp"
                            value={'Self-Employed'}
                            {...register('occupation', {
                              required: 'Occupation is required',
                            })}
                          />{' '}
                          Self-Employed
                        </div>{' '}
                        {errors.occupation && (
                          <p className="text-danger">
                            {errors.occupation.message}
                          </p>
                        )}
                      </div>
                      <div className="col-lg-12 col-md-12 col-12">
                        <div className="form_select_sec">
                          <select
                            {...register('loanType', {
                              required: 'Loan type is required',
                            })}
                          >
                            <option value="">Select Loan Type</option>
                            <option value="Home Loan">Home Loan</option>
                            <option value="Car Loan">Car Loan</option>
                            <option value="Business Loan">Business Loan</option>
                            <option value="Gold Loan">Gold Loan</option>
                            <option value="Personal Loan">Personal Loan</option>
                          </select>
                        </div>
                        {errors.loanType && (
                          <p className="text-danger">
                            {errors.loanType.message}
                          </p>
                        )}
                      </div>
                      <div className="col-lg-6 col-md-6 col-12">
                        <div className="form_sec">
                          <input
                            type="text"
                            id="fname"
                            name="fname"
                            placeholder="First Name"
                            {...register('customerFirstName', {
                              required: 'Customer first name is required',
                              maxLength: {
                                value: 50,
                                message: 'Maximum 50 characters',
                              },
                            })}
                            onKeyUp={() => {
                              trigger('customerFirstName');
                            }}
                            onKeyDown={(e) => checkIfTextOnly(e)}
                          />
                        </div>
                        {errors.customerFirstName && (
                          <p className="text-danger">
                            {errors.customerFirstName.message}
                          </p>
                        )}
                      </div>
                      <div className="col-lg-6 col-md-6 col-12">
                        <div className="form_sec">
                          <input
                            type="text"
                            id="lname"
                            name="lname"
                            placeholder="Last Name"
                            {...register('customerLastName')}
                            onKeyDown={(e) => checkIfTextOnly(e)}
                          />
                        </div>{' '}
                        {errors.customerLastName && (
                          <p className="text-danger">
                            {errors.customerLastName.message}
                          </p>
                        )}
                      </div>
                      <div className="col-lg-6 col-md-6 col-12">
                        <div className="form_sec">
                          <input
                            type="tel"
                            id="phone"
                            name="phone"
                            placeholder="Phone No"
                            {...register('customerMobileNumber', {
                              required: 'Customer phone no is required',
                              pattern: {
                                value: /^[0-9]{10}$/,
                                message: 'Please enter 10 digit phone no',
                              },
                            })}
                            onKeyUp={() => {
                              trigger('customerMobileNumber');
                            }}
                            onKeyDown={(e) => checkIfNumber(e)}
                          />
                        </div>{' '}
                        {errors.customerMobileNumber && (
                          <p className="text-danger">
                            {errors.customerMobileNumber.message}
                          </p>
                        )}
                      </div>
                      <div className="col-lg-6 col-md-6 col-12">
                        <div className="form_sec">
                          <input
                            type="email"
                            id="mail"
                            name="email"
                            placeholder="Email"
                            {...register('customerEmailId', {
                              required: 'Customer email is required',
                              pattern: {
                                value:
                                  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                message: 'Please enter a valid email',
                              },
                            })}
                            onKeyUp={() => {
                              trigger('customerEmailId');
                            }}
                          />
                        </div>{' '}
                        {errors.customerEmailId && (
                          <p className="text-danger">
                            {errors.customerEmailId.message}
                          </p>
                        )}
                      </div>
                      <div className="col-lg-6 col-md-6 col-12">
                        <div className="form_sec">
                          <input
                            type="number"
                            id="amount"
                            name="amount"
                            placeholder="Required Loan Amount"
                            {...register('loanAmountAppliedFor', {
                              required: 'Loan Amount is required',
                            })}
                            onKeyUp={() => {
                              trigger('loanAmountAppliedFor');
                            }}
                            onKeyDown={(e) => checkIfNumber(e)}
                          />
                        </div>{' '}
                        {errors.loanAmountAppliedFor && (
                          <p className="text-danger">
                            {errors.loanAmountAppliedFor.message}
                          </p>
                        )}
                      </div>

                      {watch().occupation === 'Salaried' ? (
                        <>
                          <div className="col-lg-6 col-md-6 col-12">
                            <div className="form_sec">
                              <input
                                type="text"
                                id="cname"
                                name="cname"
                                placeholder="Company Name"
                                {...register('companyName', {
                                  required: 'Company Name is required',
                                })}
                              />
                            </div>
                            {errors.companyName && (
                              <p className="text-danger">
                                {errors.companyName.message}
                              </p>
                            )}
                          </div>
                          <div className="col-lg-6 col-md-6 col-12">
                            <div className="form_select_sec">
                              <select
                                {...register('companyType', {
                                  required: 'Company type is required',
                                })}
                              >
                                <option value="" selected>
                                  Select Company Type
                                </option>
                                <option value="public">Public Limited</option>
                                <option value="private">Private Limited</option>
                                <option value="goverment">Goverment</option>
                                <option value="proprietorship">
                                  Proprietorship
                                </option>
                                <option value="partnership">Partneship</option>
                                <option value="limited liability partenrship">
                                  Limited Liability Partnership
                                </option>
                              </select>
                            </div>
                            {errors.companyType && (
                              <p className="text-danger">
                                {errors.companyType.message}
                              </p>
                            )}
                          </div>
                        </>
                      ) : (
                        <></>
                      )}

                      <div className="col-lg-6 col-md-6 col-12">
                        <div className="form_sec">
                          <input
                            type="text"
                            id="pinc"
                            name="pinc"
                            placeholder="PIN Code"
                            {...register('pinCode', {
                              required: 'Pin code is required',
                              pattern: {
                                value: /^[1-9][0-9]{5}$/,
                                message: 'Pin code sould be 6 digit',
                              },
                            })}
                            onKeyUp={() => {
                              trigger('pinCode');
                            }}
                            onKeyDown={(e) => checkIfNumber(e)}
                          />
                        </div>
                        {errors.pinCode && (
                          <p className="text-danger">
                            {errors.pinCode.message}
                          </p>
                        )}
                      </div>
                      <div className="col-lg-6 col-md-6 col-12">
                        <div className="form_sec">
                          <input
                            type="text"
                            id="city"
                            name="city"
                            placeholder="City"
                            {...register('city')}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-12">
                        <div className="form_sec">
                          <input
                            type="text"
                            id="state"
                            name="state"
                            placeholder="State"
                            {...register('State')}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-12"></div>
                    {loading === true ? (
                      <>{showLoading()}</>
                    ) : (
                      <>
                        <div className="form_btn">
                          {' '}
                          <input type="submit" defaultValue="Apply Now" />{' '}
                        </div>
                      </>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="achive_outer">
          <div className="container">
            <div className="achive_titl">
              We Help Our Clients To{' '}
              <strong>Achieve Their Desired Goals</strong>
            </div>
            <div className="achive_txt">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis
              ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas
              accumsan lacus vel facilisis.
            </div>
            <div className="common_btn text-center">
              <a href="#">Know More</a>
            </div>
          </div>
        </div>

        {/* <div className="testi_outer">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-12">
                <div className="testi_sub">Testimonials</div>
                <div className="step_titl testitop">
                  What Our <strong>Clients Say</strong>
                </div>
                <div className="testislider">
                  <div
                    id="carouselTestimonial"
                    class="carousel slide carousel-fade"
                    data-ride="carousel"
                  >
                    <div class="carousel-inner">
                      <div class="carousel-item active">
                        <div className="item">
                          <div className="star_img">
                            <img src="images/star_img.jpg" alt="img" />
                          </div>
                          <div className="testi_txt">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua. Quis ipsum suspendisse ultrices
                            gravida. Risus commodo viverra maecenas accumsan
                            lacus vel facilisis.{' '}
                          </div>
                          <div className="testi_name">Ramprsad Banik</div>
                        </div>
                      </div>
                      <div class="carousel-item">
                        <div className="item">
                          <div className="star_img">
                            <img src="images/star_img.jpg" alt="img" />
                          </div>
                          <div className="testi_txt">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua. Quis ipsum suspendisse ultrices
                            gravida. Risus commodo viverra maecenas accumsan
                            lacus vel facilisis.{' '}
                          </div>
                          <div className="testi_name">Ramprsad </div>
                        </div>
                      </div>
                    </div>

                    <div className="d-flex justify-content-center mt-4 mb-4">
                      <a
                        class="carousel-control-prev carousel-control-testimonial-prev mr-5 "
                        href="#carouselTestimonial"
                        role="button"
                        data-slide="prev"
                      >
                        <span
                          class="carousel-control-prev-icon"
                          aria-hidden="true"
                        ></span>
                        <span class="sr-only">Previous</span>
                      </a>
                      <a
                        class="carousel-control-next carousel-control-testimonial-next  "
                        href="#carouselTestimonial"
                        role="button"
                        data-slide="next"
                      >
                        <span
                          class="carousel-control-next-icon"
                          aria-hidden="true"
                        ></span>
                        <span class="sr-only">Next</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-12">
                <div className="cus_img text-end">
                  <img src="images/users_img.png" />
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="counter_outer">
          <div className="container">
            <div className="count_titl">
              Making Digital Products <strong>Accessible to Everyone</strong>
            </div>
            <div className="row">
              <div className="col-lg-3 col-md-6 col-12">
                <div className="count_cont">
                  <div className="count_no">15+</div>
                  <div className="count_txt">Banks &amp; Loan Companies</div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-12">
                <div className="count_cont">
                  <div className="count_no">100</div>
                  <div className="count_txt">
                    Cards &amp; Loan Options Offered
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-12">
                <div className="count_cont">
                  <div className="count_no">10k</div>
                  <div className="count_txt">Anex Finserv Advisors</div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-12">
                <div className="count_cont">
                  <div className="count_no">100+</div>
                  <div className="count_txt">Happy Customers</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="choose_outer">
          <div className="container">
            <div className="trust_sec">
              <div className="ch_sub">Transparent Process</div>
              <div className="ch_titl">
                Why Do People <strong>Trust Us</strong>
              </div>
              {/* <div className="ph_img_fotter">
                    <img src="images/phone_img.png" alt />
                  </div> */}
            </div>

            {/* <div className="col-lg-6 col-md-6 col-12"> */}
            <div className="row">
              <div className="col-lg-6 col-md-6 col-12">
                <div className="ch_sec">
                  <div className="ch_ico">
                    <img src="images/ch_ico.png" alt />
                  </div>
                  <div className="ch_name">Compare Lenders</div>
                  <div className="ch_txt">
                    You can compare and choose your lender from an exhaustive
                    list of 100+ partnered Banks &amp; NBFCs.
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-12">
                <div className="ch_sec">
                  <div className="ch_ico">
                    <img src="images/ch_ico2.png" alt />
                  </div>
                  <div className="ch_name">Fastest Process</div>
                  <div className="ch_txt">
                    Our easy application process provides quick and seamless
                    disbursals to our clients.
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-12">
                <div className="ch_sec">
                  <div className="ch_ico">
                    <img src="images/ch_ico3.png" alt />
                  </div>
                  <div className="ch_name">Expert Guidance</div>
                  <div className="ch_txt">
                    Our financial experts with astronomical experience assist
                    you in borrowing right.
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-12">
                <div className="ch_sec">
                  <div className="ch_ico">
                    <img src="images/ch_ico4.png" alt />
                  </div>
                  <div className="ch_name">Nationwide Presence</div>
                  <div className="ch_txt">
                    With a PAN India presence, we are able to cater to the
                    financial needs of individuals in every corner.
                  </div>
                </div>
              </div>
            </div>
            {/* </div> */}
          </div>
        </div>
      </div>
      {/* Footer */}
      <footer className="footer_outer mt-5">
        <div className="container">
          <div className="row px-lg-5">
            <div className="col-lg-4 col-md-6 col-12">
              <div className="abt_foot">
                <div className="foot_titl">About Us</div>
                <div className="foot_txt pe-lg-5">
                  Anex Finserv Financial Service Ltd is a one-stop-shop for all
                  your financial needs, advice. With a PAN India presence
                  founded by professionals and financial experts who with an
                  experience of 20+ years. We have created a rich profile of
                  satisfying the needs of more than 10 lakhs+ clients. Our
                  algorithmic engines help you by using strong factual data made
                  from relative latest changes in the market and understanding
                  of the bank requirement.
                </div>
                <div className="social_sec">
                  <a href="#">
                    <img src="images/fb.png" />
                  </a>
                  <a href="#">
                    <img src="images/linkd.png" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-12 ps-lg-5">
              <div className="abt_foot">
                <div className="foot_titl">Business</div>
                <div className="foot_txt">
                  <ul>
                    <li>
                      <a href="#">Home Loan</a>
                    </li>
                    <li>
                      <a href="#">Personal Loan</a>
                    </li>
                    <li>
                      <a href="#">Business Loan</a>
                    </li>
                    <li>
                      <a href="#">Car Loan</a>
                    </li>
                    <li>
                      <a href="#">Car Refinance</a>
                    </li>
                    <li>
                      <a href="#">Used Car Loan</a>
                    </li>
                    <li>
                      <a href="#">Gold Loan</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-12 ps-lg-4">
              <div className="abt_foot">
                <div className="foot_titl">CALCULATORS</div>
                <div className="foot_txt">
                  <ul>
                    <li>
                      <a href="#">Personal Loan</a>
                    </li>
                    <li>
                      <a href="#">Home Loan</a>
                    </li>
                    <li>
                      <a href="#">Business Loan</a>
                    </li>
                    <li>
                      <a href="#">Loan Against Property</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-6 col-12">
              <div className="abt_foot">
                <div className="foot_titl">Contact Details</div>
                <div className="foot_txt">
                  <ul>
                    <li>
                      <a href="#">+91 7890007785</a>
                    </li>

                    {/* <li>
                      <a href="#">@Tech Query</a>
                    </li>
                    <li>
                      <a href="#">@Sales Query</a>
                    </li>
                    <li>
                      <a href="#">@Service Request</a>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="footer_btm">
            <div className="row">
              <div className="col-lg-6 ol-md-6 col-12">
                <div className="copy_right">
                  Copyright @2023 All Rights Reserved
                </div>
              </div>
              <div className="col-lg-6 ol-md-6 col-12">
                <div className="copy_right text-end">
                  <a href="#"> Privacy & Policy</a> |{' '}
                  <Link data-toggle="modal" data-target="#terms_condition">
                    terms &amp; conditions
                  </Link>
                </div>
              </div>
              {/* <!-- Modal --> */}
              <div
                className="modal fade"
                id="terms_condition"
                data-backdrop="static"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="staticBackdropLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="staticBackdropLabel">
                        Terms And Conditions
                      </h5>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <p>
                        Please understand the following terms and conditions{' '}
                        <br /> 1. Commision payout amount will depend on various
                        condition like Loan Type,Processing Bank, approveed
                        interest rate etc. <br /> 2. Payout typically creited to
                        bank account within 10th day of each month but can be
                        delayed occationaly. <br /> 3. Final Payout amount will
                        be notified only after Loan Amount disbursed to
                        Customer's Account. <br /> 4. In case of any
                        query/clarification you need to notify within 15
                        Calendar Days.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Home;

import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, NavLink } from 'react-router-dom/cjs/react-router-dom.min';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const LoanRef = () => {
  const MySwal = withReactContent(Swal);
  const {
    register,
    handleSubmit,
    watch,
    trigger,
    formState: { errors },
    reset,
  } = useForm();

  const [values, setValues] = useState({
    loading: false,
  });

  const { loading } = values;

  // check number
  const checkIfNumber = (event) => {
    const regex = new RegExp(
      /(^\d*$)|(Backspace|Tab|Delete|ArrowLeft|ArrowRight)/
    );

    return !event.key.match(regex) && event.preventDefault();
  };

  // check text
  const checkIfTextOnly = (event) => {
    const regex = new RegExp(/[a-zåäö ]/i);

    return !event.key.match(regex) && event.preventDefault();
  };

  const clickRegister = async (data) => {
    // e.preventDefault();
    setValues({
      loading: true,
    });
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/agents/registerConnector`,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            name: data.firstname + ' ' + data.lastname,
            mobile: data.phone,
            email: data.email,
            city: data.city,
            pin: data.pin,
            panNumber: data.panno,
          }),
        }
      );

      if (response.status === 200) {
        const resdata = await response.json();
        setValues({
          loading: false,
        });

        MySwal.fire({
          text: `${resdata.message}`,
        });
        reset();
      } else if (response.status === 500) {
        setValues({
          loading: false,
        });
        MySwal.fire({
          text: 'Something went wrong',
        });
      }
    } catch (error) {
      setValues({
        loading: false,
      });
      MySwal.fire({
        text: 'Something went wrong',
      });
    }
  };

  const showLoading = () =>
    loading && (
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    );
  return (
    <>
      <div className="form_sec_in">
        <strong>
          Earning Opportunity for All Existing Agents with Anex Finserv Loan
          Partner Program
        </strong>
        <form action id="prtnrform" onSubmit={handleSubmit(clickRegister)}>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-12">
              <div className="form_sec">
                <input
                  type="text"
                  id="fname"
                  name="fname"
                  placeholder="First Name"
                  {...register('firstname', {
                    required: 'Firstname is required',
                    maxLength: {
                      value: 50,
                      message: 'Maximum 50 characters',
                    },
                  })}
                  onKeyUp={() => {
                    trigger('firstname');
                  }}
                  onKeyDown={(e) => checkIfTextOnly(e)}
                />
              </div>
              {errors.firstname && (
                <p className="text-danger">{errors.firstname.message}</p>
              )}
            </div>
            <div className="col-lg-6 col-md-6 col-12">
              <div className="form_sec">
                <input
                  type="text"
                  id="lname"
                  name="lname"
                  placeholder="Last Name"
                  {...register('lastname')}
                  onKeyDown={(e) => checkIfTextOnly(e)}
                />
              </div>
              {errors.lastname && (
                <p className="text-danger">{errors.lastname.message}</p>
              )}
            </div>
            <div className="col-lg-6 col-md-6 col-12">
              <div className="form_sec">
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  placeholder="Phone No"
                  {...register('phone', {
                    required: 'Phone no is required',
                    pattern: {
                      value: /^[0-9]{10}$/,
                      message: 'Please enter 10 digit phone no',
                    },
                  })}
                  onKeyUp={() => {
                    trigger('phone');
                  }}
                  onKeyDown={(e) => checkIfNumber(e)}
                />
              </div>
              {errors.phone && (
                <p className="text-danger">{errors.phone.message}</p>
              )}
            </div>
            <div className="col-lg-6 col-md-6 col-12">
              <div className="form_sec">
                <input
                  type="email"
                  id="mail"
                  name="email"
                  placeholder="Email"
                  {...register('email', {
                    required: 'Email is required',
                    pattern: {
                      value:
                        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      message: 'Please enter a valid email',
                    },
                  })}
                  onKeyUp={() => {
                    trigger('email');
                  }}
                />
              </div>
              {errors.email && (
                <p className="text-danger">{errors.email.message}</p>
              )}
            </div>
            <div className="col-lg-6 col-md-6 col-12">
              <div className="form_sec">
                <input
                  type="text"
                  id="city"
                  name="city"
                  placeholder="City/Area"
                  {...register('city', {
                    required: 'City is required',
                  })}
                  onKeyUp={() => {
                    trigger('city');
                  }}
                />
              </div>
              {errors.city && (
                <p className="text-danger">{errors.city.message}</p>
              )}
            </div>
            <div className="col-lg-6 col-md-6 col-12">
              <div className="form_sec">
                <input
                  type="text"
                  id="pinc"
                  name="pinc"
                  placeholder="PIN Code"
                  {...register('pin', {
                    required: 'Pin code is required',
                    pattern: {
                      value: /^[1-9][0-9]{5}$/,
                      message: 'Pin code sould be 6 digit',
                    },
                  })}
                  onKeyUp={() => {
                    trigger('pin');
                  }}
                  onKeyDown={(e) => checkIfNumber(e)}
                />
              </div>
              {errors.pin && (
                <p className="text-danger">{errors.pin.message}</p>
              )}
            </div>

            <div className="col-lg-12 col-md-12 col-12">
              <div className="form_sec">
                <input
                  type="text"
                  id="pannum"
                  name="pan"
                  placeholder="PAN Number"
                  {...register('panno', {
                    required: 'Pan number is required',
                  })}
                  onKeyUp={() => {
                    trigger('panno');
                  }}
                />
              </div>
              {errors.panno && (
                <p className="text-danger">{errors.panno.message}</p>
              )}
            </div>
          </div>
          <div className="col-lg-12 col-md-12 col-12">
            <div className="acpt_sec">
              By clicking on below button I agree and confirm that I have read{' '}
              <Link data-toggle="modal" data-target="#modal_terms_condition">
                terms &amp; conditions
              </Link>
            </div>
          </div>
          {errors.registercheck && (
            <p className="text-danger">{errors.registercheck.message}</p>
          )}
          {/* <!-- Modal --> */}
          <div
            className="modal fade"
            id="modal_terms_condition"
            data-backdrop="static"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="staticBackdropLabel">
                    Terms And Conditions
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body m-0 p-0">
                  <p>
                    Please understand the following terms and conditions <br />{' '}
                    1. Commision payout amount will depend on various condition
                    like Loan Type,Processing Bank, approveed interest rate etc.{' '}
                    <br /> 2. Payout typically creited to bank account within
                    10th day of each month but can be delayed occationaly.{' '}
                    <br /> 3. Final Payout amount will be notified only after
                    Loan Amount disbursed to Customer's Account. <br /> 4. In
                    case of any query/clarification you need to notify within 15
                    Calendar Days.
                  </p>
                </div>
                <div className="modal-footer">
                  <input
                    type="checkbox"
                    className="checkbox-size text-success"
                    id="registercheck"
                    {...register('registercheck', {
                      required: 'Please accept the terms and conditions',
                    })}
                  />
                  <button
                    type="button"
                    data-dismiss="modal"
                    aria-label="Close"
                    className="btn btn-primary"
                    disabled={watch().registercheck ? false : true}
                  >
                    Accept
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-12 col-md-12 col-12">
            {loading === true ? (
              <>{showLoading()}</>
            ) : (
              <>
                <div className="form_btn">
                  {' '}
                  <input type="submit" defaultValue="Become A Partner" />{' '}
                </div>
              </>
            )}
          </div>
        </form>
      </div>
    </>
  );
};

export default LoanRef;

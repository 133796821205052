import React from 'react';
import { NavLink, Link, withRouter } from 'react-router-dom';

const HomeHeader = ({ history }) => {
  const signout = () => {
    if (localStorage.getItem('username')) {
      localStorage.removeItem('userType');
      localStorage.removeItem('username');
      localStorage.removeItem('loggedInAgent');
      localStorage.removeItem('loggedInEmployee');
      localStorage.removeItem('jwt');
      localStorage.removeItem('LoginMsg');
      history.push('/');
    }
  };
  return (
    <>
      {' '}
      <header className="header">
        <div className="header_top">
          <div className="container-fluid">
            <div className="row">
              {/*  <div className="col-lg-3 col-md-3 col-12 pl-lg-0">
                <div className="call_back">
                  <a href="#">Request a Callback</a>
                </div>
              </div>*/}
              <div className="col-lg-3 col-md-3 col-12">
                <div className="top_menu">
                  <NavLink exact to="/">
                    Home
                  </NavLink>
                  <NavLink exact to="/loan-referral-program">
                    Partner with Us
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>

        <nav class="navbar  navbar-expand-lg navbar-light bg-light pt-3 pb-3 home-header-position">
          <Link className="navbar-brand logo " to="/">
            Anex Finserv
          </Link>
          <button
            class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarNavDropdown">
            <ul class="navbar-nav">
              <li class="nav-item">
                <NavLink
                  exact
                  activeClassName="navlink"
                  className="nav-link"
                  to="/initiateLead"
                >
                  Initiate Loan Lead{' '}
                </NavLink>
              </li>

              <li class="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Loans
                </a>
                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <NavLink exact className="dropdown-item" to="/personal-loan">
                    Personal Loan
                  </NavLink>

                  <NavLink exact className="dropdown-item" to="/home-loan">
                    Home Loan
                  </NavLink>
                  <NavLink exact className="dropdown-item" to="/business-loan">
                    Business Loan
                  </NavLink>
                </div>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#">
                  Current Offer
                </a>
              </li>
              <li class="nav-item active">
                <a class="nav-link" href="#">
                  About Us
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#">
                  Help & Contact
                </a>
              </li>
            </ul>

            <ul class="navbar-nav ml-auto">
              {localStorage.getItem('username') ? (
                <>
                  <li className="nav-item dropdown mr-5 ">
                    <a
                      className="nav-link dropdown-toggle"
                      href="#"
                      id="navbarDropdown"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i className="fa-solid fa-user"></i>{' '}
                      {localStorage.getItem('username')}
                    </a>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="navbarDropdown"
                    >
                      <NavLink
                        exact
                        activeClassName="navlink"
                        className="dropdown-item"
                        to="/viewProfile"
                      >
                        Profile
                      </NavLink>
                      <li>
                        <span
                          style={{ cursor: 'pointer' }}
                          className="dropdown-item"
                          onClick={() => signout()}
                        >
                          Logout
                        </span>
                      </li>
                    </div>
                  </li>
                </>
              ) : (
                <>
                  <li class="nav-item ">
                    <NavLink
                      exact
                      class="nav-link btn-login-reg mr-2"
                      to="/login"
                    >
                      Login
                    </NavLink>
                  </li>
                  <li class="nav-item">
                    <NavLink
                      exact
                      class="nav-link btn-login-reg mr-2"
                      to="/register"
                    >
                      Register
                    </NavLink>
                  </li>
                </>
              )}
            </ul>
          </div>
        </nav>
      </header>
    </>
  );
};

export default withRouter(HomeHeader);

import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Layout from '../components/Layout';
import ClosedCustomerDocumentsDetails from '../components/ClosedCustomerDocumentsDetails';
import ClosedCustomerPersonalDetails from '../components/ClosedCustomerPersonalDetails';

const ClosedLoans = () => {
  const [allAssignedLoans, setAllAssignedLoans] = useState([]);
  const [accordion, setActiveAccordion] = useState(-1);
  const [changeStatus, setChangeStatus] = useState([]);
  const [loading, setLoading] = useState(false);
  const [submitcurrentstatus, setSubmitcurrentstatus] = useState();
  const [oldstatus, setOldstatus] = useState();
  const [validateData, setValidateData] = useState([]);

  const [searchV, setSearchV] = useState({
    agentId: '',
    loanType: '',
    assignedByEmployeeId: '',
    assignedToEmployeeId: '',
    startDate: '',
    endDate: '',
    sStatus: '',
    searchLoanLead: [],
    searchError: false,
  });

  const {
    agentId,
    loanType,
    startDate,
    endDate,
    sStatus,
    searchLoanLead,
    assignedToEmployeeId,
    assignedByEmployeeId,
    searchError,
  } = searchV;

  const history = useHistory();

  const toggleAccordion = (index) => {
    if (index === accordion) {
      setActiveAccordion(-1);
      return;
    }
    setActiveAccordion(index);
  };

  useEffect(() => {
    if (localStorage.getItem('userType') === null) {
      history.push('/');
    } else if (localStorage.getItem('userType') === 'Employee') {
      setLoading(true);
      const getAllAssignedLoans = async () => {
        await fetch(`${process.env.REACT_APP_API_URL}/getAllClosedLoans`, {
          method: 'GET',
          headers: {
            Accept: 'application/json',
          },
        })
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            setLoading(false);
            setAllAssignedLoans(data);
          });
      };
      getAllAssignedLoans();
    } else {
      history.push('/');
    }
  }, [submitcurrentstatus]);

  const showLoading = () =>
    loading && (
      <div className="alert ">
        <h2>Loading...</h2>
      </div>
    );

  const searchHandleChange = (name) => (e) => {
    setSearchV({
      ...searchV,
      [name]: e.target.value,
      searchLoanLead: '',
    });
  };

  const searchSubmit = (e) => {
    e.preventDefault();

    const fetchdata = async () => {
      setLoading(true);
      const startD = moment(startDate).format('DD-MM-YYYY');
      const endD = moment(endDate).format('DD-MM-YYYY');

      try {
        await fetch(
          `${process.env.REACT_APP_API_URL}/findAssignedLoansByFilter?agentId=${agentId}&assignedByEmployeeId=${assignedByEmployeeId}&assignedToEmployeeId=${assignedToEmployeeId}&endDate=${endD}&loanStatus=${sStatus}&loanType=${loanType}&startDate=${startD}`,
          {
            method: 'GET',
            headers: {
              Accept: 'application/json',
            },
          }
        )
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            if (data.length === 0) {
              setSearchV({ ...searchV, searchError: true });
              setLoading(false);
            } else {
              setSearchV({
                ...searchV,
                searchLoanLead: data,
                searchError: false,
              });
              setLoading(false);
            }
          });
      } catch (error) {
        setSearchV({
          ...searchV,
          searchError: true,
        });
        setLoading(false);
      }
    };
    fetchdata();
  };

  const changeStatusFun = (value) => {
    var newData = [...changeStatus];
    newData.push(value);
    setChangeStatus(newData);
    setSubmitcurrentstatus();
    setOldstatus();
  };
  const MySwal = withReactContent(Swal);

  return (
    <>
      <Layout>
        <div className="inner_banner text-center">
          <div className="container">Closed Loans</div>
        </div>
        <div className="container-fluid">
          <ToastContainer />
          <form onSubmit={searchSubmit} className="mt-4">
            <div className="row justify-content-center">
              <div className="col-lg">
                <label for="inputEmail4">Agent Id</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Id"
                  onChange={searchHandleChange('agentId')}
                  value={agentId}
                />
              </div>

              <div className="col-lg">
                <label for="inputEmail4">Loan Type</label>
                <select
                  id="loantype"
                  className="form-control"
                  onChange={searchHandleChange('loanType')}
                  value={loanType}
                >
                  <option value="">Select</option>
                  <option value="PERSONAL LOAN">Personal loan</option>
                  <option value="HOME LOAN">Home loan</option>
                  <option value="BUSINESS LOAN">Business loan</option>
                  <option value="PERSONAL LOAN EXTENSION">
                    Personal loan extension
                  </option>
                </select>
              </div>
              <div className="col-lg">
                <label for="inputEmail4">Start Date</label>
                <input
                  type="date"
                  className="form-control"
                  onChange={searchHandleChange('startDate')}
                  value={startDate}
                  required
                />
              </div>
              <div className="col-lg">
                <label for="inputEmail4">End Date</label>
                <input
                  type="date"
                  className="form-control"
                  onChange={searchHandleChange('endDate')}
                  value={endDate}
                  required
                />
              </div>
              <div className="col-lg">
                <label for="inputEmail4">Bank Name</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Status"
                  onChange={searchHandleChange('bankName')}
                />
              </div>
              <div className="col-lg-1 pt-sm-2">
                <button type="submit" className="btn btn-primary mt-4">
                  Search
                </button>
              </div>
            </div>
          </form>

          <br />
          {searchLoanLead.length > 0 || searchError ? (
            <>
              <button
                className="btn btn-light mb-4"
                onClick={() =>
                  setSearchV({
                    ...searchV,
                    searchLoanLead: [],
                    agentId: '',
                    loanType: '',
                    startDate: '',
                    endDate: '',
                    assignedByEmployeeId: '',
                    assignedToEmployeeId: '',
                    sStatus: '',
                    searchError: false,
                  })
                }
              >
                <i class="fa-solid fa-arrow-left"></i> Go Back
              </button>
            </>
          ) : (
            <></>
          )}

          <div>
            <table class="table table-striped table-borderless table-responsive mt-4 mb-0">
              <thead className=" text-center">
                <tr>
                  <th scope="col" className="current-assigned-th pr-2">
                    Application ID
                  </th>
                  <th scope="col" className="current-assigned-th pr-2">
                    Customer Name
                  </th>
                  <th scope="col" className="current-assigned-th pr-2">
                    Customer Mobile No
                  </th>
                  <th scope="col" className="current-assigned-th pr-2">
                    Agent Name
                  </th>
                  <th scope="col" className="current-assigned-th pr-2">
                    Agent Mobile No
                  </th>
                  <th scope="col" className="current-assigned-th pr-2">
                    Loan Type
                  </th>
                  <th scope="col" className="current-assigned-th pr-2">
                    Loan Amount
                  </th>

                  <th scope="col" className="current-assigned-th pr-2">
                    View Details
                  </th>
                </tr>
              </thead>
              <tbody>
                <br />
                {showLoading()}

                {searchError && (
                  <>
                    <tr>
                      {' '}
                      <td colSpan="100">
                        <p className="text-center  m-0 text-danger">
                          No Result Found
                        </p>
                      </td>
                    </tr>
                  </>
                )}
                {searchLoanLead.length > 0 ? (
                  <>
                    {' '}
                    {searchLoanLead.map((loan, index) => (
                      <React.Fragment key={index}>
                        <tr>
                          <td>{loan.loanInformation.applicationId}</td>
                          <td> {loan.loanInformation.customerName}</td>
                          <td> {loan.loanInformation.customerMobileNumber}</td>
                          <td>{loan.loanInformation.agentName}</td>
                          <td>{loan.loanInformation.agentMobileNumber}</td>
                          <td>{loan.loanInformation.loanType}</td>
                          <td>{loan.loanInformation.loanAmountAppliedFor}</td>

                          <td>
                            <button
                              className="btn-success btn btn-sm rounded-0 px-2"
                              onClick={() => toggleAccordion(index)}
                            >
                              {accordion === index ? (
                                <>
                                  {' '}
                                  <i className="fa-solid fa-minus"></i>
                                </>
                              ) : (
                                <>
                                  {' '}
                                  {/* <i className="fa-solid fa-plus"></i> */}
                                  View
                                </>
                              )}
                            </button>
                          </td>
                        </tr>

                        <tr
                          className={
                            accordion === index
                              ? 'active-accordion'
                              : 'inactive-accordion'
                          }
                        >
                          <td colspan="4">
                            <div>
                              <div className="row">
                                <div className="col-lg-12">
                                  <button
                                    data-toggle="modal"
                                    data-target={`#customerPersonalDetail${loan.loanInformation.id}`}
                                    className="btn btn-primary mr-1 px-4"
                                    hidden={
                                      changeStatus
                                        .filter(
                                          (data) =>
                                            data.id ===
                                            loan.loanInformation.loanLeadId
                                        )
                                        .map((data) => data.value)
                                        .pop() === 'Select/Update Loan Status'
                                        ? true
                                        : false
                                    }
                                  >
                                    {loan.loanInformation.status &&
                                    changeStatus.filter(
                                      (data) =>
                                        data.id ===
                                        loan.loanInformation.loanLeadId
                                    ).length === 0 ? (
                                      <>
                                        {loan.loanInformation.status ===
                                        'Assigned' ? (
                                          <>Customer Personal Details</>
                                        ) : (
                                          loan.loanInformation.status
                                        )}
                                      </>
                                    ) : changeStatus
                                        .filter(
                                          (data) =>
                                            data.id ===
                                            loan.loanInformation.loanLeadId
                                        )
                                        .map((data) => data.value)
                                        .pop() === 'Assigned' ? (
                                      <>
                                        <>Customer Personal Details</>
                                      </>
                                    ) : (
                                      <>
                                        {changeStatus
                                          .filter(
                                            (data) =>
                                              data.id ===
                                              loan.loanInformation.loanLeadId
                                          )
                                          .map((data) => <>{data.value}</>)
                                          .pop()}
                                      </>
                                    )}
                                  </button>
                                </div>
                                <div className="col-lg-6"></div>
                              </div>

                              {/* <!-- Large modal --> */}

                              <div
                                className="modal fade cus-perosonal-details"
                                id={`customerPersonalDetail${loan.loanInformation.id}`}
                                data-backdrop="static"
                                tabindex="-1"
                                role="dialog"
                                aria-labelledby="staticBackdropLabel"
                                aria-hidden="true"
                              >
                                <ClosedCustomerPersonalDetails
                                  CPersonalDetails={loan}
                                  ChangeStatusValue={
                                    changeStatus.filter(
                                      (data) =>
                                        data.id ===
                                        loan.loanInformation.loanLeadId
                                    ) &&
                                    changeStatus
                                      .filter(
                                        (data) =>
                                          data.id ===
                                          loan.loanInformation.loanLeadId
                                      )
                                      .map((data) => data.value)
                                      .pop()
                                      ? changeStatus
                                          .filter(
                                            (data) =>
                                              data.id ===
                                              loan.loanInformation.loanLeadId
                                          )
                                          .map((data) => data.value)
                                          .pop()
                                      : loan.loanInformation.status
                                  }
                                />
                              </div>
                            </div>
                          </td>

                          <td colspan="4">
                            <div>
                              <div className="row">
                                <div className="col-lg-12">
                                  {changeStatus
                                    .filter(
                                      (data) =>
                                        data.id ===
                                        loan.loanInformation.loanLeadId
                                    )
                                    .map((data) => data.value)
                                    .pop() === 'Bank Approved' ||
                                  changeStatus
                                    .filter(
                                      (data) =>
                                        data.id ===
                                        loan.loanInformation.loanLeadId
                                    )
                                    .map((data) => data.value)
                                    .pop() === 'Bank Rejected' ||
                                  changeStatus
                                    .filter(
                                      (data) =>
                                        data.id ===
                                        loan.loanInformation.loanLeadId
                                    )
                                    .map((data) => data.value)
                                    .pop() === 'Disbursed' ||
                                  changeStatus
                                    .filter(
                                      (data) =>
                                        data.id ===
                                        loan.loanInformation.loanLeadId
                                    )
                                    .map((data) => data.value)
                                    .pop() === 'Agreement Signed' ? (
                                    <>
                                      <button
                                        className="btn btn-primary mr-1 px-4"
                                        data-toggle="modal"
                                        data-target={`#DocStaticId${loan.loanInformation.id}`}
                                      >
                                        Support Document Details
                                      </button>
                                    </>
                                  ) : changeStatus
                                      .filter(
                                        (data) =>
                                          data.id ===
                                          loan.loanInformation.loanLeadId
                                      )
                                      .map((data) => data.value)
                                      .pop() === 'Assigned' ? (
                                    <>
                                      {' '}
                                      <button
                                        className="btn btn-primary mr-1 px-4"
                                        data-toggle="modal"
                                        data-target={`#DocStaticId${loan.loanInformation.id}`}
                                      >
                                        Customer Document Details
                                      </button>
                                    </>
                                  ) : loan.loanInformation.status ===
                                      'Assigned' &&
                                    changeStatus.filter(
                                      (data) =>
                                        data.id ===
                                        loan.loanInformation.loanLeadId
                                    ).length === 0 ? (
                                    <>
                                      {' '}
                                      <button
                                        className="btn btn-primary mr-1 px-4"
                                        data-toggle="modal"
                                        data-target={`#DocStaticId${loan.loanInformation.id}`}
                                      >
                                        Customer Document Details
                                      </button>
                                    </>
                                  ) : (loan.loanInformation.status ===
                                      'Bank Approved' ||
                                      loan.loanInformation.status ===
                                        'Bank Rejected' ||
                                      loan.loanInformation.status ===
                                        'Disbursed' ||
                                      loan.loanInformation.status ===
                                        'Agreement Signed') &&
                                    changeStatus.filter(
                                      (data) =>
                                        data.id ===
                                        loan.loanInformation.loanLeadId
                                    ).length === 0 ? (
                                    <>
                                      {' '}
                                      <button
                                        className="btn btn-primary mr-1 px-4"
                                        data-toggle="modal"
                                        data-target={`#DocStaticId${loan.loanInformation.id}`}
                                      >
                                        Support Document Details
                                      </button>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                                <div className="col-lg-6"></div>
                              </div>

                              {/* <!-- Modal --> */}
                              <div
                                class="modal fade"
                                id={`DocStaticId${loan.loanInformation.id}`}
                                data-backdrop="static"
                                tabindex="-1"
                                role="dialog"
                                aria-labelledby="staticBackdropLabel"
                                aria-hidden="true"
                              >
                                <ClosedCustomerDocumentsDetails
                                  loanDetailsData={loan}
                                  loanDetails={loan}
                                  loanDocumentList={loan.loanDocumentList}
                                  ChangeStatusValue={
                                    changeStatus.filter(
                                      (data) =>
                                        data.id ===
                                        loan.loanInformation.loanLeadId
                                    ) &&
                                    changeStatus
                                      .filter(
                                        (data) =>
                                          data.id ===
                                          loan.loanInformation.loanLeadId
                                      )
                                      .map((data) => data.value)
                                      .pop()
                                      ? changeStatus
                                          .filter(
                                            (data) =>
                                              data.id ===
                                              loan.loanInformation.loanLeadId
                                          )
                                          .map((data) => data.value)
                                          .pop()
                                      : loan.loanInformation.status
                                  }
                                />
                              </div>
                            </div>
                          </td>
                        </tr>
                        <br />
                      </React.Fragment>
                    ))}
                  </>
                ) : (
                  <>
                    {searchError === false &&
                      allAssignedLoans.map((loan, index) => (
                        <React.Fragment key={index}>
                          <tr>
                            <td>
                              <div class="form-group form-check d-inline"></div>
                              {loan.applicationId}
                            </td>
                            <td> {loan.customerName}</td>
                            <td> {loan.customerMobileNumber}</td>
                            <td>{loan.agentName}</td>
                            <td>{loan.agentMobileNumber}</td>
                            <td>{loan.loanType}</td>
                            <td>{loan.loanAmountAppliedFor}</td>

                            <td>
                              <button
                                className="btn-success btn btn-sm rounded-0 px-2"
                                onClick={() => toggleAccordion(index)}
                              >
                                {accordion === index ? (
                                  <>
                                    {' '}
                                    <i className="fa-solid fa-minus"></i>
                                  </>
                                ) : (
                                  <>
                                    {' '}
                                    {/* <i className="fa-solid fa-plus"></i> */}
                                    View
                                  </>
                                )}
                              </button>
                            </td>
                          </tr>

                          <tr
                            className={
                              accordion === index
                                ? 'active-accordion'
                                : 'inactive-accordion'
                            }
                          >
                            <td colspan="4">
                              <div className="row">
                                <div className="col-lg-12">
                                  <button
                                    data-toggle="modal"
                                    data-target={`#customerPersonalDetails${loan.id}`}
                                    className="btn btn-primary mr-1 px-4 text-capitalize"
                                  >
                                    <>Customer Personal Details</>
                                  </button>
                                </div>
                                <div className="col-lg-6"></div>
                              </div>

                              {/* <!-- Large modal --> */}

                              <div
                                className="modal fade cus-perosonal-details"
                                id={`customerPersonalDetails${loan.id}`}
                                data-backdrop="static"
                                tabindex="-1"
                                role="dialog"
                                aria-labelledby="staticBackdropLabel"
                                aria-hidden="true"
                              >
                                <div
                                  className="modal-dialog modal-lg"
                                  role="document"
                                >
                                  <ClosedCustomerPersonalDetails
                                    CPersonalDetails={loan}
                                    ChangeStatusValue="Assigned"
                                  />
                                </div>
                              </div>
                            </td>

                            <td colspan="4">
                              <div>
                                <div className="row">
                                  <div className="col-lg-12">
                                    <button
                                      className="btn btn-primary mr-1 px-4"
                                      data-toggle="modal"
                                      data-target={`#staticBackdrop${loan.id}`}
                                    >
                                      Customer Document Details
                                    </button>
                                  </div>
                                  <div className="col-lg-6"></div>
                                </div>

                                {/* <!-- Modal --> */}
                                <div
                                  class="modal fade"
                                  id={`staticBackdrop${loan.id}`}
                                  data-backdrop="static"
                                  tabindex="-1"
                                  role="dialog"
                                  aria-labelledby="staticBackdropLabel"
                                  aria-hidden="true"
                                >
                                  <ClosedCustomerDocumentsDetails
                                    loanDetailsData={loan}
                                    loanDetails={loan}
                                    loanDocumentList={loan.loanDocumentList}
                                    ChangeStatusValue="Assigned"
                                  />
                                </div>
                              </div>
                            </td>
                          </tr>
                          <br />
                        </React.Fragment>
                      ))}
                  </>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default ClosedLoans;

import './App.css';
import HomeScreen from './screens/HomeScreen';
import { Route, Switch } from 'react-router-dom';
import Register from './screens/Register';
import TermsConditions from './screens/TermsConditions';
import InitiateLoanLead from './screens/InitiateLoanLead';
import Login from './screens/Login';
import AgentPayout from './screens/AgentPayout';
import Profile from './screens/Profile';
import AgentInvoice from './screens/AgentInvoice';
import ViewAssignedLoans from './screens/ViewAssignedLoans';
import ReviewLeadAndAssign from './screens/ReviewLeadAndAssign';
import ForgotPassword from './screens/ForgotPassword';
import ValidateOtp from './screens/ValidateOtp';
import UserProfileActivation from './screens/UserProfileActivation.jsx';
import EditProfile from './screens/EditProfile';
import closedLoans from './screens/closedLoans';
import ViewPayoutAgent from './components/ViewPayoutAgent';
import LoanPayoutView from './components/LoanPayoutView';
import Home from './screens/Home';
import LoanReferralProgram from './screens/LoanReferralProgram';
import AgentDashboard from './screens/AgentDashboard.jsx';
import PayoutWithdraw from './screens/PayoutWithdraw.jsx';
import ProcessPayout from './screens/ProcessPayout.jsx';
import PersonalLoan from './screens/PersonalLoan.jsx';
import HomeLoan from './screens/HomeLoan.jsx';
import BusinessLoan from './screens/BusinessLoan.jsx';
import NotFound from './screens/NotFound.jsx';
import ForgotPasswordNew from './screens/ForgotPasswordNew.jsx';

function App() {
  return (
    <div className="App">
      <Switch>
        {/* <Route path="/" exact component={HomeScreen} /> */}
        <Route path="/" exact component={Home} />
        <Route path="/loan-referral-program" component={LoanReferralProgram} />
        <Route path="/register" component={Register} />
        <Route path="/login" component={Login} />
        <Route path="/terms-conditions" component={TermsConditions} />
        <Route path="/initiateLead" component={InitiateLoanLead} />
        <Route path="/reviewLead" component={ReviewLeadAndAssign} />
        <Route path="/loan-under-proccess" component={ViewAssignedLoans} />
        <Route path="/viewAgentPayout" component={AgentPayout} />
        <Route path="/viewProfile" component={Profile} />
        <Route path="/agent-invoice" component={AgentInvoice} />
        {/* <Route path="/forgot-password" component={ForgotPassword} /> */}
        <Route path="/forgot-password" component={ForgotPasswordNew} />
        <Route path="/validateotp" component={ValidateOtp} />
        <Route path="/closedLoans" component={closedLoans} />
        <Route path="/agent-payout-view" component={ViewPayoutAgent} />
        <Route path="/loan-payout-view" component={LoanPayoutView} />
        <Route
          path="/user-profile-activation/username/:email"
          component={UserProfileActivation}
        />
        <Route path="/editEmployeeProfile" component={EditProfile} />
        <Route path="/editAgentProfile" component={EditProfile} />
        <Route path="/agentDashboard" component={AgentDashboard} />
        <Route path="/payout-withdraw" component={PayoutWithdraw} />
        <Route path="/process-payout" component={ProcessPayout} />
        <Route path="/personal-loan" component={PersonalLoan} />
        <Route path="/home-loan" component={HomeLoan} />
        <Route path="/business-loan" component={BusinessLoan} />
        <Route path="*" component={NotFound} />
      </Switch>
    </div>
  );
}

export default App;

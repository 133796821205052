import '../App.css';
import { Link } from 'react-router-dom';

const NotFound = () => {
  return (
    <div>
      <div className="container ">
        <div className="center-xy bg-052063 rounded-lg text-white">
          <h2>404, page not found.</h2>
          <div className="text-center mt-4">
            <Link to="/">
              <button className="btn btn-warning font-weight-medium animate__animated animate__backInDown animate__slow">
                Go to Home
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFound;

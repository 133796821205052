import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Layout from '../components/Layout';

const Profile = ({ history }) => {
  const [userDetails, setUserDetails] = useState(null);
  const [userData, setUserData] = useState({});

  useEffect(() => {
    if (localStorage.getItem('userType') === null) {
      history.push('/');
    }

    if (localStorage.getItem('userType') === 'Agent') {
      const jwt = JSON.parse(localStorage.getItem('jwt'));
      setUserDetails(JSON.parse(localStorage.getItem('loggedInAgent')));
      fetch(
        `${process.env.REACT_APP_API_URL}/agents/profileView/${
          JSON.parse(localStorage.getItem('loggedInAgent')).agentId
        }`,
        {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${jwt}`,
          },
        }
      )
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          setUserData(data);
        });
    } else {
      setUserDetails(JSON.parse(localStorage.getItem('loggedInEmployee')));
      setUserData(JSON.parse(localStorage.getItem('loggedInEmployee')));
    }
  }, []);

  return (
    <>
      <Layout>
        <div className="inner_banner text-center">
          <div className="container">Profile</div>
        </div>
        <div className="container mb-3">
          <div className="row">
            <div className="col-lg-4">
              <div className="card shadow">
                <div className="card-body">
                  <div className="text-center mb-2">
                    {userData.agentProfileImage !== undefined ? (
                      <img
                        src={`data:image/png;base64, ${userData.agentProfileImage}`}
                        className="rounded-circle shadow image-fit-profile "
                        width={500}
                        height={500}
                        alt="profile"
                      />
                    ) : (
                      <>
                        <img
                          src="https://img.icons8.com/external-anggara-outline-color-anggara-putra/100/000000/external-avatar-interface-anggara-outline-color-anggara-putra.png"
                          alt="..."
                          className="img-thumbnail border-0"
                        />
                      </>
                    )}
                  </div>
                  {localStorage.getItem('userType') === 'Agent' ? (
                    <>
                      <div className="text-center">
                        <Link
                          to="/editAgentProfile"
                          className="shadow-sm btn btn-primary rounded-0 "
                        >
                          Edit Profile
                        </Link>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="text-center">
                        <Link
                          to="/editEmployeeProfile"
                          className="shadow-sm btn btn-primary rounded-0 "
                        >
                          Edit Profile
                        </Link>
                      </div>
                    </>
                  )}

                  <p className="mt-3">
                    Full Name: {userData && userData.firstName}{' '}
                    {userData && userData.lastName}
                  </p>
                  <p>Email: {userData && userData.email} </p>
                  <p>DOB: {userData && userData.dob}</p>
                  <p>Mobile: {userData && userData.mobile}</p>
                  <p>Gender: {userData && userData.gender}</p>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="card shadow">
                <div className="card-body">
                  <h4>General Information</h4>
                  <hr />
                  <p>
                    Address: &nbsp; {userData && userData.address1},{' '}
                    {userData && userData.address2}
                  </p>
                  <p>City: &nbsp; {userData && userData.city}</p>
                  <p>Pin: &nbsp; {userData && userData.pin}</p>
                  <p>State: &nbsp; {userData && userData.state}</p>
                </div>
              </div>
            </div>
          </div>

          <br />
        </div>
      </Layout>
    </>
  );
};

export default Profile;

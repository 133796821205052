import React, { useEffect, useState } from 'react';
import Layout from '../components/Layout';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const AgentInvoice = ({ history }) => {
  const [loading, setLoading] = useState(false);
  const [searchV, setSearchV] = useState({
    agentId: '',
    month: '',
    year: '',
    searchInvoice: [],
    searchError: false,
  });
  const [years, setYears] = useState(null);
  const { agentId, month, year, searchInvoice, searchError } = searchV;

  const MySwal = withReactContent(Swal);
  const searchSubmit = (e) => {
    e.preventDefault();
    const jwt = JSON.parse(localStorage.getItem('jwt'));
    const fetchdata = async () => {
      setLoading(true);

      try {
        await fetch(
          `${
            process.env.REACT_APP_API_URL
          }/getAgentInvoiceByMonthAndYear/${agentId}?month=${month}&year=${years.getFullYear()}`,
          {
            method: 'GET',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              Authorization: `Bearer ${jwt}`,
            },
          }
        )
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            if (data.length === 0) {
              setSearchV({ ...searchV, searchError: true });
              setLoading(false);
            } else {
              setSearchV({
                ...searchV,
                searchInvoice: data,
                searchError: false,
              });
              setLoading(false);
            }

            if (data.status === 401) {
              MySwal.fire({
                text: `Unauthorized Access.`,
              });
              history.push('/');
            } else if (data.status === 403) {
              MySwal.fire({
                text: `Access Restricted You don't have permission to view this link.`,
              });
              history.push('/');
            }
          });
      } catch (error) {
        setSearchV({
          ...searchV,
          searchInvoice: [],
          searchError: true,
        });
        setLoading(false);
      }
    };
    fetchdata();
  };

  const searchHandleChange = (name) => (e) => {
    setSearchV({
      ...searchV,
      [name]: e.target.value,
      searchInvoice: '',
    });
  };

  useEffect(() => {
    if (localStorage.getItem('userType') === null) {
      history.push('/');
    } else if (
      localStorage.getItem('userType') === 'Agent' ||
      localStorage.getItem('userType') === 'Employee'
    ) {
      if (localStorage.getItem('userType') === 'Agent') {
        var agent = JSON.parse(localStorage.getItem('loggedInAgent')).agentId;
        setSearchV({
          ...searchV,
          agentId: agent,
        });
      } else {
        setSearchV({
          ...searchV,
          agentId: '',
        });
      }
    } else {
      history.push('/');
    }
  }, []);

  const showLoading = () =>
    loading && (
      <div className="alert ">
        <h2>Loading...</h2>
      </div>
    );

  return (
    <>
      <Layout>
        <div className="inner_banner text-center">
          <div className="container">Agent Invoice</div>
        </div>
        <div className="container">
          <form onSubmit={searchSubmit} className="mt-4">
            <div className="row justify-content-center">
              {localStorage.getItem('userType') === 'Employee' && (
                <div className="col-lg-3">
                  <label for="inputEmail4">Agent Id</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Id"
                    onChange={searchHandleChange('agentId')}
                    value={agentId}
                    required
                  />
                </div>
              )}
              <div className="col-lg-3">
                <label for="inputEmail4">Month</label>

                <select
                  className="form-control"
                  onChange={searchHandleChange('month')}
                  required
                >
                  <option selected value="">
                    Select Month
                  </option>
                  <option value="1">Janaury</option>
                  <option value="2">February</option>
                  <option value="3">March</option>
                  <option value="4">April</option>
                  <option value="5">May</option>
                  <option value="6">June</option>
                  <option value="7">July</option>
                  <option value="8">August</option>
                  <option value="9">September</option>
                  <option value="10">October</option>
                  <option value="11">November</option>
                  <option value="12">December</option>
                </select>
              </div>
              <div className="col-lg-3">
                <label for="inputEmail4">Year</label>
                {/* <input
                  type="number"
                  className="form-control"
                  onChange={searchHandleChange('year')}
                  value={year}
                  placeholder="Year"
                  required
                /> */}

                <DatePicker
                  selected={years}
                  onChange={(date) => setYears(date)}
                  dateFormat="yyyy"
                  showYearPicker
                  className="form-control"
                  placeholderText="Year"
                  required
                />
              </div>

              <div className="col-lg-1 pt-sm-2">
                <button type="submit" className="btn btn-primary mt-4">
                  Search
                </button>
              </div>
            </div>
          </form>

          {searchInvoice.length === 0 ? (
            <> </>
          ) : (
            <>
              {' '}
              {showLoading()}
              <div className="invoice_outer">
                <div className="invoice_cont">
                  <div class="d-flex flex-row-reverse ">
                    <a
                      href={`${
                        process.env.REACT_APP_API_URL
                      }/downloadAgentInvoice/LoanAppAgentInvoice/${agentId}/${month}/${years.getFullYear()}`}
                      download
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button className="btn btn-success d-flex text-small">
                        <i class="fa-solid fa-file-pdf mt-1 mr-1"></i> Download
                      </button>
                    </a>
                  </div>
                  <div className="invoice_name">{searchInvoice.agentName}</div>
                  <div className="invoice_titl">Invoice</div>
                  <div className="date_sec">
                    <div className="date_lft">
                      <div className="date_no">
                        Date: <span>{searchInvoice.invoiceDate}</span>
                      </div>
                      <div className="date_no">
                        Broker Code: <span>{searchInvoice.agentCode}</span>
                      </div>
                    </div>
                    <div className="date_right">
                      <div className="date_no">
                        Invoice No: <span>{searchInvoice.invoiceNumber}</span>
                      </div>
                      <div className="date_no">
                        Place of Supply: <span>Delhi</span>
                      </div>
                    </div>
                  </div>
                  <div className="to_sec">
                    <span>To,</span>
                    <span>{searchInvoice.agentCompanyName}</span>
                    <span>{searchInvoice.agentBillingAddress}</span>
                  </div>
                  <div className="gst_sec">
                    <div className="date_no">
                      GST No: <span>{searchInvoice.gstNumber}</span>
                    </div>
                    <div className="date_no">
                      State: <span>{searchInvoice.state}</span>
                    </div>
                    <div className="date_no">
                      State Code: <span>{searchInvoice.stateCode}</span>
                    </div>
                  </div>
                  <div className="good_Serv">
                    <div className="nature_left">
                      <div className="nature_titl">
                        <p>NATURE OF GOODS / SERVICES</p>{' '}
                        <span>{searchInvoice.serviceName}</span>
                      </div>
                    </div>
                    <div className="nature_left">
                      <div className="nature_titl">
                        <p>HSN/SAC CODE</p>{' '}
                        <span>{searchInvoice.invoiceStateCode}</span>
                      </div>
                    </div>
                  </div>
                  <div style={{ overflowX: 'auto' }}>
                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="col">Customer Name</th>
                          <th scope="col">Bank</th>
                          <th scope="col">Loan Type</th>
                          <th scope="col">Loan A/c No</th>
                          <th scope="col">Disbursement Amount (Rs)</th>
                          <th scope="col">Disbursement Month</th>
                          <th scope="col">Gross Payout Amount (Rs.)</th>
                          <th scope="col">TDS Amount (Rs.)</th>
                          <th scope="col">Net Payout Amount (Rs.)</th>
                        </tr>
                      </thead>
                      <tbody>
                        {searchInvoice.invoiceCustomerList.map((record, i) => {
                          return (
                            <React.Fragment key={i}>
                              <tr>
                                <td>{record.customerName}</td>
                                <td>{record.loanProviderBankName}</td>
                                <td>{record.loanType}</td>
                                <td>{record.loanAccountNumber}</td>
                                <td>{record.disbursedAmount}</td>
                                <td>
                                  {moment(record.payoutDate).format(
                                    'DD-MM-YYYY'
                                  )}
                                </td>
                                <td>{record.payoutAmount}</td>
                                <td>{record.tds}</td>
                                <td>{record.netPayoutAmount}</td>
                              </tr>
                            </React.Fragment>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                  <div className="total_sec">
                    <div className="total_lft">
                      <p>IGST@18%</p>
                      <span>0</span>
                    </div>
                    <div className="total_lft">
                      <p>Total Net Amount</p>
                      <span>{searchInvoice.totalNetPayoutAmount}</span>
                    </div>
                  </div>
                  <div className="good_Serv">
                    <div className="nature_left">
                      <div className="nature_titl">
                        <p>Payee Name</p> <span>{searchInvoice.agentName}</span>
                      </div>
                    </div>
                    <div className="nature_left">
                      <div className="nature_titl">
                        <p>Bank Name</p>{' '}
                        <span>{searchInvoice.agentBankName}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          <>
            {searchError && (
              <>
                <div>
                  {' '}
                  <div colSpan="100">
                    <p className="text-center mt-5 text-danger">
                      No Result Found
                    </p>
                  </div>
                </div>
              </>
            )}
          </>
        </div>
        <div style={{ marginTop: '10rem' }}></div>
      </Layout>
    </>
  );
};

export default AgentInvoice;

import React, { useEffect, useState } from 'react';
import Layout from '../components/Layout';
import moment from 'moment';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const ProcessPayout = () => {
  const [LoggedInAgent, setLoggedInAgnet] = useState([]);
  const [TransitionHistory, setTransitionHistory] = useState(null);
  const [searchPayout, setsearchPayout] = useState({
    startDate: '',
    endDate: '',
    searchData: null,
  });

  const [payoutProcess, setPayoutProcess] = useState({
    upi: '',
    panNumber: '',
    transId: '',
  });

  const [supportdoc, setSupportdoc] = useState({
    supportfile: null,
    supportdocumentCategory: null,
    supportselectedDocumentType: null,
    supportformData: new FormData(),
    supportdocumentsAvailable: null,
    supporteditdocument: false,
    supportfileSize: 0,
  });

  const { startDate, endDate, searchData } = searchPayout;
  const { upi, panNumber, transId } = payoutProcess;

  const history = useHistory();
  const {
    supportfile,
    supportdocumentCategory,
    supportselectedDocumentType,
    supportformData,
    supportdocumentsAvailable,
    supporteditdocument,
    supportfileSize,
  } = supportdoc;

  const MySwal = withReactContent(Swal);

  useEffect(() => {
    const jwt = JSON.parse(localStorage.getItem('jwt'));

    const fetchdata = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/viewPendingPayoutWithdrawRequestList`,
          {
            method: 'GET',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              Authorization: `Bearer ${jwt}`,
            },
          }
        );
        // .then((res) => res.json())
        // .then((data) => {
        //   setLoggedInAgnet(data);
        // })

        if (response.status === 200) {
          setLoggedInAgnet(response.data);
        } else if (response.status === 401) {
          MySwal.fire({
            text: `Unauthorized Access.`,
          });
          history.push('/');
        } else if (response.status === 403) {
          MySwal.fire({
            text: `Access Restricted You don't have permission to view this link.`,
          });
          history.push('/');
        }
      } catch (error) {
        MySwal.fire({
          text: 'Something went wrong',
        });
      }
    };
    fetchdata();

    fetch(
      `${process.env.REACT_APP_API_URL}/viewPreviousProcessedTransactionHistory`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${jwt}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setTransitionHistory(data.processedPayoutWithdrawList);
      });
  }, []);

  const searchSubmit = (e) => {
    e.preventDefault();

    const fetchdata = async () => {
      const sDate = moment(startDate).format('DD-MM-YYYY');
      const eDate = moment(endDate).format('DD-MM-YYYY');
      try {
        await fetch(
          `${process.env.REACT_APP_API_URL}/viewPreviousProcessedTransactionHistory?endDate=${eDate}&startDate=${sDate}`,
          {
            method: 'GET',
            headers: {
              Accept: 'application/json',
            },
          }
        )
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            setsearchPayout({
              ...searchPayout,
              searchData: data.processedPayoutWithdrawList,
            });
          });
      } catch (error) {
        setsearchPayout({
          ...searchPayout,
          searchData: null,
        });
      }
    };
    fetchdata();
  };

  const searchHandleChange = (name) => (e) => {
    setsearchPayout({
      ...searchPayout,
      [name]: e.target.value,
    });
  };

  const payoutReqValue = (name) => (e) => {
    setPayoutProcess({
      ...payoutProcess,
      [name]: e.target.value,
    });
  };

  const onChangeSupportdoc = (e) => {
    const value = e.target.files[0];
    supportformData.set('file', value);

    setSupportdoc({
      ...supportdoc,
      supportfile: supportformData,
      supportselectedDocumentType: 'Support Document',
    });
  };

  const leadlog = (data) => {
    return (
      <div className="table-responsive">
        <table class="table table-bordered table-striped">
          <thead>
            <tr className="text-center font-weight-bold">
              <th scope="col">Payout Request by name </th>
              <th scope="col">Broker Code </th>
              <th scope="col">Pan Number </th>
              <th scope="col">UPI Id </th>
              <th scope="col">Requested Amount </th>
              <th scope="col">Payout Balance </th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {data.map((res, i) => {
              return (
                <>
                  <tr key={i}>
                    <td>{res.requesterAgentName}</td>
                    <td>{res.brokerCode}</td>
                    <td>{res.requesterPanNumber}</td>
                    <td>{res.upiNumber}</td>
                    <td>{res.requestedWithdrawAmount}</td>
                    <td>{res.netAmountToProcess}</td>
                    <td>
                      <button
                        className="btn btn-success"
                        data-toggle="modal"
                        data-target={`#payout${res.id}`}
                      >
                        Pay
                      </button>
                    </td>
                  </tr>

                  {/* Modal */}
                  <div
                    className="modal fade"
                    id={`payout${res.id}`}
                    data-backdrop="static"
                    tabindex="-1"
                    role="dialog"
                    aria-labelledby="staticBackdropLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog" role="document">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title" id="staticBackdropLabel">
                            Payout Request
                          </h5>
                          <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <form onSubmit={(e) => payoutReq(e, res)}>
                          {' '}
                          <div className="modal-body">
                            <div className="form-group row">
                              <label className="col-md-4 col-form-label">
                                {' '}
                                UPI ID
                              </label>
                              <div className="col-md">
                                <input
                                  type="text"
                                  disabled
                                  className="form-control"
                                  defaultValue={res.upiNumber}
                                  onChange={payoutReqValue('upi')}
                                />
                              </div>
                            </div>
                            <div className="form-group row">
                              <label className="col-md-4 col-form-label">
                                {' '}
                                Pan Number{' '}
                              </label>
                              <div className="col-md">
                                <input
                                  type="text"
                                  disabled
                                  className="form-control"
                                  defaultValue={res.requesterPanNumber}
                                  onChange={payoutReqValue('panNumber')}
                                />
                              </div>
                            </div>
                            <div className="form-group row">
                              <label className="col-md-4">Transaction Id</label>
                              <div class="col-md">
                                <input
                                  type="text"
                                  class="form-control"
                                  defaultValue={res.transactionId}
                                  onChange={payoutReqValue('transId')}
                                />
                              </div>
                            </div>
                            <div class="form-group row">
                              <label className="col-md-4" for="supportDoc">
                                Payout Receipt
                              </label>
                              <div class="col-md">
                                <input
                                  type="file"
                                  class="form-control-file"
                                  id="supportDoc"
                                  onChange={onChangeSupportdoc}
                                  accept="image/jpeg,image/png,application/pdf,image/x-eps"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="modal-footer">
                            <button type="submit" className="btn btn-success">
                              Process
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };

  const processPayoutHis = (data) => {
    return (
      <div className="table_cont">
        <div className="table-responsive">
          <table class="table table-bordered table-striped ">
            <thead>
              <tr className="text-center font-weight-bold">
                <th scope="col">Transaction Id </th>
                <th scope="col">Broker Code </th>
                <th scope="col">Broker Name </th>
                <th scope="col">Amount Paid </th>
              </tr>
            </thead>
            <tbody>
              {data.map((res, i) => {
                return (
                  <tr key={i}>
                    <td>{res.transactionId}</td>
                    <td>{res.brokerCode}</td>
                    <td>{res.brokerName}</td>
                    <td>{res.requestedWithdrawAmount}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  const payoutReq = async (e, res) => {
    e.preventDefault();

    const fetchdata = async () => {
      try {
        await fetch(
          `${process.env.REACT_APP_API_URL}/uploadProcessedTransactionReceipt/${res.payoutWithdrawId}`,
          {
            method: 'POST',
            headers: {
              Accept: 'application/json',
            },
            body: supportfile,
          }
        )
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            // MySwal.fire({
            //   text: 'Document Saved Successfully...',
            // });
            console.log(data);
          });
      } catch (error) {
        // MySwal.fire({
        //   text: 'Something went wrong',
        // });
        console.log(error);
      }
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/processPayoutWithdrawRequest`,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            agentId: res.agentId,
            brokerCode: res.brokerCode,
            payoutWithdrawId: res.payoutWithdrawId,
            processorEmpId: res.processorEmpId,
            processorEmpName: res.processorEmpName,
            requestedWithdrawAmount: res.requestedWithdrawAmount,
            requesterAgentName: res.requesterAgentName,
            requesterPanNumber: panNumber,
            transactionId: transId,
            upiNumber: upi,
          }),
        }
      );

      if (response.status === 200) {
        const data = await response.json();
        fetchdata();
        MySwal.fire({
          text: `${data.message}`,
        });
      } else if (response.status === 500) {
        MySwal.fire({
          text: 'Something went wrong',
        });
      }
    } catch (error) {
      MySwal.fire({
        text: 'Something went wrong',
      });
    }
  };

  return (
    <Layout>
      <div className="container">
        <div className="card p-3 mt-5 mb-5">
          <h5 className="font-weight-bold mb-4">
            <span className="text-primary"> Pending Payout Request</span>{' '}
          </h5>
          {leadlog(LoggedInAgent)}
        </div>
        <h3 className="mt-5">Previous Transaction History</h3>
        <div className="mt-5 mb-4">
          <div className="date_range">
            <form
              id="form"
              name="form"
              className="form-inline"
              onSubmit={searchSubmit}
            >
              <div className="form-group">
                <label for="startDate">Start Date</label>
                <input
                  id="startDate"
                  name="startDate"
                  type="date"
                  className="form-control"
                  onChange={searchHandleChange('startDate')}
                  required
                />
                &nbsp;
                <label for="endDate">End Date</label>
                <input
                  id="endDate"
                  name="endDate"
                  type="date"
                  className="form-control"
                  onChange={searchHandleChange('endDate')}
                  required
                />
                <input className="srch_btn" type="submit" value="Search" />
              </div>
            </form>
          </div>
        </div>

        {searchData !== null
          ? processPayoutHis(searchData)
          : TransitionHistory !== null && processPayoutHis(TransitionHistory)}
      </div>
    </Layout>
  );
};

export default ProcessPayout;

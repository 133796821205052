import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Layout from '../components/Layout';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const Register = () => {
  const {
    register,
    handleSubmit,
    watch,
    trigger,
    formState: { errors },
    reset,
  } = useForm();

  const [values, setValues] = useState({
    loading: false,
    form: '',
  });

  const MySwal = withReactContent(Swal);

  const { loading, form } = values;

  const clickRegister = (data) => {
    // data.preventDefault();

    setValues({
      ...values,
      loading: true,
    });

    fetch(`${process.env.REACT_APP_API_URL}/agents/registerConnector`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name: data.firstname + ' ' + data.lastname,
        dob: data.dob,
        gender: data.gender,
        email: data.email,
        mobile: data.phone,
        introducedByBrokerCode: data.introducerCode,
        address1: data.address1,
        address2: data.address2,
        city: data.city,
        panNumber: data.panno,
        state: data.state,
        pin: data.pin,
        firstName: data.firstname,
        lastName: data.lastname,
        password: data.password,
        confirmPassword: data.confirm_password,
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setValues({
          ...values,
          loading: false,
          form: data.message,
        });

        reset();
      })
      .catch((err) => {
        setValues({
          ...values,
          loading: false,
        });

        MySwal.fire({
          text: 'Something went wrong. Please try again later.',
        });
      });
  };

  const clickRegisterEmp = (data) => {
    setValues({
      ...values,
      loading: true,
    });

    fetch(`${process.env.REACT_APP_API_URL}/employee/registerEmployee`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        address1: data.address1,
        address2: data.address2,
        city: data.city,
        email: data.email,
        firstName: data.firstname,
        gender: data.gender,
        lastName: data.lastname,
        mobile: data.phone,
        name: data.firstname + ' ' + data.lastname,
        password: data.password,
        confirmPassword: data.confirm_password,
        pin: data.pin,
        dob: data.dob,
        state: data.state,
        role: data.employeeRole,
        designation: data.employeeDesignation,
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setValues({
          ...values,
          loading: false,
          form: data.message,
        });

        reset();
      })
      .catch((err) => {
        setValues({
          ...values,
          loading: false,
        });

        MySwal.fire({
          text: 'Something went wrong. Please try again later.',
        });
      });
  };

  const showLoading = () =>
    loading && (
      <div className="alert alert-info">
        <h2>Loading...</h2>
      </div>
    );

  const checkIfNumber = (event) => {
    const regex = new RegExp(
      /(^\d*$)|(Backspace|Tab|Delete|ArrowLeft|ArrowRight)/
    );

    return !event.key.match(regex) && event.preventDefault();
  };

  const checkIfTextOnly = (event) => {
    const regex = new RegExp(/[a-zåäö ]/i);

    return !event.key.match(regex) && event.preventDefault();
  };

  return (
    <>
      <Layout>
        <div className="inner_banner text-center">
          <div className="container">Register</div>
        </div>

        {/* Form */}

        <ToastContainer />

        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-lg-5">
              <div className="container">{showLoading()}</div>

              <div className="register-form shadow-sm">
                {/* <form onSubmit={handleSubmit(clickRegister)}> */}
                {form.length === 0 ? (
                  <>
                    <form>
                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <span
                            className="input-group-text rounded-0"
                            id="basic-addon1"
                          >
                            <i className="fa-solid fa-user"></i>
                          </span>
                        </div>
                        <select
                          id="inputState"
                          className="form-control rounded-0"
                          aria-describedby="basic-addon1"
                          {...register('usertype', {
                            required: 'User Type is required',
                          })}
                        >
                          <option value="" selected>
                            Please select user Type...
                          </option>
                          <option value="Connector">Connector</option>
                          <option value="Employee">Employee</option>
                        </select>
                      </div>
                      {errors.usertype && (
                        <p className="text-danger">{errors.usertype.message}</p>
                      )}

                      {watch().usertype === 'Connector' && (
                        <>
                          {' '}
                          <label htmlFor="introducerCode">
                            Introducer Code
                          </label>
                          <div className="input-group mb-3">
                            <div className="input-group-prepend">
                              <span
                                className="input-group-text rounded-0"
                                id="introducerCode"
                              >
                                <i class="fa-solid fa-user-plus"></i>
                              </span>
                            </div>
                            <input
                              type="text"
                              className="form-control rounded-0"
                              id="introducerCode"
                              placeholder="Introducer Code"
                              aria-describedby="introducerCode"
                              {...register('introducerCode')}
                            />
                          </div>
                        </>
                      )}
                      {watch().usertype === 'Employee' && (
                        <div className="row mb-3">
                          <div className="col">
                            <label htmlFor="designation">
                              {' '}
                              Please select designation
                            </label>
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <span
                                  className="input-group-text rounded-0"
                                  id="designation"
                                >
                                  <i className="fa-solid fa-briefcase"></i>
                                </span>
                              </div>
                              <select
                                id="inputStatedesi"
                                className="form-control rounded-0"
                                aria-describedby="designation"
                                {...register('employeeDesignation', {
                                  required: 'Employee Designation is required',
                                })}
                              >
                                <option value="" selected>
                                  Please select designation
                                </option>
                                <option value={'executive'}>Executive</option>
                                <option value={'teamLead'}>Team Lead</option>
                              </select>
                            </div>
                            {errors.employeeDesignation && (
                              <p className="text-danger">
                                {errors.employeeDesignation.message}
                              </p>
                            )}
                          </div>
                          <div className="col">
                            <label htmlFor="role"> Please select role</label>
                            <div className="input-group ">
                              <div className="input-group-prepend">
                                <span
                                  className="input-group-text rounded-0"
                                  id="role"
                                >
                                  <i className="fa-solid fa-address-card"></i>
                                </span>
                              </div>
                              <select
                                id="inputStaterole"
                                className="form-control rounded-0"
                                aria-describedby="role"
                                {...register('employeeRole', {
                                  required: 'Employee Role is required',
                                })}
                              >
                                <option value="" selected>
                                  Please select role
                                </option>
                                <option value={'telecaller'}>Telecaller</option>
                                <option value={'reviewer'}>
                                  Loan Reviewer
                                </option>
                                <option value={'underwriter'}>
                                  Loan Underwriter
                                </option>
                              </select>
                            </div>
                            {errors.employeeRole && (
                              <p className="text-danger">
                                {errors.employeeRole.message}
                              </p>
                            )}
                          </div>
                        </div>
                      )}
                      <div className="row mb-3">
                        <div className="col">
                          <label htmlFor="fname">First Name</label>
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span
                                className="input-group-text rounded-0"
                                id="fname"
                              >
                                <i className="fa-solid fa-user"></i>
                              </span>
                            </div>
                            <input
                              type="text"
                              className="form-control rounded-0"
                              id="firstname"
                              placeholder="First Name"
                              aria-describedby="fname"
                              {...register('firstname', {
                                required: 'Firstname is required',
                                maxLength: {
                                  value: 50,
                                  message: 'Maximum 50 characters',
                                },
                              })}
                              onKeyUp={() => {
                                trigger('firstname');
                              }}
                              onKeyDown={(e) => checkIfTextOnly(e)}
                            />
                          </div>
                          {errors.firstname && (
                            <p className="text-danger">
                              {errors.firstname.message}
                            </p>
                          )}
                        </div>
                        <div className="col">
                          <label htmlFor="lname">Last Name</label>
                          <div className="input-group">
                            <div className="input-group-prepend ">
                              <span
                                className="input-group-text rounded-0"
                                id="lname"
                              >
                                <i className="fa-solid fa-user"></i>
                              </span>
                            </div>
                            <input
                              type="text"
                              className="form-control rounded-0"
                              placeholder="Last Name"
                              id="lastname"
                              aria-describedby="lname"
                              {...register('lastname')}
                              onKeyDown={(e) => checkIfTextOnly(e)}
                            />
                          </div>
                          {errors.lastname && (
                            <p className="text-danger">
                              {errors.lastname.message}
                            </p>
                          )}
                        </div>
                      </div>

                      <label htmlFor="date">Date Of Birth</label>
                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <span
                            className="input-group-text rounded-0"
                            id="date"
                          >
                            <i
                              className="fa fa-calendar"
                              aria-hidden="true"
                            ></i>
                          </span>
                        </div>
                        <input
                          type="date"
                          className="form-control rounded-0"
                          id="date"
                          aria-describedby="date"
                          {...register('dob', {
                            required: 'Date of Birth is required',
                          })}
                        />
                      </div>
                      {errors.dob && (
                        <p className="text-danger">{errors.dob.message}</p>
                      )}

                      <label htmlFor="gender">Gender</label>
                      <div className="input-group mb-3">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="gender"
                            id="gMale"
                            value={'male'}
                            {...register('gender', {
                              required: 'Gender is required',
                            })}
                          />
                          <label className="form-check-label" htmlFor="gMale">
                            male
                          </label>
                        </div>
                        <div className="form-check form-check-inline ">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="gender"
                            id="gFemale"
                            value={'female'}
                            {...register('gender', {
                              required: 'Gender is required',
                            })}
                          />
                          <label className="form-check-label" htmlFor="gFemale">
                            female
                          </label>
                        </div>
                      </div>
                      {errors.gender && (
                        <p className="text-danger">{errors.gender.message}</p>
                      )}

                      <label htmlFor="email">Email Address</label>
                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <span
                            className="input-group-text rounded-0"
                            id="email"
                          >
                            <i className="fa-solid fa-at"></i>
                          </span>
                        </div>
                        <input
                          type="email"
                          className="form-control rounded-0"
                          id="email"
                          placeholder="Email"
                          aria-describedby="email"
                          {...register('email', {
                            required: 'Email is required',
                            pattern: {
                              value:
                                /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                              message: 'Please enter a valid email',
                            },
                          })}
                          onKeyUp={() => {
                            trigger('email');
                          }}
                        />
                      </div>
                      {errors.email && (
                        <p className="text-danger">{errors.email.message}</p>
                      )}
                      <label htmlFor="password">Password</label>
                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <span
                            className="input-group-text rounded-0"
                            id="password"
                          >
                            <i className="fa-solid fa-lock"></i>
                          </span>
                        </div>
                        <input
                          type="password"
                          className="form-control rounded-0"
                          placeholder="Password"
                          aria-describedby="password"
                          {...register('password', {
                            required: 'Password is required',
                            minLength: {
                              value: 5,
                              message: 'Password must be at least 5 characters',
                            },
                          })}
                          onKeyUp={() => {
                            trigger('password');
                          }}
                        />
                      </div>
                      {errors.password && (
                        <p className="text-danger">{errors.password.message}</p>
                      )}

                      <label htmlFor="cpassword">Confirm Password</label>
                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <span
                            className="input-group-text rounded-0"
                            id="cpassword"
                          >
                            <i className="fa-solid fa-lock"></i>
                          </span>
                        </div>
                        <input
                          type="password"
                          className="form-control rounded-0"
                          placeholder="Confirm Password"
                          aria-describedby="password"
                          {...register('confirm_password', {
                            required: 'confirm password is required',
                            minLength: {
                              value: 5,
                              message: 'Password must be at least 5 characters',
                            },
                            validate: (val) => {
                              if (watch('password') != val) {
                                return 'Your passwords do no match';
                              }
                            },
                          })}
                          onKeyUp={() => {
                            trigger('confirm_password');
                          }}
                        />
                      </div>
                      {errors.confirm_password && (
                        <p className="text-danger">
                          {errors.confirm_password.message}
                        </p>
                      )}

                      <label htmlFor="phone">Mobile Number</label>
                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <span
                            className="input-group-text rounded-0"
                            id="phone"
                          >
                            <i className="fa-solid fa-mobile"></i>
                          </span>
                        </div>
                        <input
                          type="number"
                          className="form-control rounded-0"
                          id="phone"
                          min="0"
                          placeholder="Mobile Number"
                          aria-describedby="phone"
                          {...register('phone', {
                            required: 'Phone no is required',
                            pattern: {
                              value: /^[0-9]{10}$/,
                              message: 'Please enter 10 digit phone no',
                            },
                          })}
                          onKeyUp={() => {
                            trigger('phone');
                          }}
                          onKeyDown={(e) => checkIfNumber(e)}
                        />
                      </div>
                      {errors.phone && (
                        <p className="text-danger">{errors.phone.message}</p>
                      )}
                      {watch().usertype === 'Connector' && (
                        <>
                          <label htmlFor="pan">Pan Number</label>
                          <div className="input-group mb-3">
                            <div className="input-group-prepend">
                              <span
                                className="input-group-text rounded-0"
                                id="pan"
                              >
                                <i class="fa-regular fa-id-card"></i>
                              </span>
                            </div>
                            <input
                              type="text"
                              className="form-control rounded-0"
                              id="pan"
                              placeholder="Pan Number"
                              aria-describedby="pan"
                              {...register('panno', {
                                required: 'Pan number is required',
                              })}
                              onKeyUp={() => {
                                trigger('panno');
                              }}
                            />
                          </div>
                          {errors.panno && (
                            <p className="text-danger">
                              {errors.panno.message}
                            </p>
                          )}
                        </>
                      )}

                      <div className="row mb-3">
                        <div className="col">
                          <label htmlFor="add1">Address 1</label>
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span
                                className="input-group-text rounded-0"
                                id="add1"
                              >
                                <i className="fa-solid fa-location-dot"></i>
                              </span>
                            </div>
                            <input
                              type="text"
                              className="form-control rounded-0"
                              id="address1"
                              placeholder="Address 1"
                              aria-describedby="add1"
                              {...register('address1', {
                                required: 'Address is required',
                              })}
                              onKeyUp={() => {
                                trigger('address1');
                              }}
                            />
                          </div>
                          {errors.address1 && (
                            <p className="text-danger mt-1">
                              {errors.address1.message}
                            </p>
                          )}
                        </div>
                        <div className="col">
                          <label htmlFor="add2">Address 2</label>
                          <div className="input-group">
                            <div className="input-group-prepend ">
                              <span
                                className="input-group-text rounded-0"
                                id="add2"
                              >
                                <i className="fa-solid fa-location-dot"></i>
                              </span>
                            </div>
                            <input
                              type="text"
                              className="form-control rounded-0"
                              placeholder="Address 2"
                              id="address2"
                              aria-describedby="add2"
                              {...register('address2')}
                            />
                          </div>
                          {errors.address2 && (
                            <p className="text-danger">
                              {errors.address2.message}
                            </p>
                          )}
                        </div>
                      </div>

                      <div className="row mb-3">
                        <div className="col">
                          <label htmlFor="city">City</label>
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span
                                className="input-group-text rounded-0"
                                id="city"
                              >
                                <i className="fa-solid fa-earth-asia"></i>
                              </span>
                            </div>
                            <input
                              type="text"
                              className="form-control rounded-0"
                              id="city"
                              placeholder="City"
                              aria-describedby="city"
                              {...register('city', {
                                required: 'City is required',
                              })}
                              onKeyUp={() => {
                                trigger('city');
                              }}
                            />
                          </div>
                          {errors.city && (
                            <p className="text-danger">{errors.city.message}</p>
                          )}
                        </div>
                        <div className="col">
                          <label htmlFor="state">State</label>
                          <div className="input-group">
                            <div className="input-group-prepend ">
                              <span
                                className="input-group-text rounded-0"
                                id="state"
                              >
                                <i className="fa-solid fa-city"></i>
                              </span>
                            </div>
                            <input
                              type="text"
                              className="form-control rounded-0"
                              placeholder="State"
                              id="state"
                              aria-describedby="state"
                              {...register('state', {
                                required: 'State is required',
                              })}
                              onKeyUp={() => {
                                trigger('state');
                              }}
                            />
                          </div>
                          {errors.state && (
                            <p className="text-danger">
                              {errors.state.message}
                            </p>
                          )}
                        </div>
                      </div>
                      <label htmlFor="pin">Pin Code</label>
                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <span className="input-group-text rounded-0" id="pin">
                            <i className="fa-solid fa-map-pin"></i>
                          </span>
                        </div>
                        <input
                          type="number"
                          className="form-control rounded-0"
                          id="pin"
                          placeholder="Pin Code"
                          aria-describedby="pin"
                          {...register('pin', {
                            required: 'Pin code is required',
                            pattern: {
                              value: /^[1-9][0-9]{5}$/,
                              message: 'Pin code sould be 6 digit',
                            },
                          })}
                          onKeyUp={() => {
                            trigger('pin');
                          }}
                          onKeyDown={(e) => checkIfNumber(e)}
                        />
                      </div>
                      {errors.pin && (
                        <p className="text-danger">{errors.pin.message}</p>
                      )}
                      <div className="form-group form-check ">
                        <label
                          className="form-check-label"
                          htmlFor="registercheck"
                        >
                          I agree with{' '}
                          <Link
                            data-toggle="modal"
                            data-target="#terms_condition"
                          >
                            terms and conditions
                          </Link>
                        </label>
                      </div>
                      {errors.registercheck && (
                        <p className="text-danger">
                          {errors.registercheck.message}
                        </p>
                      )}

                      {watch().usertype === 'Connector' && (
                        <button
                          type="submit"
                          onClick={handleSubmit(clickRegister)}
                          className="btn btn-primary rounded-0"
                        >
                          Register
                        </button>
                      )}

                      {watch().usertype === 'Employee' && (
                        <button
                          type="submit"
                          onClick={handleSubmit(clickRegisterEmp)}
                          className="btn btn-primary rounded-0"
                        >
                          Register
                        </button>
                      )}

                      {watch().usertype === 'Connector' ||
                      watch().usertype === 'Employee' ? (
                        <></>
                      ) : (
                        <>
                          <button
                            type="submit"
                            onClick={handleSubmit(clickRegister)}
                            className="btn btn-primary rounded-0"
                          >
                            Register
                          </button>
                        </>
                      )}
                    </form>
                  </>
                ) : (
                  <>
                    <h5>
                      {form}
                      <br />
                      Please <Link to="/login"> login </Link>
                    </h5>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Modal --> */}
        <div
          className="modal fade"
          id="terms_condition"
          data-backdrop="static"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="staticBackdropLabel">
                  Terms And Conditions
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <p>
                  Please understand the following terms and conditions <br /> 1.
                  Commision payout amount will depend on various condition like
                  Loan Type,Processing Bank, approveed interest rate etc. <br />{' '}
                  2. Payout typically creited to bank account within 10th day of
                  each month but can be delayed occationaly. <br /> 3. Final
                  Payout amount will be notified only after Loan Amount
                  disbursed to Customer's Account. <br /> 4. In case of any
                  query/clarification you need to notify within 15 Calendar
                  Days.
                </p>
              </div>
              <div className="modal-footer">
                <input
                  type="checkbox"
                  className="checkbox-size text-success"
                  id="registercheck"
                  {...register('registercheck', {
                    required: 'Please accept the terms and conditions',
                  })}
                />
                <button
                  type="button"
                  data-dismiss="modal"
                  aria-label="Close"
                  className="btn btn-primary"
                  disabled={watch().registercheck ? false : true}
                >
                  Accept
                </button>
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
      </Layout>
    </>
  );
};

export default Register;

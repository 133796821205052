import React from 'react';
import Layout from '../components/Layout';
import { ToastContainer, toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const ValidateOtp = ({ history }) => {
  const MySwal = withReactContent(Swal);

  const {
    register,
    handleSubmit,
    watch,
    trigger,
    formState: { errors },
    reset,
  } = useForm();

  const [values, setValues] = React.useState({
    authorization: false,
  });

  const { authorization } = values;

  // const activeuserhandleChange = (name) => (event) => {
  //   setActivateUser({
  //     ...activateUser,
  //     [name]: event.target.value,
  //   });
  // };

  const resetPasswordSubmit = (data) => {
    // e.preventDefault();

    const fetchdata = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/user/activateUser`,
          {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              userName: data.userName,
              newPassword: data.newPassword,
              confirmPassword: data.confirmPassword,
            }),
          }
        );
        if (response.status === 200) {
          const resdata = await response.json();
          MySwal.fire({
            text: `${resdata.value}`,
          });
          history.push('/login');
        }
      } catch (error) {
        MySwal.fire({
          text: 'Something went wrong',
        });
      }
    };
    fetchdata();
  };

  const checkIfNumber = (event) => {
    const regex = new RegExp(
      /(^\d*$)|(Backspace|Tab|Delete|ArrowLeft|ArrowRight)/
    );

    return !event.key.match(regex) && event.preventDefault();
  };

  return (
    <>
      <Layout>
        <div className="inner_banner text-center">
          <div className="container">
            {' '}
            {authorization ? 'Activate User' : 'Validate OTP'}
          </div>
        </div>
        <ToastContainer />
        <div className="container mb-5">
          <div className="row justify-content-center">
            <div className="col-lg-5">
              <div className="reset-form shadow-sm">
                <>
                  <form onSubmit={handleSubmit(resetPasswordSubmit)}>
                    <label htmlFor="email">Email Address</label>
                    <div className="input-group mb-3">
                      <div className="input-group-prepend">
                        <span className="input-group-text rounded-0" id="email">
                          <i className="fa-solid fa-envelope"></i>
                        </span>
                      </div>
                      <input
                        type="email"
                        className="form-control rounded-0"
                        id="userName"
                        placeholder="Username"
                        aria-describedby="userName"
                        defaultValue={watch().email}
                        {...register('userName', {
                          required: 'User name is required',
                          pattern: {
                            value:
                              /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            message: 'Please enter a valid username',
                          },
                        })}
                        onKeyUp={() => {
                          trigger('userName');
                        }}
                      />
                    </div>
                    {errors.userName && (
                      <p className="text-danger mt-1">
                        {errors.userName.message}
                      </p>
                    )}

                    <label htmlFor="password">Password</label>
                    <div className="input-group mb-3">
                      <div className="input-group-prepend">
                        <span
                          className="input-group-text rounded-0"
                          id="password"
                        >
                          <i className="fa-solid fa-lock"></i>
                        </span>
                      </div>
                      <input
                        type="password"
                        className="form-control rounded-0"
                        id="password"
                        placeholder="Password"
                        aria-describedby="password"
                        {...register('newPassword', {
                          required: 'Password is required',
                          minLength: {
                            value: 5,
                            message: 'Password must be at least 5 characters',
                          },
                        })}
                        onKeyUp={() => {
                          trigger('newPassword');
                        }}
                      />
                    </div>
                    {errors.newPassword && (
                      <p className="text-danger">
                        {errors.newPassword.message}
                      </p>
                    )}
                    <label htmlFor="cpassword">Confirm Password</label>
                    <div className="input-group mb-3">
                      <div className="input-group-prepend">
                        <span
                          className="input-group-text rounded-0"
                          id="cpassword"
                        >
                          <i className="fa-solid fa-lock"></i>
                        </span>
                      </div>
                      <input
                        type="password"
                        className="form-control rounded-0"
                        id="confirmPassword"
                        placeholder="Confirm password"
                        aria-describedby="confirmPassword"
                        {...register('confirmPassword', {
                          required: 'Password is required',
                          minLength: {
                            value: 5,
                            message: 'Password must be at least 5 characters',
                          },
                          validate: (val) => {
                            if (watch('newPassword') != val) {
                              return 'Your passwords do no match';
                            }
                          },
                        })}
                        onKeyUp={() => {
                          trigger('confirmPassword');
                        }}
                      />
                    </div>
                    {errors.confirmPassword && (
                      <p className="text-danger">
                        {errors.confirmPassword.message}
                      </p>
                    )}

                    <button type="submit" className="btn btn-success rounded-0">
                      Reset
                    </button>
                  </form>
                </>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default ValidateOtp;

import React from 'react';
import { NavLink, Link, withRouter, useHistory } from 'react-router-dom';
import SecureLS from 'secure-ls';

const Header = ({ history }) => {
  const signout = () => {
    if (localStorage.getItem('username')) {
      localStorage.removeItem('userType');
      localStorage.removeItem('username');
      localStorage.removeItem('loggedInAgent');
      localStorage.removeItem('loggedInEmployee');
      localStorage.removeItem('jwt');
      localStorage.removeItem('LoginMsg');
      history.push('/');
    }
  };

  var ls = new SecureLS({ encodingType: 'aes', isCompression: false });

  const LoginMsg = ls.get('LoginMsg');

  return (
    <>
      {/* <div className="top_head">
        <div className="container">
          <marquee>*** Hottest Offer For The Day ***</marquee>
        </div>
      </div> */}
      <nav className="header_btm navbar navbar-expand-lg navbar-light bg-light ">
        <div className="mx-auto d-lg-flex d-block flex-lg-nowrap">
          <Link className="navbar-brand logo " to="/">
            Anex Finserv
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mr-auto">
              <li className="nav-item">
                <NavLink
                  exact
                  activeClassName="navlink"
                  className="nav-link"
                  to="/"
                >
                  Home
                </NavLink>
              </li>
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Explore Products
                </a>
                <div
                  className="dropdown-menu dropright"
                  aria-labelledby="navbarDropdown"
                >
                  <a
                    className="nav-link dropdown-toggle ml-3"
                    href="#"
                    id="navbarDropdown-drop"
                    data-toggle="dropdown"
                  >
                    Loans
                  </a>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdown-drop"
                  >
                    <a className="dropdown-item" href="#">
                      Auto
                    </a>
                    <a className="dropdown-item" href="#">
                      Home
                    </a>
                    <a className="dropdown-item" href="#">
                      Personal
                    </a>
                  </div>

                  <a className="dropdown-item" href="#">
                    Deposit
                  </a>

                  <a className="dropdown-item" href="#">
                    Investment
                  </a>
                </div>
              </li>

              <li className="nav-item">
                <a className="nav-link" href="#">
                  About Us
                </a>
              </li>
              {localStorage.getItem('userType') === 'Agent' ||
              localStorage.getItem('userType') === 'Employee' ? (
                <>
                  <li className="nav-item">
                    <NavLink
                      exact
                      activeClassName="navlink"
                      className="nav-link"
                      to="/initiateLead"
                    >
                      {localStorage.getItem('userType') === null ? (
                        <> Apply for Loan</>
                      ) : (
                        <> Provide Loan Lead</>
                      )}
                    </NavLink>
                  </li>
                </>
              ) : (
                ''
              )}

              {localStorage.getItem('userType') === 'Agent' ||
              localStorage.getItem('userType') === 'Employee' ? (
                <>
                  <li className="nav-item">
                    <NavLink
                      exact
                      activeClassName="navlink"
                      className="nav-link"
                      to="/agent-invoice"
                      hidden={LoginMsg === 'Good to login' ? false : true}
                    >
                      Agent Invoice
                    </NavLink>
                  </li>
                </>
              ) : (
                ''
              )}

              {localStorage.getItem('userType') === 'Agent' && (
                <>
                  <li className="nav-item">
                    <NavLink
                      exact
                      activeClassName="navlink"
                      className="nav-link"
                      to="/viewAgentPayout"
                      hidden={LoginMsg === 'Good to login' ? false : true}
                    >
                      Agent Payout
                    </NavLink>
                  </li>

                  <li className="nav-item">
                    <NavLink
                      exact
                      activeClassName="navlink"
                      className="nav-link"
                      to="/payout-withdraw"
                      hidden={LoginMsg === 'Good to login' ? false : true}
                    >
                      Payout Withdraw
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      exact
                      activeClassName="navlink"
                      className="nav-link"
                      to="/agentdashboard"
                      hidden={LoginMsg === 'Good to login' ? false : true}
                    >
                      Agent Dashboard
                    </NavLink>
                  </li>
                </>
              )}
              {localStorage.getItem('userType') === 'Employee' && (
                <>
                  <li className="nav-item">
                    <NavLink
                      exact
                      activeClassName="navlink"
                      className="nav-link"
                      to="/reviewLead"
                      hidden={LoginMsg === 'Good to login' ? false : true}
                    >
                      Review Lead
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      exact
                      activeClassName="navlink"
                      className="nav-link"
                      to="/process-payout"
                      hidden={LoginMsg === 'Good to login' ? false : true}
                    >
                      Process Payout
                    </NavLink>
                  </li>

                  <li className="nav-item">
                    <NavLink
                      exact
                      activeClassName="navlink"
                      className="nav-link"
                      to="/loan-under-proccess"
                      hidden={LoginMsg === 'Good to login' ? false : true}
                    >
                      In Process Loans
                    </NavLink>
                  </li>

                  <li className="nav-item">
                    <NavLink
                      exact
                      activeClassName="navlink"
                      className="nav-link"
                      to="/agent-payout-view"
                      hidden={LoginMsg === 'Good to login' ? false : true}
                    >
                      Agent Payout View
                    </NavLink>
                  </li>

                  <li className="nav-item">
                    <NavLink
                      exact
                      activeClassName="navlink"
                      className="nav-link"
                      to="/closedLoans"
                      hidden={LoginMsg === 'Good to login' ? false : true}
                    >
                      Closed Loans
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      exact
                      activeClassName="navlink"
                      className="nav-link"
                      to="/loan-payout-view"
                      hidden={LoginMsg === 'Good to login' ? false : true}
                    >
                      Loan Payout View
                    </NavLink>
                  </li>
                </>
                //
              )}

              <li className="nav-item">
                <a className="nav-link" href="#">
                  Current Offer
                </a>
              </li>
              {localStorage.getItem('username') ? (
                <>
                  <li className="nav-item dropdown">
                    <a
                      className="nav-link dropdown-toggle"
                      href="#"
                      id="navbarDropdown"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i className="fa-solid fa-user"></i>{' '}
                      {localStorage.getItem('username')}
                    </a>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="navbarDropdown"
                    >
                      <NavLink
                        exact
                        activeClassName="navlink"
                        className="dropdown-item"
                        to="/viewProfile"
                      >
                        Profile
                      </NavLink>
                      <li>
                        <span
                          style={{ cursor: 'pointer' }}
                          className="dropdown-item"
                          onClick={() => signout()}
                        >
                          Logout
                        </span>
                      </li>
                    </div>
                  </li>
                </>
              ) : (
                <>
                  <li className="nav-item dropdown">
                    <a
                      className="nav-link dropdown-toggle"
                      href="#"
                      id="navbarDropdown"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Login/Register
                    </a>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="navbarDropdown"
                    >
                      <NavLink
                        exact
                        activeClassName="navlink"
                        className="dropdown-item"
                        to="/login"
                      >
                        Login
                      </NavLink>
                      <NavLink
                        exact
                        activeClassName="navlink"
                        className="dropdown-item"
                        to="/register"
                      >
                        Register
                      </NavLink>
                    </div>
                  </li>
                </>
              )}
              <li className="nav-item">
                <a className="nav-link" href="#">
                  Help & Contact
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default withRouter(Header);

import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useForm } from 'react-hook-form';
import { Link, NavLink } from 'react-router-dom/cjs/react-router-dom.min';
import HomeHeader from '../components/HomeHeader';
import HomeFooter from '../components/HomeFooter';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const BusinessLoan = () => {
  const MySwal = withReactContent(Swal);

  const {
    register,
    handleSubmit,
    watch,
    trigger,
    formState: { errors },
    reset,
  } = useForm();

  const [values, setValues] = useState({
    loading: false,
  });

  const { loading } = values;

  function calculate() {
    // Look up the input and output elements in the document
    var amount = document.getElementById('amount');
    var apr = document.getElementById('apr');
    var years = document.getElementById('years');
    var zipcode = document.getElementById('zipcode');
    var payment = document.getElementById('payment');
    var total = document.getElementById('total');
    var totalinterest = document.getElementById('totalinterest');

    // Get the user's input from the input elements.
    // Assume all input is valid (for now).
    // Convert interest rate from percentage to decimal
    // Convert from annual rate to monthly rate
    // Convert payment period in years to number of monthly payments
    var principal = parseFloat(amount.value.replace(/,/g, ''));
    var rate = parseFloat(apr.value) / 100 / 12;
    var payments = parseFloat(years.value) * 12;

    // Compute the monthly payment
    var x = Math.pow(1 + rate, payments);
    var monthly = (principal * x * rate) / (x - 1);

    // If the result is a finite number, the user's input was good
    // and we have meaningful results to display
    if (isFinite(monthly)) {
      // Fill in the output fields, rounding to 2 decimal places
      payment.innerHTML = formatNumber(monthly, 2);
      total.innerHTML = formatNumber(monthly * payments, 2);
      totalinterest.innerHTML = formatNumber(monthly * payments - principal, 2);

      // Finally, chart loan balance, interest, and equity payments
      chart(principal, rate, monthly, payments);
    } else {
      // Result was NaN or Infinite,
      // which means the input was incomplete or invalid.
      // Clear any previously-displayed output.
      payment.innerHTML = '';
      total.innerHTML = '';
      totalinterest.innerHTML = '';
      chart(); // With no arguments, clears the chart
    }
  }

  // Chart the monthly loan balance, interest, and
  // equity in an HTML <canvas> element.
  // If called with no arguments, then just erase
  // any previously drawn chart.
  function chart(principal, rate, monthly, payments) {
    var graph = document.getElementById('graph');
    graph.width = graph.width; // Magic to clear and reset the canvas element

    // If we're called with no arguments,
    // or if the browser does not support graphics
    // in a <canvas> element, then just return now.
    if (arguments.length === 0 || !graph.getContext) return;

    // Get the "context" object for the <canvas>
    // that defines the API
    // All drawing is done with this object
    var g = graph.getContext('2d');

    var width = graph.width,
      height = graph.height; // Get canvas size

    // These functions convert payment numbers
    // and dollar amounts to pixels
    function paymentToX(n) {
      return (n * width) / payments;
    }
    function amountToY(a) {
      return height - (a * height) / (monthly * payments * 1.05);
    }

    // Payments are a straight line from (0,0)
    // to (payments, monthly * payments)
    g.moveTo(paymentToX(0), amountToY(0)); // Start at lower left
    g.lineTo(
      paymentToX(payments), // Draw to upper right
      amountToY(monthly * payments)
    );
    g.lineTo(paymentToX(payments), amountToY(0)); // Down to lower right
    g.closePath(); // And back to start
    g.fillStyle = '#72AAD0'; // Light blue
    g.fill(); // Fill the triangle

    // Cumulative equity is non-linear and trickier to chart
    var equity = 0;
    g.beginPath();
    g.moveTo(paymentToX(0), amountToY(0)); // Start at lower left
    for (var p = 1; p <= payments; p++) {
      // For each payment, figure out how much is interest
      var thisMonthsInterest = (principal - equity) * rate;
      equity += monthly - thisMonthsInterest; // The rest goes to equity
      g.lineTo(paymentToX(p), amountToY(equity)); // Line to this point
    }
    g.lineTo(paymentToX(payments), amountToY(0)); // Line back to X axis
    g.closePath(); // And back to starting point
    g.fillStyle = '#40FD65';
    g.fill(); // Fill area under curve

    // Loop again, as above, but chart loan balance as a black line
    var bal = principal;
    g.beginPath();
    g.moveTo(paymentToX(0), amountToY(bal));
    for (var p = 1; p <= payments; p++) {
      var thisMonthsInterest = bal * rate;
      bal -= monthly - thisMonthsInterest; // The rest goes to equity
      g.lineTo(paymentToX(p), amountToY(bal)); // Draw line to this point
    }

    g.lineWidth = 2;
    g.strokeStyle = '#444';
    g.stroke(); // Draw the balance curve

    // Now make yearly tick marks and year numbers on X axis
    g.textAlign = 'center'; // Center text over ticks
    g.fillStyle = '#444';
    g.font = "0.6em 'Open Sans'";
    var y = amountToY(0); // Y coordinate of X axis
    for (var year = 1; year * 12 <= payments; year++) {
      // For each year
      var x = paymentToX(year * 12); // Compute tick position
      g.fillRect(x - 0.5, y - 3, 1, 3); // Draw the tick
      if (year === 1) g.fillText('Year', x, y - 5); // Label the axis
      if (year % 5 === 0 && year * 12 !== payments)
        // Number every 5 years
        g.fillText(String(year), x, y - 5);
    }

    // Mark payment amounts along the right edge
    g.textAlign = 'right'; // Right-justify the text
    g.textBaseline = 'middle'; // Center it vertically
    var ticks = [monthly * payments, principal]; // The two points we'll mark
    var rightEdge = paymentToX(payments); // X coordinate of Y axis
    for (var i = 0; i < ticks.length; i++) {
      // For each of the 2 points
      var y = amountToY(ticks[i]); // Compute y position of tick
      g.fillRect(rightEdge - 3, y - 0.5, 3, 1); // Draw the tick mark
      g.fillText(String(formatNumber(ticks[i]), 2), rightEdge - 5, y); // And label it
    }
  }

  function formatNumber(num, decimals) {
    return num.toFixed(decimals).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    // http://blog.tompawlak.org/number-currency-formatting-javascript
  }

  // check number
  const checkIfNumber = (event) => {
    const regex = new RegExp(
      /(^\d*$)|(Backspace|Tab|Delete|ArrowLeft|ArrowRight)/
    );

    return !event.key.match(regex) && event.preventDefault();
  };

  // check text
  const checkIfTextOnly = (event) => {
    const regex = new RegExp(/[a-zåäö ]/i);

    return !event.key.match(regex) && event.preventDefault();
  };

  const clickRegister = async (data) => {
    // e.preventDefault();
    setValues({
      loading: true,
    });
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/initiateLead`,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            occupation: data.occupation,
            customerFirstName: data.customerFirstName,
            customerLastName: data.customerLastName,
            customerEmailId: data.customerEmailId,
            customerMobileNumber: data.customerMobileNumber,
            loanAmountAppliedFor: data.loanAmountAppliedFor,
            companyName: data.companyName,
            companyType: data.companyType,
            loanType: data.loanType,
            city: data.city,
            state: data.state,
          }),
        }
      );

      if (response.status === 200) {
        const resdata = await response.json();
        setValues({
          loading: false,
        });

        MySwal.fire({
          text: `${resdata.message}`,
        });
        reset();
      } else if (response.status === 500) {
        setValues({
          loading: false,
        });
        MySwal.fire({
          text: 'Something went wrong',
        });
      }
    } catch (error) {
      setValues({
        loading: false,
      });
      MySwal.fire({
        text: 'Something went wrong',
      });
    }
  };

  const showLoading = () =>
    loading && (
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    );

  return (
    <>
      {' '}
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link href="css/custom.css" rel="stylesheet" type="text/css" />
        <title>Anex Finserv</title>
      </Helmet>
      <HomeHeader />
      <section class="sticky-nav-tabs">
        <div class="sticky-nav-tabs-container">
          {' '}
          <a class="sticky-nav-tab" href="#tab-over">
            OVERVIEW
          </a>{' '}
          <a class="sticky-nav-tab" href="#tab-feat">
            FEATURES
          </a>{' '}
          <a class="sticky-nav-tab" href="#tab-eleg">
            ELIGIBILITY
          </a>{' '}
          <a class="sticky-nav-tab" href="#tab-doc">
            DOCUMENTS
          </a>{' '}
          <a class="sticky-nav-tab" href="#tab-emi">
            EMI CALCULATOR
          </a>
          <a class="sticky-nav-tab" href="#tab-fees">
            FEES AND CHARGES
          </a>
          <a class="sticky-nav-tab" href="#tab-rev">
            REVIEWS
          </a>
          <a class="sticky-nav-tab" href="#tab-faq">
            FAQ`s
          </a>{' '}
          <span class="sticky-nav-tab-slider"></span>{' '}
        </div>
      </section>
      <main class="spa-main  text-left">
        <section class="spa-slide" id="tab-over">
          <div class="container mt-2">
            <div class="elegb_sec text-left">
              <div class="elegb_titl mt-5">Overview</div>
              <div class="card-deck mb-3">
                <div class="card ">
                  <div class="card-body text-center">
                    <div className="mb-4 ">
                      <img
                        src="images/discount.svg"
                        alt=""
                        className="img-fluid"
                        width={50}
                      />
                    </div>
                    <h6 class="card-subtitle text-muted">
                      Loan for Any Type of Business
                    </h6>
                  </div>
                </div>
                <div class="card">
                  <div class="card-body text-center">
                    <div className="mb-4">
                      <img
                        src="images/rupee-money-allocation-icon.svg"
                        alt=""
                        className="img-fluid"
                        width={50}
                      />
                    </div>
                    <h6 class="card-subtitle text-muted">
                      Disbursement within 5 Working days
                    </h6>
                  </div>
                </div>
                <div class="card">
                  <div class="card-body text-center">
                    <div className="mb-4">
                      <img
                        src="images/money-bag-rupee-icon.svg"
                        alt=""
                        className="img-fluid"
                        width={35}
                      />
                    </div>
                    <h6 class="card-subtitle text-muted">
                      Loans for tailor made requirements
                    </h6>
                  </div>
                </div>
                <div class="card">
                  <div class="card-body text-center">
                    <div className="mb-4">
                      <img
                        src="images/checkmark-date-calendar-icon.svg"
                        alt=""
                        className="img-fluid"
                        width={50}
                      />
                    </div>
                    <h6 class="card-subtitle text-muted">
                      Tenure of up to 84 Months
                    </h6>
                  </div>
                </div>
                <div class="card ">
                  <div class="card-body text-center">
                    <div className="mb-4">
                      <img
                        src="images/hide-private-hidden-icon.svg"
                        alt=""
                        className="img-fluid"
                        width={50}
                      />
                    </div>
                    <h6 class="card-subtitle text-muted">Topup after 1 Year</h6>
                  </div>
                </div>
              </div>
              <div className="container">
                <div class="elegb_txt">
                  A business loan is a financial product that allows individuals
                  or businesses to borrow money for various purposes related to
                  their business operations. These loans can be used for a wide
                  range of business needs, such as starting a new venture,
                  expanding an existing business, purchasing equipment, managing
                  cash flow, or addressing other financial requirements.
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="spa-slide" id="tab-feat">
          <div class="container">
            <div class="elegb_sec text-left">
              <div class="elegb_titl">
                Features and Benefits of Business Loan
              </div>
              <div class="elegb_txt mt-4">
                Buiness loan can be taken for various purpose, example are as
                follows:
                <ul>
                  <li>
                    Term Loan: term loan is a lump sum of money borrowed from a
                    financial institution with a fixed repayment schedule over a
                    specified period, often used for business expansion, capital
                    expenditures, or major purchases.
                  </li>
                  <li>
                    Cash Credit Loan: A Cash Credit loan is a type of short-term
                    financing provided by banks or financial institutions to
                    businesses, allowing them to borrow up to a certain limit
                    based on their creditworthiness. It is a flexible form of
                    borrowing where businesses can withdraw and repay funds as
                    needed within the approved credit limit.
                  </li>
                  <li>
                    Business Expansion Loans: Support businesses in financing
                    the costs associated comapnies expansion initiatives to grow
                    and increase their operations, whether through opening new
                    locations, adding products or services, or scaling up
                    existing operations.
                  </li>
                  <li>
                    Equipment/Machinery Financing Loans: Machinery/Equipment
                    financing loans are specialized loans designed to help
                    businesses acquire the necessary machinery or equipment for
                    their operations. The loan amount is typically used to
                    purchase, lease, or upgrade machinery, with the equipment
                    itself often serving as collateral for the loan.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <section class="spa-slide" id="tab-eleg">
          <div class="container">
            <div class="full_sec mt-lg-5 mt-3 text-center">
              <div class="container">
                <div class="loan_titl">
                  Business Loan Eligibility and Documents
                </div>
                <div class="prtnr_btn">
                  <a
                    data-bs-toggle="modal"
                    className="btn btn-primary"
                    data-toggle="modal"
                    data-target="#staticBackdrop2"
                    href="#"
                  >
                    Apply Now
                  </a>
                </div>
              </div>
            </div>
            <div class="elegb_titl mt-lg-5 text-left">
              Business Loan Eligibility Criteria
            </div>
            <div class="elegb_txt text-left">
              Business loan minimum criteria is little different than other type
              of loans. However we are listing most important criterias
              <ul>
                <li>
                  Business Type: Most lenders provide loans to various types of
                  businesses, including sole proprietorships, partnerships,
                  limited liability companies (LLCs), corporations, and more.
                </li>
                <li>
                  {' '}
                  Business Age: Some lenders require a minimum operational
                  history for your business, usually ranging from a few months
                  to a year or more.
                </li>
                <li>
                  Annual Revenue: Lenders may have a minimum annual revenue
                  requirement to ensure your business has a stable income.
                </li>
                <li>
                  Collateral: Secured loans might require assets as collateral
                  to secure the loan. Collateral can be real estate, equipment,
                  inventory, or other valuable assets.
                </li>
                <li>
                  Cash Flow: Lenders often evaluate your business's cash flow to
                  determine your ability to repay the loan.
                </li>
              </ul>
            </div>
          </div>
        </section>
        <section class="spa-slide" id="tab-doc">
          <div class="container">
            <div class="elegb_sec text-left">
              <div class="elegb_titl">
                Documents required to apply for Business Loan
              </div>
              <div class="elegb_txt">
                <ul>
                  <li>
                    <strong>Identity Proof</strong> - Passport, Voter’s ID,
                    Driving License, PAN Card, Aadhaar Card
                  </li>
                  <li>
                    <strong>Proof of Residence or Address Proof</strong> -
                    Passport, Voter’s ID, Driving License, PAN Card, Aadhaar
                    Card, Electricity Bill, Telephone Bill, Ration Card{' '}
                  </li>
                  <li>
                    <strong>Age Proof </strong>- Passport, Voter’s ID, Driving
                    License, PAN Card, Aadhaar Card
                  </li>
                  <li>
                    Last Financial Year's provisional Financials and future
                    year's projections.
                  </li>
                  <li>Udhayam Aadhar registration certificate</li>
                  <li>
                    GST registration certificate and GST returns of latest 2
                    years.
                  </li>
                  <li>
                    <strong>Photograph</strong> - 2 photographs of promoters and
                    property owners.
                  </li>
                  <li>
                    <strong>Business proof</strong> - Business registration
                    documents such as a partnership deed, Memorandum of
                    Association (MOA), Articles of Association (AOA), etc.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <section class="spa-slide" id="tab-emi">
          <div class="container">
            <div class="elegb_sec text-left">
              <div class="elegb_titl">EMI Calculator for Business Loan</div>
              <div className="prtnr_btn">
                <a
                  data-bs-toggle="modal"
                  className="cal_btn text-white btn"
                  data-toggle="modal"
                  data-target="#staticBackdrop"
                  href="#"
                >
                  Calculate Your Loan EMI
                </a>
              </div>
            </div>
          </div>
        </section>
        <section class="spa-slide" id="tab-fees">
          <div class="container">
            <div class="elegb_sec text-left">
              <div class="elegb_titl">Fees and Charges for Business Loan</div>
              <div class="elegb_txt">
                The fees and charges of Business loans will vary between lenders
                and cases. Following table will give you fair idea of the fees
                and charges related to Business loans:
              </div>

              <table class="table mt-lg-5 mt-3 table-striped">
                <thead class="thead-dark">
                  <tr>
                    <th scope="col">Particulars</th>
                    <th scope="col">Charges</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Loan Processing Fees</td>
                    <td>@4999/- to 5% of Loan Amount</td>
                  </tr>
                  <tr>
                    <td>Loan Cancellation</td>
                    <td>Usually 3000 to 5% of Loan Amount</td>
                  </tr>
                  <tr>
                    <td>Stamp Duty Charges</td>
                    <td>150/- to 300/-</td>
                  </tr>
                  <tr>
                    <td>Legal Fees</td>
                    <td>As per actuals</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </section>
        {/* <section class="spa-slide" id="tab-rev">
          <div class="container">
            <div class="elegb_sec text-left">
              <div class="elegb_titl">Business Loan Reviews</div>
              <div class="teseti_sec">
                <div class="row">
                  <div class="col-lg-6 col-md-6 col-12">
                    <div class="tab_testi">
                      <img src="images/star_img.jpg" />
                      <div class="testi_inr">
                        Anim pariatur cliche reprehenderit, enim eiusmod high
                        life accusamus terry richardson ad squid. 3 wolf moon
                        officia aute, non cupidatat skateboard dolor brunch.
                        Food truck quinoa nesciunt laborum eiusmod. Brunch 3
                        wolf moon tempor, sunt aliqua put a bird on it squid
                        single-origin coffee nulla assumenda shoreditch et.
                      </div>
                      <div class="testi_author">Sanatan Jha</div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-12">
                    <div class="tab_testi">
                      <img src="images/star_img.jpg" />
                      <div class="testi_inr">
                        Anim pariatur cliche reprehenderit, enim eiusmod high
                        life accusamus terry richardson ad squid. 3 wolf moon
                        officia aute, non cupidatat skateboard dolor brunch.
                        Food truck quinoa nesciunt laborum eiusmod. Brunch 3
                        wolf moon tempor, sunt aliqua put a bird on it squid
                        single-origin coffee nulla assumenda shoreditch et.
                      </div>
                      <div class="testi_author">Sanatan Jha</div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-12">
                    <div class="tab_testi">
                      <img src="images/star_img.jpg" />
                      <div class="testi_inr">
                        Anim pariatur cliche reprehenderit, enim eiusmod high
                        life accusamus terry richardson ad squid. 3 wolf moon
                        officia aute, non cupidatat skateboard dolor brunch.
                        Food truck quinoa nesciunt laborum eiusmod. Brunch 3
                        wolf moon tempor, sunt aliqua put a bird on it squid
                        single-origin coffee nulla assumenda shoreditch et.
                      </div>
                      <div class="testi_author">Sanatan Jha</div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-12">
                    <div class="tab_testi">
                      <img src="images/star_img.jpg" />
                      <div class="testi_inr">
                        Anim pariatur cliche reprehenderit, enim eiusmod high
                        life accusamus terry richardson ad squid. 3 wolf moon
                        officia aute, non cupidatat skateboard dolor brunch.
                        Food truck quinoa nesciunt laborum eiusmod. Brunch 3
                        wolf moon tempor, sunt aliqua put a bird on it squid
                        single-origin coffee nulla assumenda shoreditch et.
                      </div>
                      <div class="testi_author">Sanatan Jha</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        <section class="spa-slide" id="tab-faq">
          <div class="container">
            <div class="faq_outer">
              <div class="container">
                <div class="procs_titl text-left">
                  Frequently Asked Questions
                </div>
                <div class="accordion mt-lg-5" id="accordionExample">
                  <div class="card">
                    <div class="card-header" id="headingOne">
                      <h2 class="mb-0">
                        <button
                          class="btn btn-link btn-block text-left"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                        >
                          What is a business loan?
                        </button>
                      </h2>
                    </div>

                    <div
                      id="collapseOne"
                      class="collapse show"
                      aria-labelledby="headingOne"
                      data-parent="#accordionExample"
                    >
                      <div class="card-body">
                        A business loan is money borrowed from a bank or lender
                        to support business-related expenses, such as expansion,
                        equipment purchase, working capital, or other
                        operational needs. The borrowed amount is repaid over
                        time with interest.
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header" id="headingTwo">
                      <h2 class="mb-0">
                        <button
                          class="btn btn-link btn-block text-left collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                        >
                          What types of business loans are available?
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapseTwo"
                      class="collapse"
                      aria-labelledby="headingTwo"
                      data-parent="#accordionExample"
                    >
                      <div class="card-body">
                        There are various types of business loans, including
                        term loans, lines of credit, equipment financing, SBA
                        loans, invoice financing, and merchant cash advances,
                        each designed to meet specific business needs.
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header" id="headingThree">
                      <h2 class="mb-0">
                        <button
                          class="btn btn-link btn-block text-left collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseThree"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                        >
                          How do I qualify for a business loan?
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapseThree"
                      class="collapse"
                      aria-labelledby="headingThree"
                      data-parent="#accordionExample"
                    >
                      <div class="card-body">
                        Qualification criteria vary but often include factors
                        like your business's creditworthiness, financial
                        stability, time in business, revenue, and the purpose of
                        the loan.
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header" id="headingThree">
                      <h2 class="mb-0">
                        <button
                          class="btn btn-link btn-block text-left collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseThree"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                        >
                          What interest rate can I expect?
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapseThree"
                      class="collapse"
                      aria-labelledby="headingThree"
                      data-parent="#accordionExample"
                    >
                      <div class="card-body">
                        Interest rates vary based on factors such as the type of
                        loan, lender, your creditworthiness, and market
                        conditions. Good credit and a strong business history
                        may lead to lower rates. The rate slabs of different
                        banks and nbfcs are attached in the last point of
                        content.
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header" id="headingThree">
                      <h2 class="mb-0">
                        <button
                          class="btn btn-link btn-block text-left collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseThree"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                        >
                          How do I apply for a business loan?
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapseThree"
                      class="collapse"
                      aria-labelledby="headingThree"
                      data-parent="#accordionExample"
                    >
                      <div class="card-body">
                        Applying for a business loan involves filling out an
                        application form, providing required documents (business
                        plan, financial statements, etc.), and potentially
                        undergoing a credit check.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      {/* <div class="choose_outer">
        <div class="container">
          <div class="row">
            <div class="col-lg-6 col-md-6 col-12">
              <div class="trust_sec">
                <div class="ch_sub">Transparent Process</div>
                <div class="ch_titl">
                  Why Do People <strong>Trust Us</strong>
                </div>
                <div class="ph_img_fotter">
                  <img src="images/phone_img.png" alt="" />
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-12">
              <div class="row">
                <div class="col-lg-6 col-md-6 col-12">
                  <div class="ch_sec">
                    <div class="ch_ico">
                      <img src="images/ch_ico.png" alt="" />
                    </div>
                    <div class="ch_name">Compare Lenders</div>
                    <div class="ch_txt">
                      You can compare and choose your lender from an exhaustive
                      list of 100+ partnered Banks & NBFCs.
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-12">
                  <div class="ch_sec">
                    <div class="ch_ico">
                      <img src="images/ch_ico2.png" alt="" />
                    </div>
                    <div class="ch_name">Fastest Process</div>
                    <div class="ch_txt">
                      Our easy application process provides quick and seamless
                      disbursals to our clients.
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-12">
                  <div class="ch_sec">
                    <div class="ch_ico">
                      <img src="images/ch_ico3.png" alt="" />
                    </div>
                    <div class="ch_name">Expert Guidance</div>
                    <div class="ch_txt">
                      Our financial experts with astronomical experience assist
                      you in borrowing right.
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-12">
                  <div class="ch_sec">
                    <div class="ch_ico">
                      <img src="images/ch_ico4.png" alt="" />
                    </div>
                    <div class="ch_name">Nationwide Presence</div>
                    <div class="ch_txt">
                      With a PAN India presence, we are able to cater to the
                      financial needs of individuals in every corner.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* Footer */}
      <footer className="footer_outer mt-5">
        <div className="container">
          <div className="row px-lg-5">
            <div className="col-lg-4 col-md-6 col-12">
              <div className="abt_foot">
                <div className="foot_titl">About Us</div>
                <div className="foot_txt pe-lg-5">
                  Anex Finserv Financial Service Ltd is a one-stop-shop for all
                  your financial needs, advice. With a PAN India presence
                  founded by professionals and financial experts who with an
                  experience of 20+ years. We have created a rich profile of
                  satisfying the needs of more than 10 lakhs+ clients. Our
                  algorithmic engines help you by using strong factual data made
                  from relative latest changes in the market and understanding
                  of the bank requirement
                </div>
                <div className="social_sec">
                  <a href="#">
                    <img src="images/fb.png" />
                  </a>
                  <a href="#">
                    <img src="images/linkd.png" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-12 ps-lg-5">
              <div className="abt_foot">
                <div className="foot_titl">Business</div>
                <div className="foot_txt">
                  <ul>
                    <li>
                      <a href="#">Home Loan</a>
                    </li>
                    <li>
                      <a href="#">Business Loan</a>
                    </li>
                    <li>
                      <a href="#">Business Loan</a>
                    </li>
                    <li>
                      <a href="#">Car Loan</a>
                    </li>
                    <li>
                      <a href="#">Car Refinance</a>
                    </li>
                    <li>
                      <a href="#">Used Car Loan</a>
                    </li>
                    <li>
                      <a href="#">Gold Loan</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-12 ps-lg-4">
              <div className="abt_foot">
                <div className="foot_titl">CALCULATORS</div>
                <div className="foot_txt">
                  <ul>
                    <li>
                      <a href="#">Business Loan</a>
                    </li>
                    <li>
                      <a href="#">Home Loan</a>
                    </li>
                    <li>
                      <a href="#">Business Loan</a>
                    </li>
                    <li>
                      <a href="#">Loan Against Property</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-6 col-12">
              <div className="abt_foot">
                <div className="foot_titl">CONTACT DETAILS</div>
                <div className="foot_txt">
                  <ul>
                    <li>
                      <a href="#">+91 7890007785</a>
                    </li>
                    {/* <li>
                      <a href="#">+91 2134 568 789</a>
                    </li>
                    <li>
                      <a href="#">@Tech Query</a>
                    </li>
                    <li>
                      <a href="#">@Sales Query</a>
                    </li>
                    <li>
                      <a href="#">@Service Request</a>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="footer_btm">
            <div className="row">
              <div className="col-lg-6 ol-md-6 col-12">
                <div className="copy_right">
                  Copyright @2023 All Rights Reserved
                </div>
              </div>
              <div className="col-lg-6 ol-md-6 col-12">
                <div className="copy_right text-end">
                  <a href="#"> Privacy & Policy</a> |{' '}
                  <Link data-toggle="modal" data-target="#terms_condition">
                    terms &amp; conditions
                  </Link>
                </div>
              </div>
              {/* <!-- Modal --> */}
              <div
                className="modal fade"
                id="terms_condition"
                data-backdrop="static"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="staticBackdropLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="staticBackdropLabel">
                        Terms And Conditions
                      </h5>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <p>
                        Please understand the following terms and conditions{' '}
                        <br /> 1. Commision payout amount will depend on various
                        condition like Loan Type,Processing Bank, approveed
                        interest rate etc. <br /> 2. Payout typically creited to
                        bank account within 10th day of each month but can be
                        delayed occationaly. <br /> 3. Final Payout amount will
                        be notified only after Loan Amount disbursed to
                        Customer's Account. <br /> 4. In case of any
                        query/clarification you need to notify within 15
                        Calendar Days.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div
        className="modal fade"
        id="staticBackdrop"
        data-backdrop="static"
        data-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header p-2">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="container">
                <div className="row">
                  <div className="col col-main">
                    <div className="calc_titl">Enter Loan Data</div>
                    <label htmlFor="amount" className="label">
                      Amount of the loan
                    </label>
                    <div className="input-wrapper">
                      <span className="input-addon">₹</span>
                      <input
                        id="amount"
                        className="input-field"
                        type="text"
                        defaultValue="15,000,00"
                      />
                    </div>
                    <label htmlFor="apr" className="label">
                      Annual interest rate
                    </label>
                    <div className="input-wrapper">
                      <input
                        id="apr"
                        className="input-field"
                        type="text"
                        defaultValue="8.5"
                      />
                      <span className="input-addon">%</span>
                    </div>
                    <label htmlFor="years" className="label">
                      Repayment period
                    </label>
                    <div className="input-wrapper">
                      <input
                        id="years"
                        className="input-field"
                        type="text"
                        defaultValue={20}
                      />
                      <span className="input-addon">years</span>
                    </div>
                    {/*
<label for="zipcode" className="label">Zipcode (to find lenders)</label>
<div className="input-wrapper">
  <input id="zipcode" className="input-field" type="text">
</div>
*/}
                    <button className="btn_cal" onClick={calculate}>
                      Calculate
                    </button>
                  </div>
                  <div className="col">
                    <div className="calc_titl">Payments</div>
                    <div className="output-wrapper">
                      <p className="label">Monthly payment</p>
                      <p className="output-value">
                        ₹<span id="payment" />
                      </p>
                    </div>
                    <div className="output-wrapper">
                      <p className="label">Total payments</p>
                      <p className="output-value">
                        ₹<span id="total" />
                      </p>
                    </div>
                    <div className="output-wrapper">
                      <p className="label">Total interest payments</p>
                      <p className="output-value">
                        ₹<span id="totalinterest" />
                      </p>
                    </div>
                  </div>
                </div>
                <div className="calc_titl">Balance vs. Payments</div>
                <canvas id="graph" />
                <div className="legend">
                  <div className="legend-item">
                    <div
                      className="legend-swatch"
                      id="total-interest-payments-swatch"
                    />
                    <div className="legend-label">Total Interest Payments</div>
                  </div>
                  <div className="legend-item">
                    <div className="legend-swatch" id="total-equity-swatch" />
                    <div className="legend-label">Total Equity</div>
                  </div>
                  <div className="legend-item">
                    <div className="legend-swatch" id="loan-balance-swatch" />
                    <div className="legend-label">Loan Balance</div>
                  </div>
                </div>
                <footer />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Apply loan */}
      <div
        className="modal fade"
        id="staticBackdrop2"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header p-2">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="form_sec_pop">
                <form
                  action
                  id="prtnrform"
                  onSubmit={handleSubmit(clickRegister)}
                >
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-12">
                      <div className="form_radio">
                        <label>Occupation*</label>
                        <input
                          type="radio"
                          id="ocp"
                          name="ocp"
                          value={'Salaried'}
                          {...register('occupation', {
                            required: 'Occupation is required',
                          })}
                        />{' '}
                        Salaried
                        <input
                          type="radio"
                          id="ocp"
                          name="ocp"
                          value={'Self-Employed'}
                          {...register('occupation', {
                            required: 'Occupation is required',
                          })}
                        />{' '}
                        Self-Employed
                      </div>{' '}
                      {errors.occupation && (
                        <p className="text-danger">
                          {errors.occupation.message}
                        </p>
                      )}
                    </div>
                    <div className="col-lg-12 col-md-12 col-12">
                      <div className="form_select_sec">
                        <select
                          {...register('loanType', {
                            required: 'Loan type is required',
                          })}
                        >
                          <option value="">Select Loan Type</option>
                          <option value="Home Loan">Home Loan</option>
                          <option value="Car Loan">Car Loan</option>
                          <option value="Business Loan">Business Loan</option>
                          <option value="Gold Loan">Gold Loan</option>
                          <option value="Personal Loan">Personal Loan</option>
                        </select>
                      </div>
                      {errors.loanType && (
                        <p className="text-danger">{errors.loanType.message}</p>
                      )}
                    </div>
                    <div className="col-lg-6 col-md-6 col-12">
                      <div className="form_sec">
                        <input
                          type="text"
                          id="fname"
                          name="fname"
                          placeholder="First Name"
                          {...register('customerFirstName', {
                            required: 'Customer first name is required',
                            maxLength: {
                              value: 50,
                              message: 'Maximum 50 characters',
                            },
                          })}
                          onKeyUp={() => {
                            trigger('customerFirstName');
                          }}
                          onKeyDown={(e) => checkIfTextOnly(e)}
                        />
                      </div>
                      {errors.customerFirstName && (
                        <p className="text-danger">
                          {errors.customerFirstName.message}
                        </p>
                      )}
                    </div>
                    <div className="col-lg-6 col-md-6 col-12">
                      <div className="form_sec">
                        <input
                          type="text"
                          id="lname"
                          name="lname"
                          placeholder="Last Name"
                          {...register('customerLastName')}
                          onKeyDown={(e) => checkIfTextOnly(e)}
                        />
                      </div>{' '}
                      {errors.customerLastName && (
                        <p className="text-danger">
                          {errors.customerLastName.message}
                        </p>
                      )}
                    </div>
                    <div className="col-lg-6 col-md-6 col-12">
                      <div className="form_sec">
                        <input
                          type="tel"
                          id="phone"
                          name="phone"
                          placeholder="Phone No"
                          {...register('customerMobileNumber', {
                            required: 'Customer phone no is required',
                            pattern: {
                              value: /^[0-9]{10}$/,
                              message: 'Please enter 10 digit phone no',
                            },
                          })}
                          onKeyUp={() => {
                            trigger('customerMobileNumber');
                          }}
                          onKeyDown={(e) => checkIfNumber(e)}
                        />
                      </div>{' '}
                      {errors.customerMobileNumber && (
                        <p className="text-danger">
                          {errors.customerMobileNumber.message}
                        </p>
                      )}
                    </div>
                    <div className="col-lg-6 col-md-6 col-12">
                      <div className="form_sec">
                        <input
                          type="email"
                          id="mail"
                          name="email"
                          placeholder="Email"
                          {...register('customerEmailId', {
                            required: 'Customer email is required',
                            pattern: {
                              value:
                                /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                              message: 'Please enter a valid email',
                            },
                          })}
                          onKeyUp={() => {
                            trigger('customerEmailId');
                          }}
                        />
                      </div>{' '}
                      {errors.customerEmailId && (
                        <p className="text-danger">
                          {errors.customerEmailId.message}
                        </p>
                      )}
                    </div>
                    <div className="col-lg-6 col-md-6 col-12">
                      <div className="form_sec">
                        <input
                          type="number"
                          id="amount"
                          name="amount"
                          placeholder="Required Loan Amount"
                          {...register('loanAmountAppliedFor', {
                            required: 'Loan Amount is required',
                          })}
                          onKeyUp={() => {
                            trigger('loanAmountAppliedFor');
                          }}
                          onKeyDown={(e) => checkIfNumber(e)}
                        />
                      </div>{' '}
                      {errors.loanAmountAppliedFor && (
                        <p className="text-danger">
                          {errors.loanAmountAppliedFor.message}
                        </p>
                      )}
                    </div>

                    {watch().occupation === 'Salaried' ? (
                      <>
                        <div className="col-lg-6 col-md-6 col-12">
                          <div className="form_sec">
                            <input
                              type="text"
                              id="cname"
                              name="cname"
                              placeholder="Company Name"
                              {...register('companyName', {
                                required: 'Company Name is required',
                              })}
                            />
                          </div>
                          {errors.companyName && (
                            <p className="text-danger">
                              {errors.companyName.message}
                            </p>
                          )}
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                          <div className="form_select_sec">
                            <select
                              {...register('companyType', {
                                required: 'Company type is required',
                              })}
                            >
                              <option value="" selected>
                                Select Company Type
                              </option>
                              <option value="public">Public Limited</option>
                              <option value="private">Private Limited</option>
                              <option value="goverment">Goverment</option>
                              <option value="proprietorship">
                                Proprietorship
                              </option>
                              <option value="partnership">Partneship</option>
                              <option value="limited liability partenrship">
                                Limited Liability Partnership
                              </option>
                            </select>
                          </div>
                          {errors.companyType && (
                            <p className="text-danger">
                              {errors.companyType.message}
                            </p>
                          )}
                        </div>
                      </>
                    ) : (
                      <></>
                    )}

                    <div className="col-lg-6 col-md-6 col-12">
                      <div className="form_sec">
                        <input
                          type="text"
                          id="pinc"
                          name="pinc"
                          placeholder="PIN Code"
                          {...register('pinCode', {
                            required: 'Pin code is required',
                            pattern: {
                              value: /^[1-9][0-9]{5}$/,
                              message: 'Pin code sould be 6 digit',
                            },
                          })}
                          onKeyUp={() => {
                            trigger('pinCode');
                          }}
                          onKeyDown={(e) => checkIfNumber(e)}
                        />
                      </div>
                      {errors.pinCode && (
                        <p className="text-danger">{errors.pinCode.message}</p>
                      )}
                    </div>
                    <div className="col-lg-6 col-md-6 col-12">
                      <div className="form_sec">
                        <input
                          type="text"
                          id="city"
                          name="city"
                          placeholder="City"
                          {...register('city')}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-12">
                      <div className="form_sec">
                        <input
                          type="text"
                          id="state"
                          name="state"
                          placeholder="State"
                          {...register('State')}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12 col-12"></div>
                  {loading === true ? (
                    <>{showLoading()}</>
                  ) : (
                    <>
                      <div className="form_btn">
                        {' '}
                        <input type="submit" defaultValue="Apply Now" />{' '}
                      </div>
                    </>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BusinessLoan;

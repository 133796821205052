import React, { useEffect, useState } from 'react';
import Layout from '../components/Layout';
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useHistory, useLocation } from 'react-router-dom';

const AgentPayout = ({ history }) => {
  const MySwal = withReactContent(Swal);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [status, setStatus] = useState(null);
  const [payout, setPayout] = useState({});
  const [datediff, setDatediff] = useState('');
  const [processLoanList, setProcessLoanList] = useState([]);
  const [processLoanListDate, setProcessLoanListDate] = useState([]);
  const [processLoanListData, setProcessLoanListData] = useState({});
  const [BankList, setBankList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loanTypes, setLoanTypes] = useState([]);
  const [selectloanTypes, setSelectLoanTypes] = useState('');
  const [networkLevel, setNetworkLevel] = useState('');
  const [selectBank, setSelectBank] = useState('');

  const location = useLocation();
  const queryParameters = new URLSearchParams(location.search);

  useEffect(() => {
    if (localStorage.getItem('userType') === null) {
      history.push('/');
    } else if (localStorage.getItem('userType') === 'Agent') {
      const userData = JSON.parse(localStorage.getItem('loggedInAgent'));
      const agentId = userData.agentId;
      const jwt = JSON.parse(localStorage.getItem('jwt'));

      const fetchdata = async () => {
        let statusValue =
          queryParameters.get('loan') === 'Pending'
            ? queryParameters.get('loan')
            : 'Payouts';
        await fetch(
          `${process.env.REACT_APP_API_URL}/viewLoanPayoutWithStatus/${agentId}/${statusValue}`,
          {
            method: 'GET',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              Authorization: `Bearer ${jwt}`,
            },
          }
        )
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            setPayout(data);
            queryParameters.get('loan') === 'Pending'
              ? setProcessLoanList(data.pendingLoanList)
              : setProcessLoanList(data.processedLoanList);
          });
      };
      fetchdata();

      const fetchBankdata = async () => {
        await fetch(`${process.env.REACT_APP_API_URL}/getBankList`, {
          method: 'GET',
          headers: {
            Accept: 'application/json',
          },
        })
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            setBankList(data);
          });
      };
      fetchBankdata();
      const fetchDataLoanType = async () => {
        try {
          await fetch(`${process.env.REACT_APP_API_URL}/getLoanTypes`, {
            method: 'GET',
            headers: {
              Accept: 'application/json',
              Authorization: `Bearer ${jwt}`,
            },
          })
            .then((res) => {
              return res.json();
            })
            .then((data) => {
              setLoanTypes(data);
            });
        } catch (error) {
          console.log(error);
        }
      };
      fetchDataLoanType();
    } else {
      history.push('/');
    }
  }, []);

  const payoutdaterange = () => {
    if (startDate === null) {
      MySwal.fire({
        text: 'Please select start date',
      });
    } else if (endDate === null) {
      MySwal.fire({
        text: 'Please select end date',
      });
    } else if (status === null) {
      MySwal.fire({
        text: 'Please select loan status',
      });
    } else {
      const sDate = moment(startDate).format('DD-MM-YYYY');
      const eDate = moment(endDate).format('DD-MM-YYYY');
      const userData = JSON.parse(localStorage.getItem('loggedInAgent'));
      const agentId = userData.agentId;

      var date1 = moment(startDate);
      var date2 = moment(endDate);
      // day count
      setDatediff(date2.diff(date1, 'days'));
      const jwt = JSON.parse(localStorage.getItem('jwt'));

      const fetchdata = async () => {
        setLoading(true);
        await fetch(
          `${process.env.REACT_APP_API_URL}/viewLoanPayoutWithStatus/${agentId}/${status}?endDate=${eDate}&startDate=${sDate}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${jwt}`,
            },
          }
        )
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            if (data.pendingLoanList === null) {
              setProcessLoanListDate(data.processedLoanList);
              setProcessLoanListData(data);
            } else {
              setProcessLoanListDate(data.pendingLoanList);
              setProcessLoanListData(data);
            }
            setLoading(false);
          });
      };
      const networkLevelParam = networkLevel
        ? `&networkLevel=${networkLevel}`
        : '';

      const processingBankIdParam = selectBank
        ? `&processingBankId=${selectBank}`
        : '';

      const selectloanTypesParam = selectloanTypes
        ? `&loanType=${selectloanTypes}`
        : '';
      const payoutdata = async () => {
        setLoading(true);
        const jwt = JSON.parse(localStorage.getItem('jwt'));

        await fetch(
          `${process.env.REACT_APP_API_URL}/viewLoanPayoutWithProvidedCriteria/${agentId}/${status}?endDate=${eDate}&startDate=${sDate}${selectloanTypesParam}${networkLevelParam}${processingBankIdParam}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${jwt}`,
            },
          }
        )
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            if (data.pendingLoanList === null) {
              setProcessLoanListDate(data.processedLoanList);
              setProcessLoanListData(data);
            } else {
              setProcessLoanListDate(data.pendingLoanList);
              setProcessLoanListData(data);
            }
            setLoading(false);
          });
      };

      if (status === 'Payouts') {
        payoutdata();
      } else {
        fetchdata();
      }
    }
  };

  const showLoading = () =>
    loading && (
      <div className="alert alert-info">
        <h2>Loading...</h2>
      </div>
    );

  const payoutData = (loan) => (
    <>
      <div className="card agentPayoutCard mb-3 shadow-sm ">
        <div className="row">
          <>
            <div className="col-lg-4 col-6 ">
              <p className="m-0 mt-1">
                Loan Account:{' '}
                <span className="p-payoutper text-center badge">
                  {loan.loanAccountNumber}
                </span>
              </p>
              <p className="m-0 mt-1">
                Applied For:{' '}
                <span className="badge bg-warning p-2">{loan.loanType}</span>
              </p>
              <p className="m-0 mt-1">
                Approve Amount: &#x20B9;
                {loan.loanApprovalAmount}
              </p>
              <p className="m-0 mt-1">
                Rate Of Interest: {loan.loanApprovalInterestRate}
              </p>

              <p className="m-0 mt-1">
                <i className="fa-solid fa-building-columns"></i> Bank Name:{' '}
                {loan.loanProviderBankName}
              </p>
              {loan.leadSharedByBrokerCode !== null ? (
                <>
                  {' '}
                  <p className="text-muted m-0 mt-2">
                    Lead Shared By Broker Code: {loan.leadSharedByBrokerCode}{' '}
                  </p>
                  <p className="text-muted m-0 mt-2">
                    Lead Id:{loan.loanLeadIdVirtual}
                  </p>{' '}
                </>
              ) : (
                <>
                  <p className="text-muted m-0 mt-2">CUSTOMER</p>
                  <p className="mt-2">
                    <i className="fa-solid fa-user"></i> {loan.customerName}
                  </p>
                  <p className="m-0 mt-1">
                    <i className="fa-solid fa-phone"></i>{' '}
                    {loan.customerMobileNumber}
                  </p>
                </>
              )}
            </div>
            <div className="col-lg-4 col-6 ">
              {/* <p className="m-0 mt-1">
                <span className="bg-warning p-2">{loan.loanType}</span>
              </p> */}

              <p className="m-0 mt-2">
                Disbursed Amount:{' '}
                {loan.loanDisbursedAmount && (
                  <>
                    <span className="p-payoutper text-center badge">
                      &#x20B9;{loan.loanDisbursedAmount}
                    </span>
                  </>
                )}
              </p>
              {/* <p className=" m-0 mt-1">
                Payout Percentage:{' '}
                <span className="p-payoutper text-center badge ">
                  {loan.payoutPercentageToDisplay}%
                </span>
              </p> */}

              <p className="m-0 mt-1">
                Gross Payout Amount:{' '}
                {loan.payoutAmount && (
                  <span className="p-payoutper text-center badge">
                    &#x20B9; {loan.payoutAmount}
                  </span>
                )}
              </p>

              <p className="m-0 mt-1">
                TDS
                {loan.tds && (
                  <span className="p-payoutper text-center badge ml-2">
                    &#x20B9; {loan.tds}
                  </span>
                )}
              </p>

              <p className="m-0 mt-1">
                Net payout amount
                {loan.netPayoutAmount && (
                  <span className="p-payoutper text-center badge ml-2">
                    &#x20B9; {loan.netPayoutAmount}
                  </span>
                )}
              </p>

              <p className="m-0 mt-1">
                Approved Date: <i className="fa-regular fa-calendar"></i>{' '}
                {new Date(loan.approvedDate).toLocaleDateString()}
              </p>
              <p className="m-0 mt-1">
                Disbursed Date : <i className="fa-regular fa-calendar"></i>{' '}
                {new Date(loan.loanDisbursedDate).toLocaleDateString()}
              </p>

              <p className="m-0 mt-1">
                Payout Date: <i className="fa-regular fa-calendar"></i>{' '}
                {new Date(loan.payoutDate).toLocaleDateString()}
              </p>
            </div>
            <div className="col-lg-4 col ">
              <p className=" bg-secondary p-loanstatus rounded text-white">
                {loan.statusDetails}
              </p>
            </div>
          </>
        </div>
      </div>
    </>
  );

  return (
    <>
      <Layout>
        <ToastContainer />
        <div className="inner_banner text-center">
          <div className="container">Agent Payout</div>
        </div>
        <div className="container-fluid mt-5 mb-5">
          <div className="border agentp-container rounded shadow ">
            {/* <button className="btn d-block px-4 btn-primary rounded-0">
              View Payout
            </button> */}
            <div className="row justify-content-center text-center p-4">
              <label className="mt-2">Lead Initiate Date Range</label>

              <div className="col-md col-lg-auto mb-3">
                <input
                  type="date"
                  id="startdate"
                  className="form-control rounded-0"
                  max={moment().format('YYYY-MM-DD')}
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </div>
              <div className="col-md col-lg-auto sedate">
                <label className="mt-2">-</label>
              </div>
              <div className="col-md col-lg-auto ">
                <input
                  type="date"
                  className="form-control rounded-0"
                  min={moment(startDate).format('YYYY-MM-DD')}
                  max={moment().format('YYYY-MM-DD')}
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </div>

              <div className="col-md col-lg-auto">
                <select
                  className="form-control text-capitalize status-dropd"
                  onChange={(e) => setStatus(e.target.value)}
                  // value={queryParameters.get('loan')}
                >
                  <option value="" selected>
                    {' '}
                    Select Loan Status
                  </option>

                  <option value="Payouts">Payouts</option>
                  <option value="Pending">Pending</option>
                </select>
              </div>

              {status === 'Payouts' && (
                <>
                  <div className="col-md col-lg-auto">
                    <select
                      className="form-control text-capitalize status-dropd"
                      onChange={(e) => setSelectLoanTypes(e.target.value)}
                    >
                      <option value="" selected>
                        {' '}
                        Select Loan Type
                      </option>
                      {loanTypes.map((data, i) => (
                        <option key={i} value={data.description}>
                          {data.description}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-md col-lg-auto">
                    <select
                      className="form-control text-capitalize status-dropd"
                      onChange={(e) => setNetworkLevel(e.target.value)}
                    >
                      <option value="" selected>
                        {' '}
                        Select Lead Network Level
                      </option>
                      <option value="0">Self</option>
                      <option value="1">Level 1</option>
                      <option value="2">Level 2</option>
                      <option value="3">Level 3</option>
                    </select>
                  </div>

                  <div className="col-md col-lg-auto">
                    <select
                      className="form-control text-capitalize status-dropd"
                      onChange={(e) => setSelectBank(e.target.value)}
                    >
                      <option value="" selected>
                        {' '}
                        Select Bank Name
                      </option>
                      {BankList.map((bank, i) => (
                        <option key={i} value={bank.bankId}>
                          {bank.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </>
              )}

              <div className="col-md col-lg-auto ">
                <button
                  type="submit"
                  onClick={payoutdaterange}
                  className="btn search-btn btn-primary rounded-0"
                >
                  Search
                </button>
              </div>
            </div>
            {/* Payout Data for the selected date range --- */}
            {processLoanListDate.length > 0 ? (
              <>
                {showLoading()}
                <div>
                  <div className="card-body agentPayout-card">
                    <p className="text-center mt-5">
                      {' '}
                      TOTAL{' '}
                      <span className="text-warning">
                        <i className="fa-solid fa-indian-rupee-sign"></i>{' '}
                        {processLoanListData.processedPayOutAmount}
                      </span>{' '}
                      IN <span className="text-warning">{datediff} DAYS</span>
                    </p>
                    {processLoanListDate.map((loan, i) => payoutData(loan))}
                  </div>
                </div>
              </>
            ) : (
              <>
                {processLoanListData.processedLoanList == '' ||
                processLoanListData.pendingLoanList == '' ? (
                  <p className="text-center text-danger mt-3">No Data Found</p>
                ) : (
                  <></>
                )}
              </>
            )}
            {/* -- End Payout Data for the selected date range */}
            <hr />
            {processLoanListDate.length > 0 ||
            processLoanListData.processedLoanList == '' ||
            processLoanListData.pendingLoanList == '' ? (
              <></>
            ) : (
              <>
                <p className="text-center mt-5">
                  {' '}
                  TOTAL{' '}
                  <span className="text-warning">
                    <i className="fa-solid fa-indian-rupee-sign"></i>{' '}
                    {payout.processedPayOutAmount}
                  </span>{' '}
                  IN <span className="text-warning">30 DAYS</span>
                </p>
                <div>
                  <div className="card-body agentPayout-card">
                    {processLoanList.map((loan, i) => payoutData(loan))}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>

        <br />
        <div className="p-5 "></div>
      </Layout>
    </>
  );
};

export default AgentPayout;

import { React, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ClosedCustomerDocumentsDetails = ({ loanDocumentList }) => {
  return (
    <>
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content ">
          <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropLabel">
              Customer Documents Details
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div className="container-fluid text-left ">
              <div>
                <div className="row">
                  {loanDocumentList.map((data) => (
                    <>
                      <div className="col-md-6 py-3 px-lg-5 border bg-light ">
                        <p className="text-primary"> {data.documentCategory}</p>
                      </div>
                      <div className="col-md-6 py-3 px-lg-5 border bg-light ">
                        <a
                          href={`${process.env.REACT_APP_API_URL}/downloadDocument/${data.fileName}`}
                          className="btn btn-primary mr-1 ml-2"
                          download
                        >
                          View{' '}
                        </a>
                      </div>
                    </>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ClosedCustomerDocumentsDetails;

import React from 'react';
import Layout from '../components/Layout';
import { ToastContainer, toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const UserProfileActivation = (props) => {
  const { match, location, history } = props;
  const urlData = match.params.email;
  const MySwal = withReactContent(Swal);

  const {
    register,
    handleSubmit,
    watch,
    trigger,
    formState: { errors },
    reset,
  } = useForm();

  const forgotSubmit = (data) => {
    //e.preventDefault();

    fetch(
      `${process.env.REACT_APP_API_URL}/agents/generateOTP/{email}/{mobile}?email=${data.email}&mobile=${data.mobile}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.status === 'Success') {
          history.push('/validateotp');
        } else {
          MySwal.fire({
            text: 'Please check your email or phone no',
          });
        }
      })
      .catch((err) => {
        MySwal.fire({
          text: 'You have entered incorrect email or mobile number',
        });
      });
  };

  const checkIfNumber = (event) => {
    const regex = new RegExp(
      /(^\d*$)|(Backspace|Tab|Delete|ArrowLeft|ArrowRight)/
    );

    return !event.key.match(regex) && event.preventDefault();
  };

  return (
    <>
      <div className="inner_banner text-center">
        <div className="container">Activate User Profile</div>
      </div>
      <ToastContainer />
      <div className="container mb-5">
        <div className="row justify-content-center">
          <div className="col-lg-5">
            <h4 className="mb-4">Please Generate OTP</h4>
            <div className="reset-form shadow-sm">
              <form onSubmit={handleSubmit(forgotSubmit)}>
                <div className="input-group mb-4">
                  <div className="input-group-prepend">
                    <span className="input-group-text rounded-0" id="email">
                      <i className="fa-solid fa-envelope"></i>
                    </span>
                  </div>
                  <input
                    type="email"
                    className="form-control rounded-0"
                    id="email"
                    placeholder="Email"
                    aria-describedby="email"
                    value={urlData}
                    readOnly
                    {...register('email', {
                      required: 'Email is required',
                      pattern: {
                        value:
                          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        message: 'Please enter a valid email',
                      },
                    })}
                    onKeyUp={() => {
                      trigger('email');
                    }}
                  />
                </div>
                {errors.email && (
                  <p className="text-danger">{errors.email.message}</p>
                )}
                <div className="input-group mb-4">
                  <div className="input-group-prepend">
                    <span className="input-group-text rounded-0" id="mobile">
                      <i className="fa-solid fa-mobile"></i>
                    </span>
                  </div>
                  <input
                    type="number"
                    className="form-control rounded-0"
                    id="mobile"
                    placeholder="Mobile Number"
                    aria-describedby="mobile"
                    {...register('mobile', {
                      pattern: {
                        value: /^[0-9]{10}$/,
                        message: 'Please enter 10 digit phone no',
                      },
                    })}
                    onKeyUp={() => {
                      trigger('mobile');
                    }}
                    onKeyDown={(e) => checkIfNumber(e)}
                  />
                </div>
                {errors.mobile && (
                  <p className="text-danger">{errors.mobile.message}</p>
                )}
                <button type="submit" className="btn btn-primary rounded-0">
                  Generate OTP
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
    </>
  );
};

export default UserProfileActivation;

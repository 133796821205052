import React, { useEffect, useState } from 'react';
import Layout from '../components/Layout';
import moment from 'moment';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const PayoutWithdraw = () => {
  const [accordion, setActiveAccordion] = useState(-1);
  const [LoggedInAgent, setLoggedInAgnet] = useState([]);
  const [TransitionHistory, setTransitionHistory] = useState(null);
  const [searchAgentDashboard, setsearchAgentDashboard] = useState({
    startDate: '',
    endDate: '',
    searchData: null,
  });

  const [payoutProcess, setPayoutProcess] = useState({
    upi: '',
    panNumber: '',
    amount: '',
    successReq: false,
  });

  const { upi, panNumber, amount, successReq } = payoutProcess;
  const { startDate, endDate, searchData } = searchAgentDashboard;

  const MySwal = withReactContent(Swal);

  const toggleAccordion = (index) => {
    if (index === accordion) {
      setActiveAccordion(-1);
      return;
    }
    setActiveAccordion(index);
  };

  useEffect(() => {
    const jwt = JSON.parse(localStorage.getItem('jwt'));
    fetch(
      `${process.env.REACT_APP_API_URL}/viewAgentPayoutOverview/${
        JSON.parse(localStorage.getItem('loggedInAgent')).agentId
      }`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${jwt}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setLoggedInAgnet(data);
        setTransitionHistory(data.payoutTransactionHistoryList);
        setPayoutProcess({
          ...payoutProcess,
          upi: data.upiId,
          panNumber: data.agentPanNumber,
          amount: data.currentAvailableBalance,
        });
      });
  }, [successReq]);

  const searchSubmit = (e) => {
    e.preventDefault();
    const jwt = JSON.parse(localStorage.getItem('jwt'));
    const fetchdata = async () => {
      const sDate = moment(startDate).format('DD-MM-YYYY');
      const eDate = moment(endDate).format('DD-MM-YYYY');
      try {
        await fetch(
          `${process.env.REACT_APP_API_URL}/viewAgentPayoutHistory/${
            JSON.parse(localStorage.getItem('loggedInAgent')).agentId
          }?endDate=${eDate}&startDate=${sDate}`,
          {
            method: 'GET',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              Authorization: `Bearer ${jwt}`,
            },
          }
        )
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            setsearchAgentDashboard({
              ...searchAgentDashboard,
              searchData: data,
            });
          });
      } catch (error) {
        setsearchAgentDashboard({
          ...searchAgentDashboard,
          searchData: null,
        });
      }
    };
    fetchdata();
  };

  const searchHandleChange = (name) => (e) => {
    setsearchAgentDashboard({
      ...searchAgentDashboard,
      [name]: e.target.value,
    });
  };

  const payoutReqValue = (name) => (e) => {
    setPayoutProcess({
      ...payoutProcess,
      [name]: e.target.value,
    });
  };

  const leadlog = (data) => {
    return (
      <div className="table_cont">
        <div className="table-responsive">
          <table class="table table-bordered table-striped ">
            <thead>
              <tr className="text-center font-weight-bold">
                <th scope="col">Request Date </th>
                <th scope="col">Processed Date </th>
                <th scope="col">Amount </th>
                <th scope="col">Transaction Id </th>
                <th scope="col">Status </th>
              </tr>
            </thead>
            <tbody>
              {data.map((res, i) => {
                return (
                  <tr key={i}>
                    <td>
                      {moment(res.payoutWithdrawRequestDate).format(
                        'DD-MM-YYYY'
                      )}
                    </td>
                    <td>{res.payoutWithdrawProcessedDate}</td>
                    <td>{res.requestedWithdrawAmount}</td>
                    <td>{res.transactionId}</td>
                    <td>{res.transactionStatus}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  const withdrawSub = async (e) => {
    e.preventDefault();
    const jwt = JSON.parse(localStorage.getItem('jwt'));
    let agentId = JSON.parse(localStorage.getItem('loggedInAgent')).agentId;
    Swal.fire({
      text: 'Are you want to withdraw?',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/initiatePayoutWithdraw`,
            {
              method: 'POST',
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwt}`,
              },
              body: JSON.stringify({
                agentId: agentId,
                requestedWithdrawAmount: amount,
                requesterPanNumber: panNumber,
                upiNumber: upi,
              }),
            }
          );

          if (response.status === 200) {
            const data = await response.json();

            MySwal.fire({
              text: `${data.message}`,
            });
            setPayoutProcess({ ...payoutProcess, successReq: true });
          } else if (response.status === 500) {
            MySwal.fire({
              text: 'Something went wrong',
            });
          }
        } catch (error) {
          MySwal.fire({
            text: 'Something went wrong',
          });
        }
      }
    });
  };

  const UPISub = async (e) => {
    e.preventDefault();
    let agentId = JSON.parse(localStorage.getItem('loggedInAgent')).agentId;
    const jwt = JSON.parse(localStorage.getItem('jwt'));
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/agents/updateAgentPayoutDetails`,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${jwt}`,
          },
          body: JSON.stringify({
            agentId: agentId,
            upiNumber: upi,
          }),
        }
      );

      if (response.status === 200) {
        const data = await response.json();

        MySwal.fire({
          text: `${data.message}`,
        });
        setPayoutProcess({ ...payoutProcess, successReq: true });
      } else if (response.status === 500) {
        MySwal.fire({
          text: 'Something went wrong',
        });
      }
    } catch (error) {
      MySwal.fire({
        text: 'Something went wrong',
      });
    }
  };

  return (
    <div>
      <Layout>
        <div className="container mt-5 mb-5">
          <div className="card p-3 ">
            <h5 className="font-weight-bold ">
              <span className="text-primary"> Available Balance:</span>{' '}
              <span>{LoggedInAgent.currentAvailableBalance}</span>
            </h5>
            <div className="d-flex mt-3 justify-content-between card-header border">
              <h6 className="">Submit withdraw request</h6>{' '}
              <button
                className="btn-success btn btn-sm"
                onClick={() => toggleAccordion(1)}
              >
                {accordion === 1 ? (
                  <>
                    {' '}
                    <i className="fa-solid fa-minus"></i>
                  </>
                ) : (
                  <>
                    {' '}
                    <i className="fa-solid fa-plus"></i>
                  </>
                )}
              </button>
            </div>
            <div
              className={
                accordion === 1 ? 'active-accordion' : 'inactive-accordion'
              }
            >
              <div className="container pt-4 ">
                <form>
                  <div className="form-group row">
                    <label className="col-md-2 col-form-label"> UPI ID:</label>
                    <div className="col-md-5">
                      <input
                        type="text"
                        disabled={
                          LoggedInAgent.isUpdateUpiButtonEnabled === 'No'
                            ? true
                            : false
                        }
                        className="form-control"
                        defaultValue={upi}
                        onChange={payoutReqValue('upi')}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-md-2 col-form-label">
                      {' '}
                      Pan Number:{' '}
                    </label>
                    <div className="col-md-5">
                      <input
                        type="text"
                        disabled
                        className="form-control"
                        defaultValue={panNumber}
                        onChange={payoutReqValue('panNumber')}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-md-3">Amount to withdraw:</label>
                    <div class="col-md-4">
                      <input
                        type="text"
                        class="form-control"
                        disabled
                        defaultValue={amount}
                        onChange={payoutReqValue('amount')}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <button
                      type="submit"
                      class="btn btn-primary"
                      onClick={withdrawSub}
                      disabled={
                        LoggedInAgent.isWithdrawButtonEnabled === 'No'
                          ? true
                          : false
                      }
                    >
                      Withdraw
                    </button>
                    <button
                      type="submit"
                      class="ml-4 btn btn-success"
                      onClick={UPISub}
                      disabled={
                        LoggedInAgent.isUpdateUpiButtonEnabled === 'No'
                          ? true
                          : false
                      }
                    >
                      Update UPI ID
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <h3 className="mt-5">Payout History</h3>
          <div className="mt-4">
            <div className="date_range">
              <form
                id="form"
                name="form"
                onSubmit={searchSubmit}
                className="form-inline"
              >
                <div className="form-group">
                  <label for="startDate">Start Date</label>
                  <input
                    id="startDate"
                    name="startDate"
                    type="date"
                    className="form-control"
                    onChange={searchHandleChange('startDate')}
                    required
                  />
                  &nbsp;
                  <label for="endDate">End Date</label>
                  <input
                    id="endDate"
                    name="endDate"
                    type="date"
                    className="form-control"
                    onChange={searchHandleChange('endDate')}
                    required
                  />
                  <input className="srch_btn" type="submit" value="Search" />
                </div>
              </form>
              {searchData !== null
                ? leadlog(searchData)
                : TransitionHistory !== null && leadlog(TransitionHistory)}
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default PayoutWithdraw;

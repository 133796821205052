import React from 'react';
import CurrentAssignedLoan from '../components/CurrentAssignedLoan';
import Layout from '../components/Layout';
import ViewPayoutAgent from '../components/ViewPayoutAgent';

const ViewAssignedLoans = () => {
  return (
    <>
      <Layout>
        <div className="inner_banner text-center">
          <div className="container">
            In Process Loan Information and Status Details
          </div>
        </div>
        <div className="container-fluid">
          <div className="card p-4 shadow">
            <ul
              className="nav nav-pills nav-justified"
              id="pills-tab"
              role="tablist"
            >
              {/* <li className="nav-item">
                <a
                  className="border mx-5 mb-3 nav-link rounded-0 active"
                  id="pills-current-assigned-loans-tab"
                  data-toggle="pill"
                  href="#pill-current-assign"
                  role="tab"
                  aria-controls="pill-current-assign"
                  aria-selected="true"
                >
                  Current Assignment Loan
                </a>
              </li> */}
              {/* <li className="nav-item">
                <a
                  className="border mx-5 mb-3 nav-link rounded-0"
                  id="pills-payout-agent"
                  data-toggle="pill"
                  href="#pill-payout-agent"
                  role="tab"
                  aria-controls="pill-payout-agent"
                  aria-selected="false"
                >
                  View Payout of Agent
                </a>
              </li> */}
              {/* <li className="nav-item">
                <a
                  className="border mx-5 nav-link rounded-0"
                  id="pills-loan-others"
                  data-toggle="pill"
                  href="#pills-others"
                  role="tab"
                  aria-controls="pills-others"
                  aria-selected="false"
                >
                  Assign Loan to Others
                </a>
              </li> */}
            </ul>
            <div className="tab-content" id="pills-tabContent">
              <div
                className="tab-pane fade show active"
                id="pill-current-assign"
                role="tabpanel"
                aria-labelledby="pills-current-assign"
              >
                <CurrentAssignedLoan />
              </div>
              {/* <div
                className="tab-pane fade"
                id="pill-payout-agent"
                role="tabpanel"
                aria-labelledby="pills-payout-agent"
              >
                <ViewPayoutAgent />
              </div> */}
              <div
                className="tab-pane fade"
                id="pills-others"
                role="tabpanel"
                aria-labelledby="pills-loan-others"
              >
                ...
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
      </Layout>
    </>
  );
};

export default ViewAssignedLoans;
